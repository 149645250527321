<template>
  <div class="NavItemHot menu-item NavItemSolarTerms"
       :class="[ {'on': topbarMenuList[3].id == navIndex} ]"
       @mouseover="isShow = true"
       @mouseleave="isShow = false"
  >
    <a
      :href="solarTermsLink "
      @click.prevent="navigateToList">
      PPT

<!--      <i class="icon_NavItemHot icon_lidong"/>-->
    </a>

    <!-- 下拉  -->
    <div
      v-show="isShow"
      class="hot-combobox">
      <div class="solar-terms-title">
        <i class="icon_solar-terms"/>
        <div class="name">近期节气</div>
      </div>

      <div class="solar-terms-pic">
        <div
          v-for="vo in solarTermsList.current"
          :key="vo.pic_url + 'solar-terms-item'"
          class="solar-terms-item"
        >
          <a
            :href="vo.link_url"
            target="_blank"
            class="img-box">
            <img
              v-lazy="IMG_BASE_URL + '/' + vo.pic_url"
              :alt="vo.title"
              @click="trackingNavDrop('solarTerms',1,'click')"
            />
          </a>
          <span class="img-name">
            <span class="img-date">{{ vo.date | disposeDate }}</span>
            <span>· {{ vo.title }}</span>
          </span>
        </div>
      </div>


      <div class="more-solar-terms">
        <div class="title">更多节气</div>
        <div class="list">
          <a
            :href="vo.link_url"
            target="_blank"
            v-for="vo in solarTermsList.more"
            :key="vo.link_url +'solarTerms' "
            class="list-item __text-hover"
          >
            <span >
              {{ vo.title }}
            </span>
          </a>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {getDropdownByHot, getDropdownBySolarTerms} from "@/api/nav";
import {IMG_BASE_URL} from "@/constant/PATH";
import {topbarMenuList} from "@/components/common/header/module";
import {TrackingNav} from "@/tracking";
import {fnRouter} from "@/fn";
import {PAGE_PATH} from "@/constant/PAGE_PATH";

export default {
  name: "NavItemSolarTerms",
  data() {
    return {
      IMG_BASE_URL,
      hotData: {},
      isShow: false,
      solarTermsList: [],
      topbarMenuList
    }
  },
  props: {
    navIndex: {
      type: String | Number,
      default: -1
    }
  },
  computed:{
    solarTermsLink(){
      return fnRouter.getRouterLink(PAGE_PATH.TU_PIAN.LIST + '/0-3-0-0-0-0-default-1.html');
    }
  },
  mounted() {
    // this.init();
  },
  async fetch() {
    const { data } = await getDropdownBySolarTerms();
    this.solarTermsList = data.data;
  },
  methods: {
    init() {
      this.getData();
    },
    navigateToList() {
      let findItem = this.topbarMenuList.find(vo => vo.id === 3)
      this.$emit('navigateToList', findItem)
    },
    getData() {
      getDropdownBySolarTerms().then(res => {
        if (res.data.status == 1) {
          this.solarTermsList = res.data.data;
        }

      })
    },

    sliceList(list) {
      return list.length > 5 ? list.slice(0, 5) : list;
    },


    averageRecommendList(data) {
      let arr = [];
      for (let i = 0; i < data.length; i += 5) {
        arr.push(data.slice(i, i + 5));
      }
      return arr;
    },
    trackingNavDrop(act, pos, e) {
      TrackingNav.navDrop(act, pos, e)
    }


  },

  filters: {
    disposeDate(data) {
      let dateArr = data.split('-');
      return dateArr[1] + '月' + dateArr[2] + '日'
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../common/nav";
@import "../common/icon";

.__text-hover {
  @include ElementHover($color-primary, '');
}

.NavItemHot {
  position: relative;
  z-index: 99;

  > a {
    position: relative;

    .icon_NavItemHot {
      @include Position(1, 2, -2px, -10px);

      &.icon_dashu {
        @include BgImage(32px, 16px, 'header/nav/solar-term/icon_dashu.png');
      }

      &.icon_liqiu {
        @include BgImage(32px, 16px, 'header/nav/solar-term/icon_liqiu.png');
      }

      &.icon_ysj {
        @include BgImage(50px, 16px, 'header/nav/solar-term/icon_ysj.gif?v=1');
      }

      &.icon_chushu {
        @include BgImage(32px, 16px, 'header/nav/solar-term/icon_chushu.png?v=1');
      }


    }

    .icon_xiaoshu {
      @include BgImage(32px, 16px, 'header/nav/solar-term/icon_xiaoshu.svg');
      @include Position(1, 2, -2px, -10px);
    }
  }

  .hot-combobox {
    @include Position(1, 5, -20px, 40px);
    border-radius: 12px;
    padding: 31px 34px 18px;
    box-sizing: border-box;
    animation: fadeinT 0.5s 1;
    width: 730px;
    height: 518px;
    background: #FFFFFF;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.06), 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
    cursor: default;

    &:after {
      content: '';
      width: 10px;
      height: 10px;
      background-color: #FFFFFF;
      @include Position(1, 5, 56px, -4px);
      transform: rotate(135deg);
    }

    .solar-terms-title {
      display: flex;
      align-items: center;
      margin-bottom: 18px;

      .icon_solar-terms {
        @include BgImage(24px, 23px, 'header/nav/icon_solar-terms.png?v=1');
        margin-right: 12px;
      }

      > .name {
        font-size: 16px;
        font-weight: bold;
        color: #424866;
        line-height: 22px;
        letter-spacing: 1px;
      }
    }

    .solar-terms-pic {
      max-width: 486px;
      display: flex;
      flex-wrap: wrap;

      .solar-terms-item {
        margin: 0 26px 0 0;
        display: flex;
        flex-direction: column;

        &:nth-child(3n){
          margin-right: 0;
        }


        .img-box {
          display: inline-block;
          width: 132px;
          height: 172px;
          border-radius: 6px;
          overflow: hidden;

          img {
            width: 100%;
            vertical-align: middle;
            transition: all 0.5s ease;
          }

          @include ElementHover('', '') {
            img {
              transform: scale(1.1);
            }
          }
        }

        .img-name {
          display: inline-block;
          margin-top: 10px;
          margin-bottom: 16px;
          line-height: 19px !important;

          span {
            font-size: 14px;
            color: #0A2540;
            line-height: 19px;
          }

          .img-date {
            font-size: 14px;
            font-weight: bold;
            color: #00AA74;
          }
        }
      }
    }


    .more-solar-terms {
      width: 226px;
      height: 518px;
      background: linear-gradient(136deg, #F5F6FA 0%, #FFFFFF 100%);
      border-radius: 0px 12px 12px 0px;
      @include Position(1, 2, 0, 0);
      padding: 32px 0 0 22px;
      box-sizing: border-box;

      > .title {
        font-size: 16px;
        font-weight: bold;
        color: #424866;
        line-height: 22px;
        letter-spacing: 1px;
        margin-bottom: 18px;
        text-align: left;
        padding-left: 10px;

      }

      > .list {
        display: flex;
        flex-wrap: wrap;

        .list-item {
          width: 52px;
          height: 26px;
          line-height: 26px;
          text-align: center;
          //background: #EEEEFF;
          border-radius: 13px;
          margin: 0 12px 10px 0;

          &:nth-child(3n){
            margin-right: 0;
          }

          @include ElementHover('', '') {
            background: #EEEEFF;
          }
        }

      }
    }
  }

}


</style>
