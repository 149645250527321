<template>
  <!-- 时间轴图片列表 -->
  <div class="TimelinePic inner_center">
    <TimelinePicTomorrow/>
    <div
      v-for="(vo,key,i) in picData"
      :key="vo.id"
      class="timeline-pic-item"
    >
      <TimelinePicHeader :date="vo[0]"/>
      <PicList
        :pic-list="vo"
        v-bind="$attrs"
        v-on="$listeners"
        :isShowPicTitle="true"
      />
      <template v-if="key === today">
        <div
          class="more"
          @click="showMore"
        >查看更多上新素材</div>
      </template>
    </div>
  </div>
</template>

<script>
import TimelinePicHeader from './timeline-pic-header';
import TimelinePicTomorrow from './timeline-pic-tomorrow';
import PicList from '@/components/pic/pic-list/pic-list';
import {toolDate} from "@/tools";

export default {
  name: "TimelinePic",
  components: {
    TimelinePicHeader,
    TimelinePicTomorrow,
    PicList
  },
  props: {
    // 包含日期和图片的对象数据
    picData: {
      type: Object | Array,
      default: () => {
      },
      required: true
    }
  },
  data(){
    return{
      today:''
    }
  },
  mounted() {
    this.initDate();
  },
  methods: {
    initDate() {
      let {month, day, week_zn} = toolDate.getDate(toolDate.getNextDate(0));
      month = toolDate.fillZero(month)
      day = toolDate.fillZero(day)
      this.today = `${month}月${day}日`;
    },
    showMore(){
      this.$emit('showMore');
    }
  }
}
</script>

<style lang="scss" scoped>
.TimelinePic{
  .timeline-pic-item{
    //margin-bottom: 40px;
    margin-bottom: 16px;

    @include Screen(1){
      margin-bottom: 20px;
    }

    .more{
      width: 966px;
      line-height: 56px;
      background: linear-gradient(224deg, #FF6E90 0%, #D093FF 42%, #6969FF 100%, #6969FF 100%);
      border-radius: 14px;
      font-size: 17px;
      font-weight: bold;
      color: #FFFFFF;
      letter-spacing: 3px;
      text-align: center;
      margin: 0 auto;
      cursor: pointer;
      @include ElementHover('',linear-gradient(136deg, #FF6E90 0%, #D093FF 42%, #6969FF 100%, #6969FF 100%))
      margin-bottom: 40px;

    }
  }
}
</style>
