<!--
  跳转盒子
-->
<template>
  <div
    :class="['_pointer NavigationTo',type]"
    @click="navigatorTo"
  >
    <slot name="content"></slot>
  </div>
</template>

<script>
import {fnBrowser, fnRouter} from "~/fn";

export default {
  name: "NavigationTo",
  props:{
    path:{
      type: String,
      default:'',
      required: true,
    },
    type:{
      type: String,
      default: ''
    }
  },
  methods:{
    navigatorTo(){
      fnRouter.navigationTo(this.path)
    }
  }
}
</script>

<style lang="scss" scoped>
.NavigationTo{

  &.inline{
    display: inline-block;
  }
  &.block{
    display: block;
    width: 100%;
    height: 100%;
  }
}

</style>
