import request from '@/utils/request'
import qs from "qs";
import {trackInviteH5} from "@/api/invite-h5";

/* 热搜推荐
*   关键字状态 1 新 2 火 3 爆
* */
export function getHotwords(query) {
  return request({
    url: '/?m=Api&a=getHotwords',
    method: 'get',
    params: query
  })
}

/* 获取banner */
export function getBanner(query) {
  return request({
    url: '/?m=Api&a=indexBanner',
    method: 'get',
    params: query
  })
}

/* 首页导航 */
export function getMenuNavList(query) {
  return request({
    url: '/?m=Dropdown&a=keywords',
    method: 'get',
    params: query
  })
}

/* 导航菜单下拉埋点
method: GET
params: act : hot 全部热点, festival 节日, solarTerms 24节日
             pos: 默认为1, 全部热点时1-5为图片位置.从左到右,6 节日,7 24节气, 8 热门场景; 节气  节日下拉时 默认为1
             event: 默认为空. 点击时为click, 当为节日下拉时. tab切换点击传tab,详情点击传click
* */
export function navDropTracking(query) {
  return request({
    url: '/?m=StatApi&a=dropDown',
    method: 'get',
    params: query
  })
}


export function getHomeCalendarList(data) {
  return request({
    url: '/?m=Dropdown&a=calendar',
    method: 'get',
    params: data
  })
}

/* 下载次数引导 */
/* 获取8.8优惠券 */
export function getRmbEightCouponStatus(data) {
  return request({
    url: '/?m=Api&a=checkSpecialCoupon',
    method: 'get',
    params: data
  })
}

/* 获取8.8优惠券 */
export function getRmbEightCoupon(data) {
  return request({
    url: '/?m=Api&a=receiveCoupon',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 获取7天免费vip */
export function getSevenDayVip(data) {
  return request({
    url: '/?m=Api&a=experienceCard',
    method: 'get',
    params: data
  })
}

/* 新人弹框数据统计 */
export function trackNewUserDialog(data) {
  return request({
    url: '/?m=StatApi&a=newPersonal',
    method: 'get',
    params: data
  })
}


/* 为您推荐 ---- */
/*  搜索词接口 */
export function getSpiderWords(data) {
  return request({
    url: '/?m=Problem&a=spiderWord',
    method: 'get',
    params: data
  })
}

/* 首页推荐 */
export function getHomeTopicRecommend(data) {
  return request({
    url: '/?m=Api&a=indexTopic',
    method: 'get',
    params: data
  })
}

/* 首页图片点击位置统计 */
export function homePicPositionClickStat(data) {
  return request({
    url: '/?m=StatApi&a=homePage',
    method: 'post',
    data: qs.stringify(data)
  })
}


/* 敏感词提示 */
export function listenInput(data) {
  return request({
    url: '/?m=Auxfun&a=forbidWord',
    method: 'post',
    data: qs.stringify(data)
  })
}


/* 搜索全部下拉 */export function getDropDownByAll(data) {
  return request({
    url: '/?m=Dropdown&a=all',
    method: 'get',
    params: data
  })
}
