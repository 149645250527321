<template>
  <div class="partTimeIcon">
    <no-ssr>
      <transition :name="transitionName">
        <div
          class="FeedbackBuoy" v-if="isShow"
          @click="navTo"
        >
          <i class="icon_feedback"></i>
          <div class="txt-feedback">
            兼职<br>
            招募
          </div>
        </div>
      </transition>
    </no-ssr>
  </div>
</template>

<script>
import {PART_TIME} from "@/constant/ROUTE_PATH";

export default {
  name: "PartTimeIcon",
  props: {
    transitionName: {
      type: String,
      default: 'fade'
    }
  },
  data() {
    return {
      PART_TIME,
      isShow: false
    }
  },
  async mounted() {
    this.handleScroll()
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    document.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    navTo() {
      window.open(PART_TIME, '_blank');
    },
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.isShow = scrollTop > 300;
    }
  },
}
</script>

<style lang="scss" scoped>
.FeedbackBuoy {
  position: fixed;
  text-align: center;
  cursor: pointer;
  width: 55px;
  height: 55px;
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(10, 37, 64, 0.1);
  right: 32px;
  bottom: 240px;
  z-index: 99;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;

  .icon_feedback {
    @include BgImage(24px, 24px, 'feedback/part-time.png')
  }

  .txt-feedback {
    display: none;
  }

  @include ElementHover('', $color-primary) {
    box-shadow: 0 0 8px 0 rgba(10, 37, 64, 0.1);
    .icon_feedback {
      display: none;
    }
    .txt-feedback {
      display: block;
      color: #FFFFFF;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0
}

</style>
