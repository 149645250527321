/* 获取8.8优惠券 */
import request from "@/utils/request";
import qs from "qs";

/* 获取某一优惠券状态 */
export function getCouponState(data) {
  return request({
    url: '/?m=Coupon&a=checkCoupon',
    method: 'post',
    data: qs.stringify(data)
  })
}
