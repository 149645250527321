class FnLayout {
  constructor() {
  }


  /**
   * 设置底部布局颜色
   * @param color
   */
  setFooterBgColor(color='#FFFFFF') {
    document.getElementById('FooterWrap').style.background = color;
  }


}

export default new FnLayout();
