/**
 * @Author: wulizhou
 * @Description:  会场接口
 * @Date: 2023.4.17
 **/

import request from "~/utils/request";
import {trackNewUserDialog} from "~/api/home";
import qs from "qs";


/**
 * 会场详情
 * @param query id 会场id
 * @returns {AxiosPromise}
 */
export function getVenueDetail(query) {
  return request({
    url: '/?m=Venue&a=detail',
    method: 'get',
    params: query
  })
}

/**
 * 获取场馆收藏状态
 * @param query id 会场id
 * @returns {AxiosPromise}
 */
export function getVenueLikeStatus(query) {
  return request({
    url: '/?m=Venue&a=isTags',
    method: 'get',
    params: query
  })
}

/**
 * 切换会场收藏状态
 * @param query id 会场id
 * @returns {AxiosPromise}
 */
export function venueLikeToggle(query) {
  return request({
    url: '/?m=Venue&a=tags',
    method: 'get',
    params: query
  })
}


/**
 * 会场数据统计
 * @param data
 * @returns {AxiosPromise}
 */
export function dataTrackByVenue(data) {
  return request({
    url: '/?m=Venue&a=stat',
    method: 'post',
    data: qs.stringify(data)
  })
}
