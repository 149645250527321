<template>
  <div class="HomeSearch">
    <div
      class="search-box">
      <input
        v-model="searchKeyword"
        :placeholder="searchPlaceholder"
        class="search-text"
        id="HomeSearchInput"
        type="text"
        @focus="getHotwords(1,true)"
        @keyup.enter="toMaterialList"
        @input="input"
        @blur="closeSearchBox"
      >

      <!-- 清空按钮 -->
      <i
        v-if="searchKeyword.length"
        class="iconfont icon-cha _pointer"
        @click="clearSearch"
      />
      <div
        class="icon_search-box"
        @click="toMaterialList(0)">
        <i class="iconfont icon-chaxun2 _pointer"/>
      </div>
    </div>

    <div class="searchComboBox">
      <SearchComboBox
        ref="searchComboBox"
        :recommend-list="recommendList"
        :recommendList2="recommendList2"
        :history-list="historyList"
        @clearSearchHistory="clearSearchHistory"
        @selectSearchKeyword="selectSearchKeyword"
        @listenHotWordClick="listenHotWordClick"
      />
    </div>


    <!--    <div class="recommend-word" v-if="recommendList.length">
          大家都在搜：
          <a
            :href="vo.url"
            target="_blank"
            v-for="(vo,index) in recommendList.slice(0,7)"
            :key="index"
            class="recommend-item _pointer"
            @click.prevent="listenHotWordClick(vo)"
          >
            <span class="keyword">{{ vo.keyword }}</span>
          </a>
        </div>-->

  </div>
</template>

<script>
import {keywordConvertPinyin, setKeyword} from "@/api/material";
import {MATERIAL_LIST_PATH} from "@/constant/PATH";
import {fnFlowStatistics, fnMaterial, fnRouter} from "@/fn";
import {getHotwords, listenInput} from "@/api/home";
import {PROMOTION_POSITION} from "@/fn/FnFlowStatistics";
import {PIC_TOTAL_NUM} from "~/constant/MATERIALS";
import {getCookie} from "~/utils/auth";

/* 引入公共方法和常量 */
import * as fnCommon from '@/fn/_fn';
import {SearchComboBox} from "@/components/home/index.js"

export default {
  name: "HomeSearch",
  props: {
    /*    recommendList: {
          type: Array,
          default: () => []
        },
        recommendList2: {
          type: Array,
          default: () => []
        },
        historyList: {
          type: Array,
          default: () => []
        },*/


  },
  components: {
    SearchComboBox
  },
  watch: {},
  data() {
    return {
      searchKeyword: '',
      searchPlaceholder: '',
      // searchPlaceholder: '搜索全站150,000+创意设计',
      recommendList: [],
      recommendList2: [],
      historyList: [],
    }
  },
  computed: {
    // eslint-disable-next-line vue/no-dupe-keys
    /*searchPlaceholder () {
      setTimeout(()=>{
        let code  = parseInt(getCookie('MODEL_updateMaterialNum'));
        let temStr = code.toLocaleString()
        return `搜索全站${temStr}创意设计`
      },300)
    },*/
  },
  mounted() {
    this.initMaterialNum();
    this.initHistory();

    // this.handleScroll()
    window.addEventListener("scroll", this.handleScroll);

  },
  methods: {

    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if ( scrollTop > 300){
        this.$refs.searchComboBox.isShowCombobox = false;
        document.getElementById('HomeSearchInput').blur();
      }else{

      }

    },

    initHistory() {
      let list = this.$localStorage.getItem('searchKeyword');
      if (list != undefined) {
        this.historyList = list
      }
    },

    async initMaterialNum() {
      let num = await fnMaterial.getMaterialNum();
      this.searchPlaceholder = `搜索全站${num}+创意设计`
    },
    clearSearch() {
      this.searchKeyword = ''
      this.$store.commit('base/SET_SEARCH_VAL', '');
    },

    clearSearchHistory() {
      this.historyList = []
      this.toggleShowCombobox(1, false)
    },
    /* 搜索下拉框选择 */
    selectSearchKeyword(val) {

      this.searchKeyword = val;
      this.$store.commit('base/SET_SEARCH_VAL', val);
      this.toMaterialList();
    },


    /* 跳转素材列表页 */
    async toMaterialList(t) {
      await setKeyword({"keyword": this.searchKeyword});

      // let tempKeyword = this.searchKeyword || this.searchPlaceholder;  // 待后台设置搜索关键词开启
      let tempKeyword = this.searchKeyword;  // 待后台设置搜索关键词开启
      this.saveSearchHistory(tempKeyword);     /* 保存搜索记录 */
      /* 获取搜索词 */
      let pinyinData = await keywordConvertPinyin({keyword: tempKeyword});
      let {status, pinyin} = pinyinData.data;
      let tempPinyin = (status === 1 && pinyin !== '') ? pinyin : '';
      let path = tempPinyin === '' ? MATERIAL_LIST_PATH : MATERIAL_LIST_PATH + `/${tempPinyin}.html`;
      this.$store.commit('nav/SET_NAV_INDEX', 0)

      // let flag = isTupianListPage(this.$route.path) ? '_self': '_blank';
      fnRouter.navigationTo(path, '_self');
      this.$emit('toMaterialList', path);
    },

    async getHotwords(type, flag) {
      /*let res = await getHotwords({module: 1})
      this.recommendList = res.data.data;
      await fnFlowStatistics.promotion(PROMOTION_POSITION.TOP, 0)*/


      let res = await getHotwords({module: 1})
      this.recommendList = res.data.data;

      let res2 = await getHotwords({module: 2})
      this.recommendList2 = res2.data.data;
      console.log(this.recommendList2)
      this.$refs.searchComboBox.isShowCombobox = true
      // await fnFlowStatistics.promotion(PROMOTION_POSITION.TOP, 0)


    },

    closeSearchBox() {
      setTimeout(() => {
        this.$refs.searchComboBox.isShowCombobox = false;
      }, 500)
    },

    listenHotWordClick(vo) {
      this.$store.commit('base/SET_SEARCH_VAL', vo.keyword);
      this.$emit("listenHotWordClick", vo)
      fnRouter.navigationTo(vo.url, '_self')
    },

    /* 保存搜索记录 */
    saveSearchHistory(searchKeyword) {
      searchKeyword = this.$utils.string.trim(searchKeyword);
      if (searchKeyword !== '') {
        this.findStorage('searchKeyword', this.searchKeyword, 'array');
      }
    },

    /* 查找本地搜索关键词，有添加,无则创建 */
    findStorage(key, val, type) {
      if (type === 'array' || type === 1) {
        let temp = this.$localStorage.getItem(key);
        if (!temp) {
          let arr = [];
          arr.push(val);
          this.$localStorage.setItem('searchKeyword', arr);
          this.historyList = arr
        } else {
          temp = temp.filter(vo => vo !== val);
          temp.unshift(val);
          this.$localStorage.setItem('searchKeyword', temp);
          this.historyList = temp
        }
      }
    },


    input() {
      fnCommon.fnBasic.listenSearchInput(this.searchKeyword)
    }
  }
}
</script>

<style lang="scss" scoped>

.HomeSearch {
  width: 870px;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0px 0px 0px 3px rgba(251, 247, 255, 0.2);
  border-radius: 32px;
  position: relative;
  z-index: 7;
}

.searchComboBox {
  /deep/ .search-combobox {
    position: relative;
    z-index: 8;
    //box-shadow:none;
    border-radius: 0 0 12px 12px;
    top: -30px;
    padding-top: 54px !important;
  }
}

.search-box {
  position: relative;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  border-radius: 32px;
  z-index: 10;


  .search-text {
    //width: 172px;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    padding: 0 120px 0 32px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.14);
    font-size: 15px;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 0px 0px 3px rgba(251, 247, 255, 0.2);
    border-radius: 32px;
    color: #001013;

    &::placeholder {
      color: rgba(0, 16, 19, 0.3);
      font-size: 15px;

    }

    &:focus {
      //background: rgba(255, 255, 255, 0.34);
      box-shadow: 0px 0px 0px 3px rgba(251, 247, 255, 0.3);

      box-shadow: 0px 0px 0px 3px rgba(251, 247, 255, 0.2);
      border-radius: 32px;
      border: 1px solid #E0E3E9;
    }
  }

  .icon-cha {
    @include Position(1, 2, 120px, 50%);
    transform: translateY(-50%);
    font-size: 14px;
    color: rgba(0, 0, 0, 0.3);
    @include ElementHover(rgba(0, 0, 0, 0.5), '');
  }

  .icon_search-box {
    display: inline-block;
    @include Position(1, 2, 0, 50%);
    transform: translateY(-50%);
    @include LayoutFlex(1);
    width: 88px;
    //height: 56px;
    height: 100%;
    background: linear-gradient(224deg, #D093FF 0%, #6969FF 100%, #6969FF 100%);
    border-radius: 28px;
    transition: all 0.5s ease-in-out;

    &:hover {
      background: linear-gradient(224deg, #6969FF 0%, #D093FF 100%);
    }


    //.icon-search {
    //  @include BgImage(18px, 18px, "home/images/icon-search.svg", 2);
    //}
    .icon-chaxun2 {
      font-size: 24px;
      color: #FFFFFF;
    }


  }

}

.recommend-word {
  font-size: 13px;
  color: #FFFFFF;
  line-height: 17px;
  margin-top: 10px;
  padding-left: 32px;

  .recommend-item {
    margin-right: 12px;
    color: #FFFFFF;

    @include ElementHover(rgba(255, 255, 255, 0.8), '')

  }
}
</style>
