<template>
  <div class="ReceiveCompanyCouponDialog tip-dialog-wrap">
    <CgModalMiddle
      v-if="isShowFreeDownload"
      @close="isShowFreeDownload = false">
      <div
        slot="modal_content"
        class="tip-dialog">

        <div class="account-type">
          <div class="name">
            当前帐号 <br>
            {{ downloadWay == 'BUSINESS' ? '仅限个人商用授权': '仅限非盈利用途使用' }}
          </div>
          <div class="desc">

            <template v-if="downloadWay === 'BUSINESS'">
              若为您就职单位、公司使 <br>
              用，请购买企业VIP
            </template>
            <template v-else>
              仅供个人学习交流使用， <br>
              不可商用使用。 <br>
              须署名：潮国创意
            </template>



          </div>
        </div>

        <div class="coupon-info">
          <div class="name">您有一张企业畅享券</div>
          <div class="coupon _pointer"
               :class=" [ couponReceiveState ? 'ON': 'NO' ] "
               @click.prevent="receiveCoupon"
          ></div>

          <div class="scene-hr scene-hr-team">
            <span class="line"></span>
            <div class="scene-text">
              {{ '尊享企业授权' }}
            </div>
            <span class="line line-r"></span>
          </div>
          <div class="equity-list">
            <div
              v-for="(vo,idx) in TU_PIAN.RECEIVE_COMPANY_COUPON.EQUITY_LIST"
              :key="idx + 'company_coupon'"
              class="equity-item">
              <i :class="'icon'+idx" />
              <span class="label">{{ vo.label }}</span>
            </div>
          </div>

        </div>



      </div>
    </CgModalMiddle>
  </div>
</template>

<script>
import RollMsgButton from '@/components/common/header/header-vip-person/roll-msg-btn'
import {TU_PIAN} from "@/constant/TU_PIAN";
import {getRmbEightCoupon} from "@/api/home";
import {fnGuideDownloadCount, fnRouter} from "@/fn";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {getCouponState} from "@/api/coupon";
import {VIP_STATE} from "@/constant/VIP";
import {getCookie} from "@/utils/auth";
import {HOME} from "@/constant/HOME";
export default {
  name: "ReceiveCompanyCouponDialog",
  data() {
    return {
      isShowFreeDownload:false,
      TU_PIAN
    }
  },
  props:{
    downloadWay: {
      type: String,
      default: ''
    },
    couponReceiveState: {
      type: Boolean,
      default: false
    }
  },
  watch:{
    isShowFreeDownload:{
      handler(n,o){
        if (n == true){
          fnGuideDownloadCount.trackNewUser(HOME.NEW_USER_COUPON_TYPE.COMPANY_ENJOY,HOME.NEW_USER_COUPON_TYPE.POSITION.CURRENT_PAGE)

        }
      }
    }
  },
  components:{
    RollMsgButton
  },
  mounted() {
    /*getCouponState({
      couponId:7
    }).then(res =>{
      let { data, status } = res.data;
      console.log(data,status)
      if (status === 200){
        if (data.num == 0){
          console.log('未领取 95折 优惠券')
          let personVipState = getCookie('personVipState')
          let businessVipState = getCookie('businessVipState')
          let companyVipState = getCookie('companyVipState')

          if (businessVipState == VIP_STATE.ON){
            this.$refs.ReceiveCompanyCouponDialog.isShowFreeDownload = true;
            this.downloadWay = 'BUSINESS'
            return false
          }else if (personVipState == VIP_STATE.ON){
            this.$refs.ReceiveCompanyCouponDialog.isShowFreeDownload = true;
            this.downloadWay = 'PERSON'
            return false
          }else{
            this.$refs.ProfitModal.isShowFreeDownload = true
          }
        }else{
          console.log('已领取 95折 优惠券')
        }
      }
    })*/
  },
  methods: {
    navigatorToPay() {
      this.$emit('navigatorToPay')
    },
    limitTag() {
      this.$emit('limitTag')
    },
    know(){
      this.isShowFreeDownload = false;
      this.$emit('know')
    },

    receiveCoupon(){
      getRmbEightCoupon({ couponId:7 }).then(res =>{


        if (res.data.status == 1){

          this.$myNotify.success( '领取成功',()=>{
            this.isShowFreeDownload = false;
            fnGuideDownloadCount.trackNewUser(HOME.NEW_USER_COUPON_TYPE.COMPANY_ENJOY,HOME.NEW_USER_COUPON_TYPE.POSITION.TO_PAY_PAGE)

            fnRouter.navigationTo(PAGE_PATH.PAYMENT.COMPANY)
          },2000)

        }else if (res.data.status == -1){

          this.isShowFreeDownload = false;
          fnGuideDownloadCount.trackNewUser(HOME.NEW_USER_COUPON_TYPE.COMPANY_ENJOY,HOME.NEW_USER_COUPON_TYPE.POSITION.TO_PAY_PAGE)
          fnRouter.navigationTo(PAGE_PATH.PAYMENT.COMPANY)

        }else if (res.data.status == -2){

          this.$myNotify.show(res.data.msg,()=>{
            this.isShowFreeDownload = false;
            // fnRouter.navigationTo(PAGE_PATH.PAYMENT.COMPANY)
          },2000)

        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.tip-dialog-wrap {
  /deep/ .dialog-cover {
    z-index: 990 !important;
  }

  /deep/ .dialog-content {
    z-index: 991 !important;
  }
  /deep/ .Modal-close{
    top: 14px !important;
    right: 10px !important;
  }
}
@mixin IconLogo($index,$count) {
  @for $i from $index through $count {
    .icon#{$i} {
      @include BgImage(32px,32px,'tupian/company-coupon-dialog/icon00#{$i+1}.png');
    }
  }
}



.tip-dialog {
  width: 655px;
  height: 424px;
  background: #FFFFFF;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include BgImage(655px,424px,'tupian/company-coupon-dialog/coupon%40bg.png');
  position: relative;

  .account-type{
    @include Position(1,5,40px,99px);
    .name{
      font-size: 16px;
      font-weight: bold;
      color: #424866;
      line-height: 24px;
      margin-bottom: 10px;
    }
    .desc{
      font-size: 14px;
      color: #424866;
      line-height: 22px;
    }
  }

  .coupon-info{
    width: 435px;
    height: 396px;
    @include Position(1,2,10px,14px);
    .name{
      padding-left: 133px;
      font-size: 20px;
      color: #2D2B33;
      line-height: 26px;
      margin: 47px 0 31px;
    }

    .coupon{
      height: 96px;
      margin-bottom: 14px;

      &.ON{
        @include BgImage(239px,137px,'tupian/company-coupon-dialog/coupon@reveive.png');
        margin-left: 108px;

      }

      &.NO{
        @include BgImage(212px,137px,'tupian/company-coupon-dialog/coupon@no-reveive.png');
        margin-left: 123px;

      }
    }

    .scene-hr {
      font-size: 14px;
      color: #71758C;
      line-height: 20px;
      text-align: center;
      display: flex;
      align-items: center;
      //justify-content: space-between;
      //margin: 24px 0 14px;
      margin-left: 118px;

      .scene-text {
        padding: 0 14px;
        white-space: nowrap;
        font-size: 15px;
        color: #233E90;
        line-height: 20px;
      }

      .line {
        display: inline-block;
        width: 56px;
        height: 1px;
        background: linear-gradient(45deg, rgba(63,93,185,0) 0%, #233E90 100%);
        border-radius: 2px
      }
      .line-r{
        background: linear-gradient(225deg, rgba(63,93,185,0) 0%, #233E90 100%);
      }
    }

    .equity-list{
      display: flex;
      align-items: center;
      margin: 28px 0 0 57px;

      .equity-item{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include IconLogo(0,3);
        margin-right: 40px;
        &:last-child{
          margin-right: 0;
        }

        .label{
          margin-top: 6px;
          font-size: 13px;
          color: #39529B;
          line-height: 17px;
        }

      }
    }
  }

}
</style>
