<template>
  <div class="home-layout">
    <no-ssr>
      <ActGuoQingBar />
    </no-ssr>
<!--    <ActTwelveTopBar />-->
<!--    <actBar />-->
    <nuxt/>
    <GlobalSlot/>
    <HomeFooter/>
    <no-ssr>
      <Login/>
      <!-- <actFloatBar /> -->
    </no-ssr>

    <Sidebar/>

    <PublicSlot/>
  </div>
</template>

<script>
import {LoginHome, Login} from "@/components/common/index.js"
import {TopUpHomeBar} from "@/components/act/top-up";
import FeedbackBuoy from '@/components/common/feedback-buoy/feedback-buoy'
import Sidebar from '@/components/common/sidebar'
import GlobalSlot from '@/components/common/global-slot';
import HomeFooter from '@/components/home/home-footer'
import ActGuoQingBar from "@/components/act/guoqing/ActGuoQingBar";
import PublicSlot from "@/components/_Public/PublicSlot";
// import actBar from "@/pages/act/618/actBar/index.vue";
// import actFloatBar from "@/pages/act/618/actBar/bar.vue";
// import ActTwelveTopBar from "@/components/_Privately/Act/Act202312/ActTwelveTopBar";

export default {
  name: "Home",
  components: {
    // actBar,
    // actFloatBar,
    Login,
    LoginHome,
    TopUpHomeBar,
    FeedbackBuoy,
    Sidebar,
    GlobalSlot,
    HomeFooter,
    ActGuoQingBar,
    PublicSlot,
    // ActTwelveTopBar
  },
}
</script>
