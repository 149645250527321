import * as VIP from "@/constant/VIP"
import {BUSINESS_VIP_INFO, BUSINESS_VIP_MONTH_INFO, PERSON_VIP_INFO} from "@/constant/VIP";

/* 会员权限 */
let permission = [
  {
    isPer: true,
    desc: '尊享商用授权'
  },
  {
    isPer: true,
    desc: '普通海量下载'
  },
  {
    isPer: true,
    desc: '在线申请发票'
  },
  {
    isPer: true,
    desc: '图片版权保障'
  },
  {
    isPer: true,
    desc: '新媒体用途'
  },
  {
    isPer: true,
    desc: '线上推广'
  },
  {
    isPer: true,
    desc: '电商平台'
  },
  {
    isPer: true,
    desc: '户外广告'
  },
  {
    isPer: true,
    desc: '传媒影视'
  },
  {
    isPer: true,
    desc: '线下印刷'
  },
  {
    isPer: true,
    desc: '图书出版'
  },
]

/* 套餐列表 */
export const memberList = [
  {
    title: VIP.BUSINESS_VIP_INFO.FULL_NAME,
    price: VIP.BUSINESS_VIP_INFO.PRICE,
    priceDesc: VIP.BUSINESS_VIP_INFO.DESC,
    class: 'business',
    permission: permission,
    permissionArr: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    id: VIP.BUSINESS_VIP_INFO.ID,
    time: VIP.BUSINESS_VIP_INFO.VALID_TIME
  },
  {
    title: VIP.BUSINESS_VIP_MONTH_INFO.FULL_NAME,
    price: VIP.BUSINESS_VIP_MONTH_INFO.PRICE,
    priceDesc: VIP.BUSINESS_VIP_MONTH_INFO.DESC,
    class: 'business',
    permission: permission,
    permissionArr: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    id: VIP.BUSINESS_VIP_MONTH_INFO.ID,
    time: VIP.BUSINESS_VIP_MONTH_INFO.VALID_TIME,
  },

  {
    title: VIP.PERSON_VIP_INFO.FULL_NAME,
    price: VIP.PERSON_VIP_INFO.PRICE,
    priceDesc: VIP.PERSON_VIP_INFO.DESC,
    class: 'person',
    permission: permission,
    permissionArr: [0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0],
    id: VIP.PERSON_VIP_INFO.ID,
    time: VIP.PERSON_VIP_INFO.VALID_TIME,
  }
]
