export default {
  namespaced: true,
  state(){
    return {
      isShowGuideDownloadCount: false,    // 首页引导下载次数弹框
    }
  },

  mutations: {
    SET_IS_SHOW_GUIDE_DOWNLOAD_COUNT(state, payload) {
      state.isShowGuideDownloadCount = payload;
    },
  },
  actions: {},
}
