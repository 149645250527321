/*
*   model框 + date日期命名
*   命名规则 必须已 _MODEL_ 开头, 后续使用匹配清除过期localstorage
* */
export const prefix = 'MODEL_';

export const MODEL_UPDATE_BY_DAY = prefix + 'updateByDay320'; // 每日更新
export const MODEL_BIND_IPHONE = prefix + 'bindIphone'; // 绑定手机
export const MODEL_USER_SURVEY = prefix + 'userSurvey'; // 用户问卷
export const MODEL_UPDATE_DOWNLOAD_COUNT = prefix + 'updateDownloadCount320'; // 日更新用户累计下载次数
export const MODEL_UPDATE_USER_VIP = prefix + 'updateUserVip320'; // 日更新用户会员
export const MODEL_EXPIRATION_TIME = prefix + 'expirationTime'; // 过期时间
export const MODEL_PV_COUNT = prefix + 'pvCount'; // pv_count
export const MODEL_LOGIN_PV_COUNT = prefix + 'loginPvCount'; // loginPvCount
export const MODEL_HOME_BAR = prefix + 'homeBar'; // 顶部bar
export const MODEL_HOME_Dialog = prefix + 'homeDialog'; // 首页dialog
export const MODEL_LIST_BAR = prefix + 'listBar'; // 列表页ba
export const MODEL_COLLECT_DESKTOP_BAR = prefix + 'collectDesktopBar'; // 列表页bar
export const MODEL_PIC_ID = prefix + 'picId'; // 列表页bar
export const MODEL_PIC_ID_STATE = prefix + 'picIdState'; // 列表页bar

export const MODEL_NOTICE_HOME = prefix + 'noticeHome'; //
export const MODEL_NOTICE_USER = prefix + 'noticeUser'; //

export const MODEL_LOGIN_GUIDE = prefix + 'loginGuide'; // 登录引导
export const MODEL_NEW_USER_GIFT = prefix + 'newUserGift'; // 登录引导
export const MODEL_NEW_USER_GIFT_DAY = prefix + 'newUserGiftDay'; // 控制是否展示
export const MODEL_DOWNLOAD_COUNT_GUIDE = prefix + 'downloadCountGuide'; // 登录引导
export const MODEL_VIP_RENEWAL = prefix + 'vipRenewal'; // 会员续费

export const MODEL_COLLECT_BAR = prefix + 'collectBar'; // 收藏
export const MODEL_COMPANY_HOME_TAGLINE = prefix + 'companyHomeTagline'; // 企业后台首页宣传图
export const MODEL_COMPANY_GUIDE = prefix + 'companyGuide'; // 企业后台引导

export const MODEL_TUPIAN_NEW_USER_COUPON_BAR = prefix + 'tupianNewUserCouponBar'; // 列表页底部新人优惠券bar
export const MODEL_TUPIAN_NEW_USER_COUPON_TOP = prefix + 'tupianNewUserCouponTop'; // 列表页底部新人优惠券bar
export const MODEL_TUPIAN_CASE_COLLECTION_BAR = prefix + 'tupianCaseCollectionBar'; // 列表页底部新人优惠券bar
export const MODEL_TUPIAN_VENUE_BAR = prefix + 'tupianVenueBar2'; // 列表页会场bar
export const MODEL_UPDATE_COUPON_BY_DAY = prefix + 'updateCouponByDay'; // 每日更新一次优惠券

export const MODEL_INTRODUCE_DIALOG = prefix + 'introduceDialog'; // 每日一次显示登录弹框
export const MODEL_SWITCH_ACCOUNT_TYPE = prefix + 'switchAccountType'; // 点击切换账号类型

export const MODEL_NEW_YEAR_ACT = prefix + 'newYearAct'; // 新年活动

export const MODEL_WEBSITE_RATING = prefix + 'websiteRating'; // 网站评分
export const MODEL_ACT_COMPANY_PAY = prefix + 'actCompanyPay'; // 网站评分
export const MODEL_ACT_COMPANY_PAY_FB = prefix + 'actCompanyPayFb'; // 网站评分

export const MODEL_ACT_DETAIL_RED_COUPON = prefix + 'actDetailRedCoupon'; // 天降红包活动

export const MODEL_CREATOR_AUTH = prefix + 'creatorAuth'; // 天降红包活动
export const MODEL_GUO_QING_FLOAT = prefix + 'guoQingFloat'; // 国庆活动活动浮框
export const MODEL_GUO_QING_BUOY = prefix + 'guoQingBuoy'; // 国庆活动活动浮标
export const MODEL_GUO_QING_BUOY_NEW = prefix + 'guoQingBuoyNew'; // 国庆活动活动浮标


export const MODEL_ACT_LOGIN_COUPON_BAR = prefix + 'actLoginCouponBar'; // 国庆活动活动浮标

export const MODEL_ACT_TWELVE_BAR = prefix + 'actTwelveBar'; // 双十二bar
export const MODEL_USER_CHECK_PROTOCOL = prefix + 'userCheckProtocol'; // 双十二bar
export const MODEL_ACT_618 = prefix + 'act618'; // 双十二bar
export const MODEL_ACT_618_BAR = prefix + 'act618Bar'; // 双十二bar


