import {fnLogin} from "~/fn";

class FnUserSurvey {
  constructor() {

  }

  /* 检查是否填写 */
  navTo(path) {
    window.open(path, '_blank');
  }


  /* 判断是否是爬虫页 */
  isSpider(UA) {
    let keys = [
      'tool',
      'curl',
      'jiankongbao',
      'monitor',
      'duckduckgo',
      'archive',
      'search',
      'bot',
      'crawl',
      'slurp',
      'spider',
      'mediapartners',
      'megaindex',
      'bubing',
    ];
    let isSpider = false;
    console.log(UA)
    try {
      for (let i = 0; i < keys.length; i++) {
        if (UA.toLowerCase().indexOf(keys[i].toLowerCase()) > -1) {
          isSpider = true;
          break;
        }
      }
    } catch {

    }
    return isSpider
  }


  backToTop(end,step = 50){ //回到顶部方法 点击按钮调用
    let top = document.documentElement.scrollTop;

    /* 判断目标距离和浏览器位置 */
    if ( top > end ){
      const timeTop = setInterval(() => {
        document.documentElement.scrollTop = top -= step;
        if (top <= end) {
          document.documentElement.scrollTop = end
          clearInterval(timeTop); //清除定时器
        }
      }, 10);

    }else{
      const timeTop = setInterval(() => {
        document.documentElement.scrollTop = top += step;
        if (top >= end) {
          document.documentElement.scrollTop = end
          clearInterval(timeTop); //清除定时器
        }
      }, 10);

    }




  }

}

export default new FnUserSurvey();
