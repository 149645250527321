<template>

  <div
      v-show="isShowCombobox"
    class="HeaderLoginUserCombobox">
    <template v-if="companyInfoByPerson.is_company_member === 1">
      <HeaderSwitch
        :companyInfoByPerson="companyInfoByPerson"
        @toggleCompany="toggleCompany"
        @loginOut="loginOut"
      />
    </template>

    <template v-if="companyInfoByPerson.is_company_member !== 1">
      <div
        class="text-login-out _pointer "
        @click="loginOut">
        退出
      </div>
    </template>

    <template>
      <HeaderLoginUserComboboxPerson
        v-show="(companyInfoByPerson.switch == null ||
        (companyInfoByPerson.switch && companyInfoByPerson.switch.type == COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.PERSON))
        || companyInfoByPerson.is_company_member === 0"/>

      <HeaderLoginUserComboboxCompany
        v-show="(companyInfoByPerson.switch && companyInfoByPerson.switch.type== COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.COMPANY) "
      />
    </template>
  </div>
</template>

<script>
//页面引入swiper
import {mapGetters, mapState} from "vuex";
import {
  MEMBER_LICENSE_VIP,
  USER_CENTER,
  USER_CENTER_COLLECT,
  USER_CENTER_DOWNLOAD,
  USER_CENTER_USERINFO,
  INVITE_FRIENDS, PAY_BUSINESS, PAY_PERSON, PAY, COMPANY_INTRODUCE
} from "@/constant/PATH";
import {tag} from "@/utils/statUtil";
import {BUSINESS_VIP_INFO, PERSON_VIP_INFO} from "@/constant/VIP";
import {DEVICE} from "@/constant/SYSTEM";
import _ from 'lodash'
import Clipboard from "clipboard";
import VipLabel from '@/components/user/vip-label';
import {VIP_STATE} from "@/constant/VIP";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import VipNotification from '@/components/user/vip-notification/indexV2'
import {fnCompany, fnInviteFriend, fnRouter} from "~/fn";

import HeaderLoginDevice from '@/components/common/header/header-login/header-login-device'
import {COMMON} from "@/constant/COMMON";

import HeaderLoginUserComboboxPerson from './person';
import HeaderLoginUserComboboxCompany from './company'
import {getCompanyInfoByPerson, switchMainAccount} from "@/api/company/home";
import HeaderSwitch from '@/components/common/header/header-switch'

export default {
  name: "HeaderLoginUserCombobox",
  components: {
    VipLabel,
    VipNotification,
    HeaderLoginDevice,
    HeaderLoginUserComboboxPerson,
    HeaderLoginUserComboboxCompany,
    HeaderSwitch
  },
  data() {
    return {
      list: COMMON.HEADER.AVATAR_COMBOBOX.USER_CENTER_NAV_LIST,
      isShowCombobox: false,
      MEMBER_LICENSE_VIP,
      USER_CENTER,
      INVITE_FRIENDS,
      PERSON_VIP_INFO,
      BUSINESS_VIP_INFO,
      DEVICE,
      PAY_PERSON,
      PAY_BUSINESS,
      VIP_STATE,
      PAY,
      COMPANY_INTRODUCE,
      PAGE_PATH,
      accountType: COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.PERSON,
      isHasCompany: true,
      COMMON,
    }
  },
  computed: {
    ...mapGetters('user',
      ['name', 'uid', 'avatar', 'uniq_id', 'vipIsPerson', 'vipIsPersonData', 'vipIsBusiness', 'vipIsBusinessData', 'downloadCount']),
    ...mapState('system', ['device']),
    ...mapState('user',
      [
        'personVipState',
        'personVipInfo',
        'businessVipState',
        'businessVipInfo',
        'companyVipState',
        'companyVipInfo',
        'userInfo',
        'companyInfoByPerson',
      ],
    ),
  },
  watch: {
    /*companyInfoByPerson:{
      handler(n,o){
        if (n.switch.type == 1){
          /!* 如果路由在企业,则返回首页 *!/
          let companyRouterList = fnCompany.getCompanyRouterList()
          if(companyRouterList.indexOf(this.$route.path) >-1){
            // fnRouter.routerTo('/')
          }
        }
      },
      deep: true
    }*/
  },

  mounted() {
    // this.getCompanyInfoByPerson();
  },
  methods: {

    toggleCompany() {
      let type;

      if (this.companyInfoByPerson.switch == null) {
        type = COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.PERSON
      } else if (this.companyInfoByPerson.switch && this.companyInfoByPerson.switch.type) {
        type = this.companyInfoByPerson.switch.type
      }
      type = type == COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.PERSON ? COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.COMPANY : COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.PERSON

      let params = {
        type: type
      }

      if (this.type === COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.COMPANY) {
        params.mainid = 0
      }


      switchMainAccount(params).then(res => {
        console.log(res)
        if (res.data.status === 200) {
          this.$store.dispatch('user/getCompanyInfoByPerson')
          this.$store.dispatch('user/getUserInfo');

          this.$store.commit('user/SET_SWITCH_ACCOUNT_TYPE', true)
        }
      })
    },
    navigationTo(path) {
      this.$router.push(path);
    },
    loginOut() {
      this.$store.dispatch('user/loginOut');
      this.$emit('loginOut')
    },
    tag(stat) {
      tag(stat)
    },

    copyUid: _.throttle(function () {
      let clipboard = new Clipboard('#ClipboardBtn')
      clipboard.on('success', e => {
        this.$myNotify.success("复制成功")
        // this.updateCopyStatus(1)
        // setTimeout(() => {
        //   this.updateCopyStatus(0)
        // }, 2000)

        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        this.$myNotify.show('该浏览器不支持自动复制')
        // 释放内存
        clipboard.destroy()
      })
    }, 2000),

    filterTime(val) {
      return val.slice(0, 10) || ''
    },

    navigationToInvite() {
      fnInviteFriend.inviteFriendEntry(7);
    },


    /* 获取当前是何类型账户 */
    getCompanyInfoByPerson() {

      getCompanyInfoByPerson().then(r => {
        /*let {data, code} = r.data;
        if (code == 200) {
          console.log(data)
        }*/
        console.log(r.data.data)

        this.companyInfoByPerson = r.data.data;

      })
    },
  },
  // eslint-disable-next-line vue/order-in-components
  filters: {
    filterTime(val) {
      return val.slice(0, 10) || ''
    }
  }
}
</script>

<style lang="scss" scoped>
.HeaderLoginUserCombobox {
  position: relative;

  .text-login-out {
    @include Position(1, 2, 28px, 28px);
    font-size: 14px;
    color: #6969FF;
    line-height: 20px;
    text-decoration: underline;
    z-index: 12;

  }
}

</style>
