import Cookies from 'js-cookie'
import {localCookies} from "@/constant/COOKIES";

const TokenKey = 'Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

/* 设置自定义cookie */
export function setCustomCookie(key, value) {
  /*Cookies.set(key, value, {
    domain: '.chaopx.com',
    expires: 7
  });*/
  Cookies.set(key, value,{
    expires: 7
  });
}

export function getCookie(key) {
  return Cookies.get(key)
}

export function setCookie(key, value) {
  return Cookies.set(key, value)
}

export function removeCookie(value) {
  if (Array.isArray(value)) {
    value.forEach(cookie => {
      Cookies.remove(cookie);
    })
  } else {
    Cookies.remove(value);
  }
}








