<template>
  <div class="TimelinePicHeader">
    <div class="headline">
      <span class="headline-date">{{ date.week }}</span>
      <span class="headline-time">{{ date.date }}</span>
    </div>
    <div
      v-if="isShowMore"
      class="more" @click="navigatorTo(PAGE_PATH.TUPIAN_LIST + '/0-0-0-0-0-0-0-new-1.html')">
      查看更多
      <i class="iconfont icon-a-arrowright"/>
    </div>
  </div>
</template>

<script>
import {fnBrowser} from "@/fn";
import {PAGE_PATH} from "@/constant/PAGE_PATH";

export default {
  name: "TimelinePicHeader",
  props:{
    date:{
      type: Object,
      default:()=>{},
      required: true
    },
    isShowMore:{
      type: Boolean,
      default: true
    }
  },
  data(){
    return{
      PAGE_PATH
    }
  },
  methods:{
    navigatorTo(path){
      fnBrowser.navTo(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.TimelinePicHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .headline {
    //margin-bottom: 24px;
    line-height: 42px;
    width: 100%;

    .headline-date {
      font-size: 32px;
      font-weight: bold;
      color: #2D2B33;
      letter-spacing: 1px;
    }

    .headline-time {
      font-size: 14px;
      color: rgba(0, 16, 19, 0.45);
      line-height: 20px;
      margin-left: 9px;

    }
  }

  .more {
    flex-shrink: 0;
    font-size: 14px;
    color: #424866;
    display: flex;
    align-items: center;

    .icon-a-arrowright {
      font-size: 20px;
      margin-left: 4px;
    }

    @include ElementHover($color-primary, '') {
      //text-decoration: underline;

    }
  }
}
</style>
