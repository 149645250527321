import request from "@/utils/request";
import qs from "qs";

/**
 * @Author: wulizhou
 * @Description:  粉丝俱乐部API
 * @Date:
 **/

/* 周签到进度 */
export function getSignListByWeek(query) {
  return request({
    url: '/?m=Activity&a=checkIsSign',
    method: 'get',
    params: query
  })
}


/* 当日签到 */
export function signByWeek(query) {
  return request({
    url: '/?m=Activity&a=signIn',
    method: 'get',
    params: query
  })
}

/* 获取活动信息 */
export function getActInfo(query) {
  return request({
    url: '/?m=Activity&a=reward',
    method: 'get',
    params: query
  })
}

/* 任务列表 */
export function getTaskList(query) {
  return request({
    url: '/?m=Activity&a=task',
    method: 'get',
    params: query
  })
}


/* 需求提交表单那 */
export function submitDemandForm(data) {
  return request({
    url: '/?m=VipDemand&a=submit',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 兑换 */
export function exchangeVip(data) {
  return request({
    url: '/?m=Activity&a=exchange',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 做任务 */
export function completeTaskByFanClub(data) {
  return request({
    url: '/?m=Activity&a=taskComplete',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 绑定邀请者 */
export function bindInviteByFansClub(data) {
  return request({
    url: '/?m=Activity&a=rebate',
    method: 'post',
    data: qs.stringify(data)
  })
}


/* 我也想要 */
export function wantDoTask(query) {
  return request({
    url: '/?m=Activity&a=wantTo',
    method: 'get',
    params: query
  })
}
