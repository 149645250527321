import {disposeDropDownData} from "@/components/_Public/Header/HeaderAllDropDown/module";

export default {
  namespaced: true,

  state() {
    return {
      dropDownDataByAll: {

      },
      headerHeight: 80
    }
  },
  getters:{
    dropDownDataByAll(state){
      return disposeDropDownData(state.dropDownDataByAll)
    }
  },
  mutations: {
    setDropDownDataByAll(state, payload) {
      state.dropDownDataByAll = JSON.stringify(payload);
    },
    setHeaderHeight(state, payload){
      state.headerHeight = payload
    }
  }
}
