/* tupian store */
export default {
  namespaced: true,

  state(){
    return {
      // 是否显示下载拦截弹框( 用户短时间下载次数过多 )
      downInterceptDialog: {
        isShow: false,
        clippingTime: 0,
      },

      /* 图片包支付弹框 */
      tupianPackagePayDialog: {
        isShow: false,
        tupianItem: ''  // 购买当前图片包信息
      },
      isShow: true,

      /* 非盈利弹框 */
      isFreeDownDialog:{
        isShow: false,
      }
    }
  },
  mutations: {

    SET_DOWN_INTERCEPT_DIALOG(state, payload) {
      state.downInterceptDialog.isShow = payload.isShow;
      state.downInterceptDialog.clippingTime = payload.clippingTime;
      state.downInterceptDialog.key = 22222222;
    },

    SET_TUPIAN_PACKAGE_PAY_DIALOG(state, payload) {
      state.tupianPackagePayDialog.isShow = payload.isShow;
      state.tupianPackagePayDialog.tupianItem = payload.tupianItem;
    },

    SET_FREE_DOWN_DIALOG(state, payload) {
      state.isFreeDownDialog.isShow = payload.isShow;
    }
  }
}
