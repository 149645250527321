<template>
  <div>
    <!-- 7天免费vip弹框 -->
    <CgModalMiddle
      :is-show-close="false"
    >
      <div
        slot="modal_content"
        class="modal_content _user-select">

        <div class="inner-content">
          <div class="title">vip体验卡</div>
          <div class="txt-indate">*有效期7天，每天可下载1次</div>
          <div
            class="receive-coupon"
            @click="receive"
          >
            {{ receiveBtnTxt }}
          </div>

          <i
            class="iconfont icon-cha _hide"
            @click="isShow = false"
          />
        </div>
      </div>
    </CgModalMiddle>
  </div>
</template>

<script>
import {getSevenDayVip} from "@/api/home";
import {fnLocalStorage, fnLogin, fnUser} from "@/fn";
import {HOME} from "@/constant/HOME";

export default {
  name: "GuideDownloadReceiveVipDialog",
  data() {
    return {
      receiveBtnTxt: '立即领取',
      timer: null
    }
  },
  mounted() {
    fnLocalStorage.setItem(HOME.GUIDE.DOWNLOAD_COUNTS.RMB_EIGHT_COUPON.SHOW_DATE_ARR, [])
  },
  methods: {
    async receive() {
      let res = await getSevenDayVip()
      let {status, msg} = res.data;

      if (status == 1) {
        this.$store.dispatch('user/getUserInfo');
        let count = 3
        this.timer = setInterval(() => {
          count--;
          if (count === 0) {
            clearInterval(this.timer);
            this.$emit('close')
          }
          this.receiveBtnTxt = `已领取（${count}s）`;
        }, 1000)
      } else if (status == -2) {
        this.$myNotify.show('已领取', () => {
          this.$emit('close')
        }, 2000)
      } else if (status == -1) {
        this.$myNotify.show('领取失败', () => {
          this.$emit('close')
        }, 2000)
      }
      if (status == 0) {
        this.$myNotify.error('请先登录', () => {
          fnLogin.loginIntercept()
        })
      }
    },

    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal_content {
  position: relative;
  @include BgImage(662px, 424px, 'home/download-count-guide/seven-vip-bg.png');

  /* 内框 */
  .inner-content {
    width: 425px;
    height: 274px;
    border-radius: 30px;
    @include Position(1, 5, 128px, 69px);
    padding: 34px 26px;
    box-sizing: border-box;
    text-align: right;

    .title {
      font-size: 20px;
      font-weight: bold;
      color: rgba(184, 95, 0, 0.85);
      line-height: 20px;
      margin-bottom: 10px;
    }

    .txt-indate {
      font-size: 12px;
      font-weight: 400;
      color: rgba(184, 95, 0, 0.85);
      line-height: 12px;
    }

    .receive-coupon {
      width: 260px;
      height: 70px;
      line-height: 70px;
      border-radius: 30px;
      text-align: center;
      cursor: pointer;
      font-size: 24px;
      font-weight: bold;
      color: #A05300;
      @include Position(1, 4, 50%, -35px);
      transform: translateX(-50%);


      @include BgImage(260px, 70px, 'home/download-count-guide/receive-vip-btn@active.png');

      @include ElementHover('', '') {
        @include BgImage(260px, 70px, 'home/download-count-guide/receive-vip-btn.png');
      }
    ;
    }

    .icon-cha {
      width: 32px;
      height: 32px;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 50%;
      font-size: 14px;
      @include LayoutFlex(1);
      @include Position(1, 2, -52px, -23px);
      cursor: pointer;
      transition: all 0.5s ease-in-out;
      @include ElementHover('', '') {
        transform: rotate(180deg);
      }
    }
  }


}
</style>
