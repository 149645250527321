<template>
  <div >
    <no-ssr>
      <div
        class="new-user-coupon-footer-bar _pointer"
        v-if="isLogin && isHasUserCoupon && isShowFooter"
        @click.prevent="navigateTo"
      >
        <i class="iconfont icon-cha" @click.stop="closeBar"/>
      </div>
    </no-ssr>


  </div>
</template>

<script>
import {fnBrowser, fnGuideDownloadCount, fnLocalStorage, fnLogin, fnRouter, fnUser} from "@/fn";
import {MODEL_LIST_BAR, MODEL_TUPIAN_NEW_USER_COUPON_BAR} from "@/constant/MODELS";
import {MEMBER_LICENSE_VIP, PAY_BUSINESS} from "@/constant/PATH";
import {tag} from "@/utils/statUtil";
import {getCookie} from "~/utils/auth";
import {mapState} from "vuex";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {HOME} from "@/constant/HOME";

export default {
  name: "TopUpListBar",
  data() {
    return {
      isShow: -1,
      isShowFooter: false,
      PAY_BUSINESS
    }
  },
  computed: {
    ...mapState('user',
      ['isHasUserCoupon', 'isLogin']
    ),
    isLogin() {
      return this.$store.state.user.isLogin
    }
  },

  mounted() {
    this.getBarShowState();
  },
  methods: {

    getBarShowState(){
      let flag = fnLocalStorage.getItemDate(MODEL_TUPIAN_NEW_USER_COUPON_BAR);
      this.isShowFooter = !flag ? true : false;
    },

    closeBar(){
      fnLocalStorage.setItemDate(MODEL_TUPIAN_NEW_USER_COUPON_BAR,1);
      this.isShowFooter = false;
    },



    navigateTo() {
      fnGuideDownloadCount.trackNewUser(HOME.NEW_USER_COUPON_TYPE.HORIZON,HOME.NEW_USER_COUPON_TYPE.POSITION.CURRENT_PAGE)
      fnRouter.navigationTo(PAGE_PATH.PAYMENT.BUSINESS)
    },
    showLogin() {
      fnLogin.loginIntercept(false)
    },

    checkIsShow() {
      setTimeout(() => {
        let noLoginWhere = !fnLogin.checkLogin()

        let loginWhere = fnLogin.checkLogin() && !fnUser.checkIsVip() && (getCookie('companyVipState') != 1) && !this.getTodayIsShow();

        let loginWhereOk = fnLogin.checkLogin() && fnUser.checkIsVip() && (getCookie('companyVipState') == 1) && !this.getTodayIsShow();


        this.isShow = -1

        if (noLoginWhere) {
          this.isShow = 0
        }

        if (loginWhere) {
          this.isShow = 1
        }
        if (loginWhereOk) {
          this.isShow = -1
        }


        this.updateIsShowListBar(this.isShow)


      }, 500)
    },

    updateIsShowListBar(n) {
      if (n != -1) {
        this.$store.commit('base/SET_IS_SHOW_LIST_BAR', true)
      } else {
        this.$store.commit('base/SET_IS_SHOW_LIST_BAR', false)
      }
    },
    getTodayIsShow() {
      return this.$localStorage.getItemDate(MODEL_LIST_BAR);
    },
    close() {
      this.isShow = -1;
      this.$localStorage.setItemDate(MODEL_LIST_BAR)
    },
    tag(stat) {
      tag(stat)
    },

    navigatorTo(path) {
      fnBrowser.navTo(path)
    }
  }
}
</script>

<style lang="scss" scoped>


.new-user-coupon-footer-bar {
  @include BgImage(638px, 156px, 'tupian/new-user-coupon-footer-bar.png');
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 12;

  .icon-cha {
    width: 24px;
    height: 24px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px 12px 4px 4px;
    @include Position(1, 2, 0, 44px);
    color: #FFFFFF;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    @include ElementHover('',rgba(0, 0, 0, 0.3))
  }
}
</style>
