<!--
  文字提示组件
    1. 类型，单文本和list滚动
    2. 出现位置，默认下方，后续拓展
-->
<template>
  <div class="HtTooltip">
    <slot name="tooltip-box"></slot>



    <!-- 普通文本hover -->
    <template v-if="hoverType == 1">
      <!--  hover 下拉  -->
      <div class="dropbox">
        <div class="dropbox-item">
          {{ text }}
        </div>
      </div>
    </template>


    <!-- icon图标 hover -->
    <template v-else>
      <!--  hover 下拉  -->
      <div class="dropbox">
        <div class="__form-scrollbar">
          <div class="dropbox-item" v-for="vo in hideList">
            {{ vo.label }}
          </div>
        </div>
      </div>
    </template>


  </div>
</template>

<script>
export default {
  name: "HtTooltip",
  props: {
    /* 数据类型 */
    text: {
      type: String,
      default: ''
    },

    /* 下来类型 1 字符串 2 数组 */
    hoverType: {
      type: String | Number,
      default: 1
    },

    iconName: {
      type: String,
      default: ''
    },

    hideList: {
      type: Array,
      default: () => []
    },
  }
}
</script>

<style lang="scss" scoped>
@import "styles/form";
.HtTooltip {
  position: relative;

  &:after {
    content: '';

    width: 100%;
    height: 20px;
    @include Position(1, 4, 0, -20px);
    display: none;
  }

  @include ElementHover('', '') {
    .dropbox {
      display: block;
    }

    &:after {
      display: inline-block;
    }
  }


  .dropbox {
    @include Position(1, 5, 50%, 30px);
    transform: translateX(-50%);
    z-index: 9;
    max-height: 156px;
    background: #424866;
    border-radius: 8px;
    display: none;
    padding: 5px 8px;


    &:after {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #424866;
      @include Position(1, 5, 50%, -5px);
      transform: translateX(-50%) rotate(135deg);
    }

    .__form-scrollbar{
      padding-right: 6px;
      width: 100%;
      overflow-y: scroll;
      max-height: 156px;
    }

    .dropbox-item {
      //width: 200px;
      height: 20px;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 19px;
      margin-bottom: 4px;
      white-space: nowrap;
      padding-right: 6px;

      &:last-child{
        margin-bottom: 0;
      }

    }
  }
}
</style>
