<template>
  <div class="DesignerPlatformMain">
    <slot name="DesignerPlatformMain"/>
  </div>
</template>

<script>
export default {
  name: "DesignerPlatformMain"
}
</script>

<style lang="scss" scoped>
@import "../common/layout";
</style>
