import localStorage from "~/utils/localStorage";
import {getSurveyUserState} from "~/api/survey-user";
import {MODEL_USER_SURVEY} from "~/constant/MODELS";
import {fnLogin} from "~/fn";

class FnUserSurvey {
  constructor() {

  }

  /* 检查是否填写 */
  checkIsWrite(store) {
    if (fnLogin.checkLogin()) {

      if (!this.getTodayIsWrite()) {
        this.getServerWriteState(store);
      }
    }
  }

  /* 获取服务端用户填写用户画像 */
  getServerWriteState(store) {
    // let {$store} = $nuxt;
    getSurveyUserState().then(res => {
      if (res.data.status === 2) {
        this.updateTodayWriteState(2);
        store.commit('surveyUser/SET_IS_SHOW_SURVEY_USER', false, {root: true});
      } else {
        // if ($store.state.user.path === '/') {
        // store.commit('surveyUser/SET_IS_SHOW_SURVEY_USER', true, {root: true});
        store.commit('surveyUser/SET_IS_SHOW_SURVEY_USER', true);
        this.updateTodayWriteState(0);
        // }
      }
    })
  }

  /* 获取当天是否填写用户画像 */
  getTodayIsWrite() {
    return localStorage.getItemDate(MODEL_USER_SURVEY);
  }

  /**
   * 更新当天填写用户画像状态
   * @param {String | Number ...} value
   */
  updateTodayWriteState(value = 1) {
    localStorage.setItemDate(MODEL_USER_SURVEY, value)
  }


}

export default new FnUserSurvey();

/**
 * 功能描述：用户画像填写
 *  - 每日检查一次服务端用户填写画像状态, 并将状态保存本地
 *  - 监听页根据状态判断是否展示
 */
