<template>
  <div
    :class="[picItemInfo.isCollect === true ? 'on':'no']"
    class="PicTagCollect collect _pointer"
    @click="toggleCollect(picItemInfo)"
  >
    <i class="iconfont icon-collect"/>
  </div>
</template>

<script>
import {toggleMaterialCollect} from "@/api/material";
import {fnLogin} from "@/fn";
import {toggleTopicCollect} from "@/api/topic";

export default {
  name: "PicTagCollectTopic",
  props: {
    // 单个图片信息
    picItemInfo: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    toggleCollect(vo) {
      console.log(vo)
      /* 解析url取其id */
      let urlArr = vo.link_url.split('/')
      let idStr = urlArr[urlArr.length -1];
      let id = idStr.replace('.html','');
      if (fnLogin.checkLogin()) {
        let isCollect = vo.isCollect || false
        toggleTopicCollect({
          id,
          mode: isCollect === false ? 'add' : 'rem'
        }).then(res => {
          let msg = isCollect === false ? '订阅成功' : '已取消订阅';
          this.$set(vo,'isCollect',!vo.isCollect)
          this.$myNotify.success(msg);
        })
      } else {
        fnLogin.loginIntercept('请先登录');
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.collect {
  @include Position(1, 2, 16px, -50px);
  width: 32px;
  height: 32px;
  background: #FFFFFF;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  @include LayoutFlex(1);
  transition: all 0.5s ease-in-out;
  z-index: 2;

  &.on {
    background-color: #F8CC44;

    .icon-collect {
      color: #FFFFFF;
    }

    &:hover {
      background: #DEB740;
    }


  }

  &.no {
    .icon-collect {
      color: #596080;
    }

    &:hover {
      .icon-collect {
        color: #F8CC44;
      }
    }
  }

}
</style>
