/**
 * @Author wulizhou
 * @Date 2022/4/24
 * @Description 日期工具函数封装( 兼容 safari )
 */
const WEEK_ZN = ['日', '一', '二', '三', '四', '五', '六']
const WEEK_ZN_KEY = ['星期', '周']

import _array from './ToolArray'

class toolDate {

  constructor() {
  }

  /**
   * 时间戳转年月日对象
   * @param timestamp
   * @returns {{hours: string, seconds: (string|number), month: string, year: string, minutes: string, day: string}}
   */
  timestampConversion(timestamp) {
    let date = new Date(parseInt(timestamp))
    let year = date.getFullYear()
    let month = (date.getMonth() + 1) >= 10 ? (date.getMonth() + 1) : 0 + '' + (date.getMonth() + 1);
    let day = (date.getDate()) >= 10 ? date.getDate() : 0 + '' + date.getDate();
    let hours = date.getHours() >= 10 ? date.getHours() : 0 + '' + date.getHours();
    let minutes = date.getMinutes() >= 10 ? date.getMinutes() : 0 + '' + date.getMinutes();
    ;
    let seconds = date.getSeconds()
    // return Y + M + D + h + m + s
    return {
      year,
      month,
      day,
      hours,
      minutes,
      seconds,
    }
  }

  /**
   * 获取指定日期的信息(包括年月日等)
   * @param {String} dateStr 传入一个日期字符串 示例 2022-4-24
   * @returns { Object }  返回包含 年月日时分秒日期周的对象
   */
  getDate(dateStr) {
    let date = !dateStr ? new Date() : new Date(dateStr.replace(/-/g, "/"));
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const time = date.getTime();
    const milliseconds = date.getMilliseconds();
    const week = date.getDay();
    const week_zn = `${WEEK_ZN_KEY[1]}${WEEK_ZN[week]}`;
    return {
      year, month, day, hours, time, minutes, milliseconds, seconds, week, week_zn, date: year + '-' + month + '-' + day
    }
  }

  /**
   * 获取指定日期至days返回的时间( 负值为日期前,正值为日期后 )
   * - 示例 (2022-4-24 , 1) 返回 2022-4-25
   * @param { Number } days  几天 示例 -1 1
   * @param { String } dayStr  日期 示例 2022-4-24
   * @returns {string} 返回指定某天的时间
   */
  getNextDate(days = 1, dayStr,isHasMill = false) {
    let dd = !dayStr ? new Date() : new Date(dayStr.replace(/-/g, "/"));
    // let dd = new Date(dayStr.replace(/-/g, "/"));
    dd.setDate(dd.getDate() + days);
    let y = dd.getFullYear();
    let m = dd.getMonth() + 1;//获取当前月份的日期
    let d = dd.getDate();
    if (isHasMill == false){
      return y + "-" + m + "-" + d;
    }
    const hours = dd.getHours();
    const minutes = dd.getMinutes();
    const seconds = dd.getSeconds();
    return this.fillZero(y) + "-" + this.fillZero(m) + "-" + this.fillZero(d) + ' ' + this.fillZero(hours) + ":"+ this.fillZero(minutes)+ ":"+ this.fillZero(seconds);
  };

  /**
   * 获取指定日期至days返回的时间数组
   * - 示例 (2022-4-21 , 2) 返回 ["2022-4-24", "2022-4-25"]
   * @param { String } dateStr  字符串日期 示例 2022-4-24
   * @param { Number } days  几天( 不能设置为0 ) 示例 -1 1
   * @returns {*[]}
   */
  getNextDateArr(dateStr, days = 1) {
    let dateArr = _array.getValuesArr(0, days);
    let dateRang = [];
    for (let i = dateArr[0]; i < dateArr[dateArr.length - 1]; i++) {
      dateRang.push(this.getNextDate(dateStr, i))
    }
    return dateRang;
  }

  /* 两个日期比较,返回 */
  compare(date1, date2) {
    console.log({
      date1,
      date2
    })
    let date1_s = date1.replace(/\-/g, '/');
    let date2_s = date2.replace(/\-/g, '/');

    let date1_unix = Date.parse(date1_s) //將字符串格式日期转化为时间戳，就是1970年到当前日期的毫秒数
    let date2_unix = Date.parse(date2_s) //將字符串格式日期转化为时间戳，就是1970年到当前日期的毫秒数

    console.log(date1_unix > date2_unix)
    return date1_unix > date2_unix
  }


  /* 计算两个日期的差值 */

  getDiffDay(date_1, date_2, type = 'mill') {
    let diffDay;
    if (type === 'mill') {
      diffDay = Math.abs(date_1 - date_2)
      return Math.floor(diffDay / (1000 * 3600 * 24)) + 1     // 相差的天数*/
    }
  }


  /**
   * 日期补0
   * @param { String | Number } val
   * @returns {string|*}
   */
  fillZero(val) {
    return val < 10 ? ('0' + Number(val)) : val;
  }

  /**
   * 日期字符串补0
   * - 示例 ( '2022-4-24', '/' ) 返回 2022/04/24
   * @param {String} val 日期字符串
   * @param format 分割格式
   * @returns {string}  返回补0后的字符串
   */
  DateFillZero(val, format = '-') {
    let dateStr = '';
    let valArr = val.split('-');
    valArr.forEach(vo => {
      dateStr += format + this.fillZero(vo);
    })
    return dateStr.slice(1);
  }

  /* 是否同一天 */
  isSameDay(timeStampA, timeStampB) {

    let dateA = !timeStampA ? new Date() : new Date(timeStampA.replace(/-/g, "/"));
    let dateB = !timeStampB ? new Date() : new Date(timeStampB.replace(/-/g, "/"));

    return (dateA.setHours(0, 0, 0, 0) == dateB.setHours(0, 0, 0, 0));
  }


}

export default new toolDate();
