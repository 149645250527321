<template>
  <div
    :class="[picItemInfo.isCollect ? 'on':'no']"
    class="PicTagCollect collect _pointer"
    @click="toggleCollect(picItemInfo)"
  >
    <i class="iconfont icon-collect"/>
  </div>
</template>

<script>
import {toggleMaterialCollect} from "@/api/material";
import {fnLogin} from "@/fn";
import {completeTaskByFanClub} from "@/api/fans/club";

export default {
  name: "PicTagCollect",
  props: {
    // 单个图片信息
    picItemInfo: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    toggleCollect(vo) {
      if (fnLogin.checkLogin()) {
        let isCollect = vo.isCollect || false
        toggleMaterialCollect({
          uniq_id:vo.uniq_id,
          act: isCollect === false ? 'collect' : 'remove',
          pck:  this.picItemInfo.package == 1 ? 'package': ''
        }).then(res => {
          let msg = isCollect === false ? '收藏成功' : '已取消收藏';
          this.$set(vo,'isCollect',!vo.isCollect)
          this.$myNotify.success(msg);

          /* 检查是否是领取任务 */
          setTimeout(()=>{
            let { t_id, task_type } = this.$route.query
            if ( task_type == 1){
              completeTaskByFanClub({id: t_id}).then(res =>{
                let { status, data, message } = res.data;
                if (status ==200){
                  this.$myNotify.success("任务已完成")
                  this.$router.push({
                    query:{}
                  })

                }else{
                  this.$myNotify.error(message)

                }
              })
            }
          },1000)


        })
      } else {
        fnLogin.loginIntercept('请先登录');
      }
    },
  }
}
</script>

<style lang="scss" scoped>

.PicTagCollect{
  display: flex !important;
}
.collect {
  @include Position(1, 2, 16px, -50px);
  width: 32px;
  height: 32px;
  background: #FFFFFF;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  @include LayoutFlex(1);
  transition: all 0.5s ease-in-out;
  z-index: 2;

  &.on {
    background-color: #F8CC44;

    .icon-collect {
      color: #FFFFFF;
    }

    &:hover {
      background: #DEB740;
    }


  }

  &.no {
    .icon-collect {
      color: #596080;
    }

    &:hover {
      .icon-collect {
        color: #F8CC44;
      }
    }
  }

}
</style>
