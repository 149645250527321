<template>
  <ul class="record-menu">
    <li
      :style="{ 'top': recordMenuTop + 'px' }"
      class="bar"
    />
    <li
      v-for="(vo,idx) in recordMenu"
      :key="vo.name"
      :class="{ 'on' : vo.id == currentMenuId }"
      @click="toggleRecordMenu(vo,idx)"
    >
      <i :class="['iconfont',vo.iconClass]"/>
      {{ vo.name }}
      <i
        v-if="vo.id === 2"
        class="iconfont icon-question"
      >
        <div
          class="hover-box"
        >
          当前为个人下载记录，<br>
          如需查看企业下载记录，<br>
          请前往企业服务后台<br>
        </div>
      </i>

      <i
        v-if="vo.id === 1"
        class="iconfont icon-question"
        @mouseover="isShow = true"
        @mouseleave="isShow = false">

        <div
          class="hover-box hover-box2"
        >
          免费用户最多新建1个收藏夹； <br>
          个人VIP最多新建10个收藏夹； <br>
          企业VIP新建文件夹数量无限制； <br>
        </div>
      </i>

    </li>
  </ul>
</template>

<script>
import {recordMenu} from "@/components/user-center/common/module";
import {fnLocalStorage, fnLogin} from "@/fn";
import {getAuthList} from "@/api/exchange-code";

export default {
  name: "RecordMenu",
  data() {
    return {
      recordMenuTop: 8,
      newRecordMenu: recordMenu.slice(),
      recordMenuIndex: 0,
      currentMenuId: 0,
      isShow: false
    }
  },
  props: {
    newRecordMenuId: {
      type: String | Number,
      default: 0
    },
    recordMenu: {
      type: Array,
      default: () => []
    },
  },
  watch: {
    newRecordMenuId: {
      handler(n, o) {
        this.currentMenuId = n;
        let recordMenuIndex = this.newRecordMenu.findIndex(item => item.id == n)
        this.updateBarPosition(n)
      },
      immediate: true
    }
  },
  mounted() {
    this.initMenuId()
    // this.getAuthList()
  },
  methods: {
    initMenuId() {
      let curPath = this.$route.path;
      let findMenu = this.recordMenu.find(item => curPath === item.path);
      if (findMenu) {
        this.toggleRecordMenu(findMenu);
      }
    },

    async getAuthList() {

      if (!fnLogin.checkLogin()){
        return false;
      }
      let {data} = await getAuthList()
      this.isShowExchange = data.data.check;
      if (data.data.check) {
        this.recordMenu = recordMenu.slice(0)
      } else {
        this.recordMenu = recordMenu.filter(vo => vo.id != 6)
      }
      console.log(this.recordMenu)
    },


    toggleRecordMenu(vo) {
      this.currentMenuId = vo.id;
      this.updateBarPosition(vo.id)
      /* 缓存当前路由地址及参数 */

      let curPath = this.$route.path;
      let cacheRecordMenuPath = fnLocalStorage.getItem('RecordMenuPath');
      if (cacheRecordMenuPath != undefined && cacheRecordMenuPath == vo.path) {
        console.log('---------路由已缓存----------')
        return false;
      }

      console.log('---------路由未缓存----------')
      fnLocalStorage.setItem('RecordMenuPath', vo.path);
      console.log(vo.path)
      this.$router.push({
        path: vo.path
      })
    },

    updateBarPosition(menuId) {
      let recordMenuIndex = this.newRecordMenu.findIndex(item => item.id == menuId);
      setTimeout(() => {
        this.recordMenuTop = (recordMenuIndex) * 60 + 8;
      }, 200)
    }
  }
}
</script>

<style lang="scss" scoped>
.record-menu {
  width: 164px;
  height: 480px;
  background: linear-gradient(180deg, #F5F6FA 0%, #F5F6FA 17%, #FBFBFD 100%);
  border-radius: 14px;
  padding: 8px 6px 0;
  box-sizing: border-box;
  position: relative;
  margin-right: 30px;
  //float: left;


  .bar {
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(10, 37, 64, 0.1);
    border-radius: 12px;
    transition: all 0.3s ease-in;
    position: absolute;
    top: 8px;

    &::before {
      content: '';
      display: inline-block;
      width: 2px;
      height: 36px;
      background: linear-gradient(180deg, #A2A2FF 0%, #6969FF 100%);
      border-radius: 2px;
      @include Position(1, 5, 0, 50%);
      transform: translateY(-50%);
    }
  }

  li {
    font-size: 14px;
    color: #596088;
    width: 152px;
    height: 60px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    .iconfont {
      font-size: 18px;
      margin: 0 10px 0 24px;
    }

    .icon-question {
      @include Position(1, 2, 12px, 50%);
      transform: translateY(-50%);
      z-index: 10;
      margin: 0;

      &:after {
        content: '';
        display: inline-block;
        padding: 10px;
        @include Position(1, 5, 50%, 50%);
        transform: translate(-50%, -50%);
      }

      &:hover {
        .hover-box {
          display: block;
        }
      }

      .hover-box {
        min-width: 140px;
        //height: 60px;
        background: #424866;
        border-radius: 4px;
        @include Position(1, 5, 24px, 50%);
        transform: translateY(-50%);
        font-size: 12px;
        color: #FFFFFF;
        line-height: 16px;
        padding: 6px 8px;
        display: none;

        &:after {
          display: inline-block;
          content: '';
          width: 8px;
          height: 8px;
          background: #424866;
          @include Position(1, 5, -4px, 50%);
          transform: translateY(-50%) rotate(135deg);
        }

        &:before {
          display: inline-block;
          content: '';
          width: 15px;
          height: 100%;
          @include Position(1, 4, -15px, 0);
          //background-color: pink;
        }
      }

      .hover-box2 {
        width: 180px;
      }


    }


    &.on {
      color: #6969FF;
    }

    @include ElementHover(#6969FF, '');

  }
}
</style>
