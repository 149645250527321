<template>
  <div class="PicFreeDownCouponModal">
    <no-ssr>
      <ModalMiddle :is-show-close="false" v-if="isShowPicFreeDownCouponModal">
        <div class="container" slot="ModalContent">
          <div class="time-box">
            <span class="h">{{ times.h }}</span>
            <span class="m">{{ times.m }}</span>
            <span class="s">{{ times.s }}{{ times.hm }}</span>
          </div>
          <div class="btn" @click="getCoupon"></div>

          <i class="iconfont icon-cha" @click="close"/>
        </div>
      </ModalMiddle>
    </no-ssr>
  </div>
</template>

<script>
import mixinCountDown from "@/mixins/mixinCountDown";
import {mapState} from "vuex";
import {getPicFreeDownCoupon} from "@/api/material";
import {fnBasic} from "@/fn/_fn";
import {tag} from "@/utils/statUtil";
import {setPicFreeDownCouponModal} from "~/components/_Privately/Pic/PicFreeDownCouponModal/module";
import {PAY_PERSON} from "@/constant/_Public/Path"

export default {
  name: "PicFreeDownCouponModal", // 免费下载红包弹框
  mixins: [mixinCountDown],
  computed: {
    ...mapState('_Privately/Pic', ['isShowPicFreeDownCouponModal']),
  },
  watch: {
    isShowPicFreeDownCouponModal: {
      handler(n, o) {
        if (n == true) {
          this.initTimer();
        } else if (n == false) {
          this.clearTimer();
        }
      },
      immediate: true
    },
  },
  methods: {
    getCoupon() {
      getPicFreeDownCoupon().then(res => {
        console.log(res)
        const {status, message} = res.data;
        console.log(status)
        console.log(message)
        tag('充值:9折抵扣券:图片:click')

        if (status == 200) {
          this.$myNotify.success(message, () => {
            this.close();
            fnBasic.navigationTo(PAY_PERSON)
          }, 1500)
        } else {
          this.close();
          this.$myNotify.error(message, () => {
            fnBasic.navigationTo(PAY_PERSON)
          }, 1500)
        }
      }).catch(err => {

      })
    },

    close() {
      setPicFreeDownCouponModal(this.$store, false);
    },
    tag(stat) {
      tag(stat)
    },
  }

}
</script>

<style lang="scss" scoped>

.container {
  @include BgImage(455.2px, 497.6px, 'tupian/PicFreeDownCoupon/PicFreeDownCouponBg2.png');

  .btn {
    width: 278px;
    height: 62px;
    box-sizing: border-box;
    position: absolute;
    left: 89px;
    bottom: 125px;
    cursor: pointer;
  }

  .icon-cha {
    font-size: 30px;
    color: #FFFFFF;
    @include Position(1, 4, 203px, 0px);
    //transform: translateX(-50%);
    cursor: pointer;
    z-index: 30;

    width: 38px;
    height: 38px;
    background: #FFFFFF;
    opacity: 0.8;
    border-radius: 50%;
    color: #FF5744;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: rgba(255, 255, 255, 0.8);
    }
  }

  .time-box {
    width: 200px;
    box-sizing: border-box;
    position: absolute;
    bottom: 80px;
    left: 199px;
    height: 30px;
    border-radius: 4px;
    display: flex;
    align-items: center;

    span {
      display: inline-block;
      margin-right: 9px;
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      font-weight: bold;
      color: #E5490F;
      text-align: center;
      box-sizing: border-box;
    }

    .h, .m {
      width: 26px;
      border-radius: 4px;
      margin-right: 16px;
    }

    .s {
      width: 48px;
      background: #FFF0C7;
      border-radius: 4px;
    }


  }

}

</style>
