import {
  SearchComboBox, CopyrightCombobox, UserCombobox
} from "@/components/home/index.js"
import { VipMandateCombobox } from '@/components/home'
import Cookies from 'js-cookie';

import {Login} from "@/components/common/index.js"

import {
  MATERIAL_LIST,
  MEMBER_LICENSE_VIP
} from '@/constant/PATH.js'

import {mapGetters, mapState} from "vuex";

import {
  menuNavList,
  topbarMenuList,
  calendarMenuList,
  materialList,
  workEfficiencyData,
  applicationData
} from "./module.js"
import {getHotwords} from "@/api/home";
import {fnFlowStatistics} from "@/fn";
import {PROMOTION_POSITION} from "@/fn/FnFlowStatistics";

let indexExtend = {
  computed: {
    ...mapGetters('user', ['isLogin', 'name', 'avatar']),
    ...mapState('user',['path'])
  },

  data() {
    return {
      HeaderName: "header",
      menuNavList,
      topbarMenuList,
      calendarMenuList,
      materialList,
      workEfficiencyData,
      applicationData,
      MATERIAL_LIST,
      MEMBER_LICENSE_VIP
    }
  },
  components: {
    SearchComboBox,
    CopyrightCombobox,
    UserCombobox,
    Login,
    VipMandateCombobox
  },
  methods: {
    async getHotwords(type, flag) {
      let res = await getHotwords({module: 1})
      this.recommendList = res.data.data;
      await this.toggleShowCombobox(type, flag);
      await fnFlowStatistics.promotion(PROMOTION_POSITION.TOP, 0)
    },



    /* 显示登录弹框 */
    showLogin() {
      /* 增加拦截判断 */
      let auth_id = Cookies.get('auth_id');
      if (auth_id){
        this.$myNotify.show('账户已登录', () => {
          // let currRouter = this.$route.path;
          window.location.href = this.path;
        },2500);
        return false;
      }

      let { path } = this.$route;
      if(path == '/' || path == ''){
        // this.$store.commit("user/SET_IS_SHOW_LOGIN_HOME", true);
        this.$store.commit("user/SET_IS_SHOW_LOGIN", true);
      }else{
        this.$store.commit("user/SET_IS_SHOW_LOGIN", true);

      }
    }
  }
}
export default indexExtend;
