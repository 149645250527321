<template>
  <a :href="picItemInfo.link_url"
     target="_blank"
     :title="picItemInfo.title"
     class="PicTagTitle material-title overflow-one">{{ picItemInfo.title }}</a>
</template>

<script>
export default {
  name: "PicTagTitle",
  props: {
    // 单个图片信息
    picItemInfo: {
      type: Object,
      default: () => {
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.material-title {
  width: 100%;
  @include Position(1, 4, 0, -99px);
  height: 76px;
  line-height: 76px;
  padding: 0 28px;
  box-sizing: border-box;
  background: rgba(0, 0, 26, 0.2);
  border-radius: 14px;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 1px;
  transition: all 0.5s ease-in-out;
  text-align: center;
}
</style>
