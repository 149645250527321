import {BaseController} from "@/controller/baseController";
import UtilsController from "@/controller/UtilsController";

class UserController extends BaseController{
  /* 联系客服 */
  toSerVice(){
     const SERVICE_LINK = 'https://qiankuwang.udesk.cn/im_client/?web_plugin_id=100652&group_id=134702';
    UtilsController.navigationTo(SERVICE_LINK);
  }
}

export default new UserController();
