<template>
  <div class="PicButtonGroupPackage button-group">
    <div class="mask" @click="navTo"/>

    <!-- 图片包未购买 未登录/ -->

    <template v-if="!isLogin">
      <PicButtonBusiness
        @click.native="navigatorToPay"
        button-txt="购买企业VIP"/>
      <PicPackageButtonPrice
        :pic-package-info="picPackageInfo"
        @click.native="showPicPackageBuyModal(picItemInfo)"
      />
    </template>



    <!-- 图片包已购买 已购买/商用年vip/企业vip -->

    <template v-if="isLogin ">
      <div v-if="picItemInfo.is_buy == 1 || companyVipState == 1 || (businessVipState == 1 && businessVipInfo.vip == 5)" >
        <PicPackageButtonPlain
          @click.native="downloadPicPackage(1,picItemInfo.uniq_id)"
        />
        <PicPackageButton
          @click.native="downloadPicPackage(2,picItemInfo.uniq_id)"
        />
      </div>

      <div v-else>
        <PicButtonBusiness
          @click.native="navigatorToPay"
          button-txt="购买企业VIP"/>
        <PicPackageButtonPrice
          :pic-package-info="picPackageInfo"
          @click.native="showPicPackageBuyModal(picItemInfo)"
        />
      </div>
    </template>



  </div>
</template>

<script>
import PicButtonGroupExtend from "@/components/pic/pic-button-group/common/pic-button-group-extend";
import {fnRouter} from "@/fn";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {mapState} from "vuex";

export default {
  name: "PicButtonGroupPackage",
  mixins: [PicButtonGroupExtend],
  components: {},
  computed: {
    ...mapState('user',
      [
        'personVipState',
        'personVipInfo',
        'businessVipState',
        'businessVipInfo',
        'companyVipState',
        'companyVipInfo',
        'isLogin',
        'companyAccountStatus',
      ]
    ),
  },
  data() {
    return {
      picPackageInfo: {}
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.picPackageInfo = {
        price: this.picItemInfo.package_price,
        number: this.picItemInfo.pic_num,
      }
    },
    /*navigatorToPay() {
      fnRouter.navigationTo(PAGE_PATH.PAY_BUSINESS);
      this.$emit('navigatorToPay')
    },

    showPicPackageBuyModal(picItemInfo) {
      this.$refs.PicPackagePayModal.showPayDialog(picItemInfo);
    },*/

    /*downloadPicPackage(channel, uniq_id) {
      this.$refs.PicPackagePayModal.download(channel, uniq_id)
    }*/
  }
}
</script>

<style lang="scss" scoped>
@import "common/button-group";

.PicButtonGroupPackage {

  /deep/ .PicButtonBusiness, .PicPackageButtonPlain {
    @include Position(1, 5, 50%, -7px);
    transform: translateX(-50%);
  }

  /deep/ .PicPackageButtonPrice, .PicPackageButton {
    @include Position(1, 5, 50%, 63px);
    transform: translateX(-50%);
  }

  /deep/ .PicPackageButtonPrice {
    .pic-price {
      .pic-child-num {
        font-size: 16px;
      }

      .text-dollar {
        font-size: 14px;
        margin-left: 4px;
      }

      .text-price {
        font-size: 26px;
        font-weight: normal;
      }

      .pic-child-num {
        font-size: 16px;
        margin-left: 2px;
      }
    }
  }


  /deep/ .PicButtonBusiness{
    color: $color-company;
    border: 1px solid $color-company;
  }


  @include Screen(1) {
    /deep/ .PicButtonBusiness, .PicPackageButtonPlain,
    .PicPackageButtonPrice, .PicPackageButton {
      //@include Position(1, 4, 50%, 64px);
      width: 220px;
    }
  }
}
</style>
