<template>

  <div class="FavoritesDefault FavoritesCompany" >
    <ModalMiddle
      v-show="showDialogByCompany"
      class="CgModalMiddle"
      @close="close"
    >
      <div slot="ModalContent"
           class="modal-content"
      >
        <div class="header">
          <div class="header-name">

            <div class="header-name-item" :class=" { 'ON': collectType==0 } "
                 @click="toggleCollectType(0)"
            >
              共享收藏夹
            </div>

            <div class="header-name-item" :class=" { 'ON': collectType==1} "
                 @click="toggleCollectType(1)"
            >
              我的收藏夹
              <i
                class="iconfont icon-question"
              >
                <div
                  class="hover-box hover-box2"
                >
                  免费用户最多新建1个收藏夹； <br>
                  普通VIP最多新建10个收藏夹； <br>
                  商用VIP最多新建20个收藏夹； <br>
                  企业VIP新建文件夹数量无限制； <br>
                </div>
              </i>
            </div>

          </div>
          <a class="txt-user-center"
             href="/company/collect-record"
             target="_blank"
          >查看收藏记录</a>
        </div>
        <div class="content">

          <happy-scroll color="rgba(203, 209, 222, 1)" size="4" resize>
            <div class="item-container">
              <!-- 默认文件夹 -->
              <div class="favorites-list">
                <div class="favorites-item" v-for="vo in favoriteList" @click="collect(vo)">
                  <div class="layout-left">
                    <div class="img-box">
                      <img v-if="vo.cover_img.length" :src=" IMG_BASE_URL + vo.cover_img[0].proto_pic" alt="">
                      <i v-else class="icon_folder"></i>
                    </div>
                    <span class="share-btn" v-show="collectType == 0">共享</span>
                    <span class="favorites-name overflow-one">{{ vo.name }}</span>
                  </div>
                  <div class="layout-right">
                    <div class="favorites-num">{{ vo.pic_total }}张</div>
                    <div class="favorites-btn">收藏</div>
                  </div>
                </div>

                <!--                <div class="no-more">到底啦～</div>-->
              </div>
            </div>
          </happy-scroll>


          <!-- 其他文件夹 -->

        </div>
        <div class="footer">

          <!--  非会员  -->

          <!--          <div class="no-vip" v-if="!isNoVip && favoriteList.length >= 2">
                      <div class="txt-sx-box">
                        <i class="iconfont icon-alert"></i>
                        <span class="txt-sx">免费用户，最多可新建1个收藏夹，已达上限</span>
                      </div>
                      <a class="upgrade-btn"
                         :href="PAGE_PATH.BASE_URL + PAGE_PATH.PAYMENT.BUSINESS"
                         target="_blank"
                         @click="dataTrackByPay"
                      >点击升级会员</a>
                    </div>-->
          <!--  会员  -->

          <div v-if="isShowFolderNameInput"
               class="folder-name">
            <el-form ref="form" :model="form" @submit.native.prevent>
              <el-input class="fold-input overflow-one"
                        v-model="folderName"
                        placeholder="收藏夹名称"
                        maxlength="50"
                        @keyup.enter.native="favoriteCreate"
              ></el-input>
            </el-form>
            <div class="btn-group">
              <div class="btn-item btn-cancel" @click="favoriteCreateCancel">取消</div>
              <div class="btn-item btn-confirm" @click="favoriteCreate">确认</div>
            </div>

            <div v-if="isShowErrTxt" class="err-txt">收藏夹名称只能由中文、英文字母、小数点和下划线构成</div>
          </div>

          <template v-else>
            <div class="vip"
                 @click="isShowFolderNameInput = !isShowFolderNameInput">
              <i class="iconfont3 icon-cz-add-circle-fill"></i>
              <span class="txt-create">
               {{ collectType == 0 ? '新建共享收藏夹' : '新建收藏夹' }}
            </span>
            </div>
          </template>


        </div>
      </div>
    </ModalMiddle>
  </div>
</template>

<script>
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {HappyScroll} from 'vue-happy-scroll';
// 引入css，推荐将css放入main入口中引入一次即可。
import 'vue-happy-scroll/docs/happy-scroll.css'
import fnFavorites from "@/fn/favorites/fnFavorites";
import {mapState} from "vuex";
import {IMG_BASE_URL} from "@/constant/PATH";
import {fnLocalStorage, fnLogin, fnUser} from "@/fn";
import {toggleMaterialCollect} from "@/api/material";
import {favoriteCreate} from "@/api/user/favorites";

/* 引入公共方法和常量 */
import * as fnCommon from '@/fn/_fn';
import * as CON_COMMON from '@/constant/_constant';
import {completeTaskByFanClub} from "@/api/fans/club";
import {COMMON} from "@/constant/COMMON";


export default {
  name: "FavoritesDefault",
  components: {HappyScroll}, //在组件内注册
  computed: {
    ...mapState('favorites',
      [
        "showDialog",
        "showDialogByCompany",
        "currentTupianInfo",
        "favoriteList",
        "favoriteHistory"
      ]
    ),
    ...mapState('user',
      [
        'personVipState',
        'personVipInfo',
        'businessVipState',
        'businessVipInfo',
        'companyVipState',
        'companyVipInfo',
        'userInfo',
        'companyInfoByPerson',
      ],
    ),
  },
  data() {
    return {
      PAGE_PATH,
      folderName: "", // 文件夹名称
      isShowFolderNameInput: false,   // 是否显示文件夹名称输入
      IMG_BASE_URL,
      isShowErrTxt: false,
      isNoVip: true,
      collectType: 0
    }
  },
  watch: {
    showDialog: {
      handler(n, o) {
        if (n == true) {
          setTimeout(() => {
            this.isNoVip = fnFavorites.getUserIsVip();
            console.log(this.isNoVip)
          }, 200)
        }

        if (n == false) {
          this.isShowFolderNameInput = false;
          this.folderName = '';
        }
      }
    },
    showDialogByCompany(n, o) {
      /* 初始化tab选中 */
      console.log({
        n, o
      })
      this.collectType = 0
    }
  },
  methods: {
    showFavoritesCreate() {
      fnFavorites.showCreateDialog(true);
    },

    close() {
      fnFavorites.showDialogByCompany(false)
    },

    favoriteCreateCancel() {
      this.folderName = "";
      this.isShowFolderNameInput = false;
    },

    favoriteCreate() {
      this.folderName=this.folderName.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\@\.]/g,'')

      if (this.folderName == '') {
        this.$myNotify.error("请先输入收藏夹名称")
        return false
      }


      let rgx = /^[\u4E00-\u9FA5A-Za-z0-9_]+$/;
      if (rgx.test(this.folderName)) {
        this.isShowErrTxt = false;
        let foldType = this.collectType == 0 ? 2 : 1;
        favoriteCreate({
          name: this.folderName,
          type: foldType
        }).then(res => {
          let {status, message} = res.data;
          if (status == 200) {
            this.isShowFolderNameInput = false;
            this.$myNotify.success("创建成功", () => {
              this.folderName = "";
              let type = this.collectType == 0 ? 'share' : 'private';
              this.$store.dispatch('favorites/getFavoriteList', {mode: type});


            })
          } else {
            this.$myNotify.error(message, () => {
              // this.folderName = "";
            })
          }
        })
      } else {
        console.log('失败')
        this.isShowErrTxt = true;

      }


    },

    /**
     *
     * @param favoriteInfo 收藏夹信息
     * @returns {Promise<void>}
     */
    async collect(favoriteInfo) {


      if (fnLogin.checkLogin()) {
        /* 获取图片信息 */
        let currentTupianInfo = this.currentTupianInfo;
        /* 获取当前收藏夹信息 favoriteInfo*/

        toggleMaterialCollect({
          uniq_id: currentTupianInfo.uniq_id,
          act: 'collect',
          pck: currentTupianInfo.package == 1 ? 'package' : 'general',
          favId: favoriteInfo.id,
          isFast: false
        }).then(res => {

          let {data, status, msg} = res.data;

          if (status == 1) {
            fnFavorites.showDialogByCompany(false)
            this.$store.commit('favorites/SET_COLLECT_SUCCESS_INFO', data)
            this.$store.commit('favorites/SET_SHOW_COLLECT_SUCCESS_DIALOG_TIMER', true)
            fnFavorites.showCollectSuccessDialog(true);

            /* 检查是否是领取任务 */
            setTimeout(() => {
              let {t_id, task_type} = this.$route.query
              if (task_type == 1) {
                completeTaskByFanClub({id: t_id}).then(res => {
                  let {status, data, message} = res.data;
                  if (status == 200) {
                    this.$myNotify.success("任务已完成")
                    this.$router.push({
                      query: {}
                    })

                  } else {
                    this.$myNotify.error(message)

                  }
                })
              }
            }, 1000)
          } else {
            this.$myNotify.error(msg)

          }


        }).catch(err => {
        })
      } else {
        fnLogin.loginIntercept(false)

      }

    },


    /* 数据统计点击 */
    dataTrackByPay() {
      fnCommon.fnPay.dataTrackByPay(CON_COMMON.CON_PAY.DATA_TRACK.FAVORITE_UPGRADE);
    },

    toggleCollectType(collectType) {
      this.collectType = collectType;

      if (collectType == 0) {
        this.$store.dispatch('favorites/getFavoriteList', {mode: 'share'})
      } else {
        this.$store.dispatch('favorites/getFavoriteList', {mode: 'private'})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.FavoritesDefault {

  /deep/ .CgModalMiddle {
    .Modal-cover {
      background: rgba(0, 0, 0, 0.25);
    }
  }


  .modal-content {
    width: 540px;
    //height: 403px;
    background: #FFFFFF;
    border-radius: 14px;
    padding: 40px 30px 0px 30px;
    box-sizing: border-box;


    .header {

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px 0 7px;
      //margin-bottom: 15px;
      padding-bottom: 15px;

      border-bottom: 1px solid #EAEFF6;

      .header-name {
        height: 28px;
        font-size: 20px;
        color: #2D2B33;
        line-height: 28px;
        display: flex;
        align-items: center;

        .header-name-item {
          margin-right: 30px;
          display: flex;
          align-items: center;
          color: rgba(45, 43, 51, 0.5);
          cursor: pointer;
          position: relative;

          &.ON {
            color: rgba(45, 43, 51, 1);

            &:after {
              content: '';
              display: inline-block;
              width: 100%;
              height: 3px;
              @include Position(1, 4, 0, -17px);
              background: rgba(37, 91, 255, 1);
            }
          }
        }
      }

      .txt-user-center {
        height: 16px;
        font-size: 12px;
        color: #424866;
        line-height: 16px;
        text-decoration: underline;

        @include ElementHover($color-primary, '');
      }
    }

    .content {
      height: 251px;
      overflow-y: hidden;
      width: 508px;

      /deep/ .happy-scroll-container {
        height: 251px !important;
        width: 508px !important;
      }

      /deep/ .happy-scroll-bar {
        position: relative;
        //right: 2px;
      }

      .item-container {
        height: 251px;
      }

      .favorites-list {

        .favorites-item {
          width: 480px;
          height: 48px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 21px 0 7px;
          box-sizing: border-box;
          position: relative;
          cursor: pointer;

          .layout-left {
            height: 100%;
            display: flex;
            align-items: center;

            .img-box {
              width: 32px;
              height: 32px;
              border-radius: 8px;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #FAFAFC;
              margin-right: 10px;

              .icon_folder {
                @include BgImage(18px, 16px, 'tupian/icon_folder.svg');
              }
            }

            img {
              width: 32px;
              height: 32px;
              border-radius: 8px;
              overflow: hidden;
            }

            .share-btn {
              width: 32px;
              height: 20px;
              line-height: 20px;
              text-align: center;
              background: #35D0BA;
              border-radius: 5px;
              font-size: 12px;
              font-weight: bold;
              color: #FFFFFF;
              margin: 0 5px 0 10px;
            }

            .favorites-name {
              max-width: 200px;
              height: 19px;
              font-size: 14px;
              color: #424866;
              line-height: 19px;
            }
          }

          .layout-right {
            .favorites-num {
              height: 20px;
              font-size: 14px;
              font-weight: bold;
              color: #596088;
              line-height: 20px;
            }

            .favorites-btn {
              width: 75px;
              height: 38px;
              background: #6969FF;
              border-radius: 8px;
              font-size: 14px;
              color: #FFFFFF;
              line-height: 38px;
              text-align: center;
              @include Position(1, 2, 5px, 50%);
              transform: translateY(-50%);
              display: none;
              cursor: pointer;
            }
          }


          &:hover {
            background: #F5F6FA;

            .favorites-btn {
              display: block !important;
            }
          }

        }
      }

      .no-more {
        height: 19px;
        font-size: 14px;
        color: #424866;
        line-height: 19px;
        text-align: center;
        padding: 15px 0;
      }

    }

    .footer {
      //height: 68px;
      //width: 481px;
      border-top: 1px solid #EAEFF6;
      display: flex;
      flex-direction: column;
      align-items: center;

      .no-vip {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;

        .txt-sx-box {
          display: flex;
          align-items: center;

          .icon-alert {
            font-size: 16px;
            color: rgba(238, 82, 83, 1);
            position: relative;
            margin-right: 10px;

            &:after {
              content: "";
              display: inline-block;
              width: 24px;
              height: 24px;
              background: rgba(238, 82, 83, 0.05);
              border-radius: 50%;
              @include Position(1, 5, 50%, 50%);
              transform: translate(-50%, -50%);
            }
          }

          .txt-sx {
            height: 16px;
            font-size: 12px;
            color: #EE5253;
            line-height: 16px;
          }
        }

        .upgrade-btn {
          width: 125px;
          height: 38px;
          background: #6969FF;
          border-radius: 8px;
          line-height: 38px;
          text-align: center;
          font-size: 14px;
          color: #FFFFFF;
          cursor: pointer;
        }
      }

      .vip {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 48px;
        margin: 10px 0;
        border-radius: 8px;
        @include ElementHover('', #F5F6FA);

        .icon-cz-add-circle-fill {
          font-size: 30px;
          color: $color-primary;
          margin-right: 10px;
        }

        .txt-create {
          height: 19px;
          font-size: 14px;
          font-weight: bold;
          color: #424866;
          line-height: 19px;
        }
      }

      .folder-name {
        width: 100%;
        position: relative;
        margin-bottom: 20px;
        border: 1px solid $color-primary;
        border-radius: 8px;
        margin: 10px 0;


        .fold-input {
          padding-right: 200px;
          box-sizing: border-box;
        }

        .btn-group {
          @include Position(1, 2, 6px, 50%);
          transform: translateY(-50%);
          background: #FFFFFF;

          display: flex;

          .btn-item {
            width: 80px;
            height: 38px;
            border-radius: 8px;
            font-size: 14px;
            line-height: 38px;
            text-align: center;
            cursor: pointer;
          }

          .btn-cancel {
            margin-right: 15px;
            background: #F6F7F8;
            color: #0A2540;
            @include ElementHover('', rgba(213, 215, 223, 1));
          }

          .btn-confirm {
            color: #FFFFFF;
            background: $color-primary;
            @include ElementHover('', rgba(120, 120, 255, 1));

          }
        }

        .err-txt {
          color: $color-fans;
          font-size: 12px;
          @include Position(1, 5, 10px, 60px);
        }
      }
    }

  }
}


/deep/ .el-input {
  .el-input__inner {
    background-color: #FFFFFF !important;
    height: 52px;
    line-height: 52px;
    border-radius: 8px;
    border-color: transparent;
    padding: 0 16px !important;

    &::placeholder {
      color: rgba(45, 43, 51, 0.6);
    }

    &:focus {
      //border-color: $color-primary;
      background-color: #FFFFFF !important;
      transition: all 1s ease-in-out;
    }
  }
}

/deep/ .el-form-item__error {
  padding-left: 16px;
}

.icon-question {
  //@include Position(1, 2, 12px, 50%);
  //transform: translateY(-50%);
  z-index: 10;
  margin: 0;
  position: relative;

  &:after {
    content: '';
    display: inline-block;
    padding: 10px;
    @include Position(1, 5, 50%, 50%);
    transform: translate(-50%, -50%);
  }

  &:hover {
    .hover-box {
      display: block;
    }
  }

  .hover-box {
    min-width: 140px;
    //height: 60px;
    background: #424866;
    border-radius: 4px;
    @include Position(1, 5, 24px, 50%);
    transform: translateY(-50%);
    font-size: 12px;
    color: #FFFFFF;
    line-height: 16px;
    padding: 6px 8px;
    display: none;

    &:after {
      display: inline-block;
      content: '';
      width: 8px;
      height: 8px;
      background: #424866;
      @include Position(1, 5, -4px, 50%);
      transform: translateY(-50%) rotate(135deg);
    }

    &:before {
      display: inline-block;
      content: '';
      width: 15px;
      height: 100%;
      @include Position(1, 4, -15px, 0);
      //background-color: pink;
    }
  }

  .hover-box2 {
    width: 180px;
  }
}
</style>
