<template>
  <div class="Headline hot-title">
    <span class="hot-pic-num" v-if="headline">{{ headline }}</span>
    <span class="hot-title-txt">{{ subhead }}</span>
  </div>
</template>

<script>
export default {
  name: "Headline",
  props:{
    headline: '', // 主标题
    subhead: '',  // 副标题
  }
}
</script>

<style lang="scss" scoped>
.hot-title {
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 0;

  .hot-pic-num {
    color: #6969FF;
    margin-right: 4px;
    font-size: 18px;
  }

  .hot-title-txt {
    color: #0A2540;
    line-height: 24px;
    letter-spacing: 1px;
    font-size: 18px;
  }
}
</style>
