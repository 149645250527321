<template>
  <div class="designer-platform">
    <Header />
    <Aside/>
    <Main>
      <div slot="DesignerPlatformMain" style="width: 100%;height: 100%;">
        <nuxt />
      </div>
    </Main>
  </div>
</template>

<script>


import {
  Aside,
  Header,
  Main,
} from "@/components/designer/layouts";

export default {
  components: {
    Aside,
    Header,
    Main
  }
}
</script>

<style lang="scss" scoped>
</style>
