<template>
  <div v-show="isShow"
    class=" container">
    <div class="__box-cover"/>
    <div class="__box-content">
      <div
        class="__box-close"
        @click="isShow = false">
        <i class="iconfont icon-cha _pointer"/>
      </div>
      <i class="iconfont icon-gou icon_font"/>
      <div class="text-member-type">
        {{ vipFullName }}购买成功
      </div>

      <div class="description">{{ description }}</div>
      <div @click="toUserCenter" class="user-center">{{ btnTxt }}</div>
    </div>
  </div>
</template>

<script>
import {USER_CENTER} from "@/constant/PATH";

export default {
  name: "PaySuccessDialogVue",
  props: {
    vipFullName: {
      type: String,
      default: ""
    },
    paySuccessUrl:{
      type: String,
      default: ""
    },
    btnTxt:{
      type: String,
      default: '进入个人中心'
    },
    description:{
      type: String,
      default: ''
    },
  },
  data() {
    return {
      isShow: false,
      USER_CENTER
    }
  },
  methods: {
    toUserCenter() {
      this.isShow = false;
      let routeUrl = this.paySuccessUrl === '' ? this.USER_CENTER : this.paySuccessUrl;
      window.open(routeUrl, '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "components/common/css/common.scss";

.container {
  @include ModalBox(516, 350);

  .__box-content {
    //min-height: 350px;
    background: #FFFFFF;
    //padding: 50px 0;
    border-radius: 14px;
    //@include LayoutFlex(2);
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 440px;
    height: 276px;
    box-sizing: border-box;

    .icon_font {
      font-size: 44px;
      color: $color-success;
      margin: 40px 0 14px;
    }

    .text-member-type {
      font-weight: bold;
      font-size: 20px;
      color: #424866;
      line-height: 26px;
      margin-bottom: 16px;
    }
    .description{
      font-size: 14px;
      color: #596088;
      line-height: 20px;
    }

    .user-center {
      width: 180px;
      height: 44px;
      background: #35D0BA;
      border-radius: 25px;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 44px;
      text-align: center;
      @include ElementHover('', #2FBAAA);
      @include Position(1,4,50%,28px);
      transform: translateX(-50%);
    }
  }
}

</style>
