import {queryJsConfig} from "../api/stat";

export function wechatShare(apiList, params) {
  let wx = {}
  if(process.client) {
    wx = require("weixin-js-sdk")
    queryJsConfig(params).then(res => {
      const {app_id, url, nonceStr, timestamp, signature} = res.data
      if (nonceStr) {
        wx.config({
          debug: false,
          appId: app_id,
          timestamp: timestamp,
          nonceStr: nonceStr,
          signature: signature,
          jsApiList: apiList
        });
        wx.ready(res => {
          wx.updateAppMessageShareData({
            title: '潮国创意_每天一个好设计', // 分享标题
            desc: '潮国创意_每天一个好设计', // 分享标题
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://pic.chaopx.com/chaopx_logo.jpg', // 分享图标
            success: function () {
              // 设置成功
            }
          })
          wx.updateTimelineShareData({
            title: '潮国创意_每天一个好设计', // 分享标题
            desc: '潮国创意_每天一个好设计', // 分享标题
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://pic.chaopx.com/chaopx_logo.jpg', // 分享图标
            success: function () {
              // 设置成功
            }
          })
        })
      }
    })
  }
}

