<template>
  <div class="SurveyUserIndustry SurveyUser">
    <div class="header">
      <i class="icon_job"/>
      <div class="title-box">
        <div class="headline">选择您从事职业(2/3)</div>
        <div class="subhead">我们将为您提供更加精准且适合您的创意设计</div>
      </div>
    </div>

    <div class="content industry-content">
      <div
        v-for="(vo,idx) in jobs"
        :key="vo"
        :class="['industry-item',{ on: idx === industryIndex }]"
        @click="toggleIndustry(vo,idx)"
      >{{ vo }}
      </div>
    </div>

    <div class="industry-input">
      <div class="name">其它行业：</div>
      <input  v-model="industryVal" type="text" placeholder="请填写行业"
              @input="listenInput"
      >
      <i v-if="industryVal !== '' " class="iconfont icon-cha2" @click="industryVal = ''"/>
    </div>

    <div class="btn-group">
      <div class="btn-common btn-previous-step" @click="previousStep">上一步</div>
      <div
        :class="['btn-common btn-submit',{ 'on': isNextStep }] "
        @click="nextStep"
      >下一步
      </div>
    </div>
  </div>
</template>

<script>
import { jobs  } from '../module'

export default {
  name: "SurveyUserIndustry",
  props:{
    step: 0
  },
  data() {
    return {
      jobs,
      industryIndex: -1,
      // isNextStep: false,
      industryVal: '',
      industryTxt:'',
      surveyUser:{
        job:''
      }
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    isNextStep: {
      get() {
        if (this.industryIndex > -1 || this.industryVal != '') {
          return true
        }
      },
      set() {
        if (this.industryIndex > -1 || this.industryVal != '') {
          return true
        }
      },
    }
  },
  watch: {

  },

  methods: {
    init(){
      let _surveyUser = this.$localStorage.getItem('SurveyUser');
      if (_surveyUser != undefined){
        let parseSurveyUser = JSON.parse(_surveyUser);
        let { profession } = parseSurveyUser;
        if (profession == undefined){
          return false;
        }
        this.surveyUser = parseSurveyUser;
        let findIndex = this.jobs.indexOf(profession);

        if (findIndex > -1){
          this.industryIndex = findIndex;
          this.surveyUser.job = this.jobs[findIndex];
        }else{
          this.industryVal = profession
          this.surveyUser.job = profession;
        }
      }
    },
    /* 禁止输入空字符串 */
    listenInput(){
      this.industryVal = this.industryVal.replace(/\s+/g,"");
      if (this.industryVal !== ''){
        this.resetForm(1)
        this.surveyUser.job = this.industryVal;
      }else{
        // this.surveyUser.job = '';
      }
    },

    resetForm(type = 1){
      /* 清空select */
      if (type === 1){
        this.industryIndex = -1;
      }else {
        this.industryVal = '';
      }
      this.surveyUser.job = '';
    },

    toggleIndustry(vo, idx) {
      if (this.industryIndex === idx) {
        this.resetForm(1)
        return false
      }
      this.resetForm(2);
      this.industryIndex = idx;
      this.surveyUser.job = jobs[idx];
    },

    previousStep(){

      console.log(this.surveyUser.job)
      let SurveyUser =  JSON.parse(this.$localStorage.getItem('SurveyUser'));
      SurveyUser.profession = this.surveyUser.job;
      this.$localStorage.setItem('SurveyUser',JSON.stringify(SurveyUser));

      this.$emit('previousStep',this.step-1)
    },

    nextStep() {
      if (this.isNextStep) {
        console.log(this.surveyUser.job)
        let SurveyUser =  JSON.parse(this.$localStorage.getItem('SurveyUser'));
        SurveyUser.profession = this.surveyUser.job;
        this.$localStorage.setItem('SurveyUser',JSON.stringify(SurveyUser));
        this.$emit('nextStep',this.step+1);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../common/common";
</style>
