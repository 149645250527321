export default ({app}) => {
  // 获取到store
  const store = app.store;
  const storeName = 'ChaoTuStore'
  // 获取到localStorage中store数据 有就存入
  if (window.localStorage.getItem(storeName)) {
    store.replaceState(Object.assign({}, store.state, JSON.parse(window.localStorage.getItem(storeName))))
  }
  // 在页面刷新时将vuex里的信息保存到localStorage里
  window.addEventListener('beforeunload', () => {
    window.localStorage.setItem(storeName, JSON.stringify(store.state))
  })
  window.addEventListener('pagehide', () => {
    window.localStorage.setItem(storeName, JSON.stringify(store.state))
  })
}


