/* tupian store */
import {setCustomCookie} from "@/utils/auth";

export default {
  namespaced: true,

  state() {
    return {
      /* 非盈利弹框 */
      isFreeDownDialog: {
        isShow: false,
      },

      /* 下载权益弹框 */
      DownloadInterestModal: {
        isShow: false,  // 显示
        isOpenTimer: false, // 是否开启定时器
        upgradeLink: '' // 设置升级跳转链接
      },
    }
  },
  mutations: {
    SET_FREE_DOWN_DIALOG(state, payload) {
      state.isFreeDownDialog.isShow = payload.isShow;
    },

    SET_DownloadInterestModal(state, payload) {
      state.DownloadInterestModal = {
        isShow: payload.isShow,
        isOpenTimer: payload.isOpenTimer,
        upgradeLink: payload.upgradeLink,
      }
      setCustomCookie('DownloadInterestModal', JSON.stringify(payload));
    },

  }
}
