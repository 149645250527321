<template>
  <div class="MaterialCount">
    <countTo
      :start-val="startVal"
      :end-val="endVal"
      :duration="duration"
    />
    +
  </div>
</template>

<script>
import countTo from 'vue-count-to';
import {getCookie} from "~/utils/auth";

export default {
  name: "MaterialCount",
  components: {countTo},
  props: {
    startVal: {
      type: Number,
      default: 100000
    },
    /*endVal: {
      type: Number,
      default: 0
    },*/
    duration: {
      type: Number,
      default: 2000
    }
  },
  data() {
    return {
      // startVal: 0,
      // endVal: 2017
      endVal: 0
    }
  },
  mounted() {
    setTimeout(()=>{
      this.endVal = parseInt(getCookie('MODEL_updateMaterialNum'));
    },100)
  }
}
</script>

<style lang="scss" scoped>
.MaterialCount{
  display: flex;
  align-items: center;
}
</style>
