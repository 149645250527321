<template>
  <div class="com_material-list ">
    <div
      v-for="(vo,idx) in list"
      :key="vo.uniq_id"
      class="material-item"

    >
      <div class="img-box">
        <NavigationTo :path="MATERIAL_DETAIL + '/' + vo.uniq_id + '.html'">
          <div slot="content" class="img-wrap">
            <img
              v-lazy=" IMG_BASE_URL + vo.proto_pic + '!/fw/336/quality/90/unsharp/true/compress/true'"
              class="material-img">
            <!-- 图片包角标 -->
            <i v-if="vo.package == 1" class="icon_badge"/>
          </div>
        </NavigationTo>
      </div>
      <div
        :class="[vo.isCollect === true ? 'on':'no']"
        class="collect _pointer"
        @click="toggleCollect(vo,idx)"
      >
        <i class="iconfont icon-collect"/>
      </div>


      <!-- 正版图标 -->
      <div v-if="vo.package != 1" class="legal-copy">正版</div>

      <NavigationTo :path="MEMBER_LICENSE_VIP">
        <div
          slot="content"
          v-if="vo.package != 1" class="_pointer legal-copy legal-copy-active">
          商用授权<i class="iconfont icon-a-arrowright"/>
        </div>
      </NavigationTo>


      <div class="material-title overflow-one">{{ vo.title }}</div>
    </div>
  </div>
</template>

<script>
import {
  IMG_BASE_URL,
  MATERIAL_DETAIL,
  MEMBER_LICENSE_VIP
} from "@/constant/PATH";
import {toggleMaterialCollect} from "@/api/material";
import {mapGetters} from 'vuex';

export default {
  name: "MaterialList",
  props: {
    list: {
      type: Array,
      default: () => []
    },
    type: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('user', ['isLogin'])
  },
  data() {
    return {
      val: [],
      IMG_BASE_URL,
      MATERIAL_DETAIL,
      MEMBER_LICENSE_VIP
    }
  },
  methods: {
    navigateTo(vo) {
      let path = MATERIAL_DETAIL + '/' + vo.uniq_id + '.html';
      window.open(path, '_blank');
    },
    showDetail(id) {
      this.$emit('showDetail', id);
    },

    toggleCollect(vo, idx) {
      let tempFlag = this.loginIntercept();
      if (!tempFlag) return;
      let isCollect = vo.isCollect || false
      const {uniq_id} = vo;
      let act = isCollect === false ? 'collect' : 'remove';
      toggleMaterialCollect({
        uniq_id,
        act
      }).then(res => {
        let msg = isCollect === false ? '收藏成功' : '已取消收藏';
        vo.isCollect = isCollect === false ? true : false;
        this.$set(this.list, idx, vo)
        this.$myNotify.success(msg);
      })

    },

    /* 登录拦截 */
    loginIntercept() {
      if (!this.isLogin) {
        this.$myNotify.show('请先登录', () => {
          let {path} = this.$route;
          if (path == '/' || path == '') {
            this.$store.commit("user/SET_IS_SHOW_LOGIN_HOME", true);
          } else {
            this.$store.commit("user/SET_IS_SHOW_LOGIN", true);
          }

        })
        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.com_material-list {
  display: flex;
  flex-wrap: wrap;

  .material-item {
    width: 336px;
    height: 440px;
    //background: #CCCCCC;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.06);
    border-radius: 14px;
    margin: 0 32px 40px 0;
    position: relative;
    transition: all 0.5s ease-in-out;
    overflow: hidden;

    display: inline-block;
    @include ElementHover('', '') {
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 26, 0.12) !important;
    }

    &:nth-child(4n) {
      margin-right: 0;
    }

    .img-box {
      display: inline-block;
      width: 100%;
      height: 100%;
      border-radius: 14px;
      position: relative;
      transition: all 0.5s ease-in-out;
      @include ElementHover('', '') {
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 26, 0.12) !important;
      }

      .material-img {
        display: inline-block;
        width: 100%;
        height: 100%;
        border-radius: 14px;
        transition: all 0.5s ease-in-out;
        @include ElementHover('', '') {
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 26, 0.12) !important;
        }
      }
    }

    .collect {
      @include Position(1, 2, 16px, -50px);
      width: 32px;
      height: 32px;
      background: #FFFFFF;
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      @include LayoutFlex(1);
      transition: all 0.5s ease-in-out;
      z-index: 2;

      &.on {
        background-color: #F8CC44;

        .icon-collect {
          color: #FFFFFF;
        }

        &:hover {
          background: #DEB740;
        }


      }

      &.no {
        .icon-collect {
          color: #596080;


        }

        &:hover {
          .icon-collect {
            color: #F8CC44;
          }
        }
      }

    }

    .material-tag {
      width: 64px;
      height: 28px;
      background: linear-gradient(134deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
      box-shadow: 0 2px 20px -1px rgba(0, 17, 144, 0.19);
      border-radius: 14px;
      //border: 1px solid #FFFFFF;
      backdrop-filter: blur(10px);
      @include Position(1, 5, 16px, -50px);
      font-size: 14px;
      color: #FFFFFF;
      line-height: 28px;
      text-align: center;
      transition: all 0.5s ease-in-out;
    }

    .material-title {
      width: 100%;
      @include Position(1, 4, 0, -99px);
      height: 76px;
      line-height: 76px;
      padding: 0 28px;
      box-sizing: border-box;
      background: rgba(0, 0, 26, 0.2);
      border-radius: 14px;
      font-size: 18px;
      color: #FFFFFF;
      letter-spacing: 1px;
      transition: all 0.5s ease-in-out;
      text-align: center;
    }

    .icon_badge {
      @include BgImage(71px, 68px, 'pic-package/icon_badge.svg');
      @include Position(1, 5, 0, 0);
      z-index: 3;
    }

    .legal-copy {
      display: block;
      transition: all 0.5s ease-in-out;
      @include Position(1, 5, 16px, 16px);
      line-height: 28px;
      background: linear-gradient(213deg, #FFF1A4 0%, #FFC04D 100%);
      border-radius: 6px;
      font-size: 14px;
      color: #A45F00;
      padding: 0 8px;


      &.legal-copy-active {
        display: none;
        padding-right: 4px;
      }
    }


    @include ElementHover('', '') {
      .material-img {
        transform: scale(1.1);
      }

      .material-title {
        bottom: 0;
      }
      .material-tag {
        top: 16px;
      }
      .collect {
        top: 16px;
      }
      .legal-copy-active {
        display: block;
      }
    }


  }

  @include Screen(1) {
    .material-item {
      width: 279px;
      height: 365px;
      margin: 0 28px 29px 0;

      .material-title {
        height: 66px;
      }
    }
  }
}
</style>
