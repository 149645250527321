<template>
  <div class="HeaderLoginDevice">
    <i
      v-show="device == DEVICE.iphone"
      class="iconfont icon-mobile icon_device"/>
    <i
      v-show="device == DEVICE.wx"
      class="iconfont icon-wechat2 icon_device"/>
    <i
      v-show="device == DEVICE.qq"
      class="iconfont icon-QQ2 icon_device"/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {DEVICE} from "@/constant/SYSTEM";

export default {
  name: "HeaderLoginDevice",
  data(){
    return{
      DEVICE
    }
  },
  computed: {
    ...mapState('system', ['device']),
  },
}
</script>

<style lang="scss" scoped>
.HeaderLoginDevice{
  position: relative;
  z-index: 11;
  .icon_device {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    font-size: 12px;
    @include LayoutFlex(1);
    @include Position(1, 3, 0, 0);
    color: #fff;
    z-index: 8;

  }

  .icon-mobile {
    background: #6969FF;
  }

  .icon-wechat2 {
    background: #51CF50;
  }

  .icon-QQ2 {
    background: #06B4FD;
  }
}
</style>
