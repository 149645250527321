<template>
  <div class="DesignerHeaderBar">
    <div class="bar">
      <DesignerHeaderBar />
    </div>
    <div class="bar-placeholder"></div>
    <nuxt/>
  </div>
</template>

<script>
import DesignerHeaderBar from '@/components/designer/header-bar'
export default {
  name: "LayoutsDesignerHeaderBar",
  components:{
    DesignerHeaderBar
  }
}
</script>

<style lang="scss" scoped>
.DesignerHeaderBar{
  .bar{
    width: 100%;
    //height: 72px;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px 0px rgba(0,0,26,0.0600);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 98;
  }
  .bar-placeholder{
    height: 72px;
  }
}
</style>
