<template>
  <!-- nav menu start -->
  <div class="menu-nav inner_center">
    <div class="recommend-word">
      精选 <br>
      推荐
    </div>
    <ul class="menu-nav-list ">
      <li
        v-for="(vo,index) in menuNavList"
        :key="vo.id"
        :class="['menu-nav-item', vo.className ,{ 'on': menuNavIndex === index }]"
        :style="{color: vo.color}"
        @mouseover="toggleMenu(index)"
      >
        <div
          class="_pointer"
          @click="navigatorTo(vo.link_url,vo)">{{ vo.title }}
        </div>
      </li>
    </ul>
  </div>
  <!-- nav menu end   -->
</template>

<script>
import {fnBrowser} from "~/fn";

export default {
  name: "RecommendNav",
  props: {
    menuNavList: {
      type: Array,
      default: () => []
    },
    /* 是否可点击跳转 */
    navIsClick: {
      type: Boolean,
      default: true
    },
    navIndex: {
      type: String | Number,
      default: -1
    },
  },
  watch: {
    navIndex:{
      handler(n, o) {
        if (n || n == 0) {
          this.menuNavIndex = n;
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      // menuNavList,      // 菜单列表
      menuNavIndex: -1,  // 菜单导航默认选中
    }
  },
  methods: {
    navigatorTo(path,vo) {
      if (this.navIsClick) {
        this.$emit('navigatorTo',vo)
        fnBrowser.navTo(path)
      }
    },
    toggleMenu(index){
      this.menuNavIndex = index
      this.$emit('toggleMenu',index)
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-nav {
  margin-bottom: 64px;
  display: flex;

  .recommend-word{
    width: 108px;
    height: 104px;
    background: linear-gradient(224deg, rgba(208, 147, 255,0.15) 1%,  rgba(105, 105, 255,0.15) 100%);
    //opacity: 0.15;
    border-radius: 20px;
    flex-shrink: 0;

    font-size: 32px;
    font-weight: bold;
    color: #2D2B33;
    line-height: 38px;
    letter-spacing: 1px;
    @include LayoutFlex(1);
    margin-right: 32px;
  }


  .menu-nav-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    .menu-nav-item {
      //width: 100px;
      text-align: center;
      color: #0A2540;
      transition: all 0.5s ease-in-out;
      //width: 76px;
      height: 40px;
      line-height: 40px;
      background: #F3F4F9;
      border-radius: 6px;
      padding: 0 24px;
      margin: 0 14px 14px 0;

      a {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }

    .on {
      color: #FFFFFF !important;
      background: $color-primary !important;

      a {
        color: #FFFFFF !important;
      }

    }
  }
}
</style>
