/* 按需加载Cg组件 */
import Vue from "vue";
import CgDialog from './dialog';
import CgModel from './modal';
import CgModelMiddle from './modal-middle';
// import CgBindIphone from '../components/user/bind-iphone/index.js'
import NavigationTo from './navigation-to';
import CgSelect from './form/select';
import CgInput from './form/input';

Vue.use(CgModelMiddle);
if (!Vue._use_components_Plugins) {
  Vue._use_components_Plugins = true
  Vue.use(CgDialog);
  Vue.use(CgModel);
  Vue.use(NavigationTo);
  Vue.use(CgSelect);
  Vue.use(CgInput);
}


// Vue.prototype.$CgBindIphone = CgBindIphone;
