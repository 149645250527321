import Clipboard from 'clipboard';
import myNotify from "@/utils/notify";

class FnClipboard {
  constructor() {

  }

  /**
   * 基于Clipboard封装 复制功能
   * @param txt 复制文本
   * @param e 复制dom对象
   * @returns {Promise<unknown>}
   */
  async copy(txt, e = '.text-copy') {
    return new Promise((resolve, reject) => {
      let clipboard = new Clipboard(e, {
        text: function () {
          return txt;
        }
      })
      clipboard.on('success', e => {
        myNotify.success("复制成功")
        resolve(e)
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        reject(e)
        myNotify.show("该浏览器不支持自动复制")
        // 释放内存
        clipboard.destroy()
      })
    })

  }

}

export default new FnClipboard();
