<template>

  <!-- 订阅 start -->
  <div class="subscribe-section _hide">
    <div class="subscribe-section-mask"/>
    <div class="inner_center">
      <h2 class="headline">本周最受欢迎的模板</h2>
      <div class="subhead">
        推送本周上新内容，实时了解最新潮流素材风向标 <br>
        随时了解每周最好的模版，提供邮箱以获取独家推荐！
      </div>
      <div class="subscribe-form">
        <input
          v-model="email"
          class="e-mail"
          type="text"
          placeholder="mail@email.com"
          @keyup.enter="subscribeSubmit">
        <span
          class="subscribe-submit _pointer"
          @click="subscribeSubmit">订阅推送</span>

        <!--  邮箱验证 -->
        <div class="email-tip">
          {{ verifyEmailMsg }}
        </div>
      </div>
    </div>
  </div>
  <!-- 订阅 end   -->

</template>

<script>
export default {
  name: "Subscribe",
  data(){
    return {
      email:"",
      verifyEmailMsg:"",
    }
  },
  methods:{
    /* 订阅推送 */
    subscribeSubmit() {
      let tempObj = this.$utils.verify.email(this.email);

      if (tempObj.status === 1) {
        this.verifyEmailMsg = '';
      } else {
        this.verifyEmailMsg = tempObj.msg;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.subscribe-section {
  height: 456px;
  text-align: center;
  position: relative;


  .subscribe-section-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(245, 246, 250, 1);
    z-index: -1;
  }

  .headline {
    font-size: 48px;
    font-weight: bold;
    color: #0A2540;
    line-height: 64px;
    letter-spacing: 2px;
    padding-top: 96px;
  }

  .subhead {
    height: 48px;
    font-size: 16px;
    color: #596088;
    line-height: 24px;
    letter-spacing: 1px;
    margin: 24px 0 48px;
  }

  .subscribe-form {
    width: 680px;
    height: 72px;
    background: #FFFFFF;
    box-shadow: 0 14px 28px 0 rgba(105, 105, 255, 0.08);
    border-radius: 14px;
    //overflow: hidden;
    margin: 0 auto;
    position: relative;
    z-index: 2;

    .e-mail {
      width: 100%;
      line-height: 72px;
      padding: 0 172px 0 24px;
      box-sizing: border-box;
      font-size: 18px;
      font-weight: bold;
      color: #001013;

      &::placeholder {
        color: #596088;
      }
    }

    .subscribe-submit {
      width: 160px;
      height: 60px;
      background: #6969FF;
      border-radius: 14px;
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 60px;
      text-align: center;
      letter-spacing: 2px;
      @include Position(1, 2, 6px, 6px);

      &:hover {
        background-color: #5E5EE8;
      }
    }

    .email-tip {
      text-align: left;
      height: 20px;
      font-size: 14px;
      color: #EE5253;
      line-height: 20px;
      padding: 14px 0 0 24px;
    }

  }
}

</style>
