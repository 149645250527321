/* 下载次数引导 */

import {fnLocalStorage, fnLogin, fnUser} from "@/fn";
import {getRmbEightCouponStatus, trackNewUserDialog} from "@/api/home";
import {HOME} from "@/constant/HOME";

class FnGuideDownloadCount {

  constructor() {
  }

  /**
   * 检测非vip（未开通过） 8.8红包未领取或已过期
   */
  async checkIsNoVipAndNoEightCoupon() {
    let temp = {
      state: -1,
      msg: '说明',
      type: -1
    }

    if (fnLogin.checkLogin() && fnUser.checkUserVipExcludeFree()) {

      /* 检测7天体验vip是否过期 */


      let downloadCount = fnUser.getUserDownloadCount();

      if (downloadCount.free_remain > 0) {
        temp.state = 1
        temp.msg = '剩余免费下载次数大于0'
      } else {

        /* 检查是否领取红包 */
        let couponResult = await getRmbEightCouponStatus();
        console.log(couponResult.data.data)
        let {popup, count} = couponResult.data.data;


        /* 红包是否有效(非过期和过期) is_valid*/

        if (count > 0) {
          /* 已领取红包 */
          if (popup === 0){
            return temp

          }else if (popup === 1){
            temp.state = 4
            temp.msg = '已领取8.8元红包未使用且已过期，弹8.8红包弹框';
            temp.type = HOME.GUIDE.DOWNLOAD_COUNTS.TYPE.RMB_EIGHT_COUPON
          }else if (popup === 2){
            temp.state = 3
            temp.msg = '已领取3次8.8红包且未使用，显示7天vip弹框';
            temp.type = HOME.GUIDE.DOWNLOAD_COUNTS.TYPE.FREE_SEVEN_VIP
          }
        } else {
          /* 未领取红包 */
          let local_showCouponDate = fnLocalStorage.getItem(HOME.GUIDE.DOWNLOAD_COUNTS.RMB_EIGHT_COUPON.SHOW_DATE_ARR);
          if (!local_showCouponDate) {
            temp.state = 4
            temp.msg = '已领取8.8元红包未使用且已过期，弹8.8红包弹框';
            temp.type = HOME.GUIDE.DOWNLOAD_COUNTS.TYPE.RMB_EIGHT_COUPON
          } else {
            if (local_showCouponDate.length >=3){
              temp.state = 3
              temp.msg = '已领取3次8.8红包且未使用，显示7天vip弹框';
              temp.type = HOME.GUIDE.DOWNLOAD_COUNTS.TYPE.FREE_SEVEN_VIP
            }else{
              temp.state = 4
              temp.msg = '已领取8.8元红包未使用且已过期，弹8.8红包弹框';
              temp.type = HOME.GUIDE.DOWNLOAD_COUNTS.TYPE.RMB_EIGHT_COUPON
            }
          }

        }
      }
    }

    console.log(temp)
    return temp;
  }


  /* 新人弹框数据统计 */
  async trackNewUser(position, type) {
    await trackNewUserDialog({
      position,
      type
    })
  }

}

export default new FnGuideDownloadCount();
