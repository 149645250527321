<template>
  <div class="SurveyUserIndustry SurveyUser SurveyAge">
    <div class="header">
      <i class="icon_age"/>
      <div class="title-box">
        <div class="headline">请选择性别及年龄(3/3)</div>
        <div class="subhead">选择您的性别和年龄段，帮助我们推荐更适合您的创意设计</div>
      </div>
    </div>

    <div class="age-content">

      <div class="age-choice-item">
        <div class="title">请选择性别</div>
        <div class="age-choice-content">
          <div class="age-choice"
               :class="['age-choice',{ on: idx === sexIndex }]"
               v-for="(vo,idx) in sex" :key="'sex' + vo "
               @click="toggleChoice(1,idx)"
          >{{ vo }}</div>
        </div>
      </div>

      <div class="age-choice-item">
        <div class="title">请选择年龄段</div>
        <div class="age-choice-content">
          <div class="age-choice"
               :class="['age-choice',{ on: idx === ageRangeIndex }]"
               v-for="(vo,idx) in ageRange" :key="'ageRange' + vo "
               @click="toggleChoice(2,idx)"
          >{{ vo }}</div>
        </div>
      </div>
    </div>

    <div class="btn-group">
      <div class="btn-common btn-previous-step" @click="previousStep">上一步</div>
      <div
        :class="['btn-common btn-submit',{ 'on': isNextStep }] "
        @click="submit"
      >提交
      </div>
    </div>
  </div>
</template>

<script>
import { ageRange , sex} from '../module'
import {submitSurveyUser} from "@/api/survey-user";

export default {
  name: "SurveyUserAge",
  props:{
    step: 0
  },
  data() {
    return {
      sexIndex: -1,
      ageRangeIndex: -1,
      industryVal: '',
      surveyUser:{
        industry:''
      },
      ageRange,
      sex
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    isNextStep: {
      get() {
        if ( this.sexIndex > -1 &&  this.ageRangeIndex > -1 ) {
          return true
        }
      },
      // set() {
      //   if (this.industryIndex > -1 || this.industryVal !== '') {
      //     return true
      //   }
      // },
    }
  },
  watch: {

  },

  methods: {
    init(){
      let _surveyUser = this.$localStorage.getItem('SurveyUser');
      if (_surveyUser != undefined){
        let parseSurveyUser = JSON.parse(_surveyUser);
        let { sex, age } = parseSurveyUser;
        this.surveyUser = parseSurveyUser;
        let findIndex = this.sex.indexOf(sex);
        let findAgeIndex = this.ageRange.indexOf(age);
        this.sexIndex = (findIndex !== -1)? findIndex : -1;
        this.ageRangeIndex = (findAgeIndex !== -1)? findAgeIndex : -1;
      }
    },

    toggleChoice(type,idx){
      if (type === 1){
        this.sexIndex = (this.sexIndex === idx) ? -1 : idx
      }else{
        this.ageRangeIndex = (this.ageRangeIndex === idx) ? -1 : idx
      }
    },

    /* 保存选中信息 */
    saveSurveyData(){
      let SurveyUser =  JSON.parse(this.$localStorage.getItem('SurveyUser'));
      SurveyUser.sex = (this.sexIndex !== -1)? this.sex[this.sexIndex] : '';
      SurveyUser.age = (this.ageRangeIndex !== -1)? this.ageRange[this.ageRangeIndex] : '';
      this.$localStorage.setItem('SurveyUser',JSON.stringify(SurveyUser));
    },

    previousStep(){
      this.saveSurveyData();
      this.$emit('previousStep',this.step-1)
    },

    submit() {
      let tempSurveyUser = JSON.parse(this.$localStorage.getItem('SurveyUser'))
      if(this.sexIndex !== -1 && this.ageRangeIndex !== -1){
        tempSurveyUser.sex = this.sex[this.sexIndex];
        tempSurveyUser.age = this.ageRange[this.ageRangeIndex];
        this.$emit('submit',tempSurveyUser)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../common/common";

.age-content{
  margin: 40px 0 46px;
  .age-choice-item{
    margin-bottom: 28px;
    .title{
      font-size: 15px;
      color: #2D2B33;
      line-height: 22px;
      margin-bottom: 24px;
    }
    .age-choice-content{
      display: flex;
      flex-wrap: wrap;
      .age-choice{
        width: 156px;
        height: 48px;
        background: #F5F6FA;
        border-radius: 24px;
        font-size: 15px;
        color: #596088;
        line-height: 48px;
        text-align: center;
        margin: 0 20px 20px 0;
        transition: all 0.3s ease-in;
        cursor: pointer;

        &.on {
          color: #FFFFFF;
          background: linear-gradient(224deg, #D093FF 1%, #6969FF 100%, #6969FF 100%);
          box-shadow: 0px 0px 0px 4px rgba(251, 247, 255, 0.2);
          cursor: pointer;
        }


        &:nth-child(4n){
          margin: 0 0 20px 0;
        }
      }
    }

    &:last-child{
      margin-bottom: 0;
    }
  }
}
</style>

