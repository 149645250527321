/* 拉新h5 api */
import request from "@/utils/request";

export function setInviterCookieByH5(query) {
  return request({
    url: '?m=InviteAct&a=setH5InviteCookie',
    method: 'get',
    params: query
  })
}

/* 邀请记录 和 邀请总记录 */
export function getInviterRecordByH5(query) {
  return request({
    url: '?m=InviteAct&a=inviteH5Record',
    method: 'get',
    params: query
  })
}

/* 拉新活动埋点
    act = view  :进入页面的时候调用,统计页面IP
    act = invited: 进入被邀请页的时候调用,统计被邀请IP数
    act = share :复制分享链接的时候调用,统计分享人数
* */
export function trackInviteH5(query) {
  return request({
    url: '?m=InviteAct&a=h5Stat',
    method: 'get',
    params: query
  })
}
