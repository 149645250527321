// import Vue from 'vue'
// import Element from 'element-ui/lib/element-ui.common'
// import locale from 'element-ui/lib/locale/lang/en'
//
// export default () => {
//   Vue.use(Element, { locale })
// }

import Vue from 'vue';
import {
  DatePicker, Input, Button, Form, FormItem, Table, TableColumn, Image, Upload,
  Select,
  Option,
  Dialog, Skeleton, SkeletonItem,
  Progress,
  Timeline,
  TimelineItem,
  Container,
  Header,
  Aside,
  Main,
  Menu,
  MenuItem,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Submenu,
  MenuItemGroup,
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
  Pagination,
  Drawer,
  TimePicker
} from 'element-ui';


Vue.use(DatePicker);
Vue.use(Input);
Vue.use(Button);
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Image)
Vue.use(Upload)
Vue.use(Select)
Vue.use(Option)
Vue.use(Dialog)
Vue.use(Skeleton)
Vue.use(SkeletonItem)
Vue.use(Progress)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Pagination)
Vue.use(Drawer)
Vue.use(TimePicker)
