import request from '@/utils/request'
import qs from 'qs'

/* 用户画像 */
export function submitSurveyUser(data) {
  return request({
    url: '/?m=Api&a=personas',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 获取用户画像填写状态 */
export function getSurveyUserState(query) {
  return request({
    url: '/?m=Api&a=showPersonas',
    method: 'get',
    params: query
  })
}
