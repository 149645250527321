import Vue from 'vue'
import VueLazyLoad from 'vue-lazyload'

Vue.use(VueLazyLoad, {
  preLoad: 1,
  // error: '',
  loading: '//static.588ku.com/chaotu/public/loading.gif',
  attempt: 2,
  throttleWait: 500
})
