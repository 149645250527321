import * as CON_COMMON from "@/constant/_constant";

class UtilsController{

  /* 路由打开 */
  navigationTo(url, target = '_blank') {
    /* 判断是否是非本站 */
    url = url.indexOf('http') == -1 ? CON_COMMON.CON_BASIC.BASE_URL + url : url
    let a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('target', target);
    a.setAttribute('rel', 'noopener noreferrer');
    document.body.appendChild(a);
    a.click();
  }
}

export default new UtilsController();
