/* 下载次数引导 */
export const HOME = {


  /* 新人优惠券类型(仅引导下载次数弹框使用) */
  NEW_USER_COUPON_TYPE: {
    COUPON: 'coupon',    // 8.8 红包
    RED_PACKET: 'redPacket', // 新人注册自动发放3 5优惠券
    HORIZON: 'horizon', // 新人10天优惠券点击统计
    RENEW: 'renew', // 续费红包 10元通用
    COMPANY_ENJOY: 'companyEnjoy', // 企业专享

    /* 弹框位置 */
    POSITION: {
      CURRENT_PAGE: 1,  // 预览
      TO_PAY_PAGE: 2,   // 点击

    }
  },

  GUIDE: {
    /* 下载次数 */
    DOWNLOAD_COUNTS: {

      /* 弹框类型 */
      TYPE: {
        RMB_EIGHT_COUPON: 'RMB_EIGHT_COUPON',
        FREE_SEVEN_VIP: 'FREE_SEVEN_VIP',
        DEFAULT: ''
      },


      /* 8.8红包 */
      RMB_EIGHT_COUPON: {
        SHOW_DATE_ARR: 'showDateArr',   // 红包显示天数数组 （ 显示3天后不再显示，呆次数用完重置 ）
      },
      /* 免费7天vip */
      FREE_SEVEN_VIP: {}
    }
  },

  HOME_MAP : [
    {
      id: '',
      label: '最新上线',
      ref:'TimelinePicMap'
    },
    {
      id: '',
      label: '专题精选',
      ref: 'HomeTopicRecommendMap'
    },
    {
      id: '',
      label: '产品定位',
      ref: 'WorkEfficiencyMap'
    },
    {
      id: '',
      label: '企业用户',
      ref: 'CooperateCompanyMap'
    }
  ]
}
