class FnTimer {
  constructor() {
  }

  toTop(time = 20,step = 50) {
    let top = document.documentElement.scrollTop || document.body.scrollTop
    // 实现滚动效果
    const timeTop = setInterval(() => {
      document.body.scrollTop = document.documentElement.scrollTop = top -= step
      if (top <= 0) {
        clearInterval(timeTop)
      }
    }, time)
  }
}

export default new FnTimer();
