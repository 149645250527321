<template>
  <div
    v-if="isShow"
    class="__box-wrap">
    <div class="__box-content box-content">
      <div
        class="__box-close"
        @click="isShow = false">
        <i class="iconfont icon-cha _pointer"/>
      </div>
      <div class="headline">{{ headline }}</div>
      <div class="subhead">{{ subhead }}</div>
      <div class="btn-handle">
        <div
          class="btn-item btn-cancel _pointer"
          @click="cancel">{{ cancelText }}
        </div>
        <div
          class="btn-item btn-confirm _pointer"
          @click="confirm">{{ confirmText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Message",
  components: {},
  props: {
    headline: {
      type: String,
      default: "真的要放弃开通吗？",
    },
    subhead: {
      type: String,
      default: "相遇不易，再考虑考虑呗",
    },
    cancelText: {
      type: String,
      default: "取消",
    },
    confirmText: {
      type: String,
      default: "确认",
    },

  },
  data() {
    return {
      isShow: false
    }
  },
  methods: {
    cancel() {
      this.isShow = false;
      this.$emit("cancel");
    },
    confirm() {
      this.isShow = false;
      this.$emit("confirm");
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/components/common/css/common.scss";

.__box-wrap {
  background: rgba(0,0,0,0.5);
  backdrop-filter: blur(0px);

  .box-content {
    width: 516px;
    height: 350px;
    border-radius: 14px;
    background-color: #fff;
    text-align: center;
    padding-top: 84px;

    .headline {
      font-size: 24px;
      font-weight: bold;
      color: #424866;
      line-height: 31px;
      letter-spacing: 1px;
    }

    .subhead {
      font-size: 14px;
      color: #596088;
      line-height: 20px;
      margin: 12px 0 86px;
    }

    .btn-handle {
      @include LayoutFlex(3);
      margin: 0 78px;

      .btn-item {
        width: 160px;
        height: 44px;
        background: #E0E3E9;
        border-radius: 25px;
        font-size: 14px;
        color: #596088;
        line-height: 44px;
        text-align: center;
      }

      .btn-cancel {
        @include ElementHover('', #C8CBD4);
      }

      .btn-confirm {
        background: $color-primary;
        color: #FFFFFF;
        @include ElementHover('', #5E5EE8);
      }
    }

  }
}
</style>
