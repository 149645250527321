<template>
  <!--  搜索下拉框  -->
  <div
      v-show="isShowCombobox"
      class="search-combobox">
    <div
      v-if="historyList.length"
      class="search-box search-history">
      <div class="header">
        <div class="title">历史搜索</div>
        <i
          v-if="historyList.length"
          class="iconfont icon-trash _pointer"
          @mousedown="clearSearchHistory"/>
      </div>
      <div class="content">
        <div
          v-for="vo in historyList.slice(0,12)"
          :key="vo"
          class="keyword-item _pointer"
          @mousedown="selectSearchKeyword(vo)">
          {{ vo }}
        </div>
      </div>
    </div>
    <div class="search-box search-recommend">
      <div class="header">
        <div class="title">热搜推荐</div>
      </div>
      <div class="content">
        <div
          v-for="(vo,index) in recommendList"
          :key="index"
          class="recommend-item"
          @click="listenHotWordClick(vo)"
        >
          <span
            :class="[ 'index', { 'index-first': index === 0 },
                      { 'index-second': index === 1 },
                      { 'index-third': index === 2 },
          ]">{{ index + 1 }}</span>
          <span class="keyword" :style="{ color: vo.font_color }">{{ vo.keyword }}</span>
          <span
            :class="[ 'tag', { 'tag-third': vo.mark == 1 },
                      { 'tag-second': vo.mark == 2 },
                      { 'tag-first': vo.mark == 3 },
          ]">{{ vo.mark == 1 ? '新' : vo.mark == 2 ? "火" : vo.mark == 3 ? "爆" : '' }}</span>
        </div>
      </div>
    </div>

    <div class="search-box search-recommend search-recommend2">
      <div class="header">
        <div class="title">精选推荐</div>
        <a
          href="https://chaopx.com/topic/0-1-0-new.html"
          target="_blank"
          class="more MORE">
          查看更多
          <i class="iconfont icon-a-arrowright" />
        </a>
      </div>
      <div class="pic-list">
        <div
          v-for="(vo,index) in recommendList2"
          :key="index"
          class="pic-item"
          @click="listenHotWordClick(vo)"
        >

          <img :src="IMG_BASE_URL + '/' + vo.keyword"
               class="hot-pic-item-img">
          <!--            <TupianMask :link="vo.link_url"/>-->
        </div>
        <a
          href="https://chaopx.com/topic/0-1-0-new.html"
          target="_blank"
          class="pic-item pic-item-more">
          查看更多 <i class="iconfont icon-a-arrowright" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {IMG_BASE_URL, MATERIAL_LIST} from "@/constant/PATH";


export default {
  name: "SearchComboBox",
  props: {
    recommendList: {
      type: Array,
      default: () => [
        "全站终身限时低价",
        "春节",
        "元宵节",
        "五福",
        "圣诞节",
        "中秋节",
        "劳动节",
        "父亲节",
        "母亲节",
        "好多节",

      ]
    },
    recommendList2: {
      type: Array,
      default: () => []
    },
    historyList: []
  },
  data() {
    return {
      isShowCombobox: false,
      // historyList: [],
      IMG_BASE_URL
    }
  },
  mounted() {
    /* 读取本地搜索历史记录 */
    if (process.client) {
      // this.historyList = this.$localStorage.getItem('searchKeyword') || [];
    }
  },
  methods: {
    clearSearchHistory() {
      // this.historyList = [];
      this.$localStorage.setItem('searchKeyword', []);
      this.$emit('clearSearchHistory')
    },

    selectSearchKeyword(vo) {
      this.$emit("selectSearchKeyword", vo)
    },
    listenHotWordClick(vo) {
      this.$store.commit('base/SET_SEARCH_VAL', vo.keyword);
      this.$emit("listenHotWordClick", vo)
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes fadeinT {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.search-combobox {
  width: 700px;
  //height: 356px;
  background: #FFFFFF;
  box-shadow: 0 0 8px 0 rgba(0, 0, 26, 0.08);
  border-radius: 12px;
  padding: 24px 27px 24px 27px;
  box-sizing: border-box;
  z-index: 2;
  animation: fadeinT 0.3s 1;
  width: 870px;

  .search-box {
    .header {
      @include LayoutFlex(3);

      .title {
        font-size: 14px;
        font-weight: bold;
        color: #2D2B33;
        line-height: 20px;
      }

      .icon-trash {
        font-size: 17px;
        color: rgba(89, 96, 128, 0.5);
        @include ElementHover(#596080, '')
      }
    }

  }

  .search-history {
    margin-bottom: 16px;

    .header {
      margin-bottom: 14px;
    }

    .content {
      width: 100%;
      //max-height: 72px;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;

      .keyword-item {
        //width: 25%;
        box-sizing: border-box;
        display: inline-block;
        padding: 0 12px;
        height: 28px;
        line-height: 28px;
        background: #ECEFF6;
        border-radius: 24px;
        font-size: 13px;
        color: #596088;
        margin: 0 8px 8px 0;

        @include ElementHover('', #E0E3E9)
      }
    }

  }

  .search-recommend {
    .header {
      margin-bottom: 12px;
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      //justify-content: space-between;
      align-items: center;
      //max-height: 160px;
      overflow: hidden;

      .recommend-item {
        width: 204px;
        height: 32px;
        @include LayoutFlex(1);
        @include ElementHover('', linear-gradient(270deg, rgba(245, 246, 250, 0) 0%, #F5F6FA 100%)) {
          border-radius: 4px;
        }
      ;

        &:nth-child(2n) {
          margin-right: 0;
        }

        .index {
          width: 18px;
          height: 20px;
          font-size: 14px;
          font-weight: bold;
          color: rgba(0, 16, 19, 0.3);
          line-height: 20px;
          text-align: center;
        }

        .index-first {
          color: #FF595E;
        }

        .index-second {
          color: #FF924C;
        }

        .index-third {
          color: #FFCA3A;
        }

        .keyword {
          display: inline-block;
          width: 142px;
          height: 20px;
          font-size: 14px;
          //color: #2D2B33;
          line-height: 20px;
          margin: 0 8px;
        }

        .tag {
          display: inline-block;
          width: 16px;
          height: 16px;
          background: #FFFFFF;
          border-radius: 2px;
          text-align: center;
          line-height: 16px;
          font-size: 13px;
          font-weight: 500;
          color: #FFFFFF;
        }

        .tag-first {
          background: #FF595E;
        }

        .tag-second {
          background: #FF924C;
        }

        .tag-third {
          background: #35D0BA;
        }

      }

    }
  }

  .search-recommend2 {
    margin-top: 18px;

    .header{

      .more{
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #424866;
        line-height: 20px;
        display: none;

        &:hover{
          color: $color-primary;
        }


        .icon-a-arrowright{
          margin-left: 5px;
          font-size: 16px;
          //color: rgba(89, 96, 128, 1);

        }
      }
    }

    .pic-list {
      display: flex;

      .pic-item {
        display: inline-block;
        width: 204px;
        height: 124px;
        border-radius: 8px;
        margin-right: 16px;
        overflow: hidden;
        cursor: pointer;

        width: 204px;
        height: 124px;

        &:last-child {
          margin-right: 0;
        }

        img {
          display: inline-block;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          transition: all 0.5s ease;

          &:hover {
            transform: scale(1.1);
          }
        }


      }

      .pic-item-more{
        width: 154px;
        height: 124px;
        background: linear-gradient(316deg, rgba(105,105,255,0.15) 0%, rgba(105,105,255,0.05) 100%);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #596088;
        .icon-a-arrowright{
          display: none;
        }
        &:hover{
          background: linear-gradient(316deg, rgba(105,105,255,0.15) 0%, rgba(105,105,255,0.05) 100%);
          color: #6969FF;
          .icon-a-arrowright{
            display: block;
          }
        }
      }


    }
  }
}
</style>
