<template>
  <div class="hot-pic FestivalPicList" >
    <a
      :href="vo.link_url"
      :title="vo.title"
      target="_blank"
      v-for="(vo,idx) in picList"
      :key="vo.link_url + 'pic'"
      class="hot-pic-item"
      @click="picListClick(vo)"
    >
      <img :src="BASE_IMG_URL + '/' + vo.pic_url  + '!/progressive/true' "
           class="hot-pic-item-img"
           :alt="vo.title"
           >
      <!--            <TupianMask :link="vo.link_url"/>-->

      <div class="hot-pic-mask overflow-one">
        <!--              {{ vo.title }}-->
        {{ vo.month | repairZero }}月{{ vo.day }}日
      </div>
    </a>
  </div>
</template>

<script>
import {BASE_IMG_URL,BASE_IMG_UPLOAD} from "@/constant/_constant/CON_BASIC";
export default {
  name: "FestivalPicList",
  data(){
    return{
      BASE_IMG_URL,
      BASE_IMG_UPLOAD
    }
  },
  props:{
    picList: []
  },
  methods:{
    picListClick(){
      this.$emit('picListClick')
    }
  },
  filters: {
    repairZero(val) {
      val = parseInt(val)
      return val < 10 ? ('0' + Number(val)) : val;
    }
  },
}
</script>

<style lang="scss" scoped>
.hot-pic {
  display: flex;
  margin-bottom: 20px;
  height: 172px;

  .hot-pic-item {
    display: inline-block;
    width: 132px;
    height: 172px;
    box-shadow: 0px 0px 4px 0px rgba(213, 221, 236, 0.4);
    border-radius: 8px;
    margin-right: 16px;
    position: relative;
    overflow: hidden;
    width: 140px;
    height: 180px;
    margin-bottom: 16px;

    &:last-child {
      margin-right: 0;
    }

    img {
      width: 100%;
      vertical-align: middle;
      transition: all 0.5s ease;
    }


    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    .hot-pic-mask {
      @include Position(1, 4, 0, 4px);
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: 70px;
      height: 18px;
      line-height: 16px;
      text-align: center;
      @include BgImage(70px,18px,'header/icon_tx.png');
      font-size: 11px;
      color: #596088;
    }

  }
}

</style>

