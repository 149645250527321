<template>
  <div class="CreatorHeader">

    <el-container>
      <el-header class="el-header">
        <div class="header-wrap ">
          <div class="bar-left">
            <a
              href="/"
              target="_blank">
              <i class="iconfont icon-logo"/>
            </a>
            <i class="dot"/>
            <span> 设计师创作平台</span>
          </div>

          <!-- 头像下拉 -->
          <no-ssr>
            <HeaderLogin/>
          </no-ssr>
        </div>
      </el-header>
    </el-container>
  </div>
</template>

<script>

/* 引入公共方法和常量 */
import * as fnCommon from '@/fn/_fn';
import * as CON_COMMON from '@/constant/_constant';

/* 引入组件 */
import HeaderLogin from '@/components/common/header/header-login';


/* 引入VUEX */
import {mapState} from "vuex";

/* 引入API */

export default {
  name: "CreatorHeader"

  , components: {
    HeaderLogin
  }

  , props: {}

  , watch: {}

  , computed: {}

  , data() {
    return {
      interceptMouse: true
    }
  }

  , methods: {}

}
</script>

<style lang="scss" scoped>
/* 头像下拉样式重写 */
.AvatarCombobox {
  /deep/ > .avatar-box {
    width: 36px !important;
    height: 36px !important;
  }

  /deep/ .text-login {
    color: #001013 !important;
  }
}

.CreatorHeader{

  .el-header{
    height: 71px !important;
  }
}


.header-wrap {
  width: 100%;
  height: 71px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 20px -1px rgba(0, 17, 144, 0.05);
  //backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 88;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;

  .bar-left {
    display: flex;
    align-items: center;

    .icon-logo {
      font-size: 24px;
      color: $color-primary;
      margin-right: 10px;
    }

    .dot {
      display: inline-block;
      width: 4px;
      height: 4px;
      color: #001013;
      border-radius: 50%;
      margin: 0 10px;
      background-color: #001013;
    }

    span {
      font-size: 16px;
      color: #001013;
      line-height: 22px;
      letter-spacing: 1px;
    }
  }

}

.avatar-box {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.6);
  margin-right: 23px;
  position: relative;
  cursor: pointer;

  .UserCombobox {
    @include Position(1, 2, 0, 55px);
    z-index: 5;
  }

  .avatar {
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .icon_device {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 16px;
    @include LayoutFlex(1);
    @include Position(1, 3, 0, 0);
    color: #fff;

  }

  .icon-mobile {
    background: #6969FF;
  }

  .icon-wechat2 {
    background: #51CF50;
  }

  .icon-QQ2 {
    background: #06B4FD;
  }

}

.text-login {
  font-size: 16px;
  color: #001013;
  line-height: 52px;
  letter-spacing: 1px;

}

@include Screen(3) {
  .inner_center {
    width: 1920px;
    padding: 0 64px;
    box-sizing: border-box;
  }
}
</style>
