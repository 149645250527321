/* store方法 */

const moduleFlag = true;
const methods = 'setUserBindIphoneModalStatus';
const methods_setIsShowUserCertificationModal = 'setIsNeedShowUserCertificationModal';
const storePath = '_Privately/User/';

export const UserBindIphoneModalState = {
  userBindIphoneModalStatus: false,
  isNeedShowUserCertificationModal: false,  // 用于绑定手机后是否弹出实名认证弹框的flag
}

export const UserBindIphoneModalMutations = {
  [methods](state, payload) {
    moduleFlag && (state.userBindIphoneModalStatus = payload)
  },
  [methods_setIsShowUserCertificationModal](state, payload) {
    moduleFlag && (state.isNeedShowUserCertificationModal = payload)
  },
}

/* 封装调用方法 */
export function setUserBindIphoneModalStatus(store, payload) {
  moduleFlag && store.commit(storePath + methods, payload);
}

export function setIsNeedShowUserCertificationModal(store, payload) {
  moduleFlag && store.commit(storePath + methods_setIsShowUserCertificationModal, payload);
}
