export const recordMenu = [
  {
    name:'VIP会员',
    iconClass:'icon-user-vip',
    module:'invoice',
    dataName:'invoiceRecord',
    id: 0
  },
  {
    name:'优惠券',
    iconClass:'icon-user-eye',
    module:'glance',
    id: 7
  },
  {
    name:'收藏记录',
    iconClass:'icon-user-collect',
    module:'collect',
    dataName:'collectRecord',
    id: 1
  },
  {
    name:'下载记录',
    iconClass:'icon-user-download',
    module: 'download',
    dataName:'downloadRecord',
    id: 2
  },
  /*{
    name:'企业授权',
    iconClass:'icon-user-download',
    module: 'companyDownload',
    dataName:'companyMemberRecord',
    id: 3
  },*/
  /*{
    name:'图片包购买',
    iconClass:'icon-packgerenzhongxin',
    module: 'packageOrder',
    dataName:'picPackageRecord',
    id: 4
  },
*/
 /* {
    name:'单张购买记录',
    iconClass:'icon-packgerenzhongxin',
    module: 'packageOrder',
    dataName:'leafletRecord',
    id: 8
  },*/
  {
    name:'个人信息',
    iconClass:'icon-user-profile',
    module: 'information',
    // dataName:'invoiceRecord',
    id: 5
  },
  {
    name:'兑换码查询',
    iconClass:'icon-user-profile',
    module: 'information',
    // dataName:'invoiceRecord',
    id: 6
  },
]
