<template>
  <div class="CategoryListVertical container">
    <a class="category-item"
       v-for="item in list" :key="item.id"
       :href="item._link"
       target="_blank"
       :title="item.name || item.title"
       @click="CategoryListVerticalClick"
    >
      {{ item.name || item.title }}
    </a>
  </div>
</template>

<script>
export default {
  name: "CategoryListVertical",
  props:{
    list: []
  },
  methods:{
    CategoryListVerticalClick(){
      this.$emit('CategoryListVerticalClick')
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  display: flex;
  flex-direction: column;

  .category-item{
    display: inline-block;
    margin-bottom: 14px;
    font-size: 13px;
    color: #0A2540;
    line-height: 17px;

    @include ElementHover($color-primary, '');
  }
}
</style>
