<!--
/**
* @Author: wulizhou
* @Description: 创作者中心布局 - aside
* @Date:
**/
-->

<template>
  <div class="CreatorAside ">

    <div class="handle-group">
      <CreatorBtn
        class="reapply-btn"
        text="上传作品"
        type="purple"
        width="208px"
        height="56px"
        radius="8px"
        font-size="15px"
        @handClick="uploadWork"
      >
        <i class="iconfont3 icon-cz-paint-fill2" slot="icon"></i>
      </CreatorBtn>
      <CreatorBtn
        class="reapply-btn"
        text="领取任务"
        type="purplePlain"
        width="208px"
        height="56px"
        radius="8px"
        font-size="15px"
        @handClick="getTask"
      >
        <i class="iconfont3 icon-cz-paint-fil1" slot="icon"></i>
      </CreatorBtn>
    </div>

    <el-aside>
      <el-menu :default-active="activeIndex">

        <!-- 无子元素 -->
        <template v-for="(item,index) in asideList">

          <el-menu-item v-if="item.children.length == 0"
                        :index="index.toString()"
                        @click="toggleMenu(item)"
          >
            <i class="iconfont3" :class="item.iconClass"></i>{{ item.label }}
          </el-menu-item>


          <el-submenu v-if="item.children.length"
                      :index="index.toString()"
          >
            <template slot="title">
              <i class="iconfont3" :class="item.iconClass"></i>{{ item.label }}
            </template>
            <el-menu-item
              :index=" (index + '-' + subIndex).toString()"
              v-for="(subItem,subIndex) in item.children"
              :key="subIndex"
              @click="toggleMenu(subItem)"
            >{{ subItem.label }} - {{ (index + '-' + subIndex).toString() }}
            </el-menu-item>
          </el-submenu>


        </template>

      </el-menu>
    </el-aside>

  </div>
</template>

<script>

/* 引入公共方法和常量 */
import * as fnCommon from '@/fn/_fn';
import * as CON_COMMON from '@/constant/_constant';

/* 引入组件 */
import CreatorBtn from "@/components/_components/creator/CreatorBtn";

/* 引入VUEX */
import {mapState} from "vuex";
import {CREATOR_TASK, CREATOR_WORKS} from "@/constant/_constant/CON_BASIC";

/* 引入API */

export default {
  name: "CreatorAside"

  , components: {
    CreatorBtn
  }

  , props: {}

  , watch: {
    $route: {
      handler(n) {
        if (n) {

          /* 简单版本 当前均为一级菜单 */
          /*let findIndex = this.asideList.findIndex(item => item.path == n.path)
          this.activeIndex = findIndex.toString() || "0";*/

          let _asideList = this.asideList

          for (let i = 0; i < _asideList.length; i++) {
            let item = _asideList[i];

            if (n.path.indexOf(item.path) > -1) {
              if (item.children.length) {
                this.activeIndex = `${i}-0`
              } else {
                this.activeIndex = `${i}`
              }
            } else {

              if (item.children.length) {
                for (let j = 0; j < item.children.length; j++) {
                  let childItem = item.children[0]
                  if (n.path.indexOf(childItem.path) > -1) {
                    this.activeIndex = `${i}-j`
                  }

                }
              }

            }

          }


        }
      },
      immediate: true
    }
  }

  , computed: {}

  , data() {
    return {
      asideList: CON_COMMON.CON_CREATOR.ASIDE
      , activeIndex: "0"
    }
  }

  , methods: {
    toggleMenu(item) {

      // fnCommon.fnBasic.navigationTo(item.path, '_self')
      //

      // console.log(item.path)
      this.$router.push(item.path);

    },

    uploadWork(){
      fnCommon.fnBasic.navigationTo(CREATOR_WORKS, '_self')
    },

    getTask(){
      fnCommon.fnBasic.navigationTo(CREATOR_TASK, '_self')
    }
  }

}
</script>

<style lang="scss" scoped>

.handle-group{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 55px  16px 15px;
  border-bottom: 1px solid #F2F3F7;

  .reapply-btn{
    margin-bottom: 15px;

    .iconfont3{
      font-size: 20px;
      margin-right: 15px;
    }
  }
}


.el-aside {
  .el-menu-item {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: rgba(94, 99, 125, 1);
    padding-left: 69px !important;

    &:hover {
      background: rgba(247, 247, 255, 1);
    }
  }

  .el-submenu {

    /deep/ .el-submenu__title {
      padding-left: 69px !important;
      font-weight: bold;
      color: rgba(94, 99, 125, 1);

      &:hover {
        background: rgba(247, 247, 255, 1);
      }
    }

    /deep/ .el-menu-item {
      padding-left: 64px !important;
    }

  }

  .el-menu-item.is-active {
    color: rgba(105, 105, 255, 1) !important;
  }

}

.iconfont3 {
  font-size: 16px;
  margin-right: 15px;
}

</style>
