<template>
  <div
    class="ReminderDialog ReminderModal"
  >
    <CgModalMiddle
      @close="close"
    >
      <div slot="modal_content" class="modal-content-box">
        <div class="modal-title">{{ titleTxt }}</div>
        <div class="modal-content overflow-more">{{ contentTxt }}</div>
        <div
          class="modal-btn modal-btn-item modal-btn-confirm"
          :class="btnTheme"
          v-if="btnType == 1"
          @click="btnClick"
        >{{ btnTxt }}
        </div>
        <div
          class="modal-btn-group"
          v-else
        >
          <div class="modal-btn-item modal-btn-cancel"
               @click="btnCancelClick"
          >{{ btnCancelTxt }}
          </div>
          <div
            class="modal-btn-item modal-btn-confirm"
            :class="btnTheme"
            @click="btnConfirmClick"
          >{{ btnConfirmTxt }}
          </div>
        </div>
      </div>
    </CgModalMiddle>
  </div>
</template>

<script>
export default {
  name: "ReminderModal",
  props: {
    btnType: {
      type: String | Number,
      default: 1
    },
    titleTxt: {
      type: String,
      default: '友情提示'
    },
    contentTxt: {
      type: String,
      default: '这是一段内容'
    },
    btnTxt: {
      type: String,
      default: '默认按钮'
    },
    btnCancelTxt: {
      type: String,
      default: '取消按钮'
    },
    btnConfirmTxt: {
      type: String,
      default: '确认按钮'
    },
    btnTheme: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isShow: false
    }
  },
  methods: {
    btnClick() {
      this.$emit('btnClick')
    },
    btnCancelClick() {
      this.$emit('btnCancelClick')
    },
    btnConfirmClick() {
      this.$emit('btnConfirmClick')
    },
    close(){
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.ReminderDialog {

  .modal-content-box {
    width: 440px;
    height: 240px;
    background: #FFFFFF;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .modal-title {
      font-size: 20px;
      font-weight: bold;
      color: #424866;
      line-height: 26px;
      margin: 40px 0 16px;
    }

    .modal-btn {

    }

    .modal-content {
      width: 340px;
      height: 40px;
      max-height: 40px;
      line-height: 20px;
      font-size: 14px;
      color: #596088;
      text-align: center;
      margin-bottom: 46px;
    }

    .modal-btn-group {
      display: flex;

      .modal-btn-item {
        margin-right: 24px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@mixin ModalBtn($bgColor: transparent,$bgColorHover: transparent) {
  padding: 0 28px;
  height: 44px;
  text-align: center;
  line-height: 44px;
  font-size: 14px;
  border-radius: 25px;
  cursor: pointer;
  background: $bgColor;
  &:hover {
    background: $bgColorHover;
  }
}

.modal-btn-item {
  &.modal-btn-cancel {
    color: rgba(89, 96, 136, 1);
    @include ModalBtn(rgba(224, 227, 233, 1), rgba(224, 227, 233, 0.9))
  }

  &.modal-btn-confirm {
    color: #FFFFFF;
    @include ModalBtn(rgba(105, 105, 255, 1), rgba(105, 105, 255, 0.8));

    &.FANS {
      @include ModalBtn($color-fans, $color-fans-hover);
    }
  }
}
</style>
