<template>
  <div
    v-show="isShowCombobox"
    class="box-wrap vip-mandate-wrap"
    @click.stop="navigatorTo"
  >
    <div class="container">
      <div class="person-card" @click.stop="navigatorTo()">
        <i class="icon_bg"></i>
        <div class="title">个人终身VIP</div>
        <div class="desc">全站100万+素材，终身海量下载</div>
        <div class="scene">
          适用于个人学习交流、学术论文、个人新媒体平台、个人社交账号等个人使用
        </div>
        <a class="buy" @click.stop="navigatorTo()" target="_blank">
          <div class="buy-left">
            <span>¥</span>
            <span class="price">299</span>
            <span class="text-zs">/终身</span>
          </div>
          <div class="buy-right">
            立即开通
            <i class="iconfont icon-a-arrowright"></i>
          </div>

        </a>

        <div class="time-box">
          倒计时<span>{{ times.h }}</span>时<span>{{ times.m }}</span>分<span class="text-miao">{{ times.s }}.{{ times.hm }}</span>秒
        </div>


      </div>
      <div class="company-card" @click.stop="navigatorTo('/introduce/company')">
        <i class="icon_bg"></i>
        <div class="title">企业VIP</div>
        <div class="desc">
          更灵活的授权方式，企业商用版权有保障！</div>
        <a class="buy" @click.stop.prevent="navigatorTo('/introduce/company')"  target="_blank">
          <div class="buy-left">
            <span>低至¥</span>
            <span class="price">0.8</span>
            <span>/天</span>
          </div>
          <div class="buy-right">
            立即开通
            <i class="iconfont icon-a-arrowright"></i>
          </div>

        </a>

        <div class="content-right card-list">

          <div class="card-item company" @click.stop="navigatorTo('/pay/enterprise-recharge/300')">
            <div class="title">中小微企业授权</div>
            <div class="desc">注册资本≤1000万</div>
            <div class="price">
              <span class="icon_dollar">￥</span>
              <span class="txt-price">300</span>
              <span class="txt-unit">/年</span>
            </div>
            <div class="down-pic">海量下载  正版保障</div>
          </div>

          <div class="card-item large-company" @click.stop.prevent="navigatorTo(INTRODUCE_COMPANY_VIP_PLUS)">
            <div class="title">大型企业/国企/事业单位授权</div>
            <div class="desc">规模和性质按需定制</div>
            <div @click.stop.prevent="navigatorTo(SERVICE_LINK)" class="btn">在线咨询</div>
            <div class="down-pic">海量下载 灵活授权</div>

          </div>

          <div class="card-item more" @click.stop.prevent="navigatorTo(INTRODUCE_MULTIPLE)">
            <div class="title">单张购买授权</div>
            <div class="desc">单张多张自由购买</div>
            <!--              <div class="btn">敬请期待</div>-->
            <div class="price">
              <span class="icon_dollar">￥</span>
              <span class="txt-price">22.8</span>
              <span class="txt-unit">/张起</span>
            </div>
            <div class="down-pic">不限时长 灵活购买</div>
          </div>


          <!--            <div class="list">
                        <div class="list-item">
                          <div class="list-item-header">
                            <span class="headline">适用于公司人数≤1000人 | 线上线下全授权</span>
                            <span class="subhead" @click.stop="navigatorTo(COMPANY_INTRODUCE)">查看详细特权 <i
                              class="iconfont icon-a-arrowright"/></span>
                          </div>

                          <div class="list-item-meal-list">

                            <div class="list-item-meal-list-item"
                                 @click.stop="navToPay2(item.payId);tag('充值:企业下拉:图片')"
                                 :class="{ 'active': index == versionIndex }"
                                 @mouseover=" versionIndex = index "
                                 v-for="(item,index) in CompanyVip.version1"
                                 :key="item.name"
                            >
                              <div class="meal-content">
                                <span class="name">{{ item.name }}</span>
                                <div class="mandate-scope">
                                <span class="mandate-scope-item"
                                      v-for="vo in item._scope"
                                      :key="vo"
                                >{{ vo }}</span>
                                </div>
                              </div>
                              <div class="meal-info">
                                <div class="price-box">
                                  <span class="dollar">￥</span>
                                  <span class="price">{{ item.price }}</span>
                                  <span class="time">/年</span>
                                </div>

                                <div class="account-info">
                                  <div class="account-item">{{ item.accountNum }}</div>
                                </div>

                                <div class="buy-btn">立即购买</div>
                              </div>

                            </div>


                          </div>

                        </div>
                        <div class="list-item"></div>
                      </div>

                      <div class="list">
                        <div class="list-item">
                          <div class="list-item-header">
                            <span class="headline">适用于大型企业 | 国企 | 事业单位 | 定制版授权</span>
                            <span class="subhead" @click.stop="toIntroduceCompanyPlus">查看详细特权 <i
                              class="iconfont icon-a-arrowright"/></span>
                          </div>

                          <div class="list-item-meal-list">

                            <div class="list-item-meal-list-item"
                                 :class="{ 'active': index == versionIndex2 }"
                                 @mouseover=" versionIndex2 = index "
                                 v-for="(item,index) in CompanyVip.version2"
                                 :key="item.name"
                                 @click.stop="navToPay3(item.payId);tag('充值:企业下拉:图片')"
                            >
                              <div class="meal-content">
                                <span class="name">{{ item.name }}</span>
                                <div class="mandate-scope">
                                <span class="mandate-scope-item"
                                      v-for="vo in item._scope"
                                      :key="vo"
                                >{{ vo }}</span>
                                </div>
                              </div>
                              <div class="meal-info">
                                <div class="price-box">
                                  <span class="dollar">￥</span>
                                  <span class="price">{{ item.price }}</span>
                                  <span class="time">/年</span>
                                </div>

                                <div class="account-info">
                                  <div class="account-item">{{ item.accountNum }}</div>
                                </div>

                                <div class="buy-btn">立即购买</div>
                              </div>

                            </div>


                          </div>

                        </div>
                        <div class="list-item"></div>
                      </div>-->

        </div>

      </div>
    </div>
  </div>
</template>

<script>

import {tag} from "@/utils/statUtil";
import {PERSON_VIP} from "@/constant/_Privately/Vip"
import {VIP_MANAGE} from "@/constant/VIP_MANAGE";
import {disposeVipList} from "@/fn/_Privately/Vip";
import {
  INTRODUCE_COMPANY_VIP_PLUS,
  INTRODUCE_MULTIPLE,
  INTRODUCE_VIP,
  PAY_DEFAULT,
  SERVICE_LINK
} from "@/constant/_Public/Path";
import {fnRouter} from "@/fn";
import mixinCountDown from "@/mixins/mixinCountDown";
import {COMPANY_INTRODUCE} from "@/constant/PATH";

/* 下拉普通VIP列表 */
const VIP_LIST = [
  {...VIP_MANAGE.PERSON.YEAR},
  {...VIP_MANAGE.PERSON.QUARTER},
  {...VIP_MANAGE.PERSON.MONTH},
  /*{...VIP_MANAGE.PERSON.YEAR_FIVE},*/
  {...VIP_MANAGE.PERSON.FOREVER},
]

export default {
  name: "VipMandateCombobox",
  mixins: [mixinCountDown],
  mounted() {
  },
  watch:{
    isShowCombobox:{
      handler(newVal,oldVal){
        if (newVal == true){
          this.initTimer();
        }else{
          this.clearTimer();
        }
      }
    }
  },
  data() {
    return {
      PAY_DEFAULT,
      isShowCombobox: false,
      PERSON_VIP,
      INTRODUCE_VIP,
      VIP_LIST: disposeVipList(VIP_LIST),
      mealListIndex: 0,
      INTRODUCE_MULTIPLE,
      SERVICE_LINK,
      INTRODUCE_COMPANY_VIP_PLUS,
      COMPANY_INTRODUCE
    }
  },
  methods: {
    tag(stat) {
      tag(stat)
    },
    navigatorTo(path = "/pay/personal-recharge/19") {
      fnRouter.navigationTo(path)
    },
  }
}
</script>

<style lang="scss" scoped>

@keyframes fadeinT {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.box-wrap {

  width: 580px;

  color: rgba(66, 72, 102, 1);

  .box-item-wrap {
    padding: 16px 22px 23px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .explain-box {
    height: 70px;
    background: #F7F7F9;
    border-radius: 12px 12px 0px 0px;

    font-size: 11px;
    color: #585A5C;
    line-height: 16px;
    letter-spacing: 1px;
    display: flex;
    flex-direction: column;
    padding: 17px 24px;
    box-sizing: border-box;

    span {
      margin-bottom: 5px;

      &.color-brown {
        color: rgba(130, 73, 35, 1);
      }
    }
  }

  //width: 712px;
  //height: 432px;
  background: #FFFFFF;
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.06), 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  //padding: 20px 22px 24px;
  animation: fadeinT 0.5s 1;

  .box-left {

    &:last-child {
      margin-right: 0;
    }

    .box-card-wrap {
      width: 532px;
      height: 174px;
      border-radius: 12px;
      display: flex;
      background: #F7F7FF;
    }

    .box-card {
      width: 290px;
      border-radius: 12px;
      overflow: hidden;
      height: 174px;
      background: #6969FF !important;
      flex-shrink: 0;

      .content {
        width: 278px;
        height: 112px;
        background: #FFFFFF;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 6px 6px;
        position: relative;
        z-index: 10;

        .title {
          font-size: 13px;
          color: #71758C;
          line-height: 20px;
          margin: 12px 0;
          position: relative;

          &:after {
            content: '';
            display: inline-block;
            width: 88px;
            border-bottom: 1px dashed #C5CBE6;
            position: absolute;
            left: -96px;
            top: 50%;
            transform: translateY(-50%);
          }

          &:before {
            content: '';
            display: inline-block;
            width: 88px;
            border-bottom: 1px dashed #C5CBE6;
            position: absolute;
            left: 60px;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        .down-scope {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;

          .down-scope-item {
            height: 24px;
            background: #F7F7F7;
            border-radius: 4px;
            padding: 0 8px;
            font-size: 13px;
            color: #424866;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin: 0 10px 8px 0;

            .iconfont {
              font-size: 14px;
              margin-right: 4px;

              &.icon-gou3 {
                color: #36D0BA;
              }

              &.icon-cha3 {
                color: #B5B6C2;
              }
            }


          }
        }
      }
    }

    .use-scope {
      width: 242px;
      padding: 18px 0 0 22px;

      .box-permission {
        display: flex;
        flex-wrap: wrap;

        .perm-item {
          width: 88px;
          margin-bottom: 14px;
          display: flex;
          align-items: center;
          line-height: 16px;
          margin-right: 38px;

          &:nth-child(2n) {
            margin-right: 0;
          }


          .iconfont {
            font-size: 16px;
            margin-right: 4px;

            &.icon-gou {
              color: #6969FF;
            }

            &.icon-cha2 {
              color: rgba(66, 72, 102, 0.45);
            }
          }

          .perm-name {
            color: #424866;
            font-size: 13px;
          }

          &.normal {
            .perm-name {
              color: rgba(66, 72, 102, 0.45);
            }
          }
        }
      }
    }

    &.person {
      position: relative;

      &:after {
        content: "";
        width: 1px;
        height: 388px;
        //border-left: 1px dotted #EBEDF5;
        @include Position(1, 5, -19px, 0);
      }
    }


  }

  @mixin VipCard($background,$headerBg,$gouColor:#6969FF,$chaColor:#6969FF) {
    .box-card {
      background: $background;

      .box-header {
        height: 56px;
        box-sizing: border-box;
        background-color: $headerBg;
        text-align: center;
        padding: 9px 0 0;
        position: relative;

        .icon_zs {
          @include Position(1, 2, 0, 0);
        }


        .headline {
          margin-bottom: 2px;
          font-size: 16px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 22px;
          letter-spacing: 1px;
        }

        .subhead {
          color: #FFFFFF;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 1px;
        }
      }


    }

    .box-list {
      display: flex;
      //justify-content: space-between;
      margin-top: 18px;

      .meal-item {
        width: 86px;
        height: 92px;
        background: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #EBEDF5;
        margin-right: 13px;
        box-sizing: border-box;
        position: relative;
        //overflow: hidden;
        text-align: center;
        transition: all 0.5s ease-in;
        cursor: pointer;

        width: 122px;
        height: 92px;
        background: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #EBEDF5;

        /* 推荐 */
        .text-recommend {
          //width: 34px;
          padding: 0 5px;
          background: linear-gradient(224deg, #FF906F 0%, #FF5E9F 100%);
          border-radius: 12px 2px 12px 2px;
          font-size: 12px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 18px;
          @include Position(1, 5, -1px, -9px);
        }

        .meal-duration {
          margin: 10px 0 5px;
          font-size: 13px;
          font-weight: bold;
          color: #424866;
          line-height: 18px;
        }

        .meal-price {
          font-size: 0;
          height: 38px;
          color: #4C4B51;

          .icon_price {
            //margin-right: 2px;
            vertical-align: 1px;
            font-size: 12px;
            font-weight: bold;
            line-height: 16px;
          }

          .price {
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
          }
        }

        .resources-download {
          @include Position(1, 4, 0, 0);
          right: 0px;
          //width: 100%;
          line-height: 28px;
          background-color: $background;
          text-align: center;
          color: $headerBg;
          border-radius: 0 0 7px 7px;
          z-index: 2;
          font-size: 12px;

        }

        .meal-duration,
        .meal-price,
        .resources-download,
        {
          transition: all 0.3s ease-in;
        }

        &.active {
          border-color: $headerBg;

          .meal-duration {
            color: #424866;
          }

          .meal-price {
            color: $headerBg;
          }

          .resources-download {
            background-color: $headerBg;
            color: #FFFFFF;
          }

        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  /* 普通Vip */
  .person {
    @include VipCard(#EAFAF8, #35D0BA, #35D0BA);

    .icon_zs {
      @include BgImage(80px, 76px, 'vip/icon_zs_person.png')
    }
  }

  /* 商用Vip */
  .business {
    @include VipCard(#F7F7FF, #6969FF, #6969FF);

    .icon_zs {
      @include BgImage(80px, 76px, 'vip/icon_zs_business.png')

    }

  }
}

.container{
  width: 598px;
  background: #FFFFFF;
  box-shadow: 0px 12px 24px 0px rgba(0,0,0,0.06), 0px 0px 12px 0px rgba(0,0,0,0.08);
  border-radius: 12px;
  margin: 0 auto;
  padding: 14px 16px 6px;
  box-sizing: border-box;

  ._card_common{
    width: 566px;
    border-radius: 8px;
    color: #202020;
    padding: 16px 0 0 20px;
    box-sizing: border-box;
    position: relative;

    .title{
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 6px;
    }
    .desc{
      font-size: 14px;
      line-height: 20px;
      color: rgba(32,32,32,0.85);
    }

    .buy{
      width: 200px;
      height: 40px;
      border-radius: 15px;
      border: 1px solid rgba(255,255,255,0.5);
      //backdrop-filter: blur(2px);
      position: absolute;
      top: 16px;
      right: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: white;
      padding: 0 14px 0 16px;
      box-sizing: border-box;
      cursor: pointer;
      transition: all 0.3s;
      border-radius: 20px;
      border: 1px solid rgba(255,255,255,0.5);

      .buy-left{
        font-size: 0;
        span{
          font-size: 12px;
        }
      }

      .price{
        font-weight: bold;
        margin: 0 1px;
      }

      .buy-right{
        font-size: 13px;
        .icon-a-arrowright{
          font-size: 12px;
        }
      }
    }
  }

  .person-card{
    @extend ._card_common;
    height: 108px;
    @include BgImage2(566px,122px,"header/person-card.png?v=2");
    margin-bottom: 16px;

    .time-box{
      height: 14px;
      width: 200px;
      text-align: center;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.75);
      line-height: 12px;
      position: absolute;
      top: 62px;
      right: 16px;

      .text-miao{
        display: inline-block;
        width: 28px;

      }

      span{
        color: rgba(255, 222, 2, 1);
        font-weight: bold;
        margin: 0 2px;
      }

    }
    .desc{
      margin-bottom: 22px;
    }

    .scene{
      width: 432px;
      height: 24px;
      background: linear-gradient( 261deg, rgba(105,105,255,0) 0%, rgba(105, 105, 255, 0.1) 100%);
      border-radius: 4px;
      font-size: 12px;
      color: #6969FF;
      line-height: 24px;
      box-sizing: border-box;
      background: linear-gradient( 261deg, rgba(255,255,255,0.0) 0%, rgba(255,255,255,0.1) 100%);
      border-radius: 6px 4px 4px 6px;
      color: rgba(255,255,255,0.85);
      padding-left: 10px;
      margin-left: -10px;
    }

    .buy{
      background: rgba(255, 255, 255, 1);
      .buy-left{
        color: rgba(255, 66, 22, 1);
        margin-top: -3px;

        .price{
          font-size: 22px !important;
        }
        .text-zs{
          position: relative;
          top: -2px;
        }
      }
      .buy-right{
        color: rgba(67, 19, 221, 1);
        font-size: 13px;
        font-weight: bold;
      }

      &:hover{
        background: rgba(255,255,255,0.70);
        box-shadow: 0px 4px 8px 0px rgba(79,81,228,0.3);
        backdrop-filter: blur(2px);
      }
    }
  }
  .company-card{
    @extend ._card_common;
    height: 226px;
    background: linear-gradient(180deg, rgba(255,170,31,0.14) 0%, rgba(255,159,105,0.02) 100%);

    .icon_bg{
      display: inline-block;
      width: 173px;
      height: 226px;
      @include BgImage2(173px,226px,"pay/company_bg.png");
      position: absolute;
      top: 0;
      right: 0;
    }
    .buy{
      background: rgba(223,151,35,0.25);
      color: rgba(132, 82, 0, 1);

      .price{
        font-weight: bold;
        font-size: 16px !important;
      }
      .txt-unit{
        font-weight: normal;
      }
      .buy-right{
        font-weight: bold;
        font-size: 13px;
      }
      &:hover{
        background: rgba(223,151,35,0.5);
      }
    }
  }
}

.content-right {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 16px;

  .card-item {
    background: linear-gradient(360deg, #FFFFFF 0%, #F0F3FF 100%);
    border-radius: 8px;
    padding: 12px 0 0 0px;
    box-sizing: border-box;
    margin-bottom: 16px;
    position: relative;
    width: 170px;
    height: 134px;
    background: #FFFFFF;
    border: 1px solid #E0E3E9;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &:before{
      content: "";
      display: inline-block;
      width: 100%;
      height: 100%;

      border-radius: 8px;
      border: 2px solid transparent;
      position: absolute;
      left: -2px;
      top: -2px;
      transition: all 0.3s;
    }

    &:hover{
      &:before{
        border: 2px solid #C67B00;
        box-shadow: 0px 2px 10px 0px rgba(198,123,0,0.2);
      }
    }

    .title {
      height: 18px;
      font-weight: bold;
      font-size: 12px;
      color: #0A2540;
      margin-bottom: 6px;
    }

    .desc {
      width: 140px;
      height: 18px;
      border-radius: 0px 2px 2px 0px;
      font-size: 12px;
      color: rgba(132,82,0,0.75);
      line-height: 18px;
      background: linear-gradient( 270deg, rgba(255,170,31,0) 0%, rgba(255, 170, 31, 0.1) 48%, rgba(255,170,31,0) 100%);
      text-align: center;
      margin-bottom: 14px;
    }

    .down-pic {
      display: inline-block;
      width: 100%;
      height: 26px;
      line-height: 26px;
      text-align: center;
      background: rgba(32, 32, 32, 0.05);
      border-radius: 0px 2px 2px 0px;
      font-size: 12px;
      color: rgba(32, 32, 32, 0.65);
      //margin-left: 17px;
      box-sizing: border-box;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .btn {
      width: 80px;
      height: 26px;
      border: 1px solid rgba(255, 66, 22, 1);
      border-radius: 13px;
      font-size: 12px;
      color: rgba(255, 66, 22, 1);
      line-height: 24px;
      text-align: center;
      box-sizing: border-box;
      position: relative;
      z-index: 10;
    }

    .price {
      color: rgba(255, 66, 22, 1);
      font-weight: bold;
      font-size: 0;

      .icon_dollar {
        font-size: 12px;
        line-height: 16px;
      }

      .txt-price {
        font-size: 22px;
        line-height: 26px;
        position: relative;
        top: 1px;
      }

      .txt-unit {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  .company {
  }

  .large-company {
  }

  .more {
    .btn {
      background-color: rgba(0, 90, 255, 0.1);
      color: rgba(0, 90, 255, 1);

      &:hover {
        background-color: rgba(0, 90, 255, 0.2);
      }
    }
  }
}


</style>

<!--
  商用会员授权下拉
-->

