<template>
  <!-- nav menu start -->
  <div class="menu-nav">
    <ul class="menu-nav-list inner_center">
      <li
        v-for="(vo,index) in menuNavList"
        :key="vo.id"
        :class="['menu-nav-item', vo.className ,{ 'on': menuNavIndex === index }]"
        :style="{color: vo.color}"
        @mouseover="toggleMenu(index)"
      >
        <div
          class="_pointer"
          @click="navigatorTo(vo.link_url,vo)">{{ vo.title }}
        </div>
      </li>
    </ul>
  </div>
  <!-- nav menu end   -->
</template>

<script>
import {fnBrowser} from "~/fn";

export default {
  name: "MenuNav",
  props: {
    menuNavList: {
      type: Array,
      default: () => []
    },
    /* 是否可点击跳转 */
    navIsClick: {
      type: Boolean,
      default: true
    },
    navIndex: {
      type: String | Number,
      default: -1
    },
  },
  watch: {
    navIndex:{
      handler(n, o) {
        if (n || n == 0) {
          this.menuNavIndex = n;
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      // menuNavList,      // 菜单列表
      menuNavIndex: -1,  // 菜单导航默认选中
    }
  },
  methods: {
    navigatorTo(path,vo) {
      if (this.navIsClick) {
        this.$emit('navigatorTo',vo)
        fnBrowser.navTo(path)
      }
    },
    toggleMenu(index){
      this.menuNavIndex = index
      this.$emit('toggleMenu',index)
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-nav {
  margin-bottom: 48px;

  .menu-nav-list {
    display: flex;

    .menu-nav-item {
      width: 100px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      background: #EBEDF5;
      border-radius: 24px;
      margin-right: 12px;
      color: #0A2540;
      transition: all 0.5s ease-in-out;

      a {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }

    .on {
      color: #FFFFFF !important;
      background: $color-primary !important;

      a {
        color: #FFFFFF !important;
      }

    }
  }
}
</style>
