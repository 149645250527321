import request from '@/utils/request'
import qs from 'qs'

/* 个人中心VIP会员接口 */
export function getTopicList(query) {
  return request({
    url: '?m=Topic',
    method: 'get',
    params: query
  })
}

/* 专题分类 */
export function getTopicClassify(query) {
  return request({
    url: '?m=Topic&a=top',
    method: 'get',
    params: query
  })
}

export function getTopicRecommend(query) {
  return request({
    url: '?m=Topic&a=bottom',
    method: 'get',
    params: query
  })
}



/* 大合集列表 */
export function getCollectionListByLarge(query) {
  return request({
    url: '?m=Topic&a=detail',
    method: 'get',
    params: query
  })
}

/* 小合集 */
export function getCollectionList(query) {
  return request({
    url: '?m=Topic&a=material',
    method: 'get',
    params: query
  })
}


/* 合集收藏 */
export function toggleTopicCollect(data) {
  return request({
    url: '?m=Topic&a=collect',
    method: 'post',
    data: qs.stringify(data)
  })
}


export function getCollectionStat(query) {
  return request({
    url: '?m=StatApi&a=topic',
    method: 'get',
    params: query
  })
}

export function getTopicClassify2(query) {
  return request({
    url: '?m=Topic&a=category',
    method: 'get',
    params: query
  })
}






/* 企业认证 */
export function companyAuthentication(data) {
  return request({
    url: '?m=Company&a=authentication',
    method: 'post',
    data: qs.stringify(data)
  })
}
