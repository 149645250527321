import request from '@/utils/request'
import qs from 'qs'

/* 设置邀请者cookie */
export function setInviterCookie(query) {
  return request({
    url: '?m=InviteAct&a=setInviteCookie',
    method: 'get',
    params: query
  })
}

/* 邀请记录 和 邀请总记录 */
export function getInviterRecord(query) {
  return request({
    url: '?m=InviteAct&a=record',
    method: 'get',
    params: query
  })
}

export function getIsNewUser(query) {
  return request({
    url: '?m=InviteAct&a=confirmInvite',
    method: 'get',
    params: query
  })
}


export function inviteActStatistical(query) {
  return request({
    url: '?m=InviteAct&a=stat',
    method: 'get',
    params: query
  })
}
