/* 用户方法集合 */
import {getCookie} from "~/utils/auth";
import {USER_VIP_TYPE} from "~/constant/_Privately/User";
import {getAccountIsVip} from "@/api/user";
import {fnLogin} from "@/fn";

/* 检查用户是否是VIP 隔离版*/
export function checkUserIsVip() {
  const vipStates = ['personVipState', 'businessVipState', 'companyVipState'];
  for (const state of vipStates) {
    if (getCookie(state) === USER_VIP_TYPE.ON) {
      return true;
    }
  }
  return false;
}

/* 检查用户是否是VIP 非隔离版 */
export async function checkUserIsVipNew() {
  if (fnLogin.checkLogin()) {
    try {
      const {business_vip_id, company_vip_id, personal_vip_id} = (await getAccountIsVip()).data.data;
      return business_vip_id > 0 || company_vip_id > 0 || personal_vip_id > 0;
    } catch (error) {
      console.error("Error checking user VIP status:", error);
      return false;
    }
  } else {
    return false;
  }
}

/* 获取vip的中文名 */
export function getVipName(type, id) {

  if (type == 3) {
    switch (parseInt(id)) {
      case 8 :
        return '企业标准版';
      case 5 :
        return '企业升级版';
      case 6 :
        return '企业高级版';
      case 7 :
        return '企业转售版';
    }
  }

  return '';
}

/* 获取vip的中文名 */
export function getVipNameByType(moduleType, type) {

  if (moduleType == 3) {
    switch (parseInt(type)) {
      case 3:
        return '企业线上版';
      case 5:
        return '企业标准版';
      case 2:
        return '企业标准版';
      case 6:
        return '企业升级版';
      case 7:
        return '企业高级版';
      case 8:
        return '企业转售版';
      case 9:
        return '企业定制版';
      case 10:
        return '大型企业线上版';
      case 11:
        return '大型企业线下版';
      case 12:
        return '医美行业专业版';
      case 13:
        return '多甲方授权版';
    }
  }

  return '';
}

/* 检测用户会员 */
export async function checkUser(type) {

  if (type == 'IS_COMPANY_VIP') {
    if (fnLogin.checkLogin()) {
      try {
        const {company_vip_id = 0} = (await getAccountIsVip()).data.data;
        return company_vip_id > 0 ;
      } catch (error) {
        return false;
      }
    } else {
      return false;
    }
  }

}




