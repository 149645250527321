/* 引入公共方法和常量 */
import * as fnCommon from '@/fn/_fn';
import * as CON_COMMON from '@/constant/_constant';

const crypto = require('crypto');

class FnPic {


  /* DownloadInterestModal 下载权益弹框 */

  /**
   * 切换显示下载权益弹框
   * @param isShow  显示/隐藏
   * @param params  true 必须传参
   */
  showDownloadInterestModal(isShow = true, params) {
    let _params = {};
    let defaultParams = this.getDownloadInterestParams();
    let {$store} = $nuxt;

    if (isShow) {
      /* 检查是否为空对象 */
      if (fnCommon.fnBasic.isEmptyObj(params)) {
        console.error('isShow为true时,必须传递params对象');
        return false;
      }
      _params = Object.assign(defaultParams, params);
    } else {
      _params = defaultParams;
    }
    $store.commit('pic/SET_DownloadInterestModal', _params);
  }

  /**
   * 获取下载权益弹框默认参数
   * @returns {*[]|*}
   */
  getDownloadInterestParams() {
    return fnCommon.fnBasic.deepClone(CON_COMMON.CON_PIC.DOWNLOAD_INTEREST_PARAMS);
  }


  uploadPic(nmethod, nuri, npolicy = null, nmd5 = null) {

    console.log({
      npolicy, nmd5
    })

    let date = new Date().toUTCString();
    let key = CON_COMMON.CON_PIC.UP_YUN.account;
    let secret = CON_COMMON.CON_PIC.UP_YUN.password;
    let method = 'PUT' || method;
    let uri = '//v0.api.upyun.com/sign/test' || nuri;

    secret = MD5(secret)

    const elems = [];
    [method, uri, date, npolicy, nmd5].forEach(item => {
      if (item != null) {
        elems.push(item)
      }
    })

    console.log( elems )
    let value = elems.join('&');
    let auth = hmacsha1(secret, value);
    // return `UPYUN ${key}:${auth}`;

    return {
      'Authorization': `UPYUN ${key}:${auth}`,
      'X-Date': date
    };

    /* function sign(key, secret, method, uri, date, policy=null, md5=null) {
       const elems = [];
       [method, uri, date, policy, md5].forEach(item => {
         if (item != null) {
           elems.push(item)
         }
       })
       let value = elems.join('&');
       let auth = hmacsha1(secret, value);
       return `UPYUN ${key}:${auth}`;
     }*/
    function MD5(value) {
      return crypto.createHash('md5').update(value).digest('hex');
    }

    function hmacsha1(secret, value) {
      return crypto.createHmac('sha1', secret).update(value, 'utf8').digest().toString('base64');
    }

  }

}

export default new FnPic();
