export default {
  namespaced: true,
  state(){
    return{
      stopListenLoginClose: true,  // 监听登录弹框关闭开启的定时器，阻止用
    }
  },
  mutations: {
    SET_STOP_LISTEN_LOGIN_CLOSE(state, payload) {
      state.stopListenLoginClose = payload;
    },

  },
  actions: {},
}
