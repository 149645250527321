import Cookies from "js-cookie";

const utils = {
  val: function (val) {
    return val + 1;
  },
  // 校验
  verify: {
    email(val) {
      let re = /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;
      //判断检测这个值是否正确，
      let trimVal = utils.string.trim(val);
      if (trimVal === '') {
        return {
          status: 0,
          msg: "邮箱不能为空"
        }
      }
      if (re.test(trimVal)) {
        //验证成功
        return {
          status: 1,
          msg: "验证成功"
        }
      } else {
        //验证不成功
        return {
          status: 2,
          msg: "邮箱格式不正确"
        }
      }
    },
    iphone(val) {
      // let reg = /^((13\d)|(14[5,7,9])|(15[0-3,5-9])|(166)|(17[0,1,3,5,7,8])|(18[0-9])|(19[8,9]))\d{8}/;
      // let reg = /^((13\d)|(14[5,7,9])|(15[0-3,5-9])|(166)|(17[0,1,3,5,6,7,8])|(18[0-9])|(19[1,8,9]))\d{8}/;
      let reg = /^1\d{10}$/; // 手机号正则表达式1
      let trimVal = utils.string.trim(val);

      if (trimVal === '') {
        return {
          status: 0,
          msg: "手机号码不能为空"
        }
      }

      if (reg.test(trimVal)) {
        //验证成功
        return {
          status: 1,
          msg: "验证成功"
        }

      } else {
        return {
          status: 2,
          msg: "手机格式错误"
        }
      }
    }
  },
  string: {
    /*
    *   type: 清空类型
    *       1 清除所有空格
    * */
    trim(str, type = 1) {
      if (type === 1) {
        return str.toString().replace(/(^\s*)|(\s*$)/g, "").replace(/\s/g, "")
      }
    },

    urlDecodeParse(zipStr) {
      var uzipStr = "";
      for (var i = 0; i < zipStr.length; i++) {
        var chr = zipStr.charAt(i);
        if (chr == "+") {
          uzipStr += " ";
        } else if (chr == "%") {
          var asc = zipStr.substring(i + 1, i + 3);
          if (parseInt("0x" + asc) > 0x7f) {
            uzipStr += decodeURI("%" + asc.toString() + zipStr.substring(i + 3, i + 9).toString());
            i += 8;
          } else {
            uzipStr += AsciiToString(parseInt("0x" + asc));
            i += 2;
          }
        } else {
          uzipStr += chr;
        }
      }


      function StringToAscii(str) {
        return str.charCodeAt(0).toString(16);
      }

      function AsciiToString(asccode) {
        return String.fromCharCode(asccode);
      }

      return uzipStr;
    },

    unicodeParse(str) {
      return unescape(str.replace(/\\u/g, "%u")); // "我是unicode编码"
    }
  },

  time: {
    // 验证码倒计时
    async countdown(second, time, bandle) {
      return new Promise((resolve, reject) => {
        let vm = setInterval(() => {
          second--;
          typeof bandle === 'function' && bandle(second);
          if (second < 1) {
            clearInterval(vm);
            second = time
            resolve(second)
          }
        }, 1000)
      })
    },

    showtime() {
      var today;
      var hour;
      var second;
      var minute;
      var year;
      var month;
      var date;
      var strDate;
      today = new Date();
      var n_day = today.getDay();
      switch (n_day) {
        case 0: {
          strDate = "星期日"
        }
          break;
        case 1: {
          strDate = "星期一"
        }
          break;
        case 2: {
          strDate = "星期二"
        }
          break;
        case 3: {
          strDate = "星期三"
        }
          break;
        case 4: {
          strDate = "星期四"
        }
          break;
        case 5: {
          strDate = "星期五"
        }
          break;
        case 6: {
          strDate = "星期六"
        }
          break;
        case 7: {
          strDate = "星期日"
        }
          break;
      }
      year = today.getYear();
      month = today.getMonth() + 1;
      date = today.getDate();
      hour = today.getHours();
      minute = today.getMinutes();
      second = today.getSeconds();
      if (month < 10) month = "0" + month;
      if (date < 10) date = "0" + date;
      if (hour < 10) hour = "0" + hour;
      if (minute < 10) minute = "0" + minute;
      if (second < 10) second = "0" + second;
      // document.getElementById('clock').innerHTML = year + "年" + month + "月" + date + "日 " + strDate + " " + hour + ":" + minute + ":" + second;
      return {
        year,
        month,
        date,
        strDate,
        hour,
        minute,
        second,
      }
    },
  },
  date: {

  },

  cookie: {
    get() {

    },
    set() {

    },
    remove() {

    },
    parse() {
      let cookies = document.cookie.split(';');
      return cookies.reduce((pre, next) => {
        const key = next.split('=')[0].trim();
        const val = next.split('=')[1];
        pre[key] = val;
        return pre;
      }, {});
    },
    listenLogin() {
      /*let tempObj = this.parse();
      return 'auth_id' in tempObj*/
      return Cookies.get('auth_id')
    }
  },

  url: {

    /* 解析单个字符串 */
    parseSingleStr() {

    }
  }
}

export default utils;
