<template>
  <div class="creatorLayout">

    <no-ssr>
      <PublicSlot />

      <el-container>
        <CreatorAside/>
        <el-main>
          <CreatorHeader/>
          <nuxt/>
        </el-main>
      </el-container>
      <Login/>

      <SignExpireModal
        ref="SignExpireModal"
      />

    </no-ssr>
  </div>
</template>

<script>
import {Login} from "@/components/common/index.js";
import CreatorHeader from "@/components/_components/creator/CreatorHeader";
import CreatorAside from "@/components/_components/creator/CreatorAside"
import SignExpireModal from "@/components/_components/creator/SignExpirateModal"
import PublicSlot from "@/components/_Public/PublicSlot";

/* 引入公共方法和常量 */
import * as fnCommon from '@/fn/_fn';
import * as CON_COMMON from '@/constant/_constant';
import {mapState} from "vuex";
import {getContractExpire, getFddCode} from "@/api/creator/sign";
import {MODEL_CREATOR_AUTH} from "@/constant/MODELS";
import {fnLogin} from "@/fn";

const dayjs = require('dayjs');

const {
  DEFAULT,
  LOADING,
  FAIL,
  CONTRACT,
  CONTRACT_FAIL,
  SUCCESS,
} = CON_COMMON.CON_CREATOR.SIGN_STATUS


const CREATOR_AUTH = 'CREATOR_AUTH';

export default {
  name: "creatorLayout"
  // , async middleware({store, redirect, app, route, $axios}) {
  //
  //   return false;
  //
  //   if (!app.$cookies.get('auth_id')) {
  //     redirect('/')
  //     return false;
  //   }
  //
  //   /* 鉴权白名单 */
  //   if (
  //     route.path.indexOf('creator/sign/sign-write') > -1
  //   ) {
  //     return false
  //   }
  //   console.log('创作者中心-鉴权状态' + app.$cookies.get(CREATOR_AUTH))
  //
  //
  //   /* 检查用户合同是否到期 */
  //   console.log("检查用户合同是否到期")
  //   let result = await app.$api.getContractExpire();
  //   let {status, data} = result;
  //   console.log({result})
  //   /* 只有签约时间不为null并且isExpire为false为续签 */
  //   if (status == 200) {
  //
  //     /* 如果合同到期,弹签约到期弹框 */
  //     console.log('data.last_time' + data.last_time)
  //     if (data.last_time != '') {
  //       // data.curr_time = '1999-10-20'
  //       const currDate = dayjs(new Date());
  //       const currDate2 = dayjs(data.last_time);
  //       const isTime1BeforeTime2 = currDate2.isBefore(currDate);
  //
  //       if (isTime1BeforeTime2) {
  //         app.$cookies.remove(CREATOR_AUTH)
  //         console.log("合同已过期" + isTime1BeforeTime2)
  //         let signRenewRes = await app.$api.getFddRenewCode()
  //
  //         console.log(signRenewRes)
  //         if (signRenewRes.status == 200) {
  //           console.log("signRenewRes.data.url" + signRenewRes.data.url)
  //           let fRes = await $axios.get(signRenewRes.data.url);
  //           if (fRes.status == 200) {
  //             app.$cookies.set('isShowSignExpireModal', 1);
  //             app.$cookies.set('signRenewLink', fRes.data.signUrl);
  //           }
  //         }
  //
  //         return false;
  //       } else {
  //         console.log("合同未过期")
  //         app.$cookies.set('isShowSignExpireModal', 0);
  //         app.$cookies.set('signRenewLink', '');
  //         app.$cookies.set(CREATOR_AUTH, 1);
  //
  //         /*if (route.path.indexOf('/creator/sign/sign-progress')== -1){
  //           return false;
  //         }*/
  //       }
  //     } else {
  //       app.$cookies.set('isShowSignExpireModal', 0);
  //       app.$cookies.set('signRenewLink', '');
  //     }
  //   }
  //
  //   if (app.$cookies.get(CREATOR_AUTH)) {
  //     console.log("----------创作者中心-鉴权通过")
  //
  //     console.log(route.path)
  //
  //     return false;
  //
  //    /* if (route.path.indexOf('/creator/sign/sign-progress')== -1){
  //       return false;
  //     }*/
  //
  //     return false
  //   }
  //
  //   console.log("创作者中心-开始鉴权")
  //   /* 判断是否申请过 */
  //   let [res] = await Promise.all([
  //     app.$api.getSignStatus()])
  //
  //   /*let res = {
  //     status: 403,
  //     data: {
  //       status: 7
  //     }
  //   };*/
  //
  //   console.log(res)
  //
  //
  //   if (res.status == 404) {
  //     /* 未提交资料 */
  //     redirect(CON_COMMON.CON_BASIC.CREATOR_SIGN)
  //   } else {
  //     let type = "";
  //     if (res.data) {
  //       let {status} = res.data;
  //       if (status == 1 || status == 4) {
  //         // 审核中
  //         type = LOADING;
  //       } else if (status == 2 || status == 5) {
  //         // 审核成功
  //         type = CONTRACT;
  //
  //       } else if (status == 3 || status == 6) {
  //         // 审核失败
  //         type = FAIL;
  //
  //       } else if (status == 7) {
  //         // 签约成功
  //         type = SUCCESS;
  //         /* 不在鉴权 */
  //         console.log('签约成功')
  //         app.$cookies.set(CREATOR_AUTH, 1);
  //       }
  //
  //       // if (status != 7) {
  //         redirect(CON_COMMON.CON_BASIC.CREATOR_SIGN_PROGRESS + `?type=${type}`);
  //       // }
  //     }
  //   }
  //
  //
  // }
  , components: {
    Login,
    CreatorHeader,
    CreatorAside,
    SignExpireModal,
    PublicSlot
  }

  , watch: {
    /* 登录更新详情收藏 */
    isLogin(n, o) {
      if (n === false) {
        window.location.href = '/'
      }
    },
  }
  , computed: {
    ...mapState('Creator', ['creatorAuth']),
    ...mapState('user', ['isLogin']),
  }

  , data() {
    return {
      link: ""
    }
  }

  , async mounted() {

  }
  , methods: {}

}
</script>

<style lang="scss" scoped>
$HeightHeight: 71px;

.creatorLayout {

  /deep/ .el-container {
    .el-header {
      height: $HeightHeight !important;
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0px 2px 20px -1px rgba(0, 17, 144, 0.05);
      border-radius: 2px;
      position: relative;
      z-index: 10;
    }

    .el-aside {
      width: 240px !important;
      height: calc(100vh - 71px);
      background: #FFFFFF;

      overflow-x: hidden;
      //padding-top: 50px;
      box-sizing: border-box;
    }
    .CreatorAside{
      padding-top: 71px;
      position: fixed;
      left: 0;
      top: 0;
      border-right: 1px solid rgba(242, 243, 247, 1);
    }

    .el-main {
      //height: calc(100vh - 71px);
      height: calc(100vh);
      padding: 0 !important;
      position: relative;
      margin-left: 240px;
      //margin-top: 71px;

      &:after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 240px;
        background: linear-gradient(360deg, rgba(245, 246, 250, 0) 0%, rgba(245, 246, 250, 0.5) 39%, #F5F6FA 100%);
        @include Position(1, 5, 0, 0);
        z-index: -1;
      }
    }

    .el-menu {
      border-right: none;
    }
  }
}
</style>
