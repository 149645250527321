import {
  PicFreeDownCouponModalMutations, PicFreeDownCouponModalState
} from "~/components/_Privately/Pic/PicFreeDownCouponModal/module";

export default {
  namespaced: true,

  state() {
    return {
      ...PicFreeDownCouponModalState,
    }
  },

  mutations: {
    ...PicFreeDownCouponModalMutations,
  }
}
