<template>
<!--

-->
  <div
    class="CompanyHomeCertificateDialog"
    v-if="showMainAuthenticationModal "
  >
    <CgModalMiddle
      :is-show-close="false"
    >
      <div slot="modal_content" class="modal-content __company-modal">
        <i
          class="iconfont2 icon-qy-close-line "
          @click="close"
        />

        <!--  一键填充 -->
        <div class="fill-btn"
             v-if="auth_type == 2"
          @click.stop="fillMainInfo"
        >
          <i class="iconfont2 icon-qy-sync"></i>
          一键同步企业信息
        </div>

        <div class="title">{{  auth_type == 2 ?'认证主体':'认证授权主体' }}</div>

        <div class="tab-type">
          <div class="tab-item"
               :class="{ 'active': item.id == auth_type }"
               v-for="item in [
            { id: 2 ,label:'企业认证'},
            { id: 1 ,label:'个人认证'},
          ]"
               @click=" auth_type = item.id "
          >{{ item.label }}</div>
        </div>

        <div class="form " v-if="auth_type == 2">
          <div
            v-for="vo in form"
            :key="vo.name"
            class="form-item"
            :class="vo.type"
          >
            <div class="form-name">
              {{ vo.name }}

              <span class="zhizhao" v-if="vo.id === 1">
                <i class="iconfont icon-question" />
                <i class="icon_yyzz" />
              </span>
            </div>
            <div class="form-input-box">
              <template v-if="vo.type == 'cascader' ">
<!--                <el-cascader
                  v-model="vo.inputVal"
                  :options="vo.options"
                  :props="{ value: 'id',label: 'industry',children: 'childs'}"
                  @change="handleChange"></el-cascader>-->
                <IndustryCascader
                  v-model="vo.inputVal"
                  :list="vo.options"
                  :input-item="vo"
                  @industryCascaderConfirm="industryCascaderConfirm"
                />

              </template>

              <template v-else-if="vo.type == 'select' ">
<!--                <el-select v-model="vo.inputVal" placeholder="请选择">
                  <el-option
                    v-for="item in vo.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>-->
                <CgSelect
                  v-model="vo.inputVal"
                  @select="selectCompanyPerson"
                  :options="vo.options"
                  :input-item="vo"
                />

              </template>

              <template v-else-if="vo.type == 'selectMore' ">
                <CgSelect
                  v-model="vo.inputVal"
                  @select="select1"
                  :options="vo.options"
                  :placeholder="vo.placeholder"
                  :input-item="vo"
                />


                <CgSelect
                  :disabled="!vo.inputVal"
                  v-model="vo.inputVal2"
                  @select="select2"
                  :options="vo.options2"
                  :placeholder="vo.placeholder2"
                  :input-item="vo"
                />
                <CgSelect
              v-if="vo.isShowOption3"
                  :disabled="!vo.inputVal2"
                  v-model="vo.inputVal3"
                  @select="select3"
                  :options="vo.options3"
                  :placeholder="vo.placeholder3"
                  :input-item="vo"
                />
              </template>

              <template v-else>
                <Cg-input
                  v-model="vo.inputVal"
                  :placeholder="vo.placeholder"
                  :input-item="vo"
                  @input="changeInput($event,vo)"
                  @blur="blurInput($event,vo)"
                />


              </template>

            </div>
          </div>
      </div>
        <div class="form " v-else>
          <div
            v-for="vo in form2"
            :key="vo.name"
            class="form-item"
            :class="vo.type"
          >
            <div class="form-name">
              {{ vo.name }}

<!--              <span class="zhizhao" v-if="vo.id === 1">-->
<!--                <i class="iconfont icon-question" />-->
<!--                <i class="icon_yyzz" />-->
<!--              </span>-->
            </div>
            <div class="form-input-box">
              <template v-if="vo.type == 'cascader' ">
                <!--                <el-cascader
                                  v-model="vo.inputVal"
                                  :options="vo.options"
                                  :props="{ value: 'id',label: 'industry',children: 'childs'}"
                                  @change="handleChange"></el-cascader>-->
                <IndustryCascader
                  v-model="vo.inputVal"
                  :list="vo.options"
                  :input-item="vo"
                  @industryCascaderConfirm="industryCascaderConfirm"
                />

              </template>

              <template v-else-if="vo.type == 'select' ">
                <!--                <el-select v-model="vo.inputVal" placeholder="请选择">
                                  <el-option
                                    v-for="item in vo.options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                  </el-option>
                                </el-select>-->
                <CgSelect
                  v-model="vo.inputVal"
                  @select="selectCompanyPerson"
                  :options="vo.options"
                  :input-item="vo"
                />

              </template>

              <template v-else-if="vo.type == 'selectMore' ">
                <CgSelect
                  v-model="vo.inputVal"
                  @select="select1"
                  :options="vo.options"
                  :placeholder="vo.placeholder"
                  :input-item="vo"
                />


                <CgSelect
                  :disabled="!vo.inputVal"
                  v-model="vo.inputVal2"
                  @select="select2"
                  :options="vo.options2"
                  :placeholder="vo.placeholder2"
                  :input-item="vo"
                />
                <CgSelect
                  v-if="vo.isShowOption3"
                  :disabled="!vo.inputVal2"
                  v-model="vo.inputVal3"
                  @select="select3"
                  :options="vo.options3"
                  :placeholder="vo.placeholder3"
                  :input-item="vo"
                />
              </template>

              <template v-else>
                <Cg-input
                  v-model="vo.inputVal"
                  :placeholder="vo.placeholder"
                  :input-item="vo"
                  @input="changeInput($event,vo)"
                  @blur="blurInput($event,vo)"
                />


              </template>

            </div>
          </div>
        </div>
        <div class="HtButton-wrap">
          <HtButton
            class="HtButton HtButton" text="提交认证" type="_blue"
            @click="submit"
          ></HtButton>
        </div>
        <div class="friendly-tips">提交后不可修改</div>
      </div>
    </CgModalMiddle>

  </div>
</template>

<script>
import { COMPANY_MANAGE, INDUSTRY_LIST} from "~/constant/COMPANY_MANAGE";
import {getCityList, getCompanyFillInfo, getIndustryList, submitCompanyVerify} from "@/api/company/home";
import IndustryCascader from '@/components/company/home/industry-cascader'
import {mapState} from "vuex";
import Cookies from "js-cookie";

export default {
  name: "CompanyHomeCertificate",
  components:{
    IndustryCascader
  },
  computed:{
    ...mapState('_Privately/Company',['showMainAuthenticationModal']),
    ...mapState('user',['companyInfoByPerson'])
  },
  data() {
    return {
      form: COMPANY_MANAGE.HOME.MAIN_CERTIFICATE_FORM.slice(0),
      form2: COMPANY_MANAGE.HOME.MAIN_CERTIFICATE_FORM_PEROSN.slice(0),
      input: '222222',
      INDUSTRY_LIST,
      state: 1,
      isShow: false,
      industryList:[],
      auth_type: 2
    }
  },
  props:{
    isCertificate:{
      type: String | Number,
      default: ''
    },
    /* 当前主体ID */
    companyMainId:{
      type: String | Number,
      default: ''
    },
    title:{
      type: String,
      default:'认证主体企业'
    }

  },
  async mounted() {
    this.init();

  },
  methods: {
    async init() {
      let res = await getIndustryList()
      this.industryList = res.data.data
      console.log(res)
      let ProvinceList = await getCityList();
      const _form = this.getAuthType();
      this['form'].forEach(vo => {
        if (vo.id == 4) {
          vo.options = res.data.data
        }

        if (vo.id == 6) {
          vo.options = ProvinceList.data.data
        }
      })
      this['form2'].forEach(vo => {
        if (vo.id == 4) {
          vo.options = res.data.data
        }

        if (vo.id == 6) {
          vo.options = ProvinceList.data.data
        }
      })
      console.log(this.form)
    },

    getAuthType(){
      return this.auth_type == 2 ? 'form': 'form2';;
    },


    /* 所属行业 */
    industryCascaderConfirm(vo){
      const _form = this.getAuthType();
      let findItem = this[_form].find(item => item.id == 4);
      findItem.inputVal = vo.theString;
      findItem.val = vo.id
      findItem.isShowErrTxt = (vo.theString.length ==  0)
    },
    /* 企业规模 */
    selectCompanyPerson(vo){
      console.log(vo)
      const _form = this.getAuthType();
      let findItem = this[_form].find(item => item.id == 5);
      console.log(findItem)
      findItem.inputVal = vo.label;
      findItem.val = vo.value
      findItem.isShowErrTxt = vo.label.length == 0
    },

    async select1(vo){
      console.log(vo)
      const _form = this.getAuthType();
      let findItem = this[_form].find(item => item.id == 6);

      /* 检查值是否变动 */
      if (findItem.inputVal === vo.name){
        return;
      }
      findItem.inputVal2 = ''
      findItem.inputVal3 = ''
      findItem.city_id = '';
      findItem.area_id = '';

      findItem.inputVal = vo.name;
      findItem.province_id = vo.id;
      let s =  await getCityList({pid : vo.id})
      console.log(s)
      findItem.options2 = s.data.data;

    },
    async select2(vo){
      console.log(vo)
      const _form = this.getAuthType();
      let findItem = this[_form].find(item => item.id == 6);
      if (findItem.inputVal2 === vo.name){
        return;
      }
      findItem.inputVal3 = ''
      findItem.area_id = '';

      findItem.inputVal2 = vo.name;
      findItem.city_id = vo.id;
      let s =  await getCityList({pid : vo.id})
      console.log(s)
      if (s.data.status == 500){
        findItem.options3 = [];
        findItem.isShowOption3 = false
      }else{
        findItem.isShowOption3 = true
        findItem.options3 = s.data.data;
      }

    },
    select3(vo){
      console.log(vo)
      const _form = this.getAuthType();
      let findItem = this[_form].find(item => item.id == 6);
      findItem.inputVal3 = vo.name;
      findItem.isShowErrTxt = vo.name.length == 0
      findItem.area_id = vo.id;

    },

   /* async getCityList(pid=1){
      let ProvinceList = await getCityList({pid});
      return  ProvinceList.data.data
    },*/

    checkForm() {
      const _form = this.getAuthType();
      for (let i = 0; i < this[_form].length; i++) {
        let item = this[_form][i];
        if (item.id === 3) {
          this.verifyIphone();
        } else {
          if (item.inputVal.length === 0) {
            item.isShowErrTxt = true;
          }
        }
      }
      return this[_form].every(vo => vo.isShowErrTxt == false)
    },
    getFormParams() {
      const _form = this.getAuthType();
      let tempForm = {}
      this[_form].forEach(vo => {
        tempForm[vo.key] = vo.inputVal;
      })
      return tempForm;
    },

    changeInput(e, vo) {
      // vo.inputVal = e;
      if (e.length > 0) {
        vo.isShowErrTxt = false
      }
    },

    verifyIphone() {
      const _form = this.getAuthType();
      let findItem = this[_form].find(vo => vo.id === 3)
      let temp = this.$utils.verify.iphone(findItem.inputVal);
      let clues = ["请输入企业联系电话", "", "手机号格式不正确"];
      findItem.errTxt = clues[temp.status];
      findItem.isShowErrTxt = temp.status === 1 ? false : true
      return temp.status === 1
    },

    blurInput(e, vo) {
      console.log(1111)
      /* 单独处理下手机号码 */
/*      if (vo.id === 3) {
        vo.inputVal = vo.inputVal.replace(/[^\d]/g, '');
        this.verifyIphone();
      }*/

      /*let findIndex = this.form.findIndex(item => vo.id == item.id)

      let checkIdArr = [0, 1, 2, 5]
      if (checkIdArr.indexOf(vo.id) > -1) {
        this.form[findIndex].isShowErrTxt = vo.inputVal.length == 0;
      }*/
      const _form = this.getAuthType();
      let findIndex = this[_form].findIndex(item => vo.id == item.id)
      if (vo.id == 6){

        if (vo.isShowOption3){
          if (!vo.inputVal || !vo.inputVal2 || !vo.inputVal3){
            vo.isShowErrTxt = true
          }
        }else{
          if (!vo.inputVal || !vo.inputVal2){
            vo.isShowErrTxt = true
          }
        }
      }else if (vo.id == 1){
        if (_form == 'form2'){
          console.log(vo)
          if (vo.inputVal.length != 18){
            vo.isShowErrTxt = true
            vo.errTxt = "请输入18位的身份证号码"
          }else{
            vo.isShowErrTxt = false
          }
        }
      }else{
        this[_form][findIndex].isShowErrTxt = vo.inputVal.length == 0;
        // this.form[findIndex]
      }
    },
    clearForm() {
      const _form = this.getAuthType();
      this[_form].forEach(vo => vo.inputVal = '');
    },

    handleChange(v){
      console.log(v)
      const _form = this.getAuthType();
      let findItem = this[_form].find(vo => vo.id === 4)
      findItem.inputVal = v[v.length-1];
    },



    checkFormWrite() {
      const _form = this.getAuthType();
      this[_form].forEach(vo =>{
        this.blurInput('',vo)
      })

      let flag = true;
      for (let i = 0; i < this[_form].length; i++) {
        if (this[_form][i].id == 6){

          if (this[_form][i].isShowOption3){
            if ( !this[_form][i].inputVal || !this[_form][i].inputVal2 || !this[_form][i].inputVal3 ) {
              flag = false;
              break
            }
          }else{
            if ( !this[_form][i].inputVal || !this[_form][i].inputVal2 ) {
              flag = false;
              break
            }
          }
        }else{
          if ( !this[_form][i].inputVal) {
            flag = false;
            break
          }
        }


      }

      return flag
    },

    getSubmitForm(){
      const _form = this.getAuthType();
      let params = {
        certificate_id: this.companyMainId,
        type: 2
      }
      this[_form].forEach( vo =>{
        if (vo.id === 6){
          params[vo.key] =  vo.inputVal + vo.inputVal2 + vo.inputVal3
          /* 新增传递 省市级对应id */
          params['province_id'] = vo.province_id
          params['city_id'] = vo.city_id
          if (vo.isShowOption3){
            params['area_id'] = vo.area_id
            params[vo.key] =  vo.inputVal + vo.inputVal2 + vo.inputVal3
          }else{
            params[vo.key] =  vo.inputVal + vo.inputVal2
          }

        }else if (vo.id === 4 || vo.id === 5){
          params[vo.key] = vo.val
        } else{
          params[vo.key] =  vo.inputVal
        }
      })

      return params


    },

    submit(){
      if (this.checkFormWrite()){
        console.log()
        let params = this.getSubmitForm()
        params.certificate_id = this.companyInfoByPerson.switch.id
        params.auth_type = this.auth_type;
        submitCompanyVerify(params).then(res => {
          if (res.data.status === 200) {
            this.$myNotify.success('提交认证成功，请继续下载吧!',()=>{},2000);
            this.close();
            this.$store.dispatch('user/getCompanyInfoByPerson');
          } else {
            this.$myNotify.error(res.data.message)

          }
        })

        this.$emit('certificateCompanyMain',this.getSubmitForm())
      }else{
      }

    },

    close(){
      this.$store.commit('_Privately/Company/setShowMainAuthenticationModal',false);
    },

    async fillMainInfo(){
      let { data } = await getCompanyFillInfo()
      let ProvinceList = await getCityList();
      console.log(data)
      for (const vo of this.form) {
        if (vo.id < 4){
          vo.inputVal = data.data[vo.key]
        }

        if (vo.id == 4){
          let str = '';
          let industryId = 0
          for (let i = 0; i < this.industryList.length; i++) {
            let item = this.industryList[i];
            if (item.id == data.data.industry_parent_id ){
              str += item.industry + '/';
              for (let j = 0; j < item.childs.length; j++) {
                let itemJ = item.childs[j];
                if (itemJ.id == data.data.industry_id){
                  str += itemJ.industry;
                  industryId = itemJ.id;

                  break;
                }
              }
              break;
            }
          }

          vo.inputVal = str
          vo.val = industryId

          console.log(vo)

        }

        if (vo.id == 5){
          vo.options.forEach(item =>{
            if (item.value == data.data[vo.key]){
              vo.inputVal = item.label;
              vo.val = item.value;
            }
          })
        }

        if (vo.id == 6){
          if (data.data.province_id == 0){
            continue;
          }

          let findItem = ProvinceList.data.data.find(item => item.id == data.data.province_id );
          vo.inputVal = findItem.name;
          vo.province_id  = findItem.id;
          let s =  await getCityList({pid : findItem.id})
          let sFindItem = s.data.data.find(item => item.id == data.data.city_id );
          vo.inputVal2 = sFindItem.name;
          vo.city_id  = sFindItem.id;
          let a =  await getCityList({pid : sFindItem.id})

          if (a.data.status == 500 || a.data.status == 404){
            vo.inputVal3 = '';
            vo.area_id  = 0;
            vo.options3 = [];
            vo.isShowOption3 = false
          }else{

            console.log()
            let asFindItem = a.data.data.find(item => item.id == data.data.area_id );
            vo.inputVal3 = asFindItem.name;
            vo.area_id  = asFindItem.id;
            vo.isShowOption3 = true
          }


        }
      }

      console.log(  this.form)


    }
  }
}
</script>

<style lang="scss" scoped>
@import "styles/company-manage/index";

.CompanyHomeCertificateDialog {
  padding: 45px 56px;

  .modal-content{
    width: 722px;
    //height: 596px;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 40px 40px 20px;

    .title{
      font-size: 18px;
      color: #131519;
      line-height: 24px;
      text-align: center;
      margin-bottom: 45px;
    }

    .fill-btn{
      font-size: 13px;
      color: #255BFF;
      line-height: 17px;
      height: 30px;
      background: #F4F7FF;
      border-radius: 22px;
      padding: 0 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      @include Position(1,2,40px,70px);
      cursor: pointer;
      @include ElementHover(#FFFFFF,#255BFF);
      .icon-qy-sync{
        font-size: 16px;
        margin-right: 4px;
      }
    }
  }


  .form {
    display: flex;
    flex-wrap: wrap;
    //justify-content: center;
    margin-bottom: 16px;
    //width: 1000px;
    width: 100%;

    .form-item {
      width: 300px;
      margin-right: 42px;
      margin-bottom: 22px;

      &:nth-child(2n) {
        margin-right: 0;
      }

      .form-name {
        font-size: 14px;
        color: #4E5053;
        line-height: 17px;
        margin-bottom: 8px;


        .zhizhao{
          @include ElementHover($color-company-server,''){
            .icon_yyzz{
              display: block;
            }
          };

          position: relative;
          .icon-question{
            font-size: 16px;
            @include ElementHover($color-company-server,'');
          }
          .icon_yyzz{
            @include Position(1,5,16px,0);
            @include BgImage(200px,285px, 'company/pay-record/yyzz.jpeg');
            z-index: 3;
            display: none;
          }


        }
      }

      .form-input-box {
        width: 100%;
        height: 48px;
        background: #F5F6FA;
        border-radius: 8px;
        position: relative;

        input {
          width: 100%;
          height: 100%;
          line-height: 100%;
          padding: 0 20px;
          box-sizing: border-box;

          &::placeholder {
            font-size: 14px;
            color: #596088;
          }
        }

        .err-txt {
          font-size: 12px;
          color: #EE5253;
          line-height: 16px;
          //padding-left: 20px;
          @include Position(1, 5, 20px, 54px);
        }

        .icon-qy-cha2 {
          font-size: 16px;
          @include Position(1, 2, 20px, 50%);
          transform: translateY(-50%);
          color: #596088;
          cursor: pointer;

          &:after {
            content: '';
            padding: 10px;
            //background-color: pink;
            @include Position(1, 5, 0, 0);
          }
        }
      }
    }

    .selectMore{

      .form-input-box{
        //width: 100%;
        display: flex;
      }

    }



  }
  .HtButton-wrap{
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .HtButton{
      width: 220px;
      box-sizing: border-box;
    }

    .color-red{
      font-size: 14px;
      color: #EE5253;
      line-height: 19px;
      margin-left: 24px;
    }
  }

  .friendly-tips{
    height: 16px;
    font-size: 12px;
    color: #EE5253;
    line-height: 16px;
    margin-top: 4px;
    text-align: center;
  }

  /deep/.__form{
    .__form-input{
      width: 300px;

      input,.select{
        width: 100%;
        height: 48px;
      }

      .input-err{
        top: 52px;
      }
    }
  }

  .selectMore{
    width: 100% !important;

    .form-input-box{
      background: transparent !important;
      //background: pink !important;
    }
    /deep/.__form{
      margin-right: 20px;

      .__form-input{
        width: 200px;
        height: 48px;

        input,.select{
          width: 100%;
        }

        .input-err{
          top: 52px;
        }
      }
    }
  }

}

.tab-type{
  display: flex;
  margin-bottom: 25px;

  .tab-item{
    height: 22px;
    font-weight: bold;
    font-size: 16px;
    color: rgba(19,21,25,0.75);
    line-height:  22px;
    margin-right: 24px;
    cursor: pointer;

    &.active{
      color: rgba(37, 91, 255, 1);
      position: relative;
      &:after{
        content: '';
        display: inline-block;
        width: 100%;
        height: 2px;
        background: #255BFF;
        position: absolute;
        left: 0;
        bottom: -4px;
      }
    }
  }
}
</style>
