/* 页面统计 */
import request from "@/utils/request";
import qs from "qs";

// 页面流通统计
export function flowStatistics(query) {
  return request({
    url: '?m=StatApi&a=pageFlow',
    method: 'get',
    params: query
  })
}


/* banner和搜索下拉词数据统计 */
export function flowStatisticsPromotion(query) {
  return request({
    url: '?m=StatApi&a=promotion',
    method: 'get',
    params: query
  })
}
