/* h5 拉新 */
import {trackInviteH5} from "@/api/invite-h5";

class FnInviteH5 {
  constructor() {

  }

  async track(type) {
    await trackInviteH5({act:type})
  }

}

export default new FnInviteH5();
