<template>
  <div class="footer-wrap" id="FooterWrap">

    <template v-if="!spider">

      <div class="inner_center inner-content">

        <!-- logo -->
<!--        <nuxt-link to="/" class="logo"/>-->
        <a href="/" class="logo"/>

        <!-- 关于公司/常见问题/客服 -->
        <div class="list-box about">
          <div class="title">关于公司</div>
          <div class="content">
            <a :href="PROTOCOL_ABOUT_0" target="_blank">关于我们</a>
            <a :href="PROTOCOL_ABOUT_1" target="_blank">版权声明</a>
            <a :href="PROTOCOL_ABOUT_2" target="_blank">联系我们</a>
            <a :href="PROTOCOL_PROBLEM_0" target="_blank">用户协议</a>
            <a :href="PROTOCOL_PROBLEM_2" target="_blank">隐私政策</a>
          </div>
        </div>
        <div class="list-box problem">
          <div class="title">常见问题</div>
          <div class="content">
            <a :href="PROTOCOL_LOGIN_0" target="_blank">登录注册</a>
            <a :href="PROTOCOL_LOGIN_1" target="_blank">支付相关</a>
            <a href="https://chaopx.com/pay/enterprise-recharge/10" target="_blank">VIP购买</a>
          </div>
        </div>
        <div class="list-box problem">
          <div class="title">联系电话</div>
          <ul class="content">
            <li>021-38760461</li>
          </ul>
        </div>
        <div class="list-box problem">
          <div class="title">官方渠道</div>
          <ul class="content">
            <FooterChannel/>
          </ul>
        </div>
        <div class="list-box service">
          <div class="title">联系客服</div>
          <div
            class="service-btn _text-hover"
            @click="navigatorTo(SERVICE_LINK)"
          >
            <i class="iconfont icon-kefu"/>在线客服
          </div>
        </div>
      </div>

      <div class="inner_center">
        <div class="_divider-line "/>
      </div>

    </template>


    <div class="inner_center ipc-info-box">
<!--      <div class="friend-link" v-if="!spider">
        <span @click="showLink = !showLink">
          友情链接
          <i
            :class="{ 'ON': showLink }"
            class="iconfont icon-a-arrowright"/>
        </span>
        <div v-if="showLink" class="friend-link-content">

          <a
            v-for="vo in CON_COMMON.CON_CONFIG.FRIENDSHIP_LINK"
            :key="vo.link"
            :href="vo.link" target="_blank">
            {{ vo.label }}
          </a>
          <span>商务合作请联系QQ：672390253</span>
        </div>
      </div>-->
      <div class="ipc-info">
        <span>Copyright © 2014-2024 上海图魂网络科技有限公司版权所有</span><span class="line"> | </span>
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          rel="nofollow"
          target="_blank"
        >沪ICP备17033508号-7</a> <span class="line"> | </span>
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          rel="nofollow"
          target="_blank">增值电信业务经营许可证：沪B2-20180417</a>

        <span class="line"> | </span>
        <a class="shgs" target="_blank"
           rel="nofollow"
           href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019702"
           style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
          <i class="icon_sj" />
          <p >沪公网安备
            31011502019702号</p>
        </a>
        <span class="line"> | </span>
        <a class="shgs" target="_blank"
           rel="nofollow"
           href="https://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020210126151529000007305892-SAIC_SHOW_310000-20230423115222948135&signData=MEQCIAy8UzqH98Eio4JYOw8oD4FGCki9HjQpYzWx4s6SvDJ1AiATaELVuwQ5Ot8rBYb8xnnnZbZqDcIzp3p9bnmdICHMzw=="
           style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
          <p >上海工商</p>
        </a>


      </div>
      <div class="license-info">
        <span>举报邮箱：3156036062@qq.com</span><span class="line"> | </span>

        <span>广播电视节目制作经营许可证（沪）字第04178号</span><span class="line"> | </span>
        <span>网络文化经营许可证 沪网文{2020}5118-355号</span>
      </div>
      <div class="license-info reminder-info">
        <span>为推动防范电信网络诈骗工作，加强警企协作，如网民接到962110电话，请立即接听</span>
      </div>
    </div>

  </div>
</template>

<script>
import {
  PROTOCOL_ABOUT_0,
  PROTOCOL_ABOUT_1,
  PROTOCOL_ABOUT_2,
  PROTOCOL_LOGIN_0,
  PROTOCOL_LOGIN_1,
  MEMBER_LICENSE_VIP_PATH, PROTOCOL_PROBLEM_0, PROTOCOL_PROBLEM_2, SERVICE_LINK,
} from "@/constant/PATH.js"
import fnBrowser from "~/fn/fnBrowser";
import FooterChannel from "@/components/common/footer/footer-channel"

/* 引入公共方法和常量 */
import * as fnCommon from '@/fn/_fn';
import * as CON_COMMON from '@/constant/_constant';
import {mapState} from "vuex";


export default {
  name: "Footer",
  components: {
    FooterChannel
  },
  computed: {
    ...mapState('base', ['spider'])
  },
  data() {
    return {
      PROTOCOL_ABOUT_0,
      PROTOCOL_ABOUT_1,
      PROTOCOL_ABOUT_2,
      PROTOCOL_LOGIN_0,
      PROTOCOL_LOGIN_1,
      PROTOCOL_PROBLEM_0,
      PROTOCOL_PROBLEM_2,
      MEMBER_LICENSE_VIP_PATH,
      showLink: true,

      CON_COMMON,
      SERVICE_LINK
    }
  },
  methods: {
    navigatorTo(path) {
      fnBrowser.navTo(path)
    }
  }
}
</script>

<style scoped lang="scss">
.footer-wrap {
  position: relative;
  background-color: #F5F6FA;

  .inner-content {
    padding: 64px 0;

    display: flex;

    .logo {
      margin: 33px 120px 0 48px;
      @include BgImage(114px, 24px, "public/logo.svg");
      flex-shrink: 0;

      @include Screen(1) {
        //margin-right: 70px;
        margin: 33px 90px 0 28px;
      }
    }

    .list-box {
      //margin-right: 120px;
      margin-right: 90px;
      flex-shrink: 0;
      @include Screen(1) {
        margin-right: 70px;
      }

      .title {
        font-size: 18px;
        font-weight: bold;
        color: #2D2B33;
        line-height: 24px;
        margin-bottom: 20px;
      }

      .content {
        display: flex;
        flex-direction: column;

        a {
          color: #424866;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 12px;

          @include ElementHover(#001013, '');
        }
      }
    }

    .problem {
      //margin-right: 495px;
      margin-right: 115px;
    }

    .service {
      margin-right: 0;
      //margin-left: 394px;
      margin-left: 124px;

      @include Screen(1) {
        margin-left: 0px;
      }

      .service-btn {
        //width: 120px;
        height: 38px;
        border-radius: 4px;
        border: 1px solid #596080;
        box-sizing: border-box;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #424866;

        &:hover {
          border-color: $color-primary;
        }

        i.icon-kefu {
          margin-right: 3px;
          font-size: 18px;
          margin-top: 2px;

        }
      }
    }

  }

  .ipc-info-box {
    position: relative;
    padding: 32px 0 28px;

    > div {
      margin-bottom: 10px;
    }

    .ipc-info {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .line{
        margin: 0 5px;
      }
      a, span {
        font-size: 13px;
        color: #0A2540;
        line-height: 17px;
      }

      a {
        &:hover {
          color: $color-primary;
          text-decoration: underline;
        }
      }
    }

    .license-info {
      a, span {
        color: #596088;

      }

      a {
        &:hover {
          color: $color-primary;
          text-decoration: underline;
        }
      }
    }

    .industry {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      //static.588ku.com/chaotu/home/images/application/icon-sketch.svg
      //static.588ku.com/chaotu/public/footer/industry.png 404 (Not Found)
      @include BgImage(120px, 38px, "public/footer/industry.png");
    }

    .friend-link {
      margin-bottom: 28px;

      > span {
        font-size: 14px;
        color: #0A2540;
        line-height: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon-a-arrowright {
          display: inline-block;
          font-size: 16px;
          transform: rotate(90deg);
          margin-left: 3px;
          transition: all 0.5s ease-in-out;

          &.ON {
            transform: rotate(270deg);
          }

        }
      }

      .friend-link-content {
        margin-top: 14px;
        font-size: 0;
        display: flex;
        flex-wrap: wrap;

        a, span {
          font-size: 14px;
          color: #424866;
          line-height: 20px;
          margin-right: 32px;

          @include ElementHover($color-primary);
        }
      }
    }

    @include Screen(1) {
      .ipc-info {
        //max-width: 1012px;
      }


    }

  }

}


.shgs {
  display: inline-flex !important;
  align-items: center;
  .icon_sj {
    display: inline-block;
    @include BgImage(20px, 20px, '_chaotu/static/icon_shgs.png');
    margin-right: 4px;
  }

  .icon_sj2{
    display: inline-block;
    @include BgImage(30px, 30px, '_chaotu/static/icon_sj2.png?v=3');
    margin-right: 4px;
  }
}


</style>
