<template>
  <div
    v-if="isShow"
    class="container">
    <div class="__box-cover"/>
    <div class="__box-content box-content">
      <div
        class="__box-close"
        @click="cancel">
        <i class="iconfont icon-cha _pointer"/>
      </div>
      <div class="headline">真的要放弃开通吗？</div>
      <div class="subhead">相遇不易，再考虑考虑呗</div>
      <div class="btn-handle">
        <div
          class="btn-item btn-cancel _pointer"
          @click="cancel">狠心离开</div>
        <div
          class="btn-item btn-confirm _pointer"
          @click="confirm">立即开通</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "PayRetainDialog",
  components: {
  },
  data() {
    return {
      isShow: false
    }
  },
  methods:{
    cancel(){
      this.isShow = false;
      this.$emit("cancel");
    },
    confirm(){
      this.isShow = false;
      this.$emit("confirm");
    }
  }
}
</script>

<style lang="scss" scoped>
@import "components/common/css/common.scss";

.container {
  @include ModalBox(516, 350);
  .box-content {
    width: 516px;
    height: 350px;
    border-radius: 14px;
    background-color: #fff;
    text-align: center;
    padding-top: 84px;

    .headline {
      font-size: 24px;
      font-weight: bold;
      color: #424866;
      line-height: 31px;
      letter-spacing: 1px;
    }

    .subhead {
      font-size: 14px;
      color: #596088;
      line-height: 20px;
      margin: 12px 0 86px;
    }

    .btn-handle {
      @include LayoutFlex(3);
      margin: 0 78px;

      .btn-item {
        width: 160px;
        height: 44px;
        background: #E0E3E9;
        border-radius: 25px;
        font-size: 14px;
        color: #596088;
        line-height: 44px;
        text-align: center;
      }

      .btn-cancel {
        @include ElementHover('', #C8CBD4);
      }

      .btn-confirm {
        background: $color-primary;
        color: #FFFFFF;
        @include ElementHover('', #5E5EE8);
      }
    }

  }
}
</style>
