/* 素材列表转换 */
import {toolDate} from "@/tools";

export function listObjToArray(data) {
  let dataArr = []
  if (Object.prototype.toString.call(data) === '[object Object]' && Object.keys(data).length > 0) {
    for (const key in data) {
      dataArr.push(data[key])
    }
  }
  if (Object.prototype.toString.call(data) === '[object Array]' && data.length > 0) {
    dataArr = data;
  }

  return dataArr;
}


/* 素材设置默认显示图片类型 */
export function setListType(data) {
  let arr = [];
  if (data.length > 0) {
    data.forEach(vo => {
      vo.show_square = -1;
    })
    arr = data;
  }
  return arr
}

/* 获取昨天+当前时分秒 */
export function listGetNextDate(){
  let timeObj = toolDate.getDate();
  let oldDate = toolDate.getNextDate(-1,timeObj.date)
  let  { hours,minutes,seconds } = timeObj
  return `${oldDate} ${hours}:${minutes}:${seconds}`
}
