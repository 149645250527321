import {mapState} from "vuex";
import PicButtonFree from '@/components/pic/pic-button/pic-button-free'
import PicButtonBusiness from '@/components/pic/pic-button/pic-button-business';
import PicPackageButton from '@/components/pic-package/pic-package-button/pic-package-button';
import PicPackageButtonPrice from '@/components/pic-package/pic-package-button/pic-package-price-button';
import PicPackageButtonPlain from '@/components/pic-package/pic-package-button/pic-package-button-plain';
import {fnLogin, fnRouter} from "@/fn";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import FnPic from "@/fn/FnPic";
import {MATERIAL_DETAIL, IMG_BASE_URL} from "@/constant/PATH";
import {LOGIN_GUIDE_STATE} from "@/constant/LOGIN_GUIDE";


let PicButtonGroupExtend = {
  props: {
    // 单个图片信息
    picItemInfo: {
      type: Object,
      default: () => {
      }
    }
  },

  watch:{
    picItemInfo:{
      handler(n,o){
        if (n){
          if (n.layout_name){
            let layoutName = n.layout_name ? n.layout_name :n.layout;
            n.layoutNameStr = layoutName.join(' / ');
          }

        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('user',
      [
        'personVipState',
        'personVipInfo',
        'businessVipState',
        'businessVipInfo',
        'companyVipState',
        'companyVipInfo',
        'isLogin'
      ]
    ),
  },
  components: {
    PicButtonFree,
    PicButtonBusiness,
    PicPackageButton,
    PicPackageButtonPrice,
    PicPackageButtonPlain
  },
  methods:{
    downloadByFree(picItemInfo,channel){
      this.$emit('downloadByFree',picItemInfo,channel)
    },

    downloadByBusiness(picItemInfo,channel){
      this.$emit('downloadByBusiness',picItemInfo,channel)
    },
    navigatorToPay() {
      if (fnLogin.checkLogin()){
        this.$emit('navigatorToPay')
      }else {
        fnLogin.loginIntercept(false);
      }
    },

    showPicPackageBuyModal(picItemInfo) {
      this.$emit('showPicPackageBuyModal',picItemInfo)
    },

    downloadPicPackage(channel, uniq_id) {
      this.$emit('downloadPicPackage',channel, uniq_id)
    },

    navTo(){
      let path = MATERIAL_DETAIL + '/' + this.picItemInfo.uniq_id + '.html';
      fnRouter.navigationTo(path)
    }

  }
}

export default PicButtonGroupExtend;
