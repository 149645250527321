<template>
  <div
    class="PicButtonBusiness"
    :class="'PicButtonBusiness' + buttonType"
  >
    {{ buttonTxt }}
  </div>
</template>

<script>
export default {
  name: "PicButtonBusiness",
  props: {
    buttonTxt: {
      type: String,
      default: '商用按钮'
    },
    buttonType:{
      type: Number | String,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.PicButtonBusiness{
  width: 240px;
  height: 54px;
  background: #6969FF;
  border-radius: 27px;
  font-size: 20px;
  color: $color-primary;
  line-height: 54px;
  text-align: center;
  background: rgba(255,255,255,0.9);
  border: 1px solid #6969FF;

  &:hover{
    background: rgba(255,255,255,1);
  }

  &.PicButtonBusiness4{
    border: 1px solid rgba(61, 66, 102, 1);
    color: rgba(61, 66, 102, 0.9);
    &:hover{
      //background: #7878FF;
    }
  }
}
</style>
