import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _43fdce7c = () => interopDefault(import('..\\pages\\3d\\index.vue' /* webpackChunkName: "pages/3d/index" */))
const _79de8996 = () => interopDefault(import('..\\pages\\calendar\\index.vue' /* webpackChunkName: "pages/calendar/index" */))
const _5b2a9ae4 = () => interopDefault(import('..\\pages\\company\\index.vue' /* webpackChunkName: "pages/company/index" */))
const _78db83d5 = () => interopDefault(import('..\\pages\\creator\\index.vue' /* webpackChunkName: "pages/creator/index" */))
const _45d2c982 = () => interopDefault(import('..\\pages\\error-page\\index.vue' /* webpackChunkName: "pages/error-page/index" */))
const _7a76be8a = () => interopDefault(import('..\\pages\\exchange-code\\index.vue' /* webpackChunkName: "pages/exchange-code/index" */))
const _24bae5f3 = () => interopDefault(import('..\\pages\\fans\\index.vue' /* webpackChunkName: "pages/fans/index" */))
const _71ead984 = () => interopDefault(import('..\\pages\\fans-club\\index.vue' /* webpackChunkName: "pages/fans-club/index" */))
const _192eaee4 = () => interopDefault(import('..\\pages\\feedback\\index.vue' /* webpackChunkName: "pages/feedback/index" */))
const _030ca97c = () => interopDefault(import('..\\pages\\introduce\\index.vue' /* webpackChunkName: "pages/introduce/index" */))
const _1de86e6c = () => interopDefault(import('..\\pages\\invite\\index.vue' /* webpackChunkName: "pages/invite/index" */))
const _2438cb10 = () => interopDefault(import('..\\pages\\invite-h5\\index.vue' /* webpackChunkName: "pages/invite-h5/index" */))
const _3419d9a3 = () => interopDefault(import('..\\pages\\invite-member\\index.vue' /* webpackChunkName: "pages/invite-member/index" */))
const _2cc68594 = () => interopDefault(import('..\\pages\\module.js' /* webpackChunkName: "pages/module" */))
const _2b43c6fa = () => interopDefault(import('..\\pages\\part-time\\index.vue' /* webpackChunkName: "pages/part-time/index" */))
const _7e2128ca = () => interopDefault(import('..\\pages\\protocol\\index.vue' /* webpackChunkName: "pages/protocol/index" */))
const _24550948 = () => interopDefault(import('..\\pages\\red-book-share\\index.vue' /* webpackChunkName: "pages/red-book-share/index" */))
const _4672a1e1 = () => interopDefault(import('..\\pages\\test\\index.vue' /* webpackChunkName: "pages/test/index" */))
const _c18bd4e8 = () => interopDefault(import('..\\pages\\timeline-update\\index.vue' /* webpackChunkName: "pages/timeline-update/index" */))
const _053a5836 = () => interopDefault(import('..\\pages\\upgrade\\index.vue' /* webpackChunkName: "pages/upgrade/index" */))
const _59bd7f0a = () => interopDefault(import('..\\pages\\user-center\\index.vue' /* webpackChunkName: "pages/user-center/index" */))
const _2c150770 = () => interopDefault(import('..\\pages\\act\\618\\index.vue' /* webpackChunkName: "pages/act/618/index" */))
const _260f7648 = () => interopDefault(import('..\\pages\\act\\hb-capture\\index.vue' /* webpackChunkName: "pages/act/hb-capture/index" */))
const _c1c35666 = () => interopDefault(import('..\\pages\\calendar\\index2.vue' /* webpackChunkName: "pages/calendar/index2" */))
const _d27ff654 = () => interopDefault(import('..\\pages\\calendar\\module.js' /* webpackChunkName: "pages/calendar/module" */))
const _601f04f3 = () => interopDefault(import('..\\pages\\collection\\downloadMixin.js' /* webpackChunkName: "pages/collection/downloadMixin" */))
const _4159b56a = () => interopDefault(import('..\\pages\\collection\\mixin.js' /* webpackChunkName: "pages/collection/mixin" */))
const _edd390cc = () => interopDefault(import('..\\pages\\collection\\share-code\\index.vue' /* webpackChunkName: "pages/collection/share-code/index" */))
const _35fdb975 = () => interopDefault(import('..\\pages\\company\\case-collection\\index.vue' /* webpackChunkName: "pages/company/case-collection/index" */))
const _299ec6e0 = () => interopDefault(import('..\\pages\\company\\collect-record\\index.vue' /* webpackChunkName: "pages/company/collect-record/index" */))
const _009a4981 = () => interopDefault(import('..\\pages\\company\\main-certificate\\index.vue' /* webpackChunkName: "pages/company/main-certificate/index" */))
const _60da5024 = () => interopDefault(import('..\\pages\\company\\mandate-record\\index.vue' /* webpackChunkName: "pages/company/mandate-record/index" */))
const _751a958e = () => interopDefault(import('..\\pages\\company\\pay-record\\index.vue' /* webpackChunkName: "pages/company/pay-record/index" */))
const _1c852abb = () => interopDefault(import('..\\pages\\company\\team-manage\\index.vue' /* webpackChunkName: "pages/company/team-manage/index" */))
const _128bdd45 = () => interopDefault(import('..\\pages\\components\\HelpStatusModal.vue' /* webpackChunkName: "pages/components/HelpStatusModal" */))
const _4e6e98c8 = () => interopDefault(import('..\\pages\\creator\\design-guide\\index.vue' /* webpackChunkName: "pages/creator/design-guide/index" */))
const _7190b6d6 = () => interopDefault(import('..\\pages\\creator\\pic\\index.vue' /* webpackChunkName: "pages/creator/pic/index" */))
const _3b98f390 = () => interopDefault(import('..\\pages\\creator\\sign\\index.vue' /* webpackChunkName: "pages/creator/sign/index" */))
const _44300170 = () => interopDefault(import('..\\pages\\creator\\task\\index.vue' /* webpackChunkName: "pages/creator/task/index" */))
const _3d9ed330 = () => interopDefault(import('..\\pages\\creator\\user-center\\index.vue' /* webpackChunkName: "pages/creator/user-center/index" */))
const _0ff8465d = () => interopDefault(import('..\\pages\\creator\\works\\index.vue' /* webpackChunkName: "pages/creator/works/index" */))
const _90cb5df6 = () => interopDefault(import('..\\pages\\creator\\works-manage\\index.vue' /* webpackChunkName: "pages/creator/works-manage/index" */))
const _0f276284 = () => interopDefault(import('..\\pages\\detail\\mixin.js' /* webpackChunkName: "pages/detail/mixin" */))
const _193f3642 = () => interopDefault(import('..\\pages\\enterprise\\case\\index.vue' /* webpackChunkName: "pages/enterprise/case/index" */))
const _6850c482 = () => interopDefault(import('..\\pages\\fans\\festival-lottery\\index.vue' /* webpackChunkName: "pages/fans/festival-lottery/index" */))
const _96099130 = () => interopDefault(import('..\\pages\\fans\\member-demand-pool\\index.vue' /* webpackChunkName: "pages/fans/member-demand-pool/index" */))
const _42821716 = () => interopDefault(import('..\\pages\\introduce\\company\\index.vue' /* webpackChunkName: "pages/introduce/company/index" */))
const _f7b4b2d0 = () => interopDefault(import('..\\pages\\introduce\\company-plus\\index.vue' /* webpackChunkName: "pages/introduce/company-plus/index" */))
const _034d5c9c = () => interopDefault(import('..\\pages\\introduce\\multiple\\index.vue' /* webpackChunkName: "pages/introduce/multiple/index" */))
const _053917de = () => interopDefault(import('..\\pages\\invite\\team-role.vue' /* webpackChunkName: "pages/invite/team-role" */))
const _434ccfd5 = () => interopDefault(import('..\\pages\\invoice\\module.js' /* webpackChunkName: "pages/invoice/module" */))
const _4bec66c1 = () => interopDefault(import('..\\pages\\invoice\\write.vue' /* webpackChunkName: "pages/invoice/write" */))
const _503d9cd0 = () => interopDefault(import('..\\pages\\member\\license-vip.vue' /* webpackChunkName: "pages/member/license-vip" */))
const _0219deca = () => interopDefault(import('..\\pages\\part-time\\module.js' /* webpackChunkName: "pages/part-time/module" */))
const _4efe5e4e = () => interopDefault(import('..\\pages\\pay\\act-company\\index.vue' /* webpackChunkName: "pages/pay/act-company/index" */))
const _56c35284 = () => interopDefault(import('..\\pages\\pay\\copy.vue' /* webpackChunkName: "pages/pay/copy" */))
const _5b750036 = () => interopDefault(import('..\\pages\\pay\\edu\\index.vue' /* webpackChunkName: "pages/pay/edu/index" */))
const _0b9b9afa = () => interopDefault(import('..\\pages\\pay\\module.js' /* webpackChunkName: "pages/pay/module" */))
const _3d82e1c5 = () => interopDefault(import('..\\pages\\protocol\\components\\index.vue' /* webpackChunkName: "pages/protocol/components/index" */))
const _796ed06e = () => interopDefault(import('..\\pages\\survey\\user\\index.vue' /* webpackChunkName: "pages/survey/user/index" */))
const _7c80f116 = () => interopDefault(import('..\\pages\\tags\\fnTag.js' /* webpackChunkName: "pages/tags/fnTag" */))
const _f00d78be = () => interopDefault(import('..\\pages\\test\\index2.vue' /* webpackChunkName: "pages/test/index2" */))
const _440b4a8e = () => interopDefault(import('..\\pages\\topic\\mixin.js' /* webpackChunkName: "pages/topic/mixin" */))
const _b9ad6da8 = () => interopDefault(import('..\\pages\\tupian\\model.js' /* webpackChunkName: "pages/tupian/model" */))
const _dcd17d0e = () => interopDefault(import('..\\pages\\user-center\\code-record\\index.vue' /* webpackChunkName: "pages/user-center/code-record/index" */))
const _6e9b1694 = () => interopDefault(import('..\\pages\\user-center\\collect-record\\index.vue' /* webpackChunkName: "pages/user-center/collect-record/index" */))
const _b30f511c = () => interopDefault(import('..\\pages\\user-center\\coupon-record\\index.vue' /* webpackChunkName: "pages/user-center/coupon-record/index" */))
const _8e114958 = () => interopDefault(import('..\\pages\\user-center\\download-record\\index.vue' /* webpackChunkName: "pages/user-center/download-record/index" */))
const _668402c9 = () => interopDefault(import('..\\pages\\user-center\\leaflet-record\\index.vue' /* webpackChunkName: "pages/user-center/leaflet-record/index" */))
const _49c5feda = () => interopDefault(import('..\\pages\\user-center\\user-info\\index.vue' /* webpackChunkName: "pages/user-center/user-info/index" */))
const _e0dd162e = () => interopDefault(import('..\\pages\\user-center\\vip-record\\index.vue' /* webpackChunkName: "pages/user-center/vip-record/index" */))
const _90f97ab0 = () => interopDefault(import('..\\pages\\act\\618\\2.vue' /* webpackChunkName: "pages/act/618/2" */))
const _90dd4bae = () => interopDefault(import('..\\pages\\act\\618\\3.vue' /* webpackChunkName: "pages/act/618/3" */))
const _779aca07 = () => interopDefault(import('..\\pages\\act\\618\\actBar\\index.vue' /* webpackChunkName: "pages/act/618/actBar/index" */))
const _74d4f0a4 = () => interopDefault(import('..\\pages\\act\\618\\actRule.vue' /* webpackChunkName: "pages/act/618/actRule" */))
const _55f3069d = () => interopDefault(import('..\\pages\\act\\618\\actWait.vue' /* webpackChunkName: "pages/act/618/actWait" */))
const _84b6742e = () => interopDefault(import('..\\pages\\act\\618\\module.js' /* webpackChunkName: "pages/act/618/module" */))
const _7eb0e306 = () => interopDefault(import('..\\pages\\act\\hb-capture\\module.js' /* webpackChunkName: "pages/act/hb-capture/module" */))
const _dcbd9220 = () => interopDefault(import('..\\pages\\company\\team-manage\\invite.vue' /* webpackChunkName: "pages/company/team-manage/invite" */))
const _7d4f1451 = () => interopDefault(import('..\\pages\\company\\team-manage\\join.vue' /* webpackChunkName: "pages/company/team-manage/join" */))
const _7e0f9cc6 = () => interopDefault(import('..\\pages\\company\\team-manage\\role.vue' /* webpackChunkName: "pages/company/team-manage/role" */))
const _1fd88e23 = () => interopDefault(import('..\\pages\\creator\\sign\\sign-progress.vue' /* webpackChunkName: "pages/creator/sign/sign-progress" */))
const _ea3218f2 = () => interopDefault(import('..\\pages\\creator\\sign\\sign-renew.vue' /* webpackChunkName: "pages/creator/sign/sign-renew" */))
const _726f704e = () => interopDefault(import('..\\pages\\creator\\sign\\sign-write.vue' /* webpackChunkName: "pages/creator/sign/sign-write" */))
const _252563a6 = () => interopDefault(import('..\\pages\\creator\\user-center\\certificate.vue' /* webpackChunkName: "pages/creator/user-center/certificate" */))
const _44abd942 = () => interopDefault(import('..\\pages\\creator\\works-manage\\WorkDrawer.vue' /* webpackChunkName: "pages/creator/works-manage/WorkDrawer" */))
const _3d868450 = () => interopDefault(import('..\\pages\\fans\\common\\mixin.js' /* webpackChunkName: "pages/fans/common/mixin" */))
const _244b7786 = () => interopDefault(import('..\\pages\\fans\\demand-pool\\mixin.js' /* webpackChunkName: "pages/fans/demand-pool/mixin" */))
const _5beec95a = () => interopDefault(import('..\\pages\\introduce\\multiple\\module.js' /* webpackChunkName: "pages/introduce/multiple/module" */))
const _b4166cf4 = () => interopDefault(import('..\\pages\\pay\\edu\\module.js' /* webpackChunkName: "pages/pay/edu/module" */))
const _5ce30078 = () => interopDefault(import('..\\pages\\pay\\enterprise-recharge\\coupon-pulldown.vue' /* webpackChunkName: "pages/pay/enterprise-recharge/coupon-pulldown" */))
const _f47db0ee = () => interopDefault(import('..\\pages\\pay\\enterprise-recharge\\module.js' /* webpackChunkName: "pages/pay/enterprise-recharge/module" */))
const _6abab41d = () => interopDefault(import('..\\pages\\pay\\enterprise-recharge\\transfer\\index.vue' /* webpackChunkName: "pages/pay/enterprise-recharge/transfer/index" */))
const _63b9705f = () => interopDefault(import('..\\pages\\pay\\leaflet\\module.js' /* webpackChunkName: "pages/pay/leaflet/module" */))
const _64297aa3 = () => interopDefault(import('..\\pages\\pay\\personal-recharge\\coupon-pulldown.vue' /* webpackChunkName: "pages/pay/personal-recharge/coupon-pulldown" */))
const _826145b0 = () => interopDefault(import('..\\pages\\pay\\personal-recharge\\module.js' /* webpackChunkName: "pages/pay/personal-recharge/module" */))
const _5d865efc = () => interopDefault(import('..\\pages\\protocol\\common\\protocol-extend.js' /* webpackChunkName: "pages/protocol/common/protocol-extend" */))
const _15c8e6b0 = () => interopDefault(import('..\\pages\\protocol\\components\\about-company.vue' /* webpackChunkName: "pages/protocol/components/about-company" */))
const _422bd04c = () => interopDefault(import('..\\pages\\protocol\\components\\common-problem.vue' /* webpackChunkName: "pages/protocol/components/common-problem" */))
const _072a84dc = () => interopDefault(import('..\\pages\\protocol\\components\\login.vue' /* webpackChunkName: "pages/protocol/components/login" */))
const _4ad0fe50 = () => interopDefault(import('..\\pages\\protocol\\components\\vip.vue' /* webpackChunkName: "pages/protocol/components/vip" */))
const _63de4b12 = () => interopDefault(import('..\\pages\\survey\\user\\components\\index.js' /* webpackChunkName: "pages/survey/user/components/index" */))
const _4d1e1a0f = () => interopDefault(import('..\\pages\\survey\\user\\module.js' /* webpackChunkName: "pages/survey/user/module" */))
const _60eac67f = () => interopDefault(import('..\\pages\\tupian\\common\\mixin.js' /* webpackChunkName: "pages/tupian/common/mixin" */))
const _7bf5f6d7 = () => interopDefault(import('..\\pages\\tupian\\common\\model.js' /* webpackChunkName: "pages/tupian/common/model" */))
const _c73c8352 = () => interopDefault(import('..\\pages\\user-center\\collect-record\\module.js' /* webpackChunkName: "pages/user-center/collect-record/module" */))
const _7a27a113 = () => interopDefault(import('..\\pages\\user-center\\coupon-record\\module.js' /* webpackChunkName: "pages/user-center/coupon-record/module" */))
const _e6b2b616 = () => interopDefault(import('..\\pages\\user-center\\download-record\\module.js' /* webpackChunkName: "pages/user-center/download-record/module" */))
const _6340be8a = () => interopDefault(import('..\\pages\\user-center\\vip-record\\module.js' /* webpackChunkName: "pages/user-center/vip-record/module" */))
const _113a4ff0 = () => interopDefault(import('..\\pages\\act\\618\\actBar\\bar.vue' /* webpackChunkName: "pages/act/618/actBar/bar" */))
const _73d759f0 = () => interopDefault(import('..\\pages\\introduce\\multiple\\components\\authorized-scope.vue' /* webpackChunkName: "pages/introduce/multiple/components/authorized-scope" */))
const _f4f13128 = () => interopDefault(import('..\\pages\\introduce\\multiple\\components\\Question.vue' /* webpackChunkName: "pages/introduce/multiple/components/Question" */))
const _e623cfd0 = () => interopDefault(import('..\\pages\\introduce\\multiple\\components\\vip-card-group.vue' /* webpackChunkName: "pages/introduce/multiple/components/vip-card-group" */))
const _63fb2745 = () => interopDefault(import('..\\pages\\survey\\user\\components\\age.vue' /* webpackChunkName: "pages/survey/user/components/age" */))
const _0e880f08 = () => interopDefault(import('..\\pages\\survey\\user\\components\\industry.vue' /* webpackChunkName: "pages/survey/user/components/industry" */))
const _4d8fbb03 = () => interopDefault(import('..\\pages\\survey\\user\\components\\job.vue' /* webpackChunkName: "pages/survey/user/components/job" */))
const _68b30637 = () => interopDefault(import('..\\pages\\user-center\\download-record\\components\\CertificationModal.vue' /* webpackChunkName: "pages/user-center/download-record/components/CertificationModal" */))
const _1dff7f0e = () => interopDefault(import('..\\pages\\enterprise\\case\\detail\\_id.vue' /* webpackChunkName: "pages/enterprise/case/detail/_id" */))
const _49164570 = () => interopDefault(import('..\\pages\\company\\pay\\_id.vue' /* webpackChunkName: "pages/company/pay/_id" */))
const _bacd3394 = () => interopDefault(import('..\\pages\\fans\\demand-detail\\_id.vue' /* webpackChunkName: "pages/fans/demand-detail/_id" */))
const _f12fedce = () => interopDefault(import('..\\pages\\fans\\demand-detail\\_mixin.js' /* webpackChunkName: "pages/fans/demand-detail/_mixin" */))
const _d50547ea = () => interopDefault(import('..\\pages\\fans\\demand-pool\\_id.vue' /* webpackChunkName: "pages/fans/demand-pool/_id" */))
const _dc9c45d4 = () => interopDefault(import('..\\pages\\fans\\member-demand-pool-detail\\_id.vue' /* webpackChunkName: "pages/fans/member-demand-pool-detail/_id" */))
const _7cce3a50 = () => interopDefault(import('..\\pages\\pay\\enterprise-recharge\\_id.vue' /* webpackChunkName: "pages/pay/enterprise-recharge/_id" */))
const _58cec0a6 = () => interopDefault(import('..\\pages\\pay\\leaflet\\_id.vue' /* webpackChunkName: "pages/pay/leaflet/_id" */))
const _68ccec7d = () => interopDefault(import('..\\pages\\pay\\package\\_id.vue' /* webpackChunkName: "pages/pay/package/_id" */))
const _f64968a2 = () => interopDefault(import('..\\pages\\pay\\personal-recharge\\_id.vue' /* webpackChunkName: "pages/pay/personal-recharge/_id" */))
const _62dfb767 = () => interopDefault(import('..\\pages\\pay\\upgrade\\_id.vue' /* webpackChunkName: "pages/pay/upgrade/_id" */))
const _73fd213d = () => interopDefault(import('..\\pages\\collection\\_id.vue' /* webpackChunkName: "pages/collection/_id" */))
const _012f7d6a = () => interopDefault(import('..\\pages\\detail\\_id.vue' /* webpackChunkName: "pages/detail/_id" */))
const _229e5178 = () => interopDefault(import('..\\pages\\invite-h5\\_id.vue' /* webpackChunkName: "pages/invite-h5/_id" */))
const _f4ad149c = () => interopDefault(import('..\\pages\\invite\\_id.vue' /* webpackChunkName: "pages/invite/_id" */))
const _627a624c = () => interopDefault(import('..\\pages\\pay-company-plus\\_id.vue' /* webpackChunkName: "pages/pay-company-plus/_id" */))
const _863e53fe = () => interopDefault(import('..\\pages\\pay\\_id.vue' /* webpackChunkName: "pages/pay/_id" */))
const _1fb1d1f4 = () => interopDefault(import('..\\pages\\pic-package\\_id.vue' /* webpackChunkName: "pages/pic-package/_id" */))
const _52ed7e3c = () => interopDefault(import('..\\pages\\tags\\_id.vue' /* webpackChunkName: "pages/tags/_id" */))
const _2a4c70cc = () => interopDefault(import('..\\pages\\topic\\_id.vue' /* webpackChunkName: "pages/topic/_id" */))
const _fc9dced8 = () => interopDefault(import('..\\pages\\tupian\\_id.vue' /* webpackChunkName: "pages/tupian/_id" */))
const _315f125a = () => interopDefault(import('..\\pages\\venue\\_id.vue' /* webpackChunkName: "pages/venue/_id" */))
const _59173bf3 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/3d",
    component: _43fdce7c,
    name: "3d"
  }, {
    path: "/calendar",
    component: _79de8996,
    name: "calendar"
  }, {
    path: "/company",
    component: _5b2a9ae4,
    name: "company"
  }, {
    path: "/creator",
    component: _78db83d5,
    name: "creator"
  }, {
    path: "/error-page",
    component: _45d2c982,
    name: "error-page"
  }, {
    path: "/exchange-code",
    component: _7a76be8a,
    name: "exchange-code"
  }, {
    path: "/fans",
    component: _24bae5f3,
    name: "fans"
  }, {
    path: "/fans-club",
    component: _71ead984,
    name: "fans-club"
  }, {
    path: "/feedback",
    component: _192eaee4,
    name: "feedback"
  }, {
    path: "/introduce",
    component: _030ca97c,
    name: "introduce"
  }, {
    path: "/invite",
    component: _1de86e6c,
    name: "invite"
  }, {
    path: "/invite-h5",
    component: _2438cb10,
    name: "invite-h5"
  }, {
    path: "/invite-member",
    component: _3419d9a3,
    name: "invite-member"
  }, {
    path: "/module",
    component: _2cc68594,
    name: "module"
  }, {
    path: "/part-time",
    component: _2b43c6fa,
    name: "part-time"
  }, {
    path: "/protocol",
    component: _7e2128ca,
    name: "protocol"
  }, {
    path: "/red-book-share",
    component: _24550948,
    name: "red-book-share"
  }, {
    path: "/test",
    component: _4672a1e1,
    name: "test"
  }, {
    path: "/timeline-update",
    component: _c18bd4e8,
    name: "timeline-update"
  }, {
    path: "/upgrade",
    component: _053a5836,
    name: "upgrade"
  }, {
    path: "/user-center",
    component: _59bd7f0a,
    name: "user-center"
  }, {
    path: "/act/618",
    component: _2c150770,
    name: "act-618"
  }, {
    path: "/act/hb-capture",
    component: _260f7648,
    name: "act-hb-capture"
  }, {
    path: "/calendar/index2",
    component: _c1c35666,
    name: "calendar-index2"
  }, {
    path: "/calendar/module",
    component: _d27ff654,
    name: "calendar-module"
  }, {
    path: "/collection/downloadMixin",
    component: _601f04f3,
    name: "collection-downloadMixin"
  }, {
    path: "/collection/mixin",
    component: _4159b56a,
    name: "collection-mixin"
  }, {
    path: "/collection/share-code",
    component: _edd390cc,
    name: "collection-share-code"
  }, {
    path: "/company/case-collection",
    component: _35fdb975,
    name: "company-case-collection"
  }, {
    path: "/company/collect-record",
    component: _299ec6e0,
    name: "company-collect-record"
  }, {
    path: "/company/main-certificate",
    component: _009a4981,
    name: "company-main-certificate"
  }, {
    path: "/company/mandate-record",
    component: _60da5024,
    name: "company-mandate-record"
  }, {
    path: "/company/pay-record",
    component: _751a958e,
    name: "company-pay-record"
  }, {
    path: "/company/team-manage",
    component: _1c852abb,
    name: "company-team-manage"
  }, {
    path: "/components/HelpStatusModal",
    component: _128bdd45,
    name: "components-HelpStatusModal"
  }, {
    path: "/creator/design-guide",
    component: _4e6e98c8,
    name: "creator-design-guide"
  }, {
    path: "/creator/pic",
    component: _7190b6d6,
    name: "creator-pic"
  }, {
    path: "/creator/sign",
    component: _3b98f390,
    name: "creator-sign"
  }, {
    path: "/creator/task",
    component: _44300170,
    name: "creator-task"
  }, {
    path: "/creator/user-center",
    component: _3d9ed330,
    name: "creator-user-center"
  }, {
    path: "/creator/works",
    component: _0ff8465d,
    name: "creator-works"
  }, {
    path: "/creator/works-manage",
    component: _90cb5df6,
    name: "creator-works-manage"
  }, {
    path: "/detail/mixin",
    component: _0f276284,
    name: "detail-mixin"
  }, {
    path: "/enterprise/case",
    component: _193f3642,
    name: "enterprise-case"
  }, {
    path: "/fans/festival-lottery",
    component: _6850c482,
    name: "fans-festival-lottery"
  }, {
    path: "/fans/member-demand-pool",
    component: _96099130,
    name: "fans-member-demand-pool"
  }, {
    path: "/introduce/company",
    component: _42821716,
    name: "introduce-company"
  }, {
    path: "/introduce/company-plus",
    component: _f7b4b2d0,
    name: "introduce-company-plus"
  }, {
    path: "/introduce/multiple",
    component: _034d5c9c,
    name: "introduce-multiple"
  }, {
    path: "/invite/team-role",
    component: _053917de,
    name: "invite-team-role"
  }, {
    path: "/invoice/module",
    component: _434ccfd5,
    name: "invoice-module"
  }, {
    path: "/invoice/write",
    component: _4bec66c1,
    name: "invoice-write"
  }, {
    path: "/member/license-vip",
    component: _503d9cd0,
    name: "member-license-vip"
  }, {
    path: "/part-time/module",
    component: _0219deca,
    name: "part-time-module"
  }, {
    path: "/pay/act-company",
    component: _4efe5e4e,
    name: "pay-act-company"
  }, {
    path: "/pay/copy",
    component: _56c35284,
    name: "pay-copy"
  }, {
    path: "/pay/edu",
    component: _5b750036,
    name: "pay-edu"
  }, {
    path: "/pay/module",
    component: _0b9b9afa,
    name: "pay-module"
  }, {
    path: "/protocol/components",
    component: _3d82e1c5,
    name: "protocol-components"
  }, {
    path: "/survey/user",
    component: _796ed06e,
    name: "survey-user"
  }, {
    path: "/tags/fnTag",
    component: _7c80f116,
    name: "tags-fnTag"
  }, {
    path: "/test/index2",
    component: _f00d78be,
    name: "test-index2"
  }, {
    path: "/topic/mixin",
    component: _440b4a8e,
    name: "topic-mixin"
  }, {
    path: "/tupian/model",
    component: _b9ad6da8,
    name: "tupian-model"
  }, {
    path: "/user-center/code-record",
    component: _dcd17d0e,
    name: "user-center-code-record"
  }, {
    path: "/user-center/collect-record",
    component: _6e9b1694,
    name: "user-center-collect-record"
  }, {
    path: "/user-center/coupon-record",
    component: _b30f511c,
    name: "user-center-coupon-record"
  }, {
    path: "/user-center/download-record",
    component: _8e114958,
    name: "user-center-download-record"
  }, {
    path: "/user-center/leaflet-record",
    component: _668402c9,
    name: "user-center-leaflet-record"
  }, {
    path: "/user-center/user-info",
    component: _49c5feda,
    name: "user-center-user-info"
  }, {
    path: "/user-center/vip-record",
    component: _e0dd162e,
    name: "user-center-vip-record"
  }, {
    path: "/act/618/2",
    component: _90f97ab0,
    name: "act-618-2"
  }, {
    path: "/act/618/3",
    component: _90dd4bae,
    name: "act-618-3"
  }, {
    path: "/act/618/actBar",
    component: _779aca07,
    name: "act-618-actBar"
  }, {
    path: "/act/618/actRule",
    component: _74d4f0a4,
    name: "act-618-actRule"
  }, {
    path: "/act/618/actWait",
    component: _55f3069d,
    name: "act-618-actWait"
  }, {
    path: "/act/618/module",
    component: _84b6742e,
    name: "act-618-module"
  }, {
    path: "/act/hb-capture/module",
    component: _7eb0e306,
    name: "act-hb-capture-module"
  }, {
    path: "/company/team-manage/invite",
    component: _dcbd9220,
    name: "company-team-manage-invite"
  }, {
    path: "/company/team-manage/join",
    component: _7d4f1451,
    name: "company-team-manage-join"
  }, {
    path: "/company/team-manage/role",
    component: _7e0f9cc6,
    name: "company-team-manage-role"
  }, {
    path: "/creator/sign/sign-progress",
    component: _1fd88e23,
    name: "creator-sign-sign-progress"
  }, {
    path: "/creator/sign/sign-renew",
    component: _ea3218f2,
    name: "creator-sign-sign-renew"
  }, {
    path: "/creator/sign/sign-write",
    component: _726f704e,
    name: "creator-sign-sign-write"
  }, {
    path: "/creator/user-center/certificate",
    component: _252563a6,
    name: "creator-user-center-certificate"
  }, {
    path: "/creator/works-manage/WorkDrawer",
    component: _44abd942,
    name: "creator-works-manage-WorkDrawer"
  }, {
    path: "/fans/common/mixin",
    component: _3d868450,
    name: "fans-common-mixin"
  }, {
    path: "/fans/demand-pool/mixin",
    component: _244b7786,
    name: "fans-demand-pool-mixin"
  }, {
    path: "/introduce/multiple/module",
    component: _5beec95a,
    name: "introduce-multiple-module"
  }, {
    path: "/pay/edu/module",
    component: _b4166cf4,
    name: "pay-edu-module"
  }, {
    path: "/pay/enterprise-recharge/coupon-pulldown",
    component: _5ce30078,
    name: "pay-enterprise-recharge-coupon-pulldown"
  }, {
    path: "/pay/enterprise-recharge/module",
    component: _f47db0ee,
    name: "pay-enterprise-recharge-module"
  }, {
    path: "/pay/enterprise-recharge/transfer",
    component: _6abab41d,
    name: "pay-enterprise-recharge-transfer"
  }, {
    path: "/pay/leaflet/module",
    component: _63b9705f,
    name: "pay-leaflet-module"
  }, {
    path: "/pay/personal-recharge/coupon-pulldown",
    component: _64297aa3,
    name: "pay-personal-recharge-coupon-pulldown"
  }, {
    path: "/pay/personal-recharge/module",
    component: _826145b0,
    name: "pay-personal-recharge-module"
  }, {
    path: "/protocol/common/protocol-extend",
    component: _5d865efc,
    name: "protocol-common-protocol-extend"
  }, {
    path: "/protocol/components/about-company",
    component: _15c8e6b0,
    name: "protocol-components-about-company"
  }, {
    path: "/protocol/components/common-problem",
    component: _422bd04c,
    name: "protocol-components-common-problem"
  }, {
    path: "/protocol/components/login",
    component: _072a84dc,
    name: "protocol-components-login"
  }, {
    path: "/protocol/components/vip",
    component: _4ad0fe50,
    name: "protocol-components-vip"
  }, {
    path: "/survey/user/components",
    component: _63de4b12,
    name: "survey-user-components"
  }, {
    path: "/survey/user/module",
    component: _4d1e1a0f,
    name: "survey-user-module"
  }, {
    path: "/tupian/common/mixin",
    component: _60eac67f,
    name: "tupian-common-mixin"
  }, {
    path: "/tupian/common/model",
    component: _7bf5f6d7,
    name: "tupian-common-model"
  }, {
    path: "/user-center/collect-record/module",
    component: _c73c8352,
    name: "user-center-collect-record-module"
  }, {
    path: "/user-center/coupon-record/module",
    component: _7a27a113,
    name: "user-center-coupon-record-module"
  }, {
    path: "/user-center/download-record/module",
    component: _e6b2b616,
    name: "user-center-download-record-module"
  }, {
    path: "/user-center/vip-record/module",
    component: _6340be8a,
    name: "user-center-vip-record-module"
  }, {
    path: "/act/618/actBar/bar",
    component: _113a4ff0,
    name: "act-618-actBar-bar"
  }, {
    path: "/introduce/multiple/components/authorized-scope",
    component: _73d759f0,
    name: "introduce-multiple-components-authorized-scope"
  }, {
    path: "/introduce/multiple/components/Question",
    component: _f4f13128,
    name: "introduce-multiple-components-Question"
  }, {
    path: "/introduce/multiple/components/vip-card-group",
    component: _e623cfd0,
    name: "introduce-multiple-components-vip-card-group"
  }, {
    path: "/survey/user/components/age",
    component: _63fb2745,
    name: "survey-user-components-age"
  }, {
    path: "/survey/user/components/industry",
    component: _0e880f08,
    name: "survey-user-components-industry"
  }, {
    path: "/survey/user/components/job",
    component: _4d8fbb03,
    name: "survey-user-components-job"
  }, {
    path: "/user-center/download-record/components/CertificationModal",
    component: _68b30637,
    name: "user-center-download-record-components-CertificationModal"
  }, {
    path: "/enterprise/case/detail/:id?",
    component: _1dff7f0e,
    name: "enterprise-case-detail-id"
  }, {
    path: "/company/pay/:id?",
    component: _49164570,
    name: "company-pay-id"
  }, {
    path: "/fans/demand-detail/:id?",
    component: _bacd3394,
    name: "fans-demand-detail-id"
  }, {
    path: "/fans/demand-detail/:mixin?",
    component: _f12fedce,
    name: "fans-demand-detail-mixin"
  }, {
    path: "/fans/demand-pool/:id?",
    component: _d50547ea,
    name: "fans-demand-pool-id"
  }, {
    path: "/fans/member-demand-pool-detail/:id?",
    component: _dc9c45d4,
    name: "fans-member-demand-pool-detail-id"
  }, {
    path: "/pay/enterprise-recharge/:id?",
    component: _7cce3a50,
    name: "pay-enterprise-recharge-id"
  }, {
    path: "/pay/leaflet/:id?",
    component: _58cec0a6,
    name: "pay-leaflet-id"
  }, {
    path: "/pay/package/:id?",
    component: _68ccec7d,
    name: "pay-package-id"
  }, {
    path: "/pay/personal-recharge/:id?",
    component: _f64968a2,
    name: "pay-personal-recharge-id"
  }, {
    path: "/pay/upgrade/:id?",
    component: _62dfb767,
    name: "pay-upgrade-id"
  }, {
    path: "/collection/:id?",
    component: _73fd213d,
    name: "collection-id"
  }, {
    path: "/detail/:id?",
    component: _012f7d6a,
    name: "detail-id"
  }, {
    path: "/invite-h5/:id",
    component: _229e5178,
    name: "invite-h5-id"
  }, {
    path: "/invite/:id",
    component: _f4ad149c,
    name: "invite-id"
  }, {
    path: "/pay-company-plus/:id?",
    component: _627a624c,
    name: "pay-company-plus-id"
  }, {
    path: "/pay/:id?",
    component: _863e53fe,
    name: "pay-id"
  }, {
    path: "/pic-package/:id?",
    component: _1fb1d1f4,
    name: "pic-package-id"
  }, {
    path: "/tags/:id?",
    component: _52ed7e3c,
    name: "tags-id"
  }, {
    path: "/topic/:id?",
    component: _2a4c70cc,
    name: "topic-id"
  }, {
    path: "/tupian/:id?",
    component: _fc9dced8,
    name: "tupian-id"
  }, {
    path: "/venue/:id?",
    component: _315f125a,
    name: "venue-id"
  }, {
    path: "/",
    component: _59173bf3,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
