<template>
  <div>
    <no-ssr>
      <CgModalMiddle
        v-if="isShow"
        class="NoticeVipUpgrade"
        @close="close"
      >
        <div slot="modal_content" class="notice-content">
          <div class="headline">会员权益升级公告</div>
          <div class="subhead">尊敬的会员：
            潮国创意在原创版权道路上一直保持初心，目前在生产运营端、硬件设备端做了战略升级，
            投入更高成本提升全站用户的使用体验，于<i class="txt-bold">2022年12月5日</i>对内容服务进行升级。
          </div>

          <div class="list-box">
            <div class="list-item">
              <div class="list-item-title"># 内容服务升级</div>
              <div class="list-item-content">
                <i class="equity"/>
              </div>
            </div>
            <div class="list-item">
              <div class="list-item-title"># 老会员权益保障</div>
              <div class="list-item-content">
                2022年12月6日前购买的会员（普通VIP、商用VIP）可继续享受全站下载权益，<br>
                2022年12月6日后续费或再次购买会员将按照新的下载权益。
              </div>
            </div>
          </div>

          <div class="know-btn" @click="close">我知道了</div>
        </div>
      </CgModalMiddle>
    </no-ssr>
  </div>
</template>

<script>
import {fnLocalStorage, fnLogin} from "@/fn";
import {mapState} from "vuex";
import {getCookie} from "~/utils/auth";
import {toolDate} from "@/tools";

const NOTICE_VIP_UPGRADE_DIALOG = 'NoticeVipUpgradeDialog';
export default {
  name: "NoticeVipUpgrade",
  computed: {
    ...mapState('user', ['isLogin', 'isSwitchAccountType'])
  },
  watch: {
    isSwitchAccountType: {
      handler(n, o) {
        if (n == true) {
          console.log('NoticeVipUpgrade -- isSwitchAccountType')
          setTimeout(() => {
            this.init();
          }, 1000)
        }
      }
    },
    isLogin: {
      handler(n, o) {
        if (n == true) {
          setTimeout(() => {
            this.init();
          }, 1000)
        }
      }
    },
  },
  data() {
    return {
      isShow: false
    }
  },
  mounted() {
    setTimeout(() => {
      this.init();
    }, 1000)
  },
  methods: {
    init() {
      if (!fnLogin.checkLogin()){
        return false
      }
      let flag = fnLocalStorage.getItem(NOTICE_VIP_UPGRADE_DIALOG)
      if (!flag) {
        this.isShow = this.checkIsOldUser();
      } else {
        this.isShow = false;
      }
      /*if (!flag && this.checkIsOldUser()) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }*/
    },

    /* 检查是否是老用户 */
    checkIsOldUser() {
      let setTime = '2022-12-06 00:00:00'; // 设置升级时间
      let personVipState = getCookie('personVipState')
      let personVipInfo = JSON.parse(getCookie('personVipInfo'))
      let businessVipState = getCookie('businessVipState')
      let businessVipInfo = JSON.parse(getCookie('businessVipInfo'))

      if (personVipState == 1) {
        if (toolDate.compare(setTime, personVipInfo.last_pay_time)){
          return true;
        }
        // return toolDate.compare(setTime, personVipInfo.last_pay_time);
      }
      if (businessVipState == 1) {
        if (toolDate.compare(setTime, businessVipInfo.last_pay_time)){
          return true;
        }
        // return toolDate.compare(setTime, businessVipInfo.last_pay_time);
      }
      return false
    },

    close() {
      this.isShow = false;
      fnLocalStorage.setItem(NOTICE_VIP_UPGRADE_DIALOG, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.NoticeVipUpgrade {

  .notice-content {
    position: relative;
    width: 580px;
    height: 536px;
    background: #FFFFFF;
    border-radius: 14px;
    padding: 32px 38px 24px;
    box-sizing: border-box;
    color: #2D2B33;

    &:before {
      content: '';
      display: inline-block;
      @include Position(1, 5, 0, 0);
      width: 100%;
      height: 134px;
      background: linear-gradient(180deg, rgba(105, 105, 255, 0.12) 0%, rgba(105, 105, 255, 0) 100%);
      border-radius: 14px;
    }

    .headline {
      height: 26px;
      font-size: 20px;
      font-weight: bold;
      line-height: 26px;
      letter-spacing: 2px;
      margin-bottom: 24px;
      text-align: center;
    }

    .subhead {
      font-size: 13px;
      line-height: 22px;
      margin-bottom: 18px;

      .txt-bold {
        font-weight: bold;
        text-decoration: underline;
      }
    }

    .list-box {
      .list-item {
        margin-bottom: 18px;

        .list-item-title {
          font-size: 13px;
          font-weight: bold;
          line-height: 18px;
          margin-bottom: 6px;
        }

        .list-item-content {
          font-size: 13px;
          line-height: 22px;

          .equity {
            margin-top: 4px;
            @include BgImage(504px, 170px, 'notice/NoticeVipUpgrade/equity.png');
          }
        }
      }
    }

    .know-btn {
      width: 190px;
      line-height: 40px;
      background: #6969FF;
      border-radius: 20px;
      text-align: center;
      font-size: 13px;
      color: #FFFFFF;
      margin: 20px auto 0;
      @include ElementHover('', rgba(105, 105, 255, 0.8));
      cursor: pointer;
    }


  }
}
</style>
