import {PAGE_PATH} from "@/constant/PAGE_PATH";

class FnRouter {
  constructor() {
  }

  /**
   * A链接跳转
   * @param url 跳转地址
   * @param target 是否新窗口打开
   * @param isCurr 是否是当前网站路由地址
   */
  navigationTo(url, target = '_blank', isCurr = false) {

    /* 设置默认网站地址 */
    /*if (isCurr) {
      url = url.indexOf('http') == -1 ? PAGE_PATH.BASE_URL + url : url
    }*/

    /* 判断是否是非本站 */
    url = url.indexOf('http') == -1 ? PAGE_PATH.BASE_URL + url : url

    // window.open(path,'_blank','noopener');
    let a = document.createElement('a');　　　　      //创建a标签
    a.setAttribute('href', url);　　　　　　　　       //url即为需要打开的新页面的url
    a.setAttribute('target', target);　　　　       //_blank新窗口打开
    a.setAttribute('rel', 'noopener noreferrer');　　//添加rel
    document.body.appendChild(a);
    a.click();　　　　　　　　　　　　　　　　　　　　     //触发click
  }

  /* router 跳转 */
  routerTo(url) {
    let {$router} = $nuxt;
    $router.push({
      path: url
    })
  }

  /**
   * 获取路由连接
   * @param router  PAGE_PATH内链接(非完整链接) 示例 /member/license-vip
   * @param params  额外参数 示例 { id：2 }
   * @returns {string}  返回完整链接 示例 http://chaopx.com/member/license-vip?id=2
   */
  getRouterLink(router, params = {}) {
    if (Object.keys(params).length > 0) {
      let tempParams = '?';
      for (const key in params) {
        tempParams += `${key}=${params[key]}&`
      }
      /* 去除最后一个& */
      if (tempParams.charAt(tempParams.length - 1) == '&') {
        tempParams = tempParams.slice(0, tempParams.length - 1)
      }
      return PAGE_PATH.BASE_URL + router + tempParams
    }
    return PAGE_PATH.BASE_URL + router
  }

}

export default new FnRouter();
