<template>
  <div class="userCenter">

    <div class="header-nav-wrap" ref="headerWrap">
      <div class="PublicHeader" id="HomeHeaderFixed">
        <PublicHeader
          id="Header"
          :params="params"
          :new-search-keyword="searchKeyword"
          :is-show-search="isShowSearch"
          class="header"
          @toMaterialList="toMaterialList"
        />
      </div>
    </div>
    <div class="header-nav-wrap-placeholder" :style=" 'height: '+ 80 + 'px'"/>

    <UserCenterHeader />

    <UserCenter>
      <div slot="content">
        <nuxt/>
      </div>
    </UserCenter>

    <no-ssr>
      <PublicSlot />
      <Login/>
      <Footer/>
      <!-- <VipUpgrade /> -->
    </no-ssr>
  </div>
</template>

<script>
import UserCenter from '@/components/_Privately/User/UserCenter'
import UserCenterHeader from '@/components/_Privately/User/UserCenter/UserCenterHeader'
import PublicSlot from "@/components/_Public/PublicSlot";
import {PublicHeader, Footer, Login} from "@/components/common/index.js"
import {mapState} from "vuex";
import Cookie from "js-cookie";
import {getDropDownByAll} from "@/api/home";
// import VipUpgrade from "@/components/home/VipUpgrade"


export default {
  name: "LayoutUserCenter",
  components: {
    UserCenter,
    PublicSlot,
    UserCenterHeader,
    Footer,
    Login,
    PublicHeader,
    // VipUpgrade
  },
  computed: {
    ...mapState('user',
      [
        'isLogin'
      ]
    ),
    ...mapState('company', ['companyRecordState']),
  },
  watch:{
    /* 登录更新详情收藏 */
    isLogin(n, o) {
      if (n === false) {
        window.location.href = '/'
      }
    },
  },
  data(){
    return{
      searchKeyword: "",
      isShowSearch: false,
      params:{}
    }
  },
  mounted() {
    this.getDropDownByALL();
    this.getPlaceholderHeight();
  },
  methods:{
    getPlaceholderHeight(){
      setTimeout(()=>{
        const element = this.$refs.headerWrap;
        const headerHeight = element.offsetHeight;
        /* 读取本地存储的头部高度，一致则跳过，否则覆盖 */
        const localHeaderHeight = Cookie.get("headerHeight");

        console.log({
          headerHeight,
          localHeaderHeight
        })
        this.$store.commit('_Public/Header/setHeaderHeight',headerHeight)
        if (headerHeight != localHeaderHeight){
          Cookie.set("headerHeight",headerHeight)
          this.placeholderHeight = headerHeight;
          this.$store.commit('_Public/Header/setHeaderHeight',headerHeight)

        }
      },300)
    },
    getDropDownByALL(){
      getDropDownByAll().then(res =>{
        const { status , data } = res.data;
        if(status == 1){
          this.$store.commit('_Public/Header/setDropDownDataByAll',data)
        }
      })

    },
    toMaterialList(path) {
      if (this.$route.path.indexOf('/list') > -1) {
        this.materialList = [];
        this.getMaterialList(this.params)
      } else {

      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "components/common/header/common/header";

.userCenter{
  padding-bottom: 50px;
}
.header-nav-wrap {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 998;
}


#HomeHeaderFixed {
  top: 0 !important;
}

</style>
