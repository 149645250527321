export const THREE_D = {

  MATERIAL_NUM:'50,000+',
  LIST: [
    {
      id: 1,
      iconType: 'scene',
      title: '3D场景',
      classifyList: []
    },
    {
      id: 2,
      iconType: 'poster',
      title: '3D海报',
      classifyList: []
    },
    {
      id: 3,
      iconType: 'business',
      title: '3D电商',
      classifyList: []
    },
    {
      id: 3,
      iconType: 'person',
      title: '3D人物',
      classifyList: []
    },
    {
      id: 3,
      iconType: 'ele',
      title: '3D元素',
      classifyList: []
    },
  ],

  USE:{
    CLASSIFY_LIST: [
      {
        id: 0,
        label: '电商促销',
        pics: [
          '//static.588ku.com/chaotu/3d/use/use-case%401-1.png',
          '//static.588ku.com/chaotu/3d/use/use-case%401-2.png'
        ]
      },
      {
        id: 1,
        label: '餐饮美食',
        pics: [
          '//static.588ku.com/chaotu/3d/use/use-case%402-1.png',
          '//static.588ku.com/chaotu/3d/use/use-case%402-2.png'
        ]
      },
      {
        id: 2,
        label: '教育培训',
        pics: [
          '//static.588ku.com/chaotu/3d/use/use-case%403-1.png',
          '//static.588ku.com/chaotu/3d/use/use-case%403-2.png'
        ]
      },
      {
        id: 3,
        label: '医疗美容',
        pics: [
          '//static.588ku.com/chaotu/3d/use/use-case%404-1.png',
          '//static.588ku.com/chaotu/3d/use/use-case%404-2.png'
        ]
      },
      {
        id: 4,
        label: '企业商务',
        pics: [
          '//static.588ku.com/chaotu/3d/use/use-case%405-1.png',
          '//static.588ku.com/chaotu/3d/use/use-case%405-2.png'
        ]
      },
      {
        id: 5,
        label: '金融理财',
        pics: [
          '//static.588ku.com/chaotu/3d/use/use-case%406-1.png',
          '//static.588ku.com/chaotu/3d/use/use-case%406-2.png'
        ]
      },
    ],
  },
  MESSAGE:{
    /* 留言 */
    LIST: [
      '偶然发现潮国创意网站，走在设计前沿啦，风格创意，内容质量高，已经成为每日必看啦。',
      '潮国创意，有不少优秀的3D设计素材，都比较高端精品，很适合电商促销使用，找3D背景再也不用愁。',
      '我们部门的设计师们已经达成了共识，做设计就去潮国创意拿图，价格便宜，一个团队多个子账号拿图也方便。',
      '超多优质图片包，内容合集，可以一键打包下载，超级方便，做企业VI延展真的很省心了。',
      '自从发现了潮国创意，我现在每天上班必浏览打卡，潮国创意的3D设计帮我搞定了超多刁钻的客户，而且每天都能给我新的设计灵感，每天都有新惊喜！',
      '作为运营要策划各种大小活动，潮国创意还有个宝藏功能：营销日历，通过营销日历不仅不会错过节日，还能快速找到优质内容，赞赞赞！',
      '潮国创意真的是设计网站的标杆，请按这个质量卷起来！3D的内容会备注好渲染器，贴图文件打包，真心很懂设计师的需求。我服务过的甲方都嘎嘎夸！价格低质量高，让每个设计师都能把时间花在创意上。',
      '潮国创意真心推荐，不像别的网站花里胡哨的广告一大堆，这里只有最优质的内容，最体贴的服务！',
      '在潮国创意能找到很多优质高端的3D海报素材，他家专做这块，高端精品素材，有正版授权能商用，所以靠谱。',
      '素材网站就选潮国创意，3D海报做得超级符合我心意，3D场景，元素，促销类的非常好用，甲方看了都一稿过！公司同事都爱用，而且还是正版的！',
      '异军突起的新网站，内容是近期发现最新潮的，作为设计师最需要当下最流行的内容，每天逛一逛潮国创意能有很多新灵感。',
      '3D海报设计素材比较高端的当属潮国创意，我喜欢在潮国创意找素材，品质高，价格也低，办个会员实在。',
      '第一眼看到潮国就毫不犹豫地收藏了，素材一直在更新，有很多高质量的3D场景，希望素材一直保持现在这样高水准呀~~',
      '真的很良心，下载的文件里贴图、材质都打包了，还有使用渲染器的说明，对设计师很友好！',
      '说真的价格真的很便宜，一开始还犹豫要不要充会员，充完会员只能说太香了！',
      '潮国创意是真的有设计审美，又懂设计师的素材网站！任何人没有见过潮国创意3D类目的我都会伤心的，好吗！都给我冲！！！！他们的3D人物IP，和3D场景真的一眼就撞在我的心巴上！',
      '发现潮国的3D设计远超同类网站，不仅内容质量高，适配性也很强。电商，海报，banner都可以使用。这个质量以前只能高价请设计师，现在充值会员可以海量下载，真心点赞！',
      '企业使用版权是最重要的，潮国创意企业授权范围广、保障完善，价格还低，已经推荐公司购买啦。',
      '设计专业学生一枚，这里每天都有上新新素材，而且都是我喜欢的，很喜欢下载这里的3D场景图用来学习，和潮国一起进步！',
      '画质很高清、设计细节很完善，内容赏心悦目，没事就会逛一逛，强推！'
    ]
  }
}
