/* store方法 */

import {checkUserPayFace} from "@/api/user";
import {getCookie} from "@/utils/auth";
import {setUserBindIphoneModalStatus} from "@/components/_Privately/User/UserBindIphoneModal/module";

const moduleFlag = true;
const methods = 'setUserCertificationModalStatus';
const methods_setUserCertificationStatus = 'methods_setUserCertificationStatus';
const storePath = '_Privately/User/';

export const UserCertificationModalState = {
  userCertificationModalStatus: false,
  userCertificationStatus: false,  // 用户实名状态
}

export const UserCertificationModalMutations = {
  [methods](state, payload) {
    moduleFlag && (state.userCertificationModalStatus = payload)
  },

  [methods_setUserCertificationStatus](state, payload) {
    moduleFlag && (state.userCertificationStatus = payload)
  },
}

/* 封装调用方法 */

/* 控制实名弹框 */
export function setCertificationModalStatus(store, payload) {
  moduleFlag && store.commit(storePath + methods, payload);
}

/* 设置实名认证状态，用户监听页面刷新页面，显示支付二维码 */
export function setCertificationStatus(store, payload) {
  moduleFlag && store.commit(storePath + methods_setUserCertificationStatus, payload);
}


/* 自定义方法 */

/* 检查用户实名 */
export function checkUserCertification() {
  return new Promise((resolve, reject) => {
    checkUserPayFace().then(res => {
      let {status, data, message} = res.data;
      console.log({status, data, message});
      if (status === 200) {
        let {auth = 0, times = 0} = data;
        if (auth == 0 && times >= 2) {
          codeCheckUserCertification();
        }
        /*const phone = getCookie('phone');
        let phoneFlag = phone === '' || phone === 0;
        const {$store} = $nuxt;
        setUserBindIphoneModalStatus($store, false);
        setCertificationModalStatus($store, false);
        // auth = 0 , times = 3, phoneFlag = false
        if (auth == 0 && times >= 2) {
          if (phoneFlag) {
            setUserBindIphoneModalStatus($store, true);
          } else {
            setCertificationModalStatus($store, true);
          }
        }*/

      }
      resolve(res)

    }).catch(error => {
      reject(error);

    })
  })
}

/* 封装方法1 获取用户是否实名结果 */
export async function getUserCertificationResult() {
  let res = await checkUserCertification();
  const {status, data, message} = res.data;
  let flag = true
  if (status == 200) {
    let {auth = 0, times = 0} = data;
    // auth = 0 , times = 3
    if (auth == 0 && times >= 2) {
      flag = false
    }
  }
  return flag
}

/* 弹出手机或实名 */
export function codeCheckUserCertification() {
  const phone = getCookie('phone');
  console.log(phone)
  let phoneFlag = phone == '' || phone == 0;
  const {$store} = $nuxt;
  setUserBindIphoneModalStatus($store, false);
  setCertificationModalStatus($store, false);

  if (phoneFlag) {
    setUserBindIphoneModalStatus($store, true);
  } else {
    setCertificationModalStatus($store, true);
  }
}

