<template>
  <div class="CompanyCertificateDialog" v-if="showMainInReviewModal">

    <CgModalMiddle
      @close="close"
    >
      <div
        slot="modal_content"
        class="modal-content __company-modal _user-select"
      >
        <i class="iconfont2 icon-qy-profile-line"
        ></i>
        <div class="msg">您当前企业主体正在认证审核中，审核通过后即可下载授权书哦~</div>
        <div class="btn-group">
            <div class="btn-item btn-confirm"
                 @click="close"
            >知道了</div>
        </div>
      </div>
    </CgModalMiddle>

  </div>
</template>

<script>
import {fnRouter} from "@/fn";
import { PAGE_PATH } from '@/constant/PAGE_PATH'
import {mapState} from "vuex";

export default {
  name: "CompanyCertificateDialog",
  computed:{
    ...mapState('_Privately/Company',['showMainInReviewModal']),
  },
  data(){
    return{
      isShow: false,
      PAGE_PATH
    }
  },
  props:{
    type: String | Number,
    default:  3
  },
  methods:{
    navigator(){
      fnRouter.navigationTo(PAGE_PATH.COMPANY.HOME)
    },
    close(){
      this.$store.commit('_Privately/Company/setShowMainInReviewModal',false);
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-content{
  width: 440px;
  height: 276px;
  background: #FFFFFF;
  border-radius: 14px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .icon-qy-profile-line{
    font-size: 40px;
    color: $color-primary;
    margin: 40px 0 14px;

  }
  .msg{
    font-size: 20px;
    font-weight: bold;
    color: #424866;
    line-height: 26px;
    margin: 0 40px 28px;
    text-align: center;
  }

  .btn-group{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 68px;
    box-sizing: border-box;

    .btn-item{
      width: 140px;
      height: 44px;
      background: #E0E3E9;
      border-radius: 25px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .btn-cancel{
      color: #596088;
    }
    .btn-confirm{
      color: #FFFFFF;
      background: #6969FF;
    }
  }
}
</style>
