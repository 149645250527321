<template>
  <div class="PicList">
    <template
      v-for="(vo,idx) in picList">
      <template v-if="vo.type == 1 || !vo.type">

        <PicItem
          :key="vo.id" :pic-item-info="vo"
          @downloadByFree="downloadByFree"
          @downloadByBusiness="downloadByBusiness"
          @navigatorToPay="navigatorToPay"
          @showPicPackageBuyModal="showPicPackageBuyModal"
          @downloadPicPackage="downloadPicPackage"
          v-bind="$attrs"
          v-on="$listeners"
          :idx="idx"
          :isNewVip="isNewVip"
        />


      </template>


      <PicItemRecommend v-if="vo.type == 2" :key="vo.id" :pic-item-info="vo"
                        v-bind="$attrs"
                        v-on="$listeners"/>
      <PicItemSpecial v-if="vo.type == 3" :key="vo.id" :pic-item-info="vo" :idx="idx"
                      v-bind="$attrs"
                      v-on="$listeners"/>


      <!--      <PicItemTopic v-if="vo.type == 4" :key="vo.id" :pic-item-info="vo" :idx="idx"/>-->

    </template>
    <slot name="morePicItem"></slot>

    <no-ssr>

      <DownloadPic ref="DownloadPic"/>
      <!--  图片包支付弹框 -->
      <PicPackagePayModal ref="PicPackagePayModal"/>

      <CompanyCertificateDialog
        ref="CompanyCertificateDialog"
        :type="type"
      />


      <FavoritesGroup
        @submit="favoritesCreate"
      />

    </no-ssr>


  </div>
</template>

<script>
import PicItem from '@/components/pic/pic-item/pic-item'
import PicItemRecommend from '@/components/pic/pic-item/pic-item-recommend'
import PicItemSpecial from '@/components/pic/pic-item/pic-item-special'
import PicItemTopic from '@/components/pic/pic-item/pic-item-topic'
import PicItemAI from '@/components/pic/pic-item/pic-item-ai'
import DownloadPic from '@/components/download/download-pic/download-pic'
import PicPackagePayModal from '@/components/pic-package/pic-package-pay-modal'
import FavoritesGroup from '@/components/user-center/favorites/favorites-group'
import {fnPay, fnRouter, fnVip} from "@/fn";
import {getCookie} from "@/utils/auth";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {mapState} from "vuex";
import CompanyCertificateDialog from '@/components/company/common/certificate-dialog'
import fnFavorites from "@/fn/favorites/fnFavorites";
import * as CON_COMMON from "@/constant/_constant";
import * as fnCommon from "@/fn/_fn";
import {setPicAttr, setPicViewType} from "@/components/_Privately/Pic/Common/module";
import {BASE_IMG_URL, PAY_COMPANY} from "@/constant/_Public/Path";
import {COMMON} from "@/constant/COMMON";
import {BLANK_LIST} from "@/constant/_Privately/User";

export default {
  name: "PicList",
  components: {
    PicItem,
    PicItemRecommend,
    PicItemSpecial,
    PicItemAI,
    DownloadPic,
    PicPackagePayModal,
    CompanyCertificateDialog,
    PicItemTopic,
    FavoritesGroup
  },
  props: {
    // 图片列表
    picList: {
      type: Array | Object,
      default: () => [],
      required: true
    },
    /* 路由额外传参 */
    params: ''
  },
  computed: {
    ...mapState('user',
      [
        'companyInfoByPerson',
        'vipIsPerson',
        'vipIsPersonData',
        'vipIsBusiness',
        'vipIsBusinessData',
        'businessVipState',
        'personVipState',
        'companyVipState',
        'uniq_id',
        'isLogin',
        'isSwitchAccountType',
      ],
    ),
  },
  watch: {
    isSwitchAccountType: {
      handler(n, o) {
        console.log(n)
        if (n == true) {
          console.log('-- isSwitchAccountType 登录检查VIP --')
          setTimeout(() => {
            this.getIsNewVip();
          }, 500)
        }
      }
    },

    picList: {
      handler(n, o) {
        if (n && n.length) {
          if (fnVip.getIsBusinessVipByYear() || getCookie('companyVipState') == 1) {
            n.forEach(vo => vo.is_buy = true)
          }

          n.forEach(vo => {
            if (vo.type == 3 && !vo.isCollect) {
              vo.isCollect = false;
            }

            /* ai翻新设置背景图等 */
            if (vo.is_transform_ai == 1) {
              const {transform_type = 0, base_color = 0, show_type = 0} = vo.transform_show

              /* 为元素设置背景色等样式，元素不分横图和竖图，只区分背景色 */

              /* 非元素竖图宽度设置80% */
              if (transform_type != 1 && show_type == 2) {
                vo._imgWidth = '80%'
              } else {
                vo._imgWidth = '100%'
              }
            }

            vo = setPicViewType(vo)
            vo = setPicAttr(vo)


            /* 专题 */
            if(vo.type == 3){
              console.log(vo)
              // if (Array.isArray(vo.pictures) && vo.pictures.length){
              //   vo._proto_pic = BASE_IMG_URL + vo.pictures[0].proto_pic
              //
              // }
              if (Array.isArray(vo.pictures) && vo.pictures.length){
                vo._proto_pic = BASE_IMG_URL + vo.pictures[0].proto_pic;
                const _title = vo.title;
                vo = Object.assign(vo,vo.pictures[0])
                vo.title = _title;
                if (Array.isArray(vo.pictures) && vo.pictures.length > 0){
                  vo.pictures.forEach(item =>{
                    item = setPicViewType(item);
                    item = setPicAttr(item);
                  })
                }
              }
            }
          })
        }
      },
      immediate: true
    },

    companyVipState:{
      handler(n,o){
        if (n == 1){
          console.log('当前企业Vip');

         this.getIsNewVip();

        }
      },
      immediate:true
    },

    companyInfoByPerson:{
      handler(n,o){
        this.getIsNewVip();
      },
      immediate:true
    },

    isLogin:{
      handler(n,o){
        this.getIsNewVip();
      },
      immediate:true
    }

  },
  data() {
    return {
      type: -1,
      isNewVip: true
    }
  },
  methods: {

    clck() {
      fnFavorites.showCollectSuccessDialog(true)
    },

    getIsNewVip(){
      console.log('触发getIsNewVip')
      console.log({
        isLogin: this.isLogin,
        companyVipState: this.companyVipState
      })

      /* 未登录或者非企业vip跳过 */
      if (!this.isLogin){
        this.isNewVip = true;
        return false;
      }


      /* 判断当前身份是企业身份还是个人 */
      const companyInfoByPerson = this.companyInfoByPerson;
      if (companyInfoByPerson.switch == null || (companyInfoByPerson.switch && companyInfoByPerson.switch.type == COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.PERSON)){
        console.log('个人身份-----')

        /* 判断是否是Vip */
        if (this.personVipState == 1 || this.businessVipState == 1){
          this.isNewVip = false
          return false;
        }
      }

      if (companyInfoByPerson.switch && companyInfoByPerson.switch.type== COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.COMPANY){
        console.log('企业身份-----')
        if (this.companyVipState == 1){
          console.log(JSON.stringify(this.companyInfoByPerson))
          const obj = this.companyInfoByPerson
          const switchId = obj.switch?.id;
          const targetItem = obj.main_list.find(item => item.id == switchId);

          if (targetItem) {
            const lastPayTime = new Date(targetItem.last_pay_time);
            const july15th = new Date("2024-07-15");

            const result = lastPayTime > july15th;
            console.log({result}); // true 或 false
            this.isNewVip = result;
          } else {
            console.log("未找到对应的main_list项或switch ID不存在");
          }
        }
      }
    },

    downloadIntercept() {
      /* 检查是否是企业账号，是否认证 */

    },
    downloadByBusiness(picItemInfo, channel) {




      // if (
      //   this.companyInfoByPerson.is_company_member == 1 &&
      //   this.companyInfoByPerson.switch.type == 1
      // ) {
      //   if (this.companyInfoByPerson.company.status != 1) {
      //     console.log('当前企业身份，请先认证')
      //     this.type = 2
      //     this.$refs.CompanyCertificateDialog.isShow = true
      //     return false
      //   } else {
      //     if (this.companyInfoByPerson.switch.id == null || this.companyInfoByPerson.switch.id == 0) {
      //       this.type = 3
      //       console.log('请先认证主体')
      //       this.$refs.CompanyCertificateDialog.isShow = true
      //       return false
      //     }
      //   }
      // }


      /*  */
      this.$refs.DownloadPic.downloadPic(channel, picItemInfo.uniq_id, () => {
        fnPay.payTracking('充值:VIP立即下载:图片:click')
      }, this.params);
    },
    downloadByFree(picItemInfo, channel) {

      this.$refs.DownloadPic.downloadPic(channel, picItemInfo.uniq_id, () => {
        fnPay.payTracking('充值:非VIP免费下载按钮:图片:click')
      }, this.params);
    },
    navigatorToPay() {
      fnRouter.navigationTo(PAY_COMPANY);
      fnPay.payTracking('充值:图片包商用购买:图片:click')
    },
    showPicPackageBuyModal(picItemInfo) {
      // fnPay.payTracking('充值:图片包单包购买:图片:click')
      // this.$refs.PicPackagePayModal.showPayDialog(picItemInfo);

      let path = CON_COMMON.CON_BASIC.PAY_LEAFLET + `/${picItemInfo.uniq_id}.html`;
      fnCommon.fnPay.dataTrackByClickLeaflet()
      fnCommon.fnBasic.navigationTo(path)
    },
    downloadPicPackage(channel, uniq_id) {
      /*console.log({
        channel, uniq_id
      })*/

      this.$refs.PicPackagePayModal.download(channel, uniq_id)
    },

    /* 收藏夹创建 */
    favoritesCreate(favoriteName) {
      console.log(favoriteName)
      /* Todo 接入创建文件夹api */
      fnFavorites.showCreateDialog(false)

    },

  }
}
</script>

<style lang="scss" scoped>
.PicList {
  display: flex;
  flex-wrap: wrap;
}
</style>
