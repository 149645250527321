import Vue from 'vue'
import Vuex from 'vuex'

const cookieparser = process.server ? require('cookieparser') : undefined

Vue.use(Vuex)

const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

export default () => new Vuex.Store({
  modules,

  actions: {

    /* 获取签约状态 */
    // nuxtServerInit方法会在服务端渲染被执行
    // cookie数据格式：key=value;key=value字符串
    async nuxtServerInit ({ commit }, { app,req }) {

      /*  */
      // let res = await app.$api.getSignStatus();



      /*let user = null
      // 如果请求头中有cookie数据
      if (req.headers.cookie) {
        // cookieparser.parse：就是把cookie格式字符串转化为一个对象
        // {key:value,key:value}
        const parsed = cookieparser.parse(req.headers.cookie)

        console.log(parsed)
        try {
          // 将Cookie中user的值转为JavaScript对象
          user = JSON.parse(parsed.user)
        } catch (err) {
          // No valid cookie found
        }
      }*/
      // commit('setUser', user)

    }

  }
})

