<template>
  <div class="PicTagLegalCopy"
       @mouseover="picTagLegalMouseover({ picItemInfo,idx})"
       @mouseleave="picTagLegalMouseleave({ picItemInfo,idx})"
  >

    <!-- 图片包标签 -->
    <i v-if="picItemInfo.package == 1" class="icon_badge pic-package-legal-copy"
       :class="{ 'actPackage': picItemInfo.discount_act == 1 }"/>


    <!--  默认图片标签  -->
    <template v-else>
      <!-- 正版和hover商用授权标签 v-show="personVipState == 1 || businessVipState == 1 || companyVipState == 1 " -->
      <!-- layer 内容层次: 1免费区, 2普通区, 3商用区, 4企业区 -->
      <div>
        <template v-if="isNewVip">
          <div class="legal-copy free" v-if="picItemInfo.layer == 1">免费下载</div>
          <div class="legal-copy company" v-else>全站通VIP商用</div>
        </template>
        <template v-else>
          <div class="legal-copy free" v-if="picItemInfo.layer == 1">免费下载{{isNewVip}}</div>
          <div class="legal-copy person" v-if="picItemInfo.layer == 2">VIP免费</div>
          <div class="legal-copy person" v-if="picItemInfo.layer == 3">VIP免费</div>
          <div class="legal-copy company" v-if="picItemInfo.layer == 4">企业商用</div>
        </template>
      </div>
    </template>

    <template v-if="picItemInfo.package != 1">

      <template v-if="isNewVip">
        <a @click="tag('充值:素材左上角标:图片:click')" :href="PAY_PERSON" target="_blank"
           v-if="picItemInfo.layer == 1" class="_pointer legal-copy free legal-copy-active">
          所有用户可下载 <i class="iconfont icon-a-arrowright"/>
        </a>
        <a @click="tag('充值:素材左上角标:图片:click')" :href="PAY_COMPANY" target="_blank"
           v-else class="_pointer legal-copy company legal-copy-active">
          全站通VIP可下载 <i class="iconfont icon-a-arrowright"/>
        </a>
      </template>

      <template v-else>
        <a @click="tag('充值:素材左上角标:图片:click')" :href="PAY_PERSON" target="_blank"
           v-if="picItemInfo.layer == 1" class="_pointer legal-copy free legal-copy-active">
          所有用户可下载 <i class="iconfont icon-a-arrowright"/>
        </a>
        <a @click="tag('充值:素材左上角标:图片:click')" :href="PAY_PERSON" target="_blank"
           v-if="picItemInfo.layer == 2 || picItemInfo.layer == 3" class="_pointer legal-copy person legal-copy-active">
          成为VIP可下载 <i class="iconfont icon-a-arrowright"/>
        </a>
        <a @click="tag('充值:素材左上角标:图片:click')" :href="PAY_COMPANY" target="_blank"
           v-if="picItemInfo.layer == 4" class="_pointer legal-copy company legal-copy-active">
          仅企业VIP可下载 <i class="iconfont icon-a-arrowright"/>
        </a>
      </template>

    </template>


  </div>
</template>

<script>
import {
  MEMBER_LICENSE_VIP
} from "@/constant/PATH";
import {mapState} from "vuex";
import {PAGE_PATH} from '@/constant/PAGE_PATH'
import {tag} from "@/utils/statUtil";
import {
  PAY_DEFAULT, PAY_COMPANY, PAY_PERSON
} from "@/constant/_Public/Path";


export default {
  name: "PicTagLegalCopy",
  computed: {
    ...mapState('user',
      [
        'personVipState',
        'personVipInfo',
        'businessVipState',
        'businessVipInfo',
        'companyVipState',
        'companyVipInfo',
        'isLogin'
      ]
    )
  },

  watch:{

  },
  props: {
    // 单个图片信息
    picItemInfo: {
      type: Object,
      default: () => {
      }
    },
    idx: {
      type: String | Number,
      default: ''
    },
    isNewVip: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      MEMBER_LICENSE_VIP,
      PAGE_PATH,
      PAY_DEFAULT,
      PAY_COMPANY,
      PAY_PERSON
    }
  },
  methods: {
    tag(stat) {
      tag(stat)
    },
    picTagLegalMouseover(info) {
      this.$emit('picTagLegalMouseover', info)
    },
    picTagLegalMouseleave(info) {
      this.$emit('picTagLegalMouseleave', info)
    },
  }
}
</script>

<style lang="scss" scoped>
.PicTagLegalCopy {
  //@include Position(1,5,0,0);

  &:hover {

    a {
      display: block;
    }
  }
}

.icon_badge {
  @include BgImage(71px, 68px, 'pic-package/icon_badge.svg');
  @include Position(1, 5, 0, 0);
  z-index: 8 !important;

  &.actPackage {
    @include BgImage(71px, 68px, 'act/guoqing/icon_badge.png');
    top: -4px !important;
  }
}

.icon_PicTagLegalCopy {
  @include BgImage(116px, 28px, 'home/timeline-pic/icon_PicTagLegalCopy.svg');
  @include Position(1, 5, 16px, 16px);

}

.legal-copy {
  display: block;
  transition: all 0.5s ease-in-out;
  @include Position(1, 5, 16px, -50px);
  line-height: 28px;
  background: linear-gradient(213deg, #FFF1A4 0%, #FFC04D 100%);
  border-radius: 6px;
  font-size: 14px;
  color: #A45F00;
  padding: 0 8px;
  //width: 72px;
  height: 28px;
  line-height: 28px;


  &.legal-copy-active {
    display: none;
    padding-right: 4px;
  }

  &.free {
    background: linear-gradient(145deg, #45A0FF 0%, #00C6FF 100%);
    color: #FFFFFF;
  }

  &.person {
    background: linear-gradient(213deg, #FFA40C 0%, #FF3C22 100%);
    color: #FFFFFF;
  }

  &.business {
    background: linear-gradient(213deg, #FFF1A4 0%, #FFC04D 100%);
    color: rgba(179, 77, 41, 1);
  }

  &.company {
    background: linear-gradient(133deg, #40456B 0%, #292C42 100%);
    color: rgba(250, 228, 178, 1);
  }
}
</style>
