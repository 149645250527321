<template>
  <!-- 活动页配置返回首页按钮 -->
  <a class="ActiveBackHome"
     :href="path"
     target="_blank"
  >
    {{ btnTxt }}
  </a>
</template>

<script>

/* 引入公共方法和常量 */
import * as fnCommon from '@/fn/_fn';
import * as CON_COMMON from '@/constant/_constant';

/* 引入组件 */

/* 引入VUEX */
import {mapState} from "vuex";

/* 引入API */

export default {
  name: "ActiveBackHome"

  , components: {}

  , props: {
    path: {
      type: String,
      default: CON_COMMON.CON_BASIC.BASE_URL
    },
    btnTxt: {
      type: String,
      default: '返回潮国创意首页'
    }
  }

  , watch: {}

  , computed: {}

  , data() {
    return {}
  }

  , methods: {}

}
</script>

<style lang="scss" scoped>
.ActiveBackHome {
  width: 164px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  position: absolute;
  top: 76px;
  left: 56px;
  z-index: 88;
  cursor: pointer;
}
</style>
