<template>
  <div class="FavoritesCreate">
    <!--    <CgModalMiddle v-if="showCreateDialog"-->
    <CgModalMiddle v-if="showCreateDialog"
                   @close="close"
    >
      <div slot="modal_content"
           class="modal-content"
      >
        <div class="title">{{ title }}</div>
        <div class="form-box">
          <el-form class="form" ref="ruleForm"
                   :model="form"
                   :rules="rules"
                   @submit.native.prevent
          >
            <el-form-item prop="name">
              <el-input v-model="form.name" :placeholder="placeholder"
                        @keyup.enter.native="submit"

              ></el-input>
            </el-form-item>
          </el-form>
          <div v-if="isShowErrTxt" class="err-txt">收藏夹名称只能由中文、英文字母、小数点和下划线构成</div>

        </div>
        <div class="submit-btn" @click="submit">确认</div>
      </div>
    </CgModalMiddle>
  </div>
</template>

<script>
import {mapState} from "vuex";
import fnFavorites from "@/fn/favorites/fnFavorites";
import {favoriteCreate} from "@/api/user/favorites";

export default {
  name: "FavoritesCreate",
  props: {
    title: {
      type: String,
      default: '新建收藏夹'
    },
    placeholder: {
      type: String,
      default: '收藏夹名称'
    }
  },
  computed: {
    // ...mapState('favorites', ["showCreateDialog"])
  },
  watch: {
    showCreateDialog(n, o) {
      if (n) {
        this.form.name = "";
      }
    }
  },
  data() {
    return {
      form: {
        name: ''
      },
      rules: {
        name: [
          {required: true, message: this.placeholder, trigger: 'blur'},
        ],
      },
      showCreateDialog: false,
      isShowErrTxt: false,
    }
  },

  methods: {
    submit() {

      if (this.form.name == '') {
        this.$myNotify.error("请先输入收藏夹名称")
        return false
      }

      let rgx = /^[\u4E00-\u9FA5A-Za-z0-9_]+$/;
      if (rgx.test(this.form.name)) {
        this.isShowErrTxt = false;
        this.$emit('submit', this.form.name);

        /*favoriteCreate({
          name: this.folderName
        }).then(res => {
          let {status, message} = res.data;
          if (status == 200) {
            this.$store.dispatch('favorites/getFavoriteList')
            this.$myNotify.success("创建成功", () => {
              this.folderName = "";
              this.isShowFolderNameInput = false;
            })
          } else {
            this.$myNotify.error(message, () => {
              // this.folderName = "";
            })
          }
        })*/
      } else {
        console.log('失败')
        this.isShowErrTxt = true;

      }



      /*this.$refs['ruleForm'].validate((valid) => {
        if (valid) {

          let rgx = /^[\u4E00-\u9FA5A-Za-z0-9_]+$/;
          if (rgx.test(this.folderName)) {
            this.isShowErrTxt = false;
            this.$emit('submit', this.form.name);

            /!*favoriteCreate({
              name: this.folderName
            }).then(res => {
              let {status, message} = res.data;
              if (status == 200) {
                this.$store.dispatch('favorites/getFavoriteList')
                this.$myNotify.success("创建成功", () => {
                  this.folderName = "";
                  this.isShowFolderNameInput = false;
                })
              } else {
                this.$myNotify.error(message, () => {
                  // this.folderName = "";
                })
              }
            })*!/
          } else {
            console.log('失败')
            this.isShowErrTxt = true;

          }
        }
      });*/
    },
    close() {
      // fnFavorites.showCreateDialog(false)
      this.showCreateDialog = false;
    }
  }
}
</script>

<style lang="scss" scoped>

.FavoritesCreate {

  .modal-content {
    width: 440px;
    height: 240px;
    background: #FFFFFF;
    border-radius: 14px;
    padding: 40px 24px 28px;
    box-sizing: border-box;

    .title {
      height: 28px;
      font-size: 20px;
      color: #2D2B33;
      line-height: 28px;
      margin-bottom: 24px;
    }

    /deep/ .el-input {
      .el-input__inner {
        background-color: #F5F6FA !important;
        height: 52px;
        line-height: 52px;
        border-radius: 8px;
        border-color: transparent;
        padding: 0 16px !important;

        &::placeholder {
          color: rgba(45, 43, 51, 0.6);
        }

        &:focus {
          border-color: $color-primary;
          background-color: #FFFFFF !important;
        }
      }
    }

    /deep/ .el-form-item__error {
      padding-left: 16px;
    }

    .form-box{
      position: relative;

      .err-txt{
        @include Position(1,5,10px,60px);
        color: $color-fans;
        font-size: 12px;
      }
    }

    .form {
      margin-bottom: 26px;
    }

    .submit-btn {
      width: 140px;
      height: 44px;
      background: #6969FF;
      border-radius: 25px;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 44px;
      text-align: center;
      float: right;
      cursor: pointer;
    }
  }
}
</style>
