<template>
  <div
    :class="[ {'on': NAV.TOPIC.id == navIndex} ]"
    class="NavItemHot menu-item"
  >
    <a
      :href="threeDLink"
      target="_blank"
      @click="listenStatistics(NAV.TOPIC.id),updateNavId(NAV.TOPIC.id)"
    >
      精选专题
    </a>
  </div>
</template>

<script>
import {IMG_BASE_URL} from "@/constant/PATH";
import {topbarMenuList} from "@/components/common/header/module";
import {PAGE_PATH} from '@/constant/PAGE_PATH';
import {THREE_D} from "@/constant/3d/3D";
import {getDropdownByHot, getDropdownByThreeD} from "@/api/nav";
import {fnRouter, fnTopic} from "@/fn";
import headerNavMixin from "~/components/common/header/header-nav/common/header-nav-mixin";
import {TOPIC} from "@/constant/tupian/TOPIC";


export default {
  name: "NavItemTopic",
  mixins: [headerNavMixin],
  data() {
    return {
      IMG_BASE_URL,
      isShow: false,
      monthIdx: 1,    // 默认显示月份
      currentMonth: [],  // 当月
      headerBgColor: '', // 头部背景色
      topbarMenuList,
      PAGE_PATH,
      THREE_D,
      classifyList: [],
      picList: [],

    }
  },
  props:{
    threeDCategoryList: [],
    threeDRecommendList: [],
  },
  computed: {
    threeDLink() {
      return fnRouter.getRouterLink(PAGE_PATH.TOPIC.DEFAULT);
    },

  },
  /*async fetch() {
    const {data} = await getDropdownByThreeD();
    let {category, recommend} = data.data;
    this.categoryList = category;
    this.recommendList = recommend;
  },*/
  watch: {
    /*isShow:{
      handler(n,o){
        if (n == true){
          this.hotMouseOver();
        }
      },
      immediate: true
    },*/
  },

  methods: {

    init() {
      this.getData();
    },

    navigateToList() {
      let findItem = this.topbarMenuList.find(vo => vo.id === 4)
      this.$emit('navigateToList', findItem)
    },

    hotMouseOver() {
      /* 合并picList 和 热搜专题数组，过滤非专题项，并遍历通过link_url取出专题id */
      let tempTopicList = this.threeDRecommendList;
      let filterTopicList = tempTopicList.filter(item => {
        return item.link_url.indexOf('/collection/') > -1;
      })
      let ids = [];
      if (filterTopicList.length) {
        filterTopicList.forEach(vo => {
          let linkUrlArr = vo.link_url.split('/');
          let linkUrlArrLastEle = linkUrlArr.slice(-1);
          let topicId = parseInt(linkUrlArrLastEle)
          ids.push(topicId)
        })
      }
      this.topicPositionStat(ids);
    },
    /* 首页专题位置统计 */
    topicPositionStat(ids) {
      fnTopic.positionStat(
        ids.toString(),
        TOPIC.POSITION_STAT.EVENT.EXPOSE,
        TOPIC.POSITION_STAT.POSITION.THREE_D_DROP_RECOMMEND,
      )
    },

    picItemTopicClick(vo) {

      if (vo.link_url.indexOf('/collection/') > -1){
        let topicId = this.parseTopicLinkUrl(vo)

        fnTopic.positionStat(
          topicId,
          TOPIC.POSITION_STAT.EVENT.CLICK,
          TOPIC.POSITION_STAT.POSITION.THREE_D_DROP_RECOMMEND,
        )
      }
    },

    topicMouseover(vo){
      if (vo.link_url && vo.link_url.indexOf('/collection/') > -1){
        let topicId = this.parseTopicLinkUrl(vo)

        fnTopic.positionStatMouseOver(
          topicId, TOPIC.POSITION_STAT.POSITION.THREE_D_DROP_RECOMMEND,
        )
      }


    },
    topicMouseleave(){
      fnTopic.closePositionStatMouseLeave();
    },

    parseTopicLinkUrl(vo){
      let linkUrlArr = vo.link_url.split('/');
      let linkUrlArrLastEle = linkUrlArr.slice(-1);
      return parseInt(linkUrlArrLastEle)
    },


  },
}
</script>

<style lang="scss" scoped>
@import "../common/nav";
@import "../common/icon";


.NavItemHot {
  position: relative;
  z-index: 99;

  .hot-combobox {
    @include Position(1, 5, -35px, 40px);
    border-radius: 12px;
    box-sizing: border-box;
    animation: fadeinT 0.5s 1;

    width: 730px;
    max-height: 402px;
    background: #FFFFFF;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.06), 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
    overflow: hidden;


    .festival-header-bar {
      @include BgImage(730px, 56px, 'header/nav/festival-bg.png');
      position: absolute;
      left: 0;
      top: 0;
    }

    /* 图片集合 */
    .festival-month {
      width: 100%;
      border-radius: 12px 12px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 19px;
      padding-left: 4px;


      .festival-month-item {
        //margin-right: 28px;
        //margin: 0 14px;
        padding: 0 14px;
        font-size: 14px;
        color: #FFFFFF;
        cursor: pointer;
        position: relative;
        z-index: 6;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          transition: all 0.5s ease;

          .festival-month-item-bar {
            @include BgImage(84px, 37px, 'header/nav/icon_festival-bg.svg');
            position: absolute;
            z-index: -1;
            left: 50%;
            transform: translateX(-50%);
          }

          .festival-month-txt {
            position: relative;
            z-index: 6;
            font-size: 14px;
            font-weight: bold;
            color: #424866;
          }
        }

      }
    }

    .festival-pics {
      display: flex;
      padding: 32px 34px 0;
      flex-wrap: wrap;

      .festival-pics-item {
        width: 202px;
        height: 124px;
        position: relative;
        margin: 0 27px 32px 0;
        border-radius: 4px;

        &:nth-child(3n) {
          margin-right: 0;
        }

        .img-box {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          overflow: hidden;

          img {
            //width: 100%;
            height: 100%;
            vertical-align: middle;
            transition: all 0.5s ease;
          }
        }

        &:hover {
          img {
            transform: scale(1.1);
          }
        }

        .icon_date {
          display: inline-block;
          width: 58px;
          line-height: 19px;
          text-align: center;
          //border-radius: 4px 4px 4px 0px;
          font-size: 12px;
          color: #0A2540;
          @include Position(1, 5, -3px, -8px);

          @include BgImage(58px, 21px, 'header/nav/icon_date.svg');
        }
      }
    }


    .icon_triangle {
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: #FFFFFF;
      @include Position(1, 5, 56px, -4px);
      transform: rotate(135deg);
    }
  }

  .d-combobox {
    display: inline-block;
    width: 523px;
    //height: 421px;
    background: #FFFFFF;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.06), 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
    @include Position(1, 5, -38px, 40px);
    border-radius: 12px;
    //padding: 15px 32px 34px 34px;
    box-sizing: border-box;
    text-align: left;
    animation: fadeinT 0.5s 1;
    height: 358px !important;
    >a{
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: 15px 32px 34px 34px;
      box-sizing: border-box;
    }

    &:after {
      content: '';
      width: 10px;
      height: 10px;
      background-color: #FFFFFF;
      @include Position(1, 5, 56px, -4px);
      transform: rotate(135deg);
    }

    .head-box {

      font-weight: bold;
      color: #0A2540;
      line-height: 40px;
      height: 40px;
      letter-spacing: 1px;
      margin-bottom: 19px;
      font-size: 0;

      .color-green {
        color: #1FCBAD;
        font-size: 17px;
      }

      .title {
        font-size: 17px;
      }

    }

    .seek-design {
      @include BgImage(167px, 40px, 'header/nav/3d/design-bg.png');
      @include Position(1, 2, 34px, 15px);
      text-align: center;
      line-height: 40px;
      font-size: 13px;
      font-weight: bold;
      color: #244962;
      letter-spacing: 1px;
    }

    .classify-wrap {
      .classify-list {

        .classify-item {
          display: flex;
          margin-bottom: 12px;

          &:last-child {
            margin-bottom: 0;
          }

          &:first-child {
            .three-level {
              padding-top: 0;
            }
          }

          .second-level {
            font-size: 16px;
            font-weight: bold;
            color: #424866;
            line-height: 22px;
            letter-spacing: 1px;
            margin-right: 17px;
          }

          .three-level {
            width: 246px;
            display: inline-flex;
            flex-wrap: wrap;
            padding-top: 1px;
            text-align: center;

            a {
              display: inline-block;
              //width: 33.3%;
              //min-width: 56px;
              min-width: 72px;
              margin-right: 10px;
              margin-bottom: 8px;
              font-size: 14px;
              color: rgba(10, 37, 64, 0.8);
              line-height: 19px;
              @include ElementHover($color-primary, '');
              text-align: left;

              &:nth-child(3n) {
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    .pics {
      @include Position(1, 2, 34px, 74px);
      display: flex;
      flex-direction: column;

      .pic-item {
        display: inline-block;
        width: 91px;
        height: 118px;
        box-shadow: 0px 0px 4px 0px rgba(213, 221, 236, 0.4);
        border-radius: 8px;
        margin-bottom: 12px;
        overflow: hidden;
        position: relative;

        img {
          display: inline-block;
          width: 100%;
          height: 100%;
          vertical-align: middle;
          transition: all 0.5s ease;
        }

        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}

</style>
