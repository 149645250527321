<template>
  <div class="dialog-wrap">
    <div class="dialog-cover"/>
    <div class="dialog-content a-bouncein">
      <div class="dialog-close" v-show="showClose" @click="close">
        <i class="iconfont icon-cha"/>
      </div>
      <slot name="dialog_content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CgDialog",
  props: {
    showClose: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // visible: false
    }
  },
  methods: {
    close() {
      // this.visible = false
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-wrap {
  position: relative;

  .dialog-cover {
    @include Position(2, 1, 0, 0);
    background: rgba(0, 0, 0, 0.55);
    //backdrop-filter: blur(20px);
    z-index: 998;
  }

  .dialog-content {
    //padding: 20px;
    min-width: 300px;
    min-height: 200px;
    border-radius: 14px;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 999;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    background-color: #fff;
    //animation: window-open 0.3s 1;
    animation-duration: 0.5s;

    .dialog-close {
      font-size: 18px;
      width: 40px;
      height: 40px;
      background: #ECEFF6;
      color: #596080;
      border-radius: 0 14px 0 14px;
      @include Position(1, 2, 0, 0);
      @include LayoutFlex(1);
      @include ElementHover('', #E1E3E7);
      z-index: 999;

      .icon-cha {
        transition: transform 0.5s ease-out;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        display: inline-block;

        &:hover {
          transform: rotateZ(180deg);
        }
      }
    }
  }

}
</style>
