<template>
  <div class="FavoritesCreateErr">
    <ReminderModal
      v-if="showCreateErrDialog"
      titleTxt="温馨提示"
      content-txt="免费用户，最多可新建1个收藏夹，已达上限"
      btnTxt="点击升级会员"
      @close="close"
      @btnClick="btnClick"
    />
  </div>
</template>

<script>
import ReminderModal from "@/components/common/modal/reminder-modal"
import mixin from "@/components/user-center/favorites/mixin";
import {fnRouter, fnFavorites} from "@/fn";
import {PAGE_PATH} from "@/constant/PAGE_PATH"


/* 引入公共方法和常量 */
import * as fnCommon from '@/fn/_fn';
import * as CON_COMMON from '@/constant/_constant';

/* 引入组件 */

/* 引入VUEX */
import {mapState} from "vuex";

/* 引入API */



export default {
  name: "FavoritesCreateErr",
  mixins: [mixin],
  components: {
    ReminderModal
  },
  methods: {
    close() {
      fnFavorites.showCreateErrDialog(false);
    },
    btnClick() {
      fnCommon.fnPay.dataTrackByPay(CON_COMMON.CON_PAY.DATA_TRACK.FAVORITE_UPGRADE);
      this.close();
      fnRouter.navigationTo(PAGE_PATH.PAYMENT.BUSINESS, '_blank', true)
    },
  }
}
</script>

<style scoped>

</style>
