<template>
  <div class="NavItemHot menu-item"
       :class="[ {'on': NAV.HAI_BAO.id == navIndex} ]"
       @mouseover="toggleHeaderAllDropDown(1)"
       @mouseleave="toggleHeaderAllDropDown(false)"
  >
    <a
      :href="festivalLink"
      target="_blank"
      @click="updateNavId(NAV.HAI_BAO.id)"
      @mouseover="toggleHeaderAllDropDown(1,4)"
    >
      海报
      <i
        v-for="(vo,idx) in HEADER.NAV.FESTIVAL.icons"
        :key="idx"
        class="icon_NavItemHot"
        :class="vo.type"
        :style="{background:'url('+ PAGE_PATH.BASE_IMG_UPLOAD + vo.url+') no-repeat center/100% '}"
      />
    </a>

    <!--  下拉  -->
    <no-ssr>
      <div v-show="isShow"
           class="dropbox">
        <HeaderAllDropDown ref="HeaderAllDropDown"/>
      </div>
    </no-ssr>

  </div>
</template>

<script>
import {getDropdownByFestival, getDropdownByHot} from "@/api/nav";
import {IMG_BASE_URL} from "@/constant/PATH";
import ToolDate from "@/tools/ToolDate";
import {topbarMenuList} from "@/components/common/header/module";
import {TrackingNav} from "@/tracking";
import {fnRouter} from "@/fn";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import headerNavMixin from "@/components/common/header/header-nav/common/header-nav-mixin";
import {HEADER} from "@/constant/common/HEADER";
import HeaderAllDropDown from "@/components/_Public/Header/HeaderAllDropDown";

export default {
  name: "NavItemFestival",
  mixins: [headerNavMixin],
  components:{
    HeaderAllDropDown
  },
  data() {
    return {
      IMG_BASE_URL,
      HEADER,
      PAGE_PATH,
      isShow: false
    }
  },
  props: {
    navIndex: {
      type: String | Number,
      default: -1
    },
    festivalData: {}
  },
  computed: {
    festivalLink() {
      return fnRouter.getRouterLink("/tupian/0-1-0-0-5-0-0-default-1-0-0-0.html");
    },
  },
  watch: {},
  mounted() {

  },
  methods: {

    toggleHeaderAllDropDown(flag = true, val) {
      if (flag) {
        setTimeout(()=>{
          this.isShow = true;
          if (val >-1){
            this.$refs.HeaderAllDropDown.menuIndex = val
          }
        },0)
      } else {
        this.isShow = false;
      }
    },

    navigateToList() {
      return
      let findItem = this.topbarMenuList.find(vo => vo.id === 2)
      this.$emit('navigateToList', findItem)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../common/nav";
@import "../common/icon";


.NavItemHot {
  position: relative;
  z-index: 99;

  .dropbox {
    @include Position(1, 5, -38px, 47px);
  }

  > a {
    position: relative;

    .icon_NavItemHot {
      //@include Position(1, 2, -8px, -10px);
      @include Position(1, 2, -2px, -10px);

      &.icon_ysj {
        @include BgImage(50px, 16px, 'header/nav/solar-term/icon_ysj.gif?v=1');
      }
    }
  }

  .hot-combobox {
    @include Position(1, 5, -30px, 40px);
    border-radius: 12px;
    box-sizing: border-box;
    animation: fadeinT 0.5s 1;
    cursor: default;

    width: 730px;
    max-height: 402px;
    min-height: 240px;
    background: #FFFFFF;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.06), 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
    //overflow: hidden;


    .festival-header-bar {
      @include BgImage(730px, 56px, 'header/nav/festival-bg.png');
      position: absolute;
      left: 0;
      top: 0;
    }

    /* 图片集合 */
    .festival-month {
      width: 100%;
      border-radius: 12px 12px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 19px;
      padding-left: 4px;
      box-sizing: border-box;

      position: relative;

      .festival-month-item-bar {
        @include BgImage(84px, 37px, 'header/nav/icon_festival-bg.svg');
        position: absolute;
        left: 50%;
        transition: all 0.5s ease;
        //transform: translateX(-50%);
      }

      .festival-month-item {
        //margin-right: 28px;
        //margin: 0 14px;
        padding: 0 14px;
        font-size: 14px;
        color: #FFFFFF;
        cursor: pointer;
        position: relative;
        z-index: 6;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          transition: all 0.5s ease;

          .festival-month-item-bar {
            @include BgImage(84px, 37px, 'header/nav/icon_festival-bg.svg');
            position: absolute;
            z-index: -1;
            left: 50%;
            transform: translateX(-50%);
          }

          .festival-month-txt {
            position: relative;
            z-index: 6;
            font-size: 14px;
            font-weight: bold;
            color: #424866;
          }
        }


      }
    }

    .festival-pics {
      display: flex;
      padding: 32px 34px 0;
      flex-wrap: wrap;

      .festival-pics-item {
        width: 202px;
        height: 124px;
        position: relative;
        margin: 0 27px 32px 0;
        border-radius: 4px;

        &:nth-child(3n) {
          margin-right: 0;
        }

        .img-box {
          display: inline-block;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          overflow: hidden;

          img {
            //width: 100%;
            height: 100%;
            vertical-align: middle;
            transition: all 0.5s ease;
          }
        }

        &:hover {
          img {
            transform: scale(1.1);
          }
        }

        .icon_date {
          display: inline-block;
          width: 58px;
          line-height: 19px;
          text-align: center;
          //border-radius: 4px 4px 4px 0px;
          font-size: 12px;
          color: #0A2540;
          @include Position(1, 5, -3px, -8px);

          @include BgImage(58px, 21px, 'header/nav/icon_date.svg');
        }
      }
    }


    .icon_triangle {
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: #FFFFFF;
      @include Position(1, 5, 56px, -4px);
      transform: rotate(135deg);
    }
  }
}

</style>
