import {getCookie} from "@/utils/auth";
import {VIP_MANAGE} from "@/constant/VIP_MANAGE";

class FnVip {
  constructor() {
  }

  /* 根据个人信息查询 */
  getVipMaxGrade(grade) {
    switch (parseInt(grade)) {
      case 5:
        return '包年';
      case 4:
        return '包季';
      case 3:
        return '包月';
    }
  }

  /* 查询是否是商用年vip */
  getIsBusinessVipByYear(){
    let businessVip = getCookie('vipIsBusiness');
    if (businessVip == 1){
      let vip = JSON.parse(getCookie('vipIsBusinessData')).vip
      return vip == 5;
    }else {
      return false
    }
  }

  /* 后端vip id 查找前端对应的会员id */
  getVipIdByBackend(type,vipId){
    console.log(vipId)
    let { COMPANY } = VIP_MANAGE
    if (type == 3){
      if (vipId == 1){
        return COMPANY.ONLINE.ID
      }else if (vipId == 2){
        console.log('1--------------')
        // return COMPANY.All_PURPOSE_NEW.ID
        return COMPANY.All_PURPOSE.ID
      }else if (vipId == 3){
        return COMPANY.ONLINE_HALF.ID
        // return COMPANY.ONLINE.ID
      }else if (vipId == 4){
        return COMPANY.All_PURPOSE_HALF.ID
        // return COMPANY.All_PURPOSE.ID
      }else if (vipId == 5){
        return COMPANY.UPGRADE.ID
      }else if (vipId == 6){
        return COMPANY.SENIOR.ID
      }else if (vipId == 7){
        return COMPANY.RESALE.ID
      }else if (vipId == 8){
        return COMPANY.All_PURPOSE_NEW.ID
      }else if (vipId == 14){
        return COMPANY.BASIC.ID
      }else if (vipId == 15){
        return COMPANY.MAJOR.ID
      }else if (vipId == 16){
        return COMPANY.RELEASE.ID
      }
    }
  }
  getVipIdByFrontend(vipId){
    if (vipId == 9){
      return 1
    }else if (vipId == 10){
      return 2
    }else if (vipId == 33){
      return 3
    }else if (vipId == 34){
      return 4
    }
  }


}

export default new FnVip();
