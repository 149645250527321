import request from "@/utils/request";
import qs from "qs";

/**
 * 专题位置接口
 * @param data
 * @returns {AxiosPromise}
 */
export function topicPositionStat(data) {
  return request({
    url: '/?m=StatApi&a=topicStatInfo',
    method: 'post',
    data:qs.stringify(data)
  })
}
