/* tupian store */
export default {
  namespaced: true,

  state(){
    return {
      /* 图片包支付弹框 */
      tupianPackagePayDialog: {
        isShow: false,
        tupianItem: {}  // 购买当前图片包信息

      },
    }
  },
  mutations: {

    SET_TUPIAN_PACKAGE_PAY_DIALOG(state, payload) {
      state.tupianPackagePayDialog.isShow = payload.isShow;
      state.tupianPackagePayDialog.tupianItem = payload.tupianItem;
    }
  }
}
