<template>
  <div class="SidebarCode sidebar-item">
    <i class="iconfont icon-a-sidegroup" />

    <div class="explain-box">
      <div class="title">企业经理一键响应</div>
      <div class="code-list">
<!--        <div class="code-item">
          <i class="icon_code icon_code-qq"></i>
          <div class="desc">
            官方QQ群 <br>
          </div>
          <div class="desc desc2">
            {{ qq }}<i class="iconfont icon-copy text-copy" @click="copyUid" />
          </div>
        </div>-->

        <div class="code-item"
             v-for="vo in CON_COMMON.CON_CONFIG.SIDEBAR_WX_CODE"
             :key="vo.link"
        >
          <img class="icon_code icon_code-wx" :src="vo.link" alt="">
          <div class="desc">
            {{ vo.label }}
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {fnClipboard} from "@/fn";
import * as CON_COMMON from '@/constant/_constant';

export default {
  name: "SidebarCode",
  data(){
    return{
      qq: '643623211',
      CON_COMMON
    }
  },
  methods:{
    copyUid(){
      fnClipboard.copy(this.qq)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/components/common/sidebar/common/sidebar.scss";


.SidebarCode{
  .explain-box{
    cursor: default;
    padding: 20px 24px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title{
      font-size: 14px;
      color: #00001A;
      line-height: 18px;
      margin-bottom: 20px;
      text-align: center;
    }

    .code-list{
      display: flex;
      //align-items: center;
      justify-content: space-between;

      .code-item{
        margin-right: 28px;

        &:last-child{
          margin-right: 0;
        }
        .icon_code{
          margin-bottom: 8px;
        }
        .icon_code-qq{
          @include BgImage(100px,100px,'common/sidebar/icon_code-qq.jpg?v=5');
        }

        .desc{
          font-size: 12px;
          color: #424866;
          line-height: 16px;
          text-align: center;
          margin-bottom: 2px;
          display: flex;
          align-items: center;
          justify-content: center;

          .icon-copy{
            font-size: 10px;
            color: #424866;
            @include ElementHover($color-primary,'');
            margin-left: 4px;
          }

          &.desc2{
            margin-bottom: 0;
          }
        }

        .icon_code-wx{
          @include BgImage(100px,100px,'common/sidebar/icon_code-qq.png');

        }

        .icon_code-wx2{
          @include BgImage(100px,100px,'common/sidebar/icon_code-wx2.jpg');
        }
      }
    }
  }
}


i.icon_copy{
  width: 10px;
  height: 10px;
  display: inline-block;
  background: url("//static.588ku.com/chaotu/home/icon_copy.png") no-repeat center/100%;
  vertical-align: middle;
  margin-left: 4px;
  position: relative;
  cursor: pointer;
  //@include ElementHover($color-primary,'');
}


</style>

