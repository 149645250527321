<!--
  素材预览
-->
<template>
  <div
    ref="MaterialList"
    class="material-preview-section inner_center">
    <div
      class="material-list-box inner_center" style="padding-bottom: 0">
      <div class="headline-wrap">
        <div class="headline" style="margin-bottom: 0">
          <span class="headline-date">{{ tomorrow.date }}</span>
          <span class="headline-time">{{ tomorrow.week }}</span>
        </div>
      </div>

      <div class="stay-tuned-box">
        <i class="icon_stay-tuned"/>
        <span class="stay-tuned-text">明日更新，敬请期待</span>
      </div>
    </div>
    <div
      v-for="(val) in materialData"
      :key="val.id"
      class="material-list-box inner_center">
      <div class="headline-wrap">
        <div class="headline">
          <span class="headline-date">{{ val[0].week }}</span>
          <span class="headline-time">{{ val[0].date }}</span>
        </div>
        <div class="more" @click="navigatorTo(MATERIAL_LIST + '/0-0-0-0-0-0-default-1.html')">
          查看更多<i class="iconfont icon-a-arrowright"></i>
        </div>

      </div>
      <div class="material-content">
        <MaterialList
          :list="val"
          @showDetail="showDetail"
        />
      </div>
    </div>

  </div>
</template>

<script>
import {MaterialList} from "@/components/material/index.js"
import {toolDate} from "@/tools";
import {MATERIAL_LIST} from "@/constant/PATH";
import {fnBrowser} from "~/fn";

export default {
  name: "MaterialPreview",
  props: {
    materialData: {
      type: Object,
      default: () => {
      }
    }
  },
  components: {
    MaterialList
  },
  data() {
    return {
      tomorrow: {
        week: '',
        date: ''
      },
      MATERIAL_LIST
    }
  },
  mounted() {
    this.getTomorrowInfo();
  },
  methods: {
    showDetail(id) {
      let path = `/tupian/detail/${id}.html`;
      this.$router.push({
        path
      })
    },
    /* 获取明天日期 */
    getTomorrowInfo() {
      let tomorrowInfo = toolDate.getDate(toolDate.getNextDate(1))
      let {month, day, week_zn} = tomorrowInfo;
      this.tomorrow.week = `${month}月${day}日`;
      this.tomorrow.date = week_zn;
    },

    navigatorTo(path){
      fnBrowser.navTo(path)
    }
  }
}
</script>

<style lang="scss" scoped>

.material-preview-section {
  background-color: #fff;
  position: relative;
  //
  //border: 1px solid #000;
  //background-color: pink;
  //overflow-y: scroll;
  //height: 800px;

  .material-list-box {
    padding-bottom: 40px;
    position: relative;

    &:last-child {
      .axial-line {
        bottom: 96px;
      }
    }


    .headline-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .headline {
        margin-bottom: 24px;
        line-height: 42px;
        width: 100%;

        .headline-date {
          font-size: 32px;
          font-weight: bold;
          color: #2D2B33;
          letter-spacing: 1px;
        }

        .headline-time {
          font-size: 14px;
          color: rgba(0, 16, 19, 0.45);
          line-height: 20px;
          margin-left: 9px;

        }
      }

      .more {
        flex-shrink: 0;
        font-size: 14px;
        color: #424866;
        display: flex;
        align-items: center;

        .icon-a-arrowright{
          font-size: 20px;
          margin-left: 4px;
        }

        @include ElementHover($color-primary, '') {
          //text-decoration: underline;

        }
      }
    }


    .material-content {
      display: flex;
      flex-wrap: wrap;

      .material-item {
        width: 336px;
        height: 440px;
        //background: #CCCCCC;
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.06);
        border-radius: 14px;
        margin: 0 32px 40px 0;
        position: relative;
        //overflow: hidden;

        a {
          display: inline-block;
          width: 100%;
          height: 100%;

          .material-img {
            display: inline-block;
            width: 100%;
            height: 100%;
            box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.06);
          }
        }

        &:nth-child(4n) {
          margin-right: 0;
        }


      }

      @include Screen(1) {
        .material-item {
          width: 279px;
          height: 365px;
          margin: 0 28px 29px 0;
        }

      }

    }

    /* 明日更新，敬请期待 */
    .stay-tuned-box {
      @include LayoutFlex(1);

      .icon_stay-tuned {
        @include BgImage(150px, 150px, 'home/icon_stay-tuned.gif');
      }

      .stay-tuned-text {
        border-radius: 1px;
        margin-left: 32px;
        font-size: 22px;
        color: $color-primary;
        @supports (-webkit-background-clip: text) or (background-clip: text) {
          background: linear-gradient(224deg, #FF6E90 0%, #D093FF 42%, #6969FF 100%, #6969FF 100%);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
        }
      }
    }

    .show-more {
      width: 100%;
      height: 56px;
      background: #F5F6FA;
      border-radius: 8px;
      text-align: center;
      line-height: 56px;
      font-size: 14px;
      color: #596088;
      transition: all 0.5s ease-in-out;

      &:hover {
        background-color: $color-primary;
        color: #FFFFFF;
      }
    }


  }


  .axial-line {
    width: 4px;
    //height: 100%;
    box-shadow: inset -3px -3px 8px 0px #C8D0E7, inset 3px 3px 8px 0px rgba(255, 255, 255, 0.5);
    border-radius: 2px;
    @include Position(1, 5, -30px, 10px);
    bottom: -10px;

    .axial-line-on {
      @include Position(1, 5, 0, 0);
      height: 100px;
      width: 4px;
      background: linear-gradient(224deg, #D093FF 0%, #6969FF 100%, #6969FF 100%);
      border-radius: 2px;
    }

    &:after {
      content: "";
      display: inline-block;
      width: 24px;
      height: 24px;
      background: linear-gradient(224deg, #D093FF 0%, #6969FF 100%, #6969FF 100%);
      box-shadow: 0px 2px 4px 0px rgba(105, 105, 255, 0.6);
      position: absolute;
      @include Position(1, 5, -10px, 0);
      border-radius: 50%;
    }

    &:before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      background: linear-gradient(134deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
      box-shadow: 0px 2px 40px -1px rgba(0, 17, 144, 0.19);
      backdrop-filter: blur(10px);
      @include Position(1, 5, -3px, 7px);
      border-radius: 50%;
      z-index: 3;

    }

  }


}
</style>
