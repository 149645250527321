/* 头部导航 */
import {PAGE_PATH} from "@/constant/PAGE_PATH";

export let NAV = {
  HOT:{
    id: 0,
    label:'全部热点',
    link: PAGE_PATH.TUPIAN_LIST
  },
  THREE_D:{
    id: 4,
    label:'3D',
    link: PAGE_PATH.TUPIAN_LIST +'/0-4-0-0-0-0-0-default-1-0.html'
  },
  SOLAR_TERMS:{
    id: 2,
    label:'节日',
    link: PAGE_PATH.TUPIAN_LIST
  },
  FESTIVAL:{
    id: 2,
    label:'节日节气',
    link: PAGE_PATH.TUPIAN_LIST + '/0-2-0-0-0-0-0-default-1-0-0-0.html'
  },
  HAI_BAO:{
    id: 5,
    label:'海报',
    link: PAGE_PATH.TUPIAN_LIST + '/0-1-0-0-5-0-0-default-1-0-0-0.html'
  },
  PPT:{
    id: 137,
    label:'节日节气',
    link: PAGE_PATH.TUPIAN_LIST + '/0-137-0-0-0-0-0-new-1-0-0-0.html'
  },
  BUSINESS:{
    id: 43,
    label: "电商",
    link: PAGE_PATH.TUPIAN_LIST + '/0-43-0-0-0-0-0-default-1-0.html'
  },

  PIC_PACKAGE:{
    id: 98,
    label:'图片包',
    link: PAGE_PATH.PIC_PACKAGE
  },
  FREE:{
    id: 999,
    label:'免费专区',
    link: PAGE_PATH.NAV.FREE
  },
  STYLE:{
    id: 103,
    label:'设计风格',
    link: PAGE_PATH.NAV.STYLE
  },
  CALENDAR:{
    id: 100,
    label: "营销日历",
    link: PAGE_PATH.CALENDAR
  },
  TOPIC:{
    id: 101,
    label: "精选专题",
    link: PAGE_PATH.BASE_URL + PAGE_PATH.TOPIC.DEFAULT
  },
  TIMELINE:{
    id: 99,
    label: "时间轴更新",
    link: PAGE_PATH.TIMELINE
  },
  INVITE:{
    id: 95,
    label: "邀请有礼",
    link: PAGE_PATH.BASE_URL+PAGE_PATH.INVITE.DEFAULT
  },
  PART_TIME:{
    id: 998,
    label: "供稿",
    link: PAGE_PATH.BASE_URL+PAGE_PATH.PART_TIME
  }

}
