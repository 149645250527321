<template>
  <div class="NavItemHot menu-item"
       :class="[ {'on': NAV.FESTIVAL.id == navIndex} ]"
       @mouseover="isShow = true"
       @mouseleave="isShow = false"
  >
    <a
      :href="festivalLink "
      target="_blank"
      @click="updateNavId(NAV.FESTIVAL.id)"
    >
      节日节气
      <i
        v-for="(vo,idx) in HEADER.NAV.FESTIVAL.icons"
        :key="idx"
        class="icon_NavItemHot"
        :class="vo.type"
        :style="{background:'url('+ PAGE_PATH.BASE_IMG_UPLOAD + vo.url+') no-repeat center/100% '}"
      />
    </a>

    <!-- 下拉 -->
    <div
      v-show="isShow"
      class="hot-combobox">
      <i class="icon_triangle" :style="{ backgroundColor: headerBgColor} "></i>
      <div class="festival-header-bar"></div>

      <div class="festival-header">
        <div class="festival-month" :style="{backgroundColor: headerBgColor} ">
          <span class="festival-month-item-bar"
                :style="{ 'left': festivalLeft + 'px' }"
          ></span>
          <span
            :class="{ 'active' : monthIdx === vo.key }"
            class="festival-month-item"
            v-for="(vo,idx) in FESTIVAL_MONTH"
            :key="vo.label + 'festival' "
            @click="toggleFestivalMonth(vo);trackingNavDrop('festival',1,'tab')"
          >
            <span class="festival-month-txt"> {{ vo.label }}</span>
          </span>
        </div>
      </div>


      <div class="festival-pics">
<!--        {{ festivalData[monthIdx] }}-->
 <!--               v-for="vo in FESTIVAL_MONTH[monthIdx-1].pics"
   -->
        <div
          class="festival-pics-item"
          v-for="vo in festivalData[monthIdx]"
          :key="vo.pic_url + 'festival-pics'"
        >
          <a
            :href="vo.link_url"
            target="_blank"
            class="img-box">
            <img v-lazy="IMG_BASE_URL + '/' + vo.pic_url"
                 @click="trackingNavDrop('festival',1,'click')"
                 :alt="vo.title"
            />
          </a>
          <i class="icon_date">{{ vo.date | disposeDate }}</i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getDropdownByFestival, getDropdownByHot} from "@/api/nav";
import {IMG_BASE_URL} from "@/constant/PATH";
import ToolDate from "@/tools/ToolDate";
import {topbarMenuList} from "@/components/common/header/module";
import {TrackingNav} from "@/tracking";
import {fnRouter} from "@/fn";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import headerNavMixin from "@/components/common/header/header-nav/common/header-nav-mixin";
import {HEADER} from "@/constant/common/HEADER";

const FESTIVAL_MONTH = [
  {
    label: '一月',
    bgColor: "#FA6269",
    key: 1
  },
  {
    label: '二月',
    bgColor: "#FF6D9C",
    key: 2
  },
  {
    label: '三月',
    bgColor: "#9ACE73",
    key: 3
  },
  {
    label: '四月',
    bgColor: "#78BC8E",
    key: 4
  },
  {
    label: '五月',
    bgColor: "#FCA661",
    key: 5
  },
  {
    label: '六月',
    bgColor: "#F88562",
    key: 6
  },
  {
    label: '七月',
    bgColor: "#3EC7E7",
    key: 7
  },
  {
    label: '八月',
    bgColor: "#68CCBE",
    key: 8
  },
  {
    label: '九月',
    bgColor: "#FCA661",
    key: 9
  },
  {
    label: '十月',
    bgColor: "#F88562",
    key: 10
  },
  {
    label: '十一月',
    bgColor: "#FA6269",
    key: 11
  },
  {
    label: '十二月',
    bgColor: "#2EB39E",
    key: 12
  },
]

export default {
  name: "NavItemFestival",
  mixins: [headerNavMixin],
  data() {
    return {
      IMG_BASE_URL,
      isShow: false,
      FESTIVAL_MONTH,
      monthIdx: 1,    // 默认显示月份
      currentMonth: [],  // 当月
      headerBgColor: '', // 头部背景色
      topbarMenuList,
      festivalLeft: 18 - 14,
      HEADER,
      PAGE_PATH
    }
  },
  props: {
    navIndex: {
      type: String | Number,
      default: -1
    },
    festivalData:{}
  },
  computed: {
    festivalLink() {
      return fnRouter.getRouterLink(PAGE_PATH.NAV.FESTIVAL);
    },
  },
  watch: {
    monthIdx: {
      handler(n, o) {
        if (n) {
          /*  let findCurrent  = this.FESTIVAL_MONTH.find(vo => vo.key == n)
            this.currentMonth = findCurrent.pics;*/
          this.findCurrentMonthPic();
          this.headerBgColor = this.FESTIVAL_MONTH[n - 1].bgColor
          let menuItemWidth = 56
          let menuItemLongWidth = 66
          if (n > 10) {
            this.festivalLeft = 18 - 14 + menuItemWidth * (10 - 1) + (n - 10) * menuItemLongWidth
            if (n == 12) {
              this.festivalLeft = 18 - 14 + menuItemWidth * (10 - 1) + (n - 10) * menuItemLongWidth + 4
            }
          } else {
            this.festivalLeft = 18 - 14 + menuItemWidth * (n - 1)
          }
        }
      },
      immediate: true
    }
  },
  mounted() {

  },
  async fetch() {
    this.getCurrentMonth();

    /*this.getCurrentMonth();

    const {data} = await getDropdownByFestival();
    console.log(  '--节日data')
    console.log(data)
    let result = data.data

    if (Object.prototype.toString.call(result) === '[object Object]' && Object.keys(result).length > 0) {
      for (const key in result) {
        this.FESTIVAL_MONTH.forEach(vo => {
          if (vo.key == key) {
            vo.pics = result[key]
          }
        })
      }
      /!*
            let findCurrent  = this.FESTIVAL_MONTH.find(vo => vo.key == this.monthIdx)
            this.currentMonth = []
            setTimeout(()=>{
              this.currentMonth = findCurrent.pics;
            },0)*!/
    }*/

  },
  methods: {

    init() {
      this.getCurrentMonth();
      this.getData();
    },

    navigateToList() {
      return
      let findItem = this.topbarMenuList.find(vo => vo.id === 2)
      this.$emit('navigateToList', findItem)
    },

    /* 获取当前月份 */
    getCurrentMonth() {
      let currentMonth = ToolDate.getDate().month;
      this.monthIdx = currentMonth
      return currentMonth;
    },

    /* 根据条件筛选对应的月份图片 */
    findCurrentMonthPic() {
      // let currentMonth  = ToolDate.getDate().month;
      let findCurrent = this.FESTIVAL_MONTH.find(vo => vo.key == this.monthIdx)
      this.currentMonth = []
      setTimeout(() => {
        this.currentMonth = findCurrent.pics;
      }, 0)
    },

    /* 获取下拉所有图片数据 */
    getData() {
      getDropdownByFestival().then(res => {
        if (res.data.status == 1) {
          let result = res.data.data
          if (Object.prototype.toString.call(result) === '[object Object]' && Object.keys(result).length > 0) {
            for (const key in result) {
              this.FESTIVAL_MONTH.forEach(vo => {
                if (vo.key == key) {
                  vo.pics = result[key]
                }
              })
            }
            /* 显示当月 */

            this.findCurrentMonthPic();
          }
        }
      })
    },

    toggleFestivalMonth(vo) {
      this.monthIdx = vo.key;
    },

    sliceList(list) {
      return list.length > 5 ? list.slice(0, 5) : list;
    },
    averageRecommendList(data) {
      let arr = [];
      for (let i = 0; i < data.length; i += 5) {
        arr.push(data.slice(i, i + 5));
      }
      return arr;
    },

    trackingNavDrop(act, pos, e) {
      TrackingNav.navDrop(act, pos, e)
    }
  },

  filters: {
    disposeDate(data) {
      let dateArr = data.split('-');
      return dateArr[1] + '月' + dateArr[2] + '日'
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../common/nav";
@import "../common/icon";


.NavItemHot {
  position: relative;
  z-index: 99;

  > a {
    position: relative;

    .icon_NavItemHot {
      //@include Position(1, 2, -8px, -10px);
      @include Position(1, 2, -2px, -10px);

      &.icon_ysj {
        @include BgImage(50px, 16px, 'header/nav/solar-term/icon_ysj.gif?v=1');
      }
    }
  }

  .hot-combobox {
    @include Position(1, 5, -30px, 40px);
    border-radius: 12px;
    box-sizing: border-box;
    animation: fadeinT 0.5s 1;
    cursor: default;

    width: 730px;
    max-height: 402px;
    min-height: 240px;
    background: #FFFFFF;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.06), 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
    //overflow: hidden;


    .festival-header-bar {
      @include BgImage(730px, 56px, 'header/nav/festival-bg.png');
      position: absolute;
      left: 0;
      top: 0;
    }

    /* 图片集合 */
    .festival-month {
      width: 100%;
      border-radius: 12px 12px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 19px;
      padding-left: 4px;
      box-sizing: border-box;

      position: relative;

      .festival-month-item-bar {
        @include BgImage(84px, 37px, 'header/nav/icon_festival-bg.svg');
        position: absolute;
        left: 50%;
        transition: all 0.5s ease;
        //transform: translateX(-50%);
      }

      .festival-month-item {
        //margin-right: 28px;
        //margin: 0 14px;
        padding: 0 14px;
        font-size: 14px;
        color: #FFFFFF;
        cursor: pointer;
        position: relative;
        z-index: 6;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          transition: all 0.5s ease;

          .festival-month-item-bar {
            @include BgImage(84px, 37px, 'header/nav/icon_festival-bg.svg');
            position: absolute;
            z-index: -1;
            left: 50%;
            transform: translateX(-50%);
          }

          .festival-month-txt {
            position: relative;
            z-index: 6;
            font-size: 14px;
            font-weight: bold;
            color: #424866;
          }
        }


      }
    }

    .festival-pics {
      display: flex;
      padding: 32px 34px 0;
      flex-wrap: wrap;

      .festival-pics-item {
        width: 202px;
        height: 124px;
        position: relative;
        margin: 0 27px 32px 0;
        border-radius: 4px;

        &:nth-child(3n) {
          margin-right: 0;
        }

        .img-box {
          display: inline-block;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          overflow: hidden;

          img {
            //width: 100%;
            height: 100%;
            vertical-align: middle;
            transition: all 0.5s ease;
          }
        }

        &:hover {
          img {
            transform: scale(1.1);
          }
        }

        .icon_date {
          display: inline-block;
          width: 58px;
          line-height: 19px;
          text-align: center;
          //border-radius: 4px 4px 4px 0px;
          font-size: 12px;
          color: #0A2540;
          @include Position(1, 5, -3px, -8px);

          @include BgImage(58px, 21px, 'header/nav/icon_date.svg');
        }
      }
    }


    .icon_triangle {
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: #FFFFFF;
      @include Position(1, 5, 56px, -4px);
      transform: rotate(135deg);
    }
  }
}

</style>
