<template>
  <div class="FavoritesCollectSuccess"
       :class="{ 'ON': showCollectSuccessDialog }">
    <div class="content">
      <div class="layout-left">
        <img v-if="currentTupianInfo && currentTupianInfo.proto_pic"
             :src=" PAGE_PATH.BASE_IMG_URL +  currentTupianInfo.proto_pic +  '!/fw/672/quality/52/unsharp/true/compress/true'"
             alt="">
      </div>
      <div class="layout-right">
        <div class="txt-collect-box">
          <span class="txt-collect">已收藏到</span>
          <span class="favorite-name overflow-one" v-if="collectSuccessInfo">{{ collectSuccessInfo.name }}</span>
        </div>
        <div class="to-collect"
             @click="toUserCenterCoupon"
        >查看收藏
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {fnFavorites, fnLogin, fnRouter} from "@/fn";
import {PAGE_PATH} from "@/constant/PAGE_PATH"
import {COMMON} from "@/constant/COMMON";

export default {
  name: "FavoritesCollectSuccess",
  computed: {
    ...mapState('favorites', [
      "showCollectSuccessDialog",
      "showCollectSuccessDialogTimer",
      "collectSuccessInfo", "currentTupianInfo"]),
    ...mapState('user',
      [
        'personVipState',
        'personVipInfo',
        'businessVipState',
        'businessVipInfo',
        'companyVipState',
        'companyVipInfo',
        'userInfo',
        'companyInfoByPerson',
      ],
    ),
  },

  watch: {

    showCollectSuccessDialogTimer: {
      handler(n, o) {
        if (n == true) {
          window.clearInterval(this.timer);
          this.times = 5000;
          this.$store.commit('favorites/SET_SHOW_COLLECT_SUCCESS_DIALOG_TIMER', false);
          this.timer = window.setInterval(() => {
            this.times -= 1000;
            if (this.times <= 0) {
              window.clearInterval(this.timer);
              this.$store.commit('favorites/SET_SHOW_COLLECT_SUCCESS_DIALOG', false)
            }
          }, 1000)
        }
      }
    }
  },
  destroyed() {
    window.clearInterval(this.timer);
  },
  data() {
    return {
      PAGE_PATH,
      timer: null,
      times: 3000,
      COMMON,
    }
  },
  methods: {
    toUserCenterCoupon() {
      if (fnLogin.checkLogin()) {


        if ((this.companyInfoByPerson.switch && this.companyInfoByPerson.switch.type== COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.COMPANY)){
          fnRouter.navigationTo('/company/collect-record')
        }else{
          fnRouter.navigationTo(PAGE_PATH.USER_CENTER.COLLECT)

        }
      } else {
        fnLogin.loginIntercept(false);
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.FavoritesCollectSuccess {

  position: fixed;
  top: -990px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  transition: all 1s ease;

  &.ON {
    top: 90px;
  }

  .content {
    width: 260px;
    height: 72px;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px 0px rgba(10, 37, 64, 0.1);
    border-radius: 14px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .layout-left {
      img {
        width: 52px;
        height: 52px;
        border-radius: 8px;
        overflow: hidden;
        margin-right: 16px;
      }
    }

    .layout-right {
      .txt-collect-box {
        margin-bottom: 14px;
        display: flex;
        align-items: center;

        .txt-collect {
          height: 14px;
          font-size: 14px;
          color: #8F93AF;
          line-height: 14px;
          margin-right: 16px;
        }

        .favorite-name {
          display: inline-block;
          max-width: 95px;
          height: 14px;
          font-size: 14px;
          color: #2D2B33;
          line-height: 14px;
        }
      }

      .to-collect {
        height: 14px;
        font-size: 14px;
        font-weight: bold;
        color: #6969FF;
        line-height: 14px;
        cursor: pointer;
      }
    }
  }

}
</style>
