<template>
  <div class="DesignerHeaderBar">
    <div class="inner_center">
      <div class="left">
        <a
          href="/"
          target="_blank">
          <i class="iconfont icon-logo"/>
        </a>
        <i class="dot"/>
        <span> 设计师创作者平台</span>
      </div>
      <div class="right">
        <no-ssr>
          <AvatarCombobox
            :intercept-mouse="interceptMouse"
            class="AvatarCombobox"
          />
        </no-ssr>
      </div>
    </div>
    <Login/>
  </div>
</template>

<script>
import AvatarCombobox from '@/components/common/user/avatar-combobox.vue'
import {Login} from "@/components/common/index.js"

export default {
  name: "DesignerHeaderBar",
  components: {
    AvatarCombobox,
    Login
  },
  data() {
    return {
      interceptMouse: true
    }
  },
}
</script>

<style lang="scss" scoped>
.DesignerHeaderBar {
  .inner_center {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;

    .left {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .icon-logo {
      font-size: 24px;
      margin-right: 10px;
      @include ElementHover('', '');
    }

    .dot {
      display: inline-block;
      width: 4px;
      height: 4px;
      color: #001013;
      border-radius: 50%;
      margin: 0 10px;
      background-color: #001013;
    }

    span {
      font-size: 16px;
      color: #001013;
      line-height: 22px;
      letter-spacing: 1px;
    }

  }
}

/* 头像下拉样式重写 */
.AvatarCombobox {
  /deep/ > .avatar-box {
    width: 36px !important;
    height: 36px !important;
  }

  /deep/ .text-login {
    color: #001013 !important;
  }
}
</style>
