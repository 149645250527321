<template>
  <div
    class="user-combobox _user-select">
    <div class="user-panel">
      <div class="avatar-box">
        <div class="avatar-wrap avatar-wrap-company">
          <NavigationTo type="block" :path="PAGE_PATH.COMPANY.HOME">
            <div class="icon_company-wrap" slot="content">
              <i class="icon_company"/>
            </div>
          </NavigationTo>
          <div class="company-role">
            {{ companyInfoByPerson.is_admin == 1 ? '管理员' : '成员' }}
          </div>
        </div>

        <div class="user-box">
          <div class="user-name-box">
            <div class="user-name" v-if="companyInfoByPerson.company">
              <span v-if="companyInfoByPerson.company.status == 1">{{ companyInfoByPerson.company.name }}</span>
              <span
                class="txt-auth _pointer" v-else
                @click="navigationTo(PAGE_PATH.COMPANY.HOME)"
              >去认证</span>
            </div>
            <!--            <VipLabel/>-->
          </div>
          <div class="uid">
            <span class="uid">潮国ID：<span class="clipboardBtn">{{ uniq_id }}</span></span>
            <i
              id="ClipboardBtn"
              class="icon_copy text-copy"
              @click="copyUid"/>
          </div>
          <div class="badge"/>
        </div>

        <div class="card-box card-box2">
          <div
            v-for="vo in cardList"
            :key="vo.label"
            class="card-item _pointer"
            @click="navigationTo(vo.path)"
          >
            <i class="iconfont2" :class="vo.iconClass"/>
            {{ vo.label }}
          </div>
        </div>


      </div>
    </div>


    <div class="account-info">
      <div class="account-title">
        当前素材下载的授权主体
      </div>

      <template v-if="companyInfoByPerson.main_list == null">
        <div class="account-name no-account">
          您当前是管理员，但无授权主体
        </div>
      </template>

      <template v-show="companyInfoByPerson.main_list && companyInfoByPerson.main_list.length">

        <div class="account-list-wrap" >
<!--          <HappyScroll hide-horizontal resize>-->
            <div class="account-list">
              <div
                class="account-item"
                v-for="vo in companyInfoByPerson.main_list"
                :key="vo.id"
                :class="[
              { 'ON' : vo.id == companyInfoByPerson.switch.id },
              { 'expire' : vo.valid_days == 0 },
            ]"
                @click="toggleCompanyMain(vo)"
              >
                <div class="account-name" v-if="vo.name !=null">
                  {{ vo.name }}
                </div>
                <div class="account-name" v-else>
                  未认证主体
                </div>

                <div class="account-handle-box">
                  <div class="account-status" v-if="vo.valid_days <= 7">
                    <span class="end-time-7" v-if="vo.valid_days == 7">{{ '7天后到期' }}</span>
                    <span class="end-time-0" v-if="vo.valid_days == 0">{{ '已过期' }}</span>
                  </div>
                  <div class="account-status" v-if="vo.name == null">
                    <span class="end-time-0 end-time-1"
                          @click.stop="navigator"
                    >去认证</span>
                  </div>
                  <div class="icon_select" v-if="vo.id == companyInfoByPerson.switch.id">
                    <span>当前</span>
                    <i class="iconfont2 icon-qy-success"/>
                  </div>
                </div>

              </div>
            </div>
<!--          </HappyScroll>-->
        </div>
      </template>

    </div>

  </div>

</template>

<script>
//页面引入swiper
import {mapGetters, mapState} from "vuex";
import {
  MEMBER_LICENSE_VIP,
  USER_CENTER,
  USER_CENTER_COLLECT,
  USER_CENTER_DOWNLOAD,
  USER_CENTER_USERINFO,
  INVITE_FRIENDS, PAY_BUSINESS, PAY_PERSON, PAY, COMPANY_INTRODUCE
} from "@/constant/PATH";
import {tag} from "@/utils/statUtil";
import {BUSINESS_VIP_INFO, PERSON_VIP_INFO} from "@/constant/VIP";
import {DEVICE} from "@/constant/SYSTEM";
import _ from 'lodash'
import Clipboard from "clipboard";
import VipLabel from '@/components/user/vip-label';
import {VIP_STATE} from "@/constant/VIP";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import VipNotification from '@/components/user/vip-notification/indexV2'
import {fnClipboard, fnInviteFriend, fnRouter} from "~/fn";

import HeaderLoginDevice from '@/components/common/header/header-login/header-login-device'
import {COMMON} from "@/constant/COMMON";
import {switchMainAccount} from "@/api/company/home";

import {HappyScroll} from 'vue-happy-scroll';
// 引入css，推荐将css放入main入口中引入一次即可。
import 'vue-happy-scroll/docs/happy-scroll.css'

export default {
  name: "HeaderLoginUserComboboxCompany",
  components: {
    VipLabel,
    VipNotification,
    HeaderLoginDevice,
    HappyScroll
  },
  data() {
    return {
      list: COMMON.HEADER.AVATAR_COMBOBOX.USER_CENTER_NAV_LIST,
      isShowCombobox: false,
      MEMBER_LICENSE_VIP,
      USER_CENTER,
      INVITE_FRIENDS,
      PERSON_VIP_INFO,
      BUSINESS_VIP_INFO,
      DEVICE,
      PAY_PERSON,
      PAY_BUSINESS,
      VIP_STATE,
      PAY,
      COMPANY_INTRODUCE,
      PAGE_PATH,
      accountType: COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.COMPANY,
      COMMON,
      cardList: [],
    }
  },
  computed: {
    ...mapGetters('user',
      ['name', 'uid', 'avatar', 'uniq_id', 'vipIsPerson', 'vipIsPersonData', 'vipIsBusiness', 'vipIsBusinessData', 'downloadCount']),
    ...mapState('system', ['device']),
    ...mapState('user',
      [
        'personVipState',
        'personVipInfo',
        'businessVipState',
        'businessVipInfo',
        'companyVipState',
        'companyVipInfo',
        'companyInfoByPerson',
      ]
    ),
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      setTimeout(() => {
        this.cardList = parseInt(this.companyInfoByPerson.is_admin) === 1 ? COMMON.HEADER.AVATAR_COMBOBOX.COMPANY_NAV.ADMIN : COMMON.HEADER.AVATAR_COMBOBOX.COMPANY_NAV.DEFAULT;
      }, 300)
    },
    navigator(){
      fnRouter.navigationTo(PAGE_PATH.COMPANY.HOME)
    },

    navigationTo(path) {
      if (path === PAGE_PATH.COMPANY.HOME) {
        fnRouter.navigationTo(path);
        return
      }

      if (this.companyInfoByPerson.company.status == 1) {
        if (this.companyInfoByPerson.cert_main_num != 0) {
          fnRouter.navigationTo(path)
        } else {
          fnRouter.navigationTo(PAGE_PATH.COMPANY.MAIN_CERTIFICATE)
        }
      } else {
        fnRouter.navigationTo(PAGE_PATH.COMPANY.HOME)
      }


    },
    loginOut() {
      this.$store.dispatch('user/loginOut');
      this.$emit('loginOut')
    },
    tag(stat) {
      tag(stat)
    },

    copyUid: _.throttle(function () {
      fnClipboard.copy(this.uniq_id);
    }, 2000),

    toggleCompanyMain(vo) {

      /* 同一主体跳过 */
      if (vo.id == this.companyInfoByPerson.switch.id) {
        return false;
      }


      switchMainAccount({
        type: 1,
        mainId: vo.id
      }).then(res => {
        console.log(res)
        if (res.data.status === 200) {
          this.$store.dispatch('user/getCompanyInfoByPerson')
          this.$store.dispatch('user/getUserInfo');
          this.$myNotify.success('切换主体成功', () => {
          }, 2000)

          this.$store.commit('user/SET_SWITCH_COMPANY_MAIN', true)
        }
      })
    }
  },
  filters: {
    disposeEndTime() {

    }
  },
}
</script>

<style lang="scss" scoped>

.VipNotification-wrap {
  margin: 0 28px;

  /deep/ .VipNotification {
    //margin: 0 28px;
    margin: 0 auto;
    //text-align: center;
    border-radius: 0px 0px 12px 12px !important;
    padding-left: 8px;
    box-sizing: border-box;

    .icon-cha {
      margin-left: 12px !important;
    }
  }
}

@keyframes fadeinT {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.user-combobox {
  width: 480px;
  //height: 333px;
  box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0 0 8px 0 rgba(0, 0, 26, 0.08);
  border-radius: 12px;
  z-index: 10;
  animation: fadeinT 0.5s 1;
  overflow: hidden;
  cursor: default;


  .user-panel {
    padding: 28px 0 0 28px;
    height: 182px;
    background: linear-gradient(134deg, #F7F8FB 0%, #ECEFF6 100%);
    border-radius: 12px;
    box-sizing: border-box;
    position: relative;
    @include BgImage(480px, 182px, 'user/user-combobox-bg.png?v=1');

    .avatar-box {
      display: flex;
      align-items: center;

      .avatar-wrap {
        width: 52px;
        height: 52px;
        position: relative;
        margin-right: 16px;

        .avatar {
          display: inline-block;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 1px solid rgba(255, 255, 255, 0.6);
          margin-right: 16px;
          overflow: hidden;
        }

        .company-role {
          height: 18px;
          background: #FFFFFF;
          border-radius: 9px;
          color: $color-primary;
          color: #4472FF;
          font-size: 12px;
          padding: 0 6px;
          @include Position(1, 4, 50%, -8px);
          transform: translateX(-50%);
          display: inline-block;
          white-space: nowrap;
        }


        .icon_company-wrap {
          display: inline-flex;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: #255BFF;
          align-items: center;
          justify-content: center;

          .icon_company {
            @include BgImage(24px, 24px, 'header/login/icon_company.png');
            margin-top: -6px;
          }
        }
      }

      .user-box {
        margin-top: 4px;

        .user-name-box {
          display: flex;

          .user-name {
            font-size: 16px;
            color: #2D2B33;
            margin-right: 8px;

            .txt-auth {
              font-size: 16px;
              color: #255BFF;
              line-height: 22px;
            }
          }

        }

        .uid {
          font-size: 12px;
          color: #596088;
          line-height: 16px;
          margin-top: 6px;

          .icon_copy {
            @include BgImage(12px, 12px, 'home/icon_copy.png');
            margin-left: 12px;
            position: relative;

            &:after {
              content: '';
              position: absolute;
              top: -10px;
              right: -10px;
              bottom: -10px;
              left: -10px;
            }
          }
        }
      }

      .text-login-out {
        @include Position(1, 2, 28px, 28px);
        font-size: 14px;
        color: #6969FF;
        line-height: 20px;
        text-decoration: underline;

      }

      .card-box {
        width: 424px;
        @include Position(1, 4, 28px, 0);
        @include LayoutFlex(3);

        .VipNotification {
          //@include Position(1, 4, 0, -26px);
        }

        @mixin CardItem(
          $bgImg,
          $titleColor:#FFFFFF,
          $btnTxtColor:transparent,
          $btnBgColor:rgba(255, 255, 255, 0.9),
          $btnHoverBgColor:rgba(255, 255, 255, 1),
        ) {
          @include BgImage(136px, 78px, 'home/#{$bgImg}.png?v=5');
          padding: 8px 0 0 8px;
          box-sizing: border-box;
          position: relative;
          transition: all 0.5s ease-in-out;

          @include ElementHover('', '') {
          }
          .title {
            font-size: 14px;
            font-weight: bold;
            color: $titleColor;
            line-height: 20px;
          }
          .desc {
            font-size: 12px;
            color: $titleColor;
            line-height: 16px;
            margin: 2px 0 7px;
            font-weight: normal;

          }
          .btn {
            display: inline-block;
            padding: 0 8px;
            background: $btnBgColor;
            font-size: 12px;
            color: $btnTxtColor;
            line-height: 18px;
            border-radius: 4px;
            @include ElementHover('', $btnHoverBgColor);

            a {
              color: $btnTxtColor;
            }
          }

          .expire-tip {
            @include Position(1, 4, 0, -26px);
            display: flex;
            width: 293px;
            height: 28px;
            background: #424866;
            z-index: 3;
            font-size: 12px;
            color: #FFFFFF;
            @include LayoutFlex(1);
            border-radius: 4px;


            .remaining-days {
            }

            a {
              color: #EFC78C;
              margin: 0 12px 0 8px;
            }

            .icon-cha {
              font-size: 10px;
            }
          }
        }

        .card-item {
          @include LayoutFlex(2);

          &.person {
            @include CardItem('card-person', #ffffff, #29BEA9);
          }

          &.business {
            @include CardItem('card-business', #ffffff, #5C5CF2);
          }

          &.company {
            @include CardItem('card-company', #FFE198, #253F92, rgba(255, 225, 152, 0.9), rgba(255, 225, 152, 1));
          }
        }


      }

      .card-box2 {
        height: 78px;
        background: #4472FF;
        border-radius: 20px 20px 1px 1px;

        .card-item {
          height: 100%;
          flex: 1;
          position: relative;
          color: #FFFFFF;
          font-size: 13px;

          @include ElementHover(#ECF1FF, '');

          .iconfont2 {
            font-size: 24px;
            margin-bottom: 4px;
          }

          &:after {
            content: '';
            display: inline-block;
            width: 1px;
            height: 20px;
            background: rgba(255, 255, 255, 0.3);
            border-radius: 1px;
            @include Position(1, 2, 0, 50%);
            transform: translateY(-50%);

          }

          &:last-child {
            &:after {
              display: none;
            }
          }
        }

      }


    }
  }

  .account-info {
    margin: 0 28px;
    padding-bottom: 14px;

    .account-title {
      font-size: 12px;
      color: rgba(89, 96, 136, 0.73);
      line-height: 16px;
      margin: 20px 0 10px;
    }

    .no-account {
      width: 424px;
      line-height: 32px;
      border-radius: 4px;
      padding: 0 19px 0 10px;
      box-sizing: border-box;
      background: #F4F7FF;
      color: #255BFF;
    }

    .account-list-wrap{
    }
    .account-list {
      width: 424px;
      max-height: 160px;
      overflow-y: scroll;
      overflow-x: hidden;

      //scrollbar-color: #1890FF #91D5FF; /* 第一个方块颜色，第二个轨道颜色(用于更改火狐浏览器样式) */
      scrollbar-color: #CBD1DE #FFFFFF; /* 第一个方块颜色，第二个轨道颜色(用于更改火狐浏览器样式) */
      scrollbar-width: thin; /* 火狐滚动条无法自定义宽度，只能通过此属性使滚动条宽度变细 */
      -ms-overflow-style: none; /* 隐藏滚动条（在IE和Edge两个浏览器中很难更改样式，固采取隐藏方式） */
      -webkit-border-radius: 8px;



      /* 以下是chrome浏览器自定义滚动条样式方式 */
      &::-webkit-scrollbar { /*滚动条整体样式*/
        width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
        border-radius: 8px;
      }


      &::-webkit-scrollbar-thumb { /*滚动条里面小方块*/
        border-radius:  8px;
        -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
        //background: #1890FF;
        background: #E0E3E9;
      }

      &::-webkit-scrollbar-track { /*滚动条里面轨道*/
        -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
        border-radius:  8px;
        //background: #91D5FF;
        background: #FFFFFF;
      }


      .account-item {
        width: 424px;
        height: 32px;
        border-radius: 4px;
        padding: 0 19px 0 10px;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include ElementHover('', #F4F7FF);

        .account-name {
          font-size: 13px;
          line-height: 32px;
        }

        .account-handle-box {
          display: flex;
          align-items: center;

          .account-status {

            .end-time-7 {
              font-size: 12px;
              color: #EE5253;
              line-height: 16px;
            }

            .end-time-0 {
              font-size: 12px;
              color: rgba(45, 43, 51, 0.5);
              line-height: 16px;
            }
            .end-time-1 {
              position: relative;

              &:hover{
                color: $color-primary;
              }
            }
          }
        }

        .icon_select {
          height: 18px;
          background: #255BFF;
          border-radius: 10px;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 1px 0 7px;
          margin-left: 10px;

          span {

            font-size: 12px;
          }

          .icon-qy-success {
            font-size: 16px;
            padding-left: 2px;
          }
        }

        &.ON {
          background: #F4F7FF;
          color: #255BFF;

        }

        &.expire {
          .account-name {
            color: rgba(45, 43, 51, 0.5);
          }
        }
      }
    }
  }
}
</style>
