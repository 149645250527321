import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {topicPositionStat} from "@/api/topic/topicStat";
import {TOPIC} from "@/constant/tupian/TOPIC";


class FnTopic {

  constructor() {

    this.topicPicTimer = null
    this.topicOverTime = 5
  }


  disposeTopicId(id) {


    let temp = {
      pinyin: '',
      page: 1,
      category: 0,
      sort: 'sort'
    }
    if (!id) {
      return temp;
    }

    id = id.replace('.html', '');
    let idArr = id.split('-');
    temp.pinyin = idArr[0] || 0;
    temp.page = idArr[1] || 1;
    temp.category = idArr[2] || 0;
    temp.sort = idArr[3] || 'sort';

    return temp;
  }

  /* 处理分类 */
  disposeTopicClassify(data) {
    data.forEach(vo => {

      vo.type = 3
      if (vo.childs && vo.childs > 0) {
        vo.link = PAGE_PATH.BASE_URL + PAGE_PATH.TOPIC.COLLECTION + '/' + vo.id + '-' + 'large.html'
      } else {
        vo.link = PAGE_PATH.BASE_URL + PAGE_PATH.TOPIC.COLLECTION + '/' + vo.id + '.html'
      }
    })
    return data;
  }

  /* 更新页面 */
  navigationTo() {

  }

  /* 专题位置统计接口 */
  async positionStat(ids, event, position) {

    /* 去除曝光 */

    if (position == TOPIC.POSITION_STAT.EVENT.EXPOSE){
      return false;
    }

    let res = topicPositionStat({
      ids, event, position
    });
    console.log(res)
  }

  /* 专题位置hover5秒统计接口 */
  async positionStatMouseOver(ids, position, event = TOPIC.POSITION_STAT.EVENT.ACROSS,) {
    this.closePositionStatMouseLeave()
    this.topicPicTimer = window.setInterval(() => {
      console.log(this.topicOverTime)
      this.topicOverTime -= 5;
      if (this.topicOverTime == 0) {
        let res = topicPositionStat({
          ids, event, position
        });
        this.closePositionStatMouseLeave()
      }
    }, 500)
  }

  closePositionStatMouseLeave() {
    this.topicOverTime = 5
    window.clearInterval(this.topicPicTimer);
  }


}

export default new FnTopic();
