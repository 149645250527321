let storeName = "ChaoTu";

class FnLocalStorage{

  constructor() {
  }

  /**
   * 设置
   * @param key
   * @param value
   * @returns {any}
   */
  setItem(key, value) {
    let storeData = window.localStorage.getItem(storeName);
    if (!storeData) {
      window.localStorage.setItem(storeName, '{}');
      storeData = window.localStorage.getItem(storeName);
    }
    storeData = JSON.parse(storeData);
    storeData[key] = value;

    window.localStorage.setItem(storeName, JSON.stringify(storeData));
    return storeData;
  }

  /**
   * 设置一个带日期的localStorage
   * @param key
   * @param value
   * @returns {*}
   */
  setItemDate(key, value = 1) {
    let year = new Date().getFullYear()
    let date = new Date().getDate();
    let month = new Date().getMonth() + 1;
    return this.setItem(`${key}_${year}/${month}/${date}`, value);
  }



  // 获取
  // 获取全部
  getItemAll() {
    let storeData = window.localStorage.getItem(storeName);
    if (!storeData) {
      return null;
    }
    storeData = JSON.parse(storeData);
    return storeData;
  }

  getItem(key) {
    let result = this.getItemAll();
    if (!result) {
      return null;
    }
    return result[key];
  }

  /* 获取一个带有日期的key */
  getItemDate(key) {
    let year = new Date().getFullYear()
    let date = new Date().getDate();
    let month = new Date().getMonth() + 1;
    return this.getItem(`${key}_${year}/${month}/${date}`);
  }


  // 删除
  // 删除一个
  remove(key) {
    let storeData = window.localStorage.getItem(storeName);
    if (!storeData) {
      return;
    }
    storeData = JSON.parse(storeData);
    delete storeData[key];
    window.localStorage.setItem(storeName, JSON.stringify(storeData));
    return storeData;
  }


  // 清空
  clear() {
    window.localStorage.removeItem(storeName);
  }
}

export default new FnLocalStorage();
