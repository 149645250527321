/* 收藏夹api */
import request from "@/utils/request";
import qs from "qs";

export function getFavoriteList(data) {
  return request({
    url: '/?m=Favorite&a=index',
    method: 'get',
    params: data
  })
}

/* 获取上次存储的收藏夹id */
export function getFavoriteHistory(data) {
  return request({
    url: '/?m=Favorite&a=history',
    method: 'get',
    params: data
  })
}


/* 创建文件夹 */
export function favoriteCreate(data) {
  return request({
    url: '?m=Favorite&a=create',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 删除文件夹 */
export function favoriteDelete(data) {
  return request({
    url: '?m=Favorite&a=delete',
    method: 'post',
    data: qs.stringify(data)
  })
}


/* 删除文件夹 */
export function favoriteUpdate(data) {
  return request({
    url: '?m=Favorite&a=update',
    method: 'post',
    data: qs.stringify(data)
  })
}


/* 获取文件夹和图片收藏数量 */
export function getFavoriteNumber(data) {
  return request({
    url: '/?m=Favorite&a=allNum',
    method: 'get',
    params: data
  })
}

/* 获取文件夹下图片列表 */
export function getFavoriteDetail(data) {
  return request({
    url: '/?m=Favorite&a=detail',
    method: 'post',
    data: qs.stringify(data)
  })
}


/* 获取文件夹下图片列表 */
export function FavoriteBatch(data) {
  return request({
    url: '/?m=Favorite&a=batch',
    method: 'post',
    data: qs.stringify(data)
  })
}
