export const TOPIC = {

  SORT_LIST: [
    {
      id: 0,
      sort: 'sort',
      label: '推荐',
      iconClass: 'icon-hot',
    },
    {
      id: 1,
      sort: 'hot',
      label: '热门',
      iconClass: 'icon-new',
    },
    {
      id: 2,
      sort: 'new',
      label: '最新',
      iconClass: 'icon-thumb',
    },
  ],

  POSITION_STAT:{

    EVENT:{
      EXPOSE:'expose',
      ACROSS:'across',
      CLICK:'click',
    },
    // 1 首页,2 素材列表底部,3 素材详情页,4 专题列表,5 专题合集,6 专题底部推荐,7 个人中心专题收集,8 全部热点推荐
    POSITION:{
      HOME: 1,
      TUPIAN_BOTTOM: 2,
      DETAIL_BOTTOM: 3,
      TOPIC: 4,
      COLLECTION: 5,
      TOPIC_BOTTOM: 6,
      USER_CENTER_COLLECT: 7,
      HOT_DROP_RECOMMEND:8,
      THREE_D_DROP_RECOMMEND:9,
    },
  }
}
