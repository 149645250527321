<template>
  <div>
    <no-ssr>
      <div
        v-if="isShow"
        id="paymentDialog"
        class="model-wrap">
        <cg-modal @close="closeDialog" :isShowClose="false">
          <div
            slot="modal_content"
            class="payment-dialog-content model-content">
            <div class="dialog-main">
              <div class="dialog-main-left">
                <div class="user-box">
                  <div class="avatar-box"
                  >
                    <img
                      :src="avatar"
                      class="avatar"
                      alt="">
                    <!-- 登录设备 -->
                    <i
                      v-show="device == DEVICE.iphone"
                      class="iconfont icon-mobile icon_device"/>
                    <i
                      v-show="device == DEVICE.wx"
                      class="iconfont icon-wechat2 icon_device"/>
                    <i
                      v-show="device == DEVICE.qq"
                      class="iconfont icon-QQ2 icon_device"/>
                  </div>

                  <div class="account-box">
                    <div class="account">潮国ID：{{ uniq_id }}</div>
                    <div class="member-opening" v-if="vipIsPerson == 0 && vipIsBusiness == 0">VIP会员未开通</div>
                    <div class="member-opening" v-else>{{ vipEndTime }}到期</div>
                    <i class="icon_chaopx"/>
                  </div>
                </div>

                <div class="permission-card">
                  <h3>图片包</h3>
                  <ul>
                    <li
                      v-for="vo in permission"
                      :key="vo.ID"
                    >
                      <i
                        :class=" [ vo.iconClass ] "
                        class="iconfont"/>
                      <span>{{ vo.desc  | permissionText }} </span>
                    </li>
                  </ul>
                </div>

              </div>
              <div class="dialog-main-right">
                <div class="meal-box">
                  <div class="title-box overflow-one">
                    <i class="iconfont icon-pack" />
                    购买图片包：{{ picPackageDesc.title }}
                  </div>
                  <div class="member-list">
                    <div
                      class="member-item"
                    >
                      <div class="title">{{ picPackageDesc.num - 1 }}张图片</div>
                      <div class="price">
                        <i class="icon_dollar">¥</i>
                        {{ picPackageDesc.price }}
                      </div>
                      <div class="price-desc">尊享商用授权 永久免费升级</div>
                    </div>
                  </div>
                  <div class="payment-wrap">
                    <div class="payment-box">
                      <div class="payment-item wx">
                        <div class="qr-code">
                          <img
                            class="qr-wft"
                            v-if="paymentData.isShowWft"
                            :src="paymentData.wxUrl"
                            alt="">
                          <vue-qr
                            v-else
                            :text="paymentData.wxUrl"
                            :margin="0"
                            :logo-scale="0.3"
                            :size="200"
                          />
                        </div>
                        <div class="icon-box">
                          <i class="iconfont icon-wepay"/>
                          微信支付
                        </div>
                      </div>
                      <div class="payment-item zfb">
                        <div class="qr-code qr-code-zfb">
                          <vue-qr
                            :text="paymentData.zfbUrl"
                            :margin="0"
                            :logo-scale="0.3"
                            :size="200"
                          />
                        </div>
                        <div class="icon-box">
                          <i class="iconfont icon-alipay" style="color:#06B4FD;"/>
                          支付宝支付
                        </div>
                      </div>
                    </div>
                    <div class="protocol">支付即同意
                      <NavigationTo :path="PROTOCOL_PAY_0">
                        <span  slot="content" class="_pointer">《VIP服务协议》</span>
                      </NavigationTo>
                    </div>

                    <div class="tip-bar">
                      温馨提示：您可单独购买此图片包，推荐购买商用包年VIP/企业VIP 立享全站图片包免费下载。
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="dialog-bar _hide"/>
          </div>
          <div slot="modal_close" class="icon-close" @click="closeDialog">
            <i class="iconfont icon-cha"/>
          </div>
        </cg-modal>
      </div>
    </no-ssr>
  </div>
</template>

<script>
import {PROTOCOL_PAY_0} from "~/constant/PATH";
import {vipList} from "./module";
import vueQr from 'vue-qr'
import {mapGetters, mapState} from "vuex";
import {fnUser} from "@/fn";
import {toggleStat} from "~/api/stat";
import {fnPay} from "~/fn";
import {DEVICE} from "~/constant/SYSTEM";


let permission = [
  {
    isPer: true,
    desc: '尊享商用授权',
    iconClass: 'icon-a-1shang'
  },
  {
    isPer: true,
    desc: '图片下载无限',
    iconClass: 'icon-a-2img'
  },
  {
    isPer: true,
    desc: '在线申请发票',
    iconClass: 'icon-a-3ticket'
  },
  {
    isPer: true,
    desc: '图片版权保障',
    iconClass: 'icon-a-4copyright'
  },
  {
    isPer: true,
    desc: '新媒体用途',
    iconClass: 'icon-a-5media'
  },
  {
    isPer: true,
    desc: '线上推广',
    iconClass: 'icon-a-6xianshang'
  },
  /*{
    isPer: true,
    desc: '电商平台',
    iconClass: 'icon-a-7dianshang'
  },

  {
    isPer: true,
    desc: '户外广告',
    iconClass: 'icon-a-8huwai'
  },
  {
    isPer: true,
    desc: '传媒影视',
    iconClass: 'icon-a-9film'
  },
  {
    isPer: true,
    desc: '线下印刷',
    iconClass: 'icon-a-10printer'
  },
  {
    isPer: true,
    desc: '图书出版',
    iconClass: 'icon-a-11book'
  },*/
]

export default {
  name: "PicPackagePay",
  components: {
    vueQr
  },
  computed: {
    ...mapGetters('user', [
      'phone', 'uniq_id', 'vipIsPerson', 'vipIsPersonData', 'vipIsBusiness', 'vipIsBusinessData', 'avatar', 'iphone', 'isLogin'
    ]),
    ...mapState('system', ['device'])
  },
  props: {
    picPackageDesc: {
      type: Object,
      default: () => {
      }
    },
    /* 支付码信息 包括支付宝/微信 */
    payment: {
      type: Object,
      default: () => {
        return {
          isShowWft: '',
          zfbUrl: '',
          wxUrl: '',
        }
      }
    },
  },
  data() {
    return {
      PROTOCOL_PAY_0,
      vipList,
      isShow: false,
      paymentData: {},    /* 二维码信息 */
      vipIndex: 0,        /* 会员类型索引 */
      currentVipInfo: {}, /* 当前套餐对象 */
      vipEndTime: '',
      DEVICE,
      permission
    }
  },
  mounted() {
    this.vipEndTime = fnUser.getVipEndTime();
  },
  watch: {
    payment: {
      handler(val) {
        if (val == undefined) return;
        this.paymentData = {...this.paymentData, ...val};

      },
      immediate: true
    },
  },
  methods: {


    /**
     * 获取当前会员套餐信息
     * @param { Array }  arr vipList
     * @param { Number }  n 当前套餐ID
     */
    getCurrentVipInfo(arr, n) {
      let temArr = []
      for (let i = 0; i < arr.length; i++) {
        temArr = temArr.concat(arr[i].LIST)
      }
      let currentVipInfo = temArr.find(vo => vo.ID == n)
      if (currentVipInfo != undefined) {
        this.currentVipInfo = this.disposePermission(currentVipInfo);
      }
    },
    /**
     * 处理会员权限显示，不同会员权限略有不同
     * @param { Object } currentVipInfo 当前vip套餐信息
     * @returns { Object }  currentVipInfo 返回当前vip套餐信息
     */
    disposePermission(currentVipInfo) {
      let defaultDesc = '图片下载无限';
      let findVip = fnPay.getCurrentVipInfo(currentVipInfo.ID)
      let {ID} = currentVipInfo
      /* 商用vip/普通vip 单独设置val */
      if (ID === 1 || ID === 2) {
        currentVipInfo.PERMISSION[1].desc = defaultDesc;
      } else {
        currentVipInfo.PERMISSION[1].desc = findVip.DESC.replace(' ', '');
      }
      return currentVipInfo

    },
    toggleVip(vo, idx) {
      this.$emit('toggleVip', {
        PARENT_ID: vo.PARENT_ID,
        ID: this.vipList[idx].LIST[0].ID
      });
    },
    toggleStat(position) {
      toggleStat({position})
    },

    toggleMeal(vo) {
      this.$emit('toggleVip', {
        PARENT_ID: vo.PARENT_ID,
        ID: vo.ID,
      });
    },

    closeDialog() {
      // this.isShow = false;
      this.$emit('closePayDialog');
    },



  },
  filters: {
    filterTime(val) {
      return val.slice(0, 10)
    },
    mealDuration(val) {
      let vNum = parseInt(val)
      if (vNum === 1 || vNum === 2) {
        return '1年'
      } else if (vNum === 6 || vNum === 5) {
        return '90天'
      } else if (vNum === 4 || vNum === 7) {
        return '30天'
      }
    },
    permissionText(val) {
      return val === '图片下载无限' ? '一键打包下载' : val
    }
  }
}
</script>


<style lang="scss" scoped>


#paymentDialog {
  /deep/ .Modal-content {
    background-color: transparent;
  }

  /deep/ .Modal-close {
    background: #555B7A !important;
    @include ElementHover('', '');

    .icon-cha {
      color: #FFFFFF;
    }
  }
}


.payment-dialog-content {
  width: 882px;
  position: relative;

  .dialog-header {
    padding: 17px 0 15px 40px;
    background: #424866;
    position: relative;

    .account {
      font-size: 13px;
      color: #FFFFFF;
      line-height: 17px;
      margin-bottom: 6px;
    }

    .member-opening {
      font-size: 13px;
      color: #A3AFEF;
      line-height: 17px;
    }

    .icon_chaopx {
      @include BgImage(430px, 43px, 'member/icon_chaopx.png');
      @include Position(1, 2, 36px, 0);

    }
  }

  .dialog-bar {
    width: 100%;
    @include BgImage(100%, 36px, 'vip/payment-act-bar.png')
    @include Position(1, 4, 0, -1px);
  }

  .dialog-main {
    height: 522px;
    background-color: #ECEFF6;
    display: flex;

    .dialog-main-left {
      width: 208px;
      padding: 30px 0px 40px;
      height: 100%;
      box-sizing: border-box;
      flex-shrink: 0;
      position: relative;

      .user-box {
        display: flex;
        align-items: center;
        padding-left: 0px;
        margin-bottom: 28px;
        justify-content: center;
        max-width: 208px;

        .avatar-box {
          width: 38px;
          height: 38px;
          border: 1px solid rgba(255, 255, 255, 0.6);
          border-radius: 50%;
          margin-right: 10px;
          position: relative;
          cursor: pointer;
          display: flex;
          flex-shrink: 0;

          .UserCombobox {
            @include Position(1, 2, 0, 55px);
            z-index: 5;
          }

          .avatar {
            display: inline-block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }

          .icon_device {
            display: inline-block;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            font-size: 12px;
            @include LayoutFlex(1);
            @include Position(1, 3, 0, 0);
            color: #fff;

          }

          .icon-mobile {
            background: #6969FF;
          }

          .icon-wechat2 {
            background: #51CF50;
          }

          .icon-QQ2 {
            background: #06B4FD;
          }

        }

        .account-box {
          .account {
            font-size: 13px;
            font-weight: bold;
            color: #424866;
            line-height: 17px;
            margin-bottom: 2px;
          }

          .member-opening {
            font-size: 12px;
            color: #596088;
            line-height: 16px;
          }

        }
      }

      .permission-card {
        width: 180px;
        height: 412px;
        background: #FFFFFF;
        border-radius: 14px;
        margin: 0 14px;

      }


      h3 {
        font-size: 16px;
        font-weight: bold;
        color: #424866;
        line-height: 21px;
        text-align: center;
        padding: 30px 0;
      }

      ul {
        padding-left: 25px;

        li {
          @include LayoutFlex(1);
          justify-content: flex-start;
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: 0;
          }

          span {
            font-size: 14px;
            color: #424866;
            line-height: 20px;
            letter-spacing: 1px;
          }

          .iconfont {
            font-size: 16px;
            margin-right: 10px;
            color: #F8CC44;
          }

          &.no {
            span {
              color: #B3B7C7;
            }
          }

          &.on {
            span {
              color: #424866;
            }
          }
        }

        li.person {
          &.on {
            .iconfont {
              color: #35D0BA;
            }
          }

          &.no {
            .iconfont,
            span {
              color: rgba(66, 72, 102, 0.6);
            }
          }
        }

        li.business {
          &.on {
            .iconfont {
              color: $color-primary;
            }
          }

          &.no {
            .iconfont,
            span {
              color: rgba(66, 72, 102, 0.6);
            }
          }
        }
      }
    }

    .dialog-main-right {
      width: 678px;
      //background: #FFFFFF;
      //box-shadow: -4px 0px 12px 0px rgba(0, 0, 26, 0.06);
      border-radius: 0px 0px 14px 14px;
      position: relative;
      //padding: 36px 48px 0;


      .vip-tabs {
        display: flex;
        height: 78px;
        width: 614px;

        .vip-tab-item {
          flex: 1;
          display: flex;
          align-items: center;
          cursor: pointer;


          .icon_zuanshi {
            position: relative;
            z-index: 88;
            margin: 0 8px 0 32px;
          }

          .business-info {
            position: relative;
            z-index: 88;
            color: #5E637D;

            h4 {
              font-size: 19px;
              font-weight: bold;

              line-height: 22px;
              letter-spacing: 1px;
              margin-bottom: 2px;
            }

            .subhead {
              font-size: 13px;
              line-height: 17px;

            }
          }
        }

        .person {

          &.on {
            width: 310px;
            height: 78px;
            background: #FFFFFF;
            border-radius: 14px 14px 0px 0px;

            .icon_zuanshi {
              @include BgImage(40px, 40px, 'user/sy.svg');
            }

            .business-info {
              color: #35D0BA;
            }
          }

          &.no {
            width: 310px;
            height: 78px;
            background: #FFFFFF;
            //border-radius: 14px 14px 0px 0px;
            position: relative;

            &:after {
              content: '';
              display: inline-block;
              @include Position(1, 4, 0, 0);
              background: #ECEFF6;
              width: 100%;
              height: 100%;
              border-radius: 0 0 0 14px;

            }

            .icon_zuanshi {
              @include BgImage(40px, 40px, 'user/sy@active.svg');
            }
          }
        }

        .business {
          &.on {
            width: 310px;
            height: 78px;
            background: #FFFFFF;
            border-radius: 14px 14px 0px 0px;

            .icon_zuanshi {
              @include BgImage(40px, 40px, 'user/gr.svg');
            }

            .business-info {
              color: $color-primary;
            }

          }

          &.no {
            width: 310px;
            height: 78px;
            background: #FFFFFF;
            //border-radius: 0 0 0 14px;
            overflow: hidden;
            position: relative;

            &:after {
              content: '';
              display: inline-block;
              @include Position(1, 4, 0, 0);
              background: #ECEFF6;
              width: 100%;
              height: 100%;
              border-radius: 0 0 14px 0;

            }

            .icon_zuanshi {
              @include BgImage(40px, 40px, 'user/gr@active.svg');
            }
          }
        }
      }

      .meal-box {
        margin: 14px;
        height: 494px;
        //width: 614px;
        //height: 430px;
        //background: #FFFFFF;
        border-radius: 14px;
        background-color: #fff;
        position: relative;
        .title-box{
          width: 420px;
          max-width: 420px;
          font-size: 19px;
          font-weight: bold;
          color: #2D2B33;
          line-height: 24px;
          letter-spacing: 1px;
          padding: 24px 0 0px;
          margin: 0 auto;
          .iconfont{
            font-size: 24px;
            margin-right: 8px;
            vertical-align: -2px;
          }
        }



        &.on {
          background-color: #fff;
          border-radius: 14px 0 14px 14px;

        }

        &.no {
          background-color: #fff;
          border-radius: 0 14px 14px 14px;
        }
      }

    }
  }
}


.member-list {

  //background-color: #fff;
  display: flex;
  padding: 20px 0 28px;
  justify-content: center;

  .member-item {
    width: 225px;
    height: 128px;
    box-sizing: border-box;
    margin-right: 20px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    flex-shrink: 0;
    //overflow: hidden;
    border: 2px solid #F8CC44;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    .title {
      font-size: 16px;
      font-weight: bold;
      color: #424866;
      line-height: 20px;
      letter-spacing: 1px;
      margin: 20px 0 4px;
    }

    .price {
      font-size: 32px;
      font-weight: bold;
      color: #4C4B51;
      //line-height: 44px;
      height: 44px;


      .icon_dollar {
        font-size: 14px;
        font-weight: bold;
        color: #2D2B33;
        line-height: 19px;
        position: relative;
        top: -2px;
      }

      .time {
        font-size: 14px;
      }

    }

    .price-desc {
      width: 100%;
      line-height: 38px;
      text-align: center;
      @include Position(1, 4, 0, 0);
      font-size: 13px;
      transition: all 0.3s ease-in-out;
      border-radius: 0 0 6px 6px;
      background-color: #F8CC44;
      color: #4C4B51;

    }

    &:last-child {
      margin-right: 0;
    }

    &.business {

      .price-desc {
        color: #7D7DFF;
        background-color: #F3F3FF;
      }

      &.on {
        //border: 2px solid #6969FF;
        position: relative;
        z-index: 99;
        transition: all 0.3s, ease-in-out;

        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          top: -2px;
          right: -2px;
          bottom: -2px;
          left: -2px;
          border: 2px solid #6969FF;
          border-radius: 8px;
          z-index: 8;
        }


        .title, .price {
          color: #2D2B33;

        }

        .price-desc {
          background-color: #6969FF;
          color: #FFFFFF;
        }
      }
    }

    &.person {
      .price-desc {
        color: #35D0BA;
        background-color: #EFFCFA;
      }

      &.on {
        position: relative;
        z-index: 99;
        transition: all 0.3s, ease-in-out;

        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          top: -2px;
          right: -2px;
          bottom: -2px;
          left: -2px;
          border: 2px solid #35D0BA;
          border-radius: 8px;
          z-index: 8;
        }

        .title, .price {
          color: #2D2B33;
        }

        .price-desc {
          background-color: #35D0BA;
          color: #FFFFFF;
        }
      }
    }
  }
}


/* 支付 */
.payment-wrap {
  //height: 236px;
  border-radius: 8px;
  border: 1px dotted #EBEDF5;;
  box-sizing: border-box;
  //padding-bottom: 22px;
  width: 550px;
  height: 242px;
  margin: 0 auto;
  position: relative;

  .tip-bar{
    width: 100%;
    background: #FEF4E7;
    border-radius: 0px 0px 8px 8px;
    @include Position(1,4,0,0);
    font-size: 12px;
    color: #EE8022;
    line-height: 32px;
    text-align: center;
  }
}

.payment-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 0 20px 20px;
  //box-sizing: border-box;
  width: 100%;

  .payment-item {
    margin-right: 66px;

    &:nth-child {
      margin-right: 0;
    }

    .qr-code {
      width: 120px;
      height: 120px;
      padding: 3px;
      box-sizing: border-box;
      margin-bottom: 4px;

      img {
        display: inline-block;
        width: 100%;

        &.qr-wft {
          width: 148px;
          margin: -17px 0 0 -17px;
        }
      }
    }

    .icon-box {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      .iconfont {
        font-size: 16px;
        margin-right: 8px;

        &.icon-wepay {
          color: #00AA74;
        }

        &.icon-alipay {
          color: #00A7E3;
        }
      }
    }

    /* 微信 */
    &.wx {
      color: #00AA74;
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon-box {
        width: 148px;
        box-sizing: border-box;
        margin: 0 auto;
        //margin-left: 12px;
      }
    }

    /* 支付宝 */
    &.zfb {
      color: #00A7E3;
    }
  }

}


/* 协议 */
.protocol {
  font-size: 12px;
  color: #9397B1;
  line-height: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #8181F5;
    @include ElementHover($color-primary, '');
  }

  //letter-spacing: 1px;
}


.model-wrap {


  .model-content {
    width: 836px;
    //height: 558px;
    background-color: #ECEFF6;
    position: relative;

    .header {
      padding: 17px 0 70px 40px;
      position: relative;

      @mixin CardItem($bgImg,$bgHoverImg) {
        display: inline-block;
        @include BgImage(449px, 50px, $bgImg);


        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          @include BgImage(449px, 50px, $bgHoverImg);
          opacity: 0;
          transition: .5s;
        }

        @include ElementHover('', '') {
          z-index: 10;
          &:after {
            opacity: 1;
          }
        }
      }

      .business {
        @include CardItem('vip/tab_business.png', 'vip/tab_business@active.png');
        @include Position(1, 4, 0, 0);

        &.on {
          @include CardItem('vip/tab_business@active.png', 'vip/tab_business@active.png');
          z-index: 9;
        }
      }

      .person {
        @include CardItem('vip/tab_person.png', 'vip/tab_person@active.png');
        @include Position(1, 3, 0, 0);

        .vip-card-desc {
          padding-left: 62px !important;
        }

        &.on {
          @include CardItem('vip/tab_person@active.png', 'vip/tab_person@active.png');
          z-index: 9;
        }

      }

      .vip-card {
        font-size: 0;

        .vip-card-desc {
          font-size: 19px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 22px;
          letter-spacing: 1px;
          position: relative;
          z-index: 10;
          padding: 14px 0 14px 40px;

          span {
            font-size: 13px;
            color: #FFFFFF;
            line-height: 17px;
            letter-spacing: 1px;
            margin-left: 10px;
            font-weight: normal;
          }
        }
      }
    }

  }

  .icon-close {
    width: 32px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 20px;
    @include Position(1, 2, -16px, -16px);
    @include LayoutFlex(1);
    cursor: pointer;

    .icon-cha {
      font-size: 16px;
      color: #5E637D;
      transition: transform 0.5s ease-out;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      display: inline-block;

      &:hover {
        transform: rotateZ(180deg);
      }

    }
  }

}


</style>
