import _ from 'lodash'
import axios from "axios";
import {packageStat} from "@/api/stat";
import {downStat} from "@/api/material";
import {toolDevice} from "@/tools";
import {BROWSERS} from "@/constant/SYSTEM";
import * as fnCommon from "@/fn/_fn";
import {setPicFreeDownCouponModal} from "@/components/_Privately/Pic/PicFreeDownCouponModal/module";
import {PIC_VIEW_TYPE} from "@/constant/_Privately/Pic";
import {BASE_IMG_URL} from "@/constant/_Public/Path";

/**
 *
 * @param Object picInfo 图片信息
 * @param string channel 下载类型 2-源文件
 * @param string uniq_id 图片uq-id
 * @param string downloadType  下载类型 pic-图片 picPackage-图片包
 * @param Object from 拼接表单 比如来源等等
 * @returns {Promise<unknown>}
 */
export function downloadPic(picInfo) {

  const {channel = 2, uniq_id = 0, downloadType = 'pic', form = {}} = picInfo;

  /* 检查是否登录 */

  /* 拼接下载链接 */
  let downLink = `https://dl.chaopx.com/down/download?channel=${channel}&uniq_id=${uniq_id}`;

  /* 判断并解析表单form使其转换成查询字符串 */
  if (!_.isEmpty(form)) {
    const queryString = _.map(form, (value, key) => `${key}=${value}`).join('&');
    downLink += (downLink.endsWith('&') ? '' : '&') + queryString;
  }

  return new Promise((resolve, reject) => {

    axios.get(downLink).then(res => {
      let {status, time, layer, url, msg, d_type, free_down} = res.data.data;
      if (status === 1) {
        /* 下载成功操作 */
        downloadPicSuccess(picInfo)
        let safariTime = toolDevice.getCurrentBrowser() === BROWSERS.Safari ? 200 : 0;
        setTimeout(() => {
          window.location.href = url;
        }, safariTime)

        /* 免费下载操作 */
        if (parseInt(free_down) === 1) {
          downloadPicSuccessByFree();
        }
      } else if (status === 0 || status === 2) {
        downloadPicErrToPay();
      } else if (status === -200) {
        downloadPicFrequently(res)
      }

      resolve(res)
    }).catch(err => {
      // myNotify.show(msg)
      // reject(res)
    })
  })
}


/* 下载成功执行公共方法 */
export function downloadPicSuccess(picInfo) {
  const {channel = 2, uniq_id = 0, downloadType = 'pic', form = {}} = picInfo;
  packageStat({event: 'down', channel: channel})
  downStat({uniq_id: uniq_id, module: 2})
}


/* 免费下载执行方法 */
export function downloadPicSuccessByFree(picInfo) {
  const {$store} = $nuxt;
  setPicFreeDownCouponModal($store, true);
}

/* 短时间内下载次数过多 */
export function downloadPicFrequently(res) {
  let {status, time, layer, url, msg, d_type, free_down} = res.data.data;
  const {$store} = $nuxt;
  $store.commit('tupian/SET_DOWN_INTERCEPT_DIALOG', {isShow: true, clippingTime: time})
}

/* 下载失败跳转充值页面 */
export function downloadPicErrToPay() {
  let {status, time, layer, url, msg, d_type, free_down} = res.data.data;
  let openUrl = fnCommon.fnUser.getUserVipUpgradeLinkByPicLayer(parseInt(layer));
  fnCommon.fnBasic.navigationTo(openUrl, "_blank")
}

/* 设置不同图片展示class */

/*
*   is_transform_ai: int 是否AI翻新: 0 否,1 是
    transform_show: array 翻新图片参数:
                    transform_type 1元素 2 背景 3 插画 4 摄影图,
                    base_color 0 空,1 灰色 2 黑色,
                    show_type 1 横图,2 竖图
* */
export function setPicViewType(picItem) {

  let tempPicItem = {}
  /* 单独处理专题，专题根据其第一张子图判断是否是翻新ai*/
  if (picItem.type == 3) {
    tempPicItem = Array.isArray(picItem.pictures) && picItem.pictures.length > 0 ? picItem.pictures[0] : {};
  } else {
    tempPicItem = picItem
  }

  const {
    is_transform_ai = 0, transform_show = {
      transform_type: 0,
      base_color: 0,
      show_type: 0,
    }
  } = tempPicItem

  let _prototype_default_where = is_transform_ai == 0; // 默认图片

  const {
    transform_type,
    base_color,
    show_type
  } = transform_show

  /* 元素黑 */
  let _prototype_black_where = is_transform_ai == 1 && transform_type == 1 && base_color == 2;
  /* 元素灰 */
  let _prototype_gray_where = is_transform_ai == 1 && transform_type == 1 && base_color == 1;
  /* 背景 摄影 插画 横图 */
  let _prototype_vertical_where = is_transform_ai == 1 && transform_type != 1 && show_type == 1;
  /* 背景 摄影 插画 竖图 */
  let _prototype_horizontal = is_transform_ai == 1 && transform_type != 1 && show_type == 2;

  if (_prototype_default_where) {
    picItem._view_type = PIC_VIEW_TYPE.DEFAULT;
  } else if (_prototype_black_where) {
    picItem._view_type = PIC_VIEW_TYPE.BLACK;
  } else if (_prototype_gray_where) {
    picItem._view_type = PIC_VIEW_TYPE.GRAY;
  } else if (_prototype_vertical_where) {
    picItem._view_type = PIC_VIEW_TYPE.VERTICAL;
  } else if (_prototype_horizontal) {
    picItem._view_type = PIC_VIEW_TYPE.HORIZONTAL;
  } else {

  }
  return picItem;
}

/* 补充图片部分属性,比如图片完整地址等等 */
/**
 * 补充图片部分属性,比如图片完整地址等等
 * @param picItem array/object 数组则表示列表，否则单个图片
 */
export function setPicAttr(picItem) {
  if (Array.isArray(picItem)) {
    picItem.forEach(item => {
      item._proto_pic = BASE_IMG_URL + item.proto_pic
    })
  } else {
    picItem._proto_pic = BASE_IMG_URL + picItem.proto_pic
  }
  return picItem
}
