/**
* @Author: wulizhou
* @Description: 新用户登录免费下载引导提示弹框
* @Date:
**/

<template>
  <div class="container NewUserFreeDownGuideModal" v-if="NewUserFreeDownGuideModal">

    <div class="inner_center">

      <div class="high-free-box"
           >

       <div class="high-free-box-inner" @click.stop="toFree">
         <i
           v-for="(vo,idx) in HEADER.NAV.FREE.icons"
           :key="idx"
           class="icon_NavItemHot"
           :class="vo.type"
           :style="{background:'url('+ PAGE_PATH.BASE_IMG_UPLOAD + vo.url+') no-repeat center/100% '}"

         />
         免费专区
       </div>


        <div class="line"/>


        <div class="desc-box" @click.stop="toFree">
          新用户前10天，每天可免费下载一张【免费专区】图快来体验吧！

          <i class="iconfont icon-cha" @click.self.stop="close"/>
          <div class="btn">
            立即体验

            <i class="icon_hand"/>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

/* 引入公共方法和常量 */
import * as fnCommon from '@/fn/_fn';
import * as CON_COMMON from '@/constant/_constant';

import {HEADER} from "@/constant/common/HEADER";

/* 引入组件 */

/* 引入VUEX */
import {mapState} from "vuex";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {tag} from "@/utils/statUtil";

/* 引入API */

export default {
  name: "NewUserFreeDownGuideModal"

  , components: {}

  , props: {}

  , watch: {}

  , computed: {
    ...mapState('Modal',['NewUserFreeDownGuideModal'])
  }

  , data() {
    return {
      HEADER,
      PAGE_PATH
    }
  }

  , methods: {

    close(){
      this.$store.commit('Modal/SET_NEW_USER_FREE_DOWN_GUIDE_MODAL',false)
    },
    toFree(){
      this.$store.commit('Modal/SET_NEW_USER_FREE_DOWN_GUIDE_MODAL',false)
      fnCommon.fnBasic.navigationTo('/tupian/0-999-0-0-0-0-0-default-1-1.html')
    },

  }

}
</script>

<style lang="scss" scoped>

.container {
  position: fixed;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.55);
  z-index: 999;

  .high-free-box {
    width: 108px;
    height: 70px;


    position: relative;
    margin-left: 410px;
    margin-top: 12px;

    .high-free-box-inner{
      width: 108px;
      height: 70px;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 16px;
      color: #001013;
      line-height: 22px;
      border-radius: 20px;
      letter-spacing: 1px;
      cursor: pointer;


    }


    @include Screen(1){
      //margin-left: 110px;
    }

    .icon_NavItemHot {
      display: inline-block;
      width: 39px !important;
      height: 23px !important;;
      background-size: 39px 23px;
    }

    .line {
      width: 1px;
      height: 90px;
      background: #FFFFFF;
      @include Position(1, 5, 31px, 70px);
    }

    .desc-box {
      width: 439px;
      height: 114px;
      background: linear-gradient(90deg, #B98FFF 0%, #6969FF 100%);
      border-radius: 10px;
      @include Position(1, 5, 0, 160px);
      padding: 24px 20px 0;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 24px;
      letter-spacing: 1px;
      box-sizing: border-box;

      .icon-cha {
        font-size: 14px;
        @include Position(1, 2, -22px, 0);
        color: #FFFFFF;
        cursor: pointer;

        &:hover {
          color: rgba(255, 255, 255, 0.5)
        }
      }

      .btn {
        width: 110px;
        height: 34px;
        background: #FFFFFF;
        border-radius: 20px;
        line-height: 34px;
        text-align: center;
        font-size: 15px;
        font-weight: bold;
        color: #424866;
        letter-spacing: 1px;
        @include Position(1, 3, 20px, 15px);
        cursor: pointer;

        &:hover {
          color: $color-primary;
        }

        .icon_hand {
          @include BgImage(40px, 50px, 'header/nav/icon_hand.png');
          @include Position(1, 2, -11px, 13px);
        }
      }

    }

  }

}

</style>
