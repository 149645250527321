<template>
  <div class="SurveyUserIndustry SurveyUser">
    <div class="header">
      <i class="icon_industry"/>
      <div class="title-box">
        <div class="headline">选择您所属行业(1/3)</div>
        <div class="subhead">我们将为您提供行业属性匹配的创意设计</div>
      </div>
    </div>

    <div class="content industry-content">
      <div
        v-for="(vo,idx) in industry"
        :key="vo"
        :class="['industry-item',{ on: idx === industryIndex }]"
        @click="toggleIndustry(vo,idx)"
      >{{ vo }}
      </div>
    </div>

    <div class="industry-input">
      <div class="name">其它行业：</div>
      <input v-model="industryVal" type="text" placeholder="请填写行业"
             @input="listenInput">
      <i v-if="industryVal !== '' " class="iconfont icon-cha2" @click="industryVal = ''"/>
    </div>

    <div
      :class="['submit',{ 'on': isNextStep }] "
      @click="nextStep"
    >下一步
    </div>
  </div>
</template>

<script>
import {industry} from '../module'

export default {
  name: "SurveyUserIndustry",
  props:{
    step: 0
  },
  data() {
    return {
      industry,
      industryIndex: -1,
      industryVal: '',
      surveyUser:{
        industry:''
      }
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    isNextStep: {
      get() {
        if (this.industryIndex > -1 || this.industryVal !== '') {
          return true
        }
      },
      set() {
        if (this.industryIndex > -1 || this.industryVal !== '') {
          return true
        }
      },
    }
  },
  watch: {
    /*industryIndex(nVal, oVal) {
      if (nVal > -1) {
        this.industryVal = ''
        this.surveyUser.industry = industry[nVal]
      }else{
        this.surveyUser.industry = ''
      }
      this.isNextStep = nVal > -1;
    },
    industryVal(nVal, oVal) {
      if (nVal !== '') {
        this.industryIndex = -1;
        this.surveyUser.industry = nVal
      }else{
        this.surveyUser.industry = ''
      }
      this.isNextStep = nVal !== '';
    }*/
  },

  methods: {
    init(){
      let _surveyUser = this.$localStorage.getItem('SurveyUser');
      if (_surveyUser != undefined){
        let parseSurveyUser = JSON.parse(_surveyUser);
        let { industry } = parseSurveyUser;
        this.surveyUser = parseSurveyUser;
        let findIndex = this.industry.indexOf(industry);

        if (findIndex > -1){
          this.industryIndex = findIndex
        }else{
          this.industryVal = industry
        }
      }
    },

    /* 禁止输入空字符串 */
    listenInput(){
      this.industryVal = this.industryVal.replace(/\s+/g,"");
      if (this.industryVal !== ''){
        this.resetForm(1)
        this.surveyUser.industry = this.industryVal;
      }else{
        // this.surveyUser.industry = '';
      }
    },

    resetForm(type = 1){
      /* 清空select */
      if (type === 1){
        this.industryIndex = -1;
      }else {
        this.industryVal = '';
      }
      this.surveyUser.industry = '';
    },

    toggleIndustry(vo, idx) {
      if (this.industryIndex === idx) {
        this.resetForm(1)
        return false
      }
      this.resetForm(2);
      this.industryIndex = idx;
      this.surveyUser.industry = industry[idx];
    },
    nextStep() {
      if (this.isNextStep) {
        this.$localStorage.setItem('SurveyUser',JSON.stringify(this.surveyUser));
        this.$emit('nextStep',this.step+1);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../common/common";
</style>
