<template>
  <div class="PicItem" :class="[
    { 'isChecked': isChecked },
    { 'checked': picItemInfo.checked },
   ]"
  >

    <a
      class=" pic-img-wrap"
      @click="picItemClick(picItemInfo)"
      @mouseenter="getPicCollectStatus(picItemInfo)"
      :href="TUPIAN_DETAIL + '/' + picItemInfo.uniq_id + '.html' + (spider? '' :( picItemInfo._topic_id ? '?_topic_id='+ picItemInfo._topic_id + '&_refererUrl=' + picItemInfo._refererUrl : ''))"
      :title="picItemInfo.title"
      target="_blank"
    >
      <PicImgBox :pic-item="picItemInfo"></PicImgBox>
      <TupianMask
        :link="TUPIAN_DETAIL + '/' + picItemInfo.uniq_id + '.html' + (spider? '' :( picItemInfo._topic_id ? '?_topic_id='+ picItemInfo._topic_id + '&_refererUrl=' + picItemInfo._refererUrl : ''))"
        :title="picItemInfo.title"
      ></TupianMask>

    </a>

    <no-ssr>
      <!-- 收藏 -->
      <PicTagCollect v-if="picItemInfo.package == 1"
                     class="_PicTagCollectNew"
                     :pic-item-info="picItemInfo"/>

      <!--
        @PicTagCollectMouseover="PicTagCollectMouseover"
        @PicTagCollectMouseleave="PicTagCollectMouseleave"
        -->
      <PicTagCollectNew v-else
                        class="_PicTagCollectNew" :pic-item-info="picItemInfo"
                        v-bind="$attrs"
                        v-on="$listeners"


      />


      <!-- 标题 -->
      <!--    <PicTagTitle :pic-item-info="picItemInfo"/>-->
      <!-- 标签 -->
      <PicTagLegalCopy class="_PicTagLegalCopy"
                       :pic-item-info="picItemInfo"
                       v-bind="$attrs"
                       v-on="$listeners"
                       @picTagLegalMouseover="picTagLegalMouseover"
                       @picTagLegalMouseleave="picTagLegalMouseleave"
      />
      <!-- 图片按钮组 -->
      <PicButtonGroup
        v-show="picItemInfo.package != 1"
        :pic-item-info="picItemInfo"
        @downloadByBusiness="downloadByBusiness"
        @downloadByFree="downloadByFree"


      />
      <!-- 图片包按钮组 -->
      <PicButtonGroupPackage
        v-show="picItemInfo.package == 1"
        :pic-item-info="picItemInfo"
        @navigatorToPay="navigatorToPay"
        @showPicPackageBuyModal="showPicPackageBuyModal"
        @downloadPicPackage="downloadPicPackage"/>

      <!-- 勾选 -->
      <div class="checked-mask" v-if="isChecked"
           @click="checkTupianItem(picItemInfo)"
      >
        <div class="iconfont-item icon_checkbox2"
             :class="{ 'icon_checkbox' : picItemInfo.checked}"

        ></div>
      </div>

    </no-ssr>

    <!-- 图片标题 -->
    <a
      v-if="isShowPicTitle"
      class="pic-title overflow-one"
      :href="MATERIAL_DETAIL + '/' + picItemInfo.uniq_id + '.html' + (spider? '' :( picItemInfo._topic_id ? '?_topic_id='+ picItemInfo._topic_id + '&_refererUrl=' + picItemInfo._refererUrl : ''))"
      :title="picItemInfo.title"
      target="_blank"
    >
      {{ picItemInfo.title }}
    </a>

  </div>
</template>

<script>
import picItemExtend from "@/components/pic/pic-item/common/pic-item-extend";
import {fnLogin, fnPic, fnRouter} from "@/fn";
import {tag} from "@/utils/statUtil";
import PicButtonGroupExtend from "@/components/pic/pic-button-group/common/pic-button-group-extend";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {mapState} from "vuex";
import {getCookie} from "@/utils/auth";
import _ from 'lodash'
import {getPicCollectStatus} from "@/api/material";
import PicImgBox from '@/components/_Privately/Pic/PicImgBox'


export default {
  name: "PicItem",
  mixins: [picItemExtend, PicButtonGroupExtend],
  components: {
    PicImgBox
  },
  props: {
    isChecked: {
      type: Boolean,
      default: false
    },
    isGetPicCollectStatus: {
      type: Boolean,
      default: true
    },

    /* 是否展示标题 */
    isShowPicTitle: {
      type: Boolean,
      default: true
    },
  },

  data() {
    return {
      checkPicCollectList: [],
    }
  },
  computed: {
    ...mapState('favorites',
      [
        "showDialog",
        "showDialogByCompany",
        "currentTupianInfo",
        "favoriteList",
        "favoriteHistory"
      ]
    ),
    ...mapState('base', ['spider']),
  },

  watch: {
    showDialog(n) {
      if (n == false) {
        if (this.checkPicCollectList.indexOf(this.currentTupianInfo.uniq_id) > -1) {
          let index = this.checkPicCollectList.indexOf(this.currentTupianInfo.uniq_id)
          this.checkPicCollectList.splice(index, 1);

          console.log(this.currentTupianInfo.uniq_id)
          console.log(this.checkPicCollectList)
        }
      }
    },
    showDialogByCompany(n) {
      if (n == false) {
        if (this.checkPicCollectList.indexOf(this.currentTupianInfo.uniq_id) > -1) {
          let index = this.checkPicCollectList.indexOf(this.currentTupianInfo.uniq_id)
          this.checkPicCollectList.splice(index, 1);

          console.log(this.currentTupianInfo.uniq_id)
          console.log(this.checkPicCollectList)
        }
      }
    }
  },

  methods: {
    getPicCollectStatus: _.throttle(function (item) {

        console.log(this.isGetPicCollectStatus)
        if (this.isGetPicCollectStatus == false) {
          return false;
        }

        if (fnLogin.checkLogin()) {
          /* 检查是否查询过 */
          if (this.checkPicCollectList.indexOf(item.uniq_id) > -1) {
            return false;
          }
          getPicCollectStatus({
            id: item.uniq_id
          }).then(res => {
            let {is_there} = res.data.data;
            this.checkPicCollectList.push(item.uniq_id)
            if (is_there == 1) {
              this.$set(item, 'isCollect', is_there)
            }
          }).catch(err => {
          })
        } else {
          this.$set(item, 'isCollect', 0)
        }

      },
    ),
    picTagLegalMouseover(info) {
      console.log(info.idx)
      let _classArr = Array.from(document.getElementsByClassName('_PicTagCollectNew'))
      console.log({
        _classArr
      })
      _classArr[info.idx].style.display = 'none'
    },
    picTagLegalMouseleave(info) {
      let _classArr = Array.from(document.getElementsByClassName('_PicTagCollectNew'))
      _classArr[info.idx].style.display = 'block'
    },

    PicTagCollectMouseover(info) {
      let _classArr = Array.from(document.getElementsByClassName('_PicTagLegalCopy'))
      _classArr[info.idx].style.display = 'none'

    },
    PicTagCollectMouseleave(info) {
      let _classArr = Array.from(document.getElementsByClassName('_PicTagLegalCopy'))
      _classArr[info.idx].style.display = 'block'

    },

    checkTupianItem(vo) {
      let checked = vo.checked || false;
      let msg = checked === false ? '选中' : '取消';
      this.$set(vo, 'checked', !vo.checked)
      // this.$myNotify.success(msg);

    }
  }

}
</script>

<style lang="scss" scoped>
@import "./common/pic-item";

.PicItem {
  position: relative;

  &:hover {
    .pic-title {
      bottom: 0;
    }
  }

  .pic-title {
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    z-index: 10;
    //padding: 0 24px 18px;
    //width: 288px;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 19px;
    text-align: center;
    bottom: -100px;
    transition: all 0.5s ease-in-out;
    background-color: rgba(0, 0, 0, 0.5);

    padding: 0 24px 0;
    line-height: 37px;

    @include Screen(1) {
      padding: 0 24px 14px;
      padding: 0 24px 0px;
      line-height: 33px;
    }
  }

  ._PicTagCollectNew {
  }

  &.checked {
    border: 3px solid $color-primary;
    box-sizing: border-box;
  }


  &.isChecked {


    @include ElementHover('', '') {

      border: 3px solid $color-primary;
      box-sizing: border-box;


      .pic-img {
        transform: scale(1);
      }

      .material-title {
        bottom: 0;
      }
      .material-tag {
        top: -999px;
      }
      .collect {
        top: -999px;
      }

      .collect2 {
        top: -999px;
      }


      /deep/ .legal-copy-active {
        //display: block !important;
      }
      /deep/ .icon_PicTagLegalCopy {
        //display: none !important;
      }

      .button-group {
        bottom: -999px;
      }
    }

  }


  .checked-mask {
    width: 100%;
    height: 100%;
    @include Position(1, 5, 0, 0);
    background: rgba(0, 0, 0, 0.7);
    z-index: 10;

    .iconfont-item {
      font-size: 16px;
      @include Position(1, 2, 16px, 16px);
      z-index: 20;

      &.icon_checkbox2 {
        @include BgImage(16px, 16px, 'tupian/icon_check.png');
      }

      &.icon_checkbox {
        @include BgImage(16px, 16px, 'tupian/icon_checked.png');
      }
    }

  }


}
</style>
