<template>
  <div id="box"
       v-if="isLogin && isVip && items.length"
       :class="{'on': isShow == true}"
  >
    <div
      class="belll "
      :class="{'a-ring': isShow == false}"
      @click="isShow = !isShow">
      <i class="icon_belll"></i>
    </div>
    <ul
      id="con1"
      ref="con1"
      :class="[
        {anim:animate},
        {'on': isShow},
      ]"
      @mouseenter="Stop()" @mouseleave="Up()"
    >
      <li v-for="item in items" >
        <span>亲爱的用户，你的</span>
        <NavigationTo :path="item.url">
          <span slot="content" @click="tag('充值:续费通知:图片')" class="dark-bule" style="color: #6262F8"> &nbsp;{{ item.vipType }} &nbsp;</span>
        </NavigationTo>
        <span>离到期还有</span>
        <span class="dark-bule">{{ item.endDay }}天</span>
        <span>，请及时</span>
        <NavigationTo :path="item.url">
          <span slot="content" @click="tag('充值:续费通知:图片')" class="dark-bule dark-bule1" style="color: #6262F8"> &nbsp;续费 &nbsp;</span>
        </NavigationTo>
        <i class="iconfont icon-cha" @click="isShow = false"/>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {VIP_STATE, vipType} from "@/constant/VIP";
import {PAY_BUSINESS, PAY_COMPANY} from "@/constant/PATH";
import {fnUser} from "@/fn";
import {tag} from "@/utils/statUtil";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {PAY_PERSON} from "@/constant/_Public/Path"

export default {
  name: "VipNotification",
  data() {
    return {
      items: [],
      animate: false,
      intNum: undefined,
      timer: null,
      isShow: true,
      isVip: false
    }
  },
  computed: {
    ...mapState('user',
      [
        'personVipState',
        'personVipInfo',
        'businessVipState',
        'businessVipInfo',
        'companyVipState',
        'companyVipInfo',
        'isLogin'
      ]
    ),
  },
  mounted() {
    this.getNoticeData();
    this.init();
  },
  methods: {

    init() {
      this.isVip = fnUser.checkUserIsVip()
    },

    vipNoticeTip(remaining_days, vipType, url) {
      let dataArr = [7, 3, 2, 1],
        noticeData = [];

      dataArr.forEach((vo, idx) => {
        if (vo == remaining_days) {
          noticeData.push({
            vipType: vipType,
            url: url,
            endDay: remaining_days
          })
        }
      })
      return noticeData;
    },

    getNoticeData() {
      let noticeData = [];

      /* 检查过期会员 */
      if (this.personVipState === VIP_STATE.ON) {
        let remaining_days = this.personVipInfo.remaining_days;
        let data = this.vipNoticeTip(remaining_days, '普通VIP', PAY_PERSON);
        noticeData = noticeData.concat(data)
      }

      /*if (this.businessVipState === VIP_STATE.ON) {
        let remaining_days = this.businessVipInfo.remaining_days;
        let data = this.vipNoticeTip(remaining_days, '商用VIP', PAY_BUSINESS);
        noticeData = noticeData.concat(data)
      }*/

      if (this.companyVipState === VIP_STATE.ON) {
        let remaining_days = this.companyVipInfo.remaining_days;
        let data = this.vipNoticeTip(remaining_days, '企业VIP', PAGE_PATH.PAYMENT.COMPANY);
        noticeData = noticeData.concat(data)
      }

      this.items = noticeData;
      // this.ScrollUp();
      if (this.items.length > 1) {
        this.timer = setInterval(this.ScrollUp, 5000)
      }

    },
    ScrollUp() {

      this.animate = true;    // 因为在消息向上滚动的时候需要添加css3过渡动画，所以这里需要设置true
      setTimeout(() => {      //  这里直接使用了es6的箭头函数，省去了处理this指向偏移问题，代码也比之前简化了很多
        this.items.push(this.items[0]);  // 将数组的第一个元素添加到数组的
        this.items.shift();               //删除数组的第一个元素
        this.animate = false;  // margin-top 为0 的时候取消过渡动画，实现无缝滚动
      }, 500)
    },
    //鼠标移上去停止
    Stop() {
      clearInterval(this.timer);
    },
    Up() {
      if (this.items.length > 1) {
        this.timer = setInterval(this.ScrollUp, 5000)
      }
    },

    close() {
      this.$emit('close')
    },
    tag(stat) {
      tag(stat)
    }
  }
}

</script>

<style lang="scss" scoped>
//@import "styles/myAnimation";
.a-ring {
  //-webkit-animation-name:ring;-moz-animation-name:ring;-ms-animation-name:ring;animation-name:ring;
  animation: ring 2s infinite;
}


@-webkit-keyframes ring {
  0% {
    -webkit-transform: scale(1) rotate(0deg);
  }
  10%, 20% {
    -webkit-transform: scale(1) rotate(-15deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1) rotate(15deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale(1) rotate(-15deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
  }
}

#box {
  //width: 300px;
  display: inline-flex;
  height: 28px;
  overflow: hidden;

  &.on {
    width: 100%;
    background: rgba(0, 167, 227, 0.06);
    border-radius: 14px;

    #con1 {
      background: transparent;
      width: 100%;

      li {
        display: flex;
        align-items: center;
        justify-content: center;

        /deep/ .NavigationTo {
          @include LayoutFlex(2);
          cursor: pointer;
        }
      }

    }

    .belll {
      background: transparent;
    }
  }
}

.anim {
  transition: all 0.5s;
  margin-top: -28px;
}

.belll {
  width: 28px;
  height: 28px;
  border-radius: 14px;
  background: rgba(0, 167, 227, 0.06);
  flex-shrink: 0;
  @include LayoutFlex(1);
  cursor: pointer;

  .icon_belll {
    @include BgImage(14px, 14px, 'user/icon_belll.png')
  }
}


#con1 {
  width: 0;
  height: 28px;
  //background: rgba(0, 167, 227, 0.06);
  border-radius: 14px;

  li {
    line-height: 28px;
    height: 28px;
    //background: #424866;
    padding: 0 8px 0 0;
    white-space: nowrap;
    border-radius: 4px;
    font-size: 0;

    span {
      font-size: 12px;
      color: #00A7E3;
      letter-spacing: 0.5px;
    }

    .dark-bule {
      color: #6262F8 !important;
    }

    a {
      font-size: 12px;
      color: #EFC78C;
      //margin: 0 8px;

      /*@include ElementHover(#EFC78C, '') {
        text-decoration: underline;
      }*/
    }

    .dark-bule1 {
      text-decoration: underline;
    }

    .icon-cha {
      font-size: 10px;
      color: #00A7E3;
      margin-left: 6px;
      cursor: pointer;
    }
  }

}


</style>
