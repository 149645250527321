const storePath = '_Privately/Pic/';
const methods = 'setPicFreeDownCouponModal';
const moduleFlag = true;  // 模块开关，true 打开该活动，关闭反之

export const PicFreeDownCouponModalState = {
  isShowPicFreeDownCouponModal: true,  // 图片免费下载红包弹框
}

export const PicFreeDownCouponModalMutations = {
  [methods](state, payload) {
    moduleFlag && (state.isShowPicFreeDownCouponModal = payload)
  },
};


/* 自定义方法 -------- */

/* 设置图片免费下载红包弹框 */
export function setPicFreeDownCouponModal(store, payload) {
  moduleFlag && store.commit(storePath + methods, payload);
}
