/**
 * @Author: wulizhou
 * @Description:  浏览器爬虫判断
 * @Date:
 **/

export default function ({ isHMR, app, store, route, params, error, redirect,req,res }) {
  if(!req || isHMR) return;
  let keys = ['Googlebot','bingbot','Baiduspider'];
  let isboot = false
  try {
    for(let i=0;i<keys.length;i++) {
      if(req.headers['user-agent'].toLowerCase()
        .includes(keys[i].toLowerCase())) {
        isboot = true;
        break;
      }
    }
  }catch {}
  if(isboot || route.fullPath.includes('isboot=1')) {
    store.commit('base/SET_SPIDER', true)
  }

}
