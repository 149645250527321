import {NAV} from "~/components/common/header/header-nav/common/module";
import TupianMask from '@/components/tupian/tupian-mask'
import {HEADER} from "@/constant/common/HEADER";


let headerNavMixin = {

  components:{
    TupianMask,
  },
  data(){
    return{
      NAV,
      HEADER
    }
  },
  props: {
    navIndex: {
      type: String | Number,
      default: -1
    },
  },
  methods:{
    updateNavId(navId){
      console.log(navId)
      this.$emit('updateNavId', navId)
    },
    listenStatistics(navId) {
      this.$emit('listenStatistics', navId)
    },

  }
}

export default headerNavMixin;
