<template>

  <div class="CompanyVipExpiredModal" >

    <no-ssr>
      <ModalMiddle v-if="CompanyVipExpiredModal && isLogin" @close="close">
        <div slot="ModalContent" class="ModalContent">
          <div class="title">
            友情提示

          </div>
          <div class="desc">
            您当前企业主体已到期，续费后继续享受企业服务。
          </div>

          <div class="btn-group">
            <div class="btn-item cancel" @click="toService">
              咨询客服
            </div>
            <div class="btn-item confirm" @click="toRenew">
              前去续费
            </div>
          </div>
        </div>
      </ModalMiddle>
    </no-ssr>

  </div>
</template>

<script>
import {mapState} from "vuex";
import {fnBasic} from "@/fn/_fn";
import {SERVICE_LINK} from "@/constant/_constant/CON_CONFIG";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {fnRouter} from "@/fn";
import getCookie from "@/utils/getCookie";
import {tag} from "@/utils/statUtil";

export default {
  name: "CompanyVipExpiredModal",
  computed: {
    ...mapState('Modal', ['CompanyVipExpiredModal']),
    ...mapState('user', ['isLogin']),
  },
  methods:{
    close(){
      this.$store.commit('Modal/SET_COMPANY_VIP_EXPIRED_MODAL', false)
    },
    toService(){
      fnBasic.navigationTo(SERVICE_LINK)
    },
    toRenew(){
      tag('充值:下载续费弹窗:图片')
      let { $store } = $nuxt;
      let companyInfoByPerson = $store.state.user.companyInfoByPerson;

      let path = `${PAGE_PATH.COMPANY.PAY}?id=1&renewId=${companyInfoByPerson.switch.id}`;
      fnRouter.navigationTo(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalContent {
  width: 440px;
  height: 240px;
  background: #FFFFFF;
  border-radius: 14px;

  .title {
    font-size: 20px;
    font-weight: bold;
    color: #424866;
    line-height: 26px;
    text-align: center;
    padding-top: 40px;
  }

  .desc {
    margin: 16px 0 66px;
    text-align: center;
    font-size: 14px;
    color: #596088;
    line-height: 20px;
  }

  .btn-group {
    margin: 0 68px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-item {
      width: 140px;
      height: 44px;
      background: rgba(224, 227, 233, 1);
      border-radius: 25px;
      line-height: 44px;
      text-align: center;

      &:hover {
        background: rgba(224, 227, 233, 0.9);
      }
    }

    .cancel {
    }

    .confirm {
      background: rgba(55, 62, 100, 1);
      color: rgba(255, 229, 206, 1);

      &:hover {
        background: rgba(55, 62, 100, 0.9);
      }
    }

  }

}
</style>
