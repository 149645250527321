<!--
/**
* @Author: wulizhou
* @Description: 创作者中心按钮总封装
* @Date: 2023.4.26
**/
-->


<template>
  <div class="CreatorBtn"
      @click="click"
  >


    <!-- 立即签约 紫色 长圆角   -->

    <div class="btn-item"
         :class="type"
         :style="{
          borderRadius: radius,
          width: width,
          height: height,
          fontSize: fontSize,
        }"
    >
      <slot name="icon"></slot>
      {{ text }}
    </div>


  </div>
</template>

<script>

/* 引入公共方法和常量 */
import * as fnCommon from '@/fn/_fn';
import * as CON_COMMON from '@/constant/_constant';

/* 引入组件 */

/* 引入VUEX */
import {mapState} from "vuex";

/* 引入API */

export default {
  name: "CreatorBtn"

  , components: {}

  , props: {
    radius: {
      type: String,
      default: "0px"
    },
    width: {
      type: String,
      default: "100px"
    },
    height: {
      type: String,
      default: "40px"
    },
    fontSize: {
      type: String,
      default: "22px"
    },
    text: {
      type: String,
      default: "Click me"
    },
    /* 按钮颜色类型 */
    type: {
      type: String,
      default: "0px"
    },
  }

  , watch: {}

  , computed: {}

  , data() {
    return {}
  }

  , methods: {
    click(){
      this.$emit('handClick')
    }
  }

}
</script>

<style lang="scss" scoped>

.btn-item {

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;


  /* 浅紫色按钮 */
  &.purpleLight {
    color: #FFFFFF;
    background: rgba(135, 103, 255, 1);

    &:hover {
      background: #9276FF;
    }
  }

  /* 紫色 */
  &.purple {
    color: #FFFFFF;
    background: #696AFF;


    &:hover {
      background: #7878FF;
    }
  }

  /* 紫色空心 */
  &.purplePlain {
    color: rgba(105, 105, 255, 1);
    background: #FFFFFF;
    border: 1px solid rgba(105, 105, 255, 1);

    &:hover {
      background: rgba(105, 105, 255, 1);
      color: #FFFFFF;
    }
  }


  /* 蓝色 */
  &.blue {
    color: #FFFFFF;
    background: #197AFE;

    &:hover {
      background: #3087FF;
    }
  }

  &.grey {
    background: #D4D7DE;
    color: rgba(10, 37, 64, 1);

    &:hover {
      background: #F6F7F8;
      color: rgba(30, 55, 80, 0.5);
    }
  }


  &.grey2 {
    background: rgba(245, 246, 250, 1);
    color: rgba(10, 37, 64, 1);

    &:hover {
      background: rgba(235, 237, 245, 1);
      color: rgba(66, 72, 102, 1);
    }
  }
}

</style>
