<template>
  <div class="HeaderSearch">
    <div
      class="search-box">
      <input
        ref="input"
        v-model="searchKeyword"
        class="search-text"
        type="text"
        placeholder="搜索全站创意设计"
        @focus="getHotwords(1,true)"
        @blur="toggleShowCombobox(1,false)"
        @keyup.enter="toMaterialList"
        @input="input"
      >

      <i
        v-if="searchKeyword.length"
        class="iconfont icon-cha _pointer"
        @click="clearSearch"
      />
      <div
        class="icon_search-box"
        @click="toMaterialList(0)">
        <i class="iconfont icon-chaxun2 _pointer"/>
      </div>
      <div class="searchComboBox">
        <SearchComboBox
          ref="searchComboBox"
          :recommend-list="recommendList"
          :recommendList2="recommendList2"
          :history-list="historyList"
          @clearSearchHistory="clearSearchHistory"
          @selectSearchKeyword="selectSearchKeyword"
          @listenHotWordClick="listenHotWordClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {SearchComboBox} from "@/components/home/index.js"
import {getHotwords} from "@/api/home";
import {fnFlowStatistics, fnRouter} from "@/fn";
import {PROMOTION_POSITION} from "@/fn/FnFlowStatistics";
import {keywordConvertPinyin, setKeyword} from "@/api/material";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {mapGetters} from "vuex";
import {parseListUrl} from "@/components/list/module";
import * as fnCommon from "@/fn/_fn";

export default {
  name: "HeaderSearch",
  props: {
    isShowSearch: false
  },
  components: {
    SearchComboBox
  },
  computed: {
    ...mapGetters('user', ['isLogin', 'avatar']),
    searchVal() {
      return this.$store.state.base.searchVal
    },
  },

  data() {
    return {
      searchKeyword: '',
      recommendList: [],
      recommendList2: [],
      historyList: [],
    }
  },

  mounted() {
    this.initHistory();
    setTimeout(() => {
      this.searchKeyword = this.searchVal;
    }, 500)
  },

  methods: {
    initHistory() {
      let list = this.$localStorage.getItem('searchKeyword');
      if (list != undefined) {
        this.historyList = list
      }
    },

    async getHotwords(type, flag) {
      let res = await getHotwords({module: 1})
      this.recommendList = res.data.data;

      let res2 = await getHotwords({module: 2})
      this.recommendList2 = res2.data.data;
      console.log(this.recommendList2)
      await this.toggleShowCombobox(type, flag);
      await fnFlowStatistics.promotion(PROMOTION_POSITION.TOP, 0)
    },

    toggleShowCombobox(type, flag) {
      setTimeout(() => {
        if (type === 1) {
          this.$refs.searchComboBox.isShowCombobox = flag
        } else if (type === 2) {
          this.$refs.CopyrightCombobox.isShowCombobox = flag;
        } else if (type === 3) {
          this.$refs.UserCombobox.isShowCombobox = flag;
        }
      }, 500)
    },
    clearSearch() {
      this.searchKeyword = ''
      this.$store.commit('base/SET_SEARCH_VAL', '');
    },

    clearSearchHistory() {
      this.historyList = []
      this.toggleShowCombobox(1, false)
    },
    /* 搜索下拉框选择 */
    selectSearchKeyword(val) {

      this.searchKeyword = val;
      this.$store.commit('base/SET_SEARCH_VAL', val);
      this.toMaterialList();
    },

    listenHotWordClick(vo) {
      fnFlowStatistics.promotion(PROMOTION_POSITION.TOP, vo.id)
      window.open(vo.url, '_blank');

    },

    /* 跳转素材列表页 */
    async toMaterialList(t) {

      await setKeyword({"keyword": this.searchKeyword});

      let tupianRouterId = this.$route.params.id;
      let tempParams = parseListUrl(this.$route.path, tupianRouterId)
      console.log(tempParams)
      console.log(this.$route.params)
      console.log(this.$route.params.id)
      let tempKeyword = this.searchKeyword;  // 待后台设置搜索关键词开启
      this.saveSearchHistory(tempKeyword);     /* 保存搜索记录 */
      /* 获取搜索词 */

      let pinyinData = await keywordConvertPinyin({keyword: tempKeyword});
      let {status, pinyin} = pinyinData.data;
      let tempPinyin = (status === 1 && pinyin !== '') ? pinyin : '';

      let currentPath = this.$route.path;
      if (currentPath.indexOf('/tupian/') > -1) {
        /* 列表页带参数 */
        if (tempParams.categoryType == 0) {
          let path;
          if (tempParams.home_keyword_id == 0) {
            path = tempPinyin == '' ? `${PAGE_PATH.TU_PIAN.LIST}` : `${PAGE_PATH.TU_PIAN.LIST}/${tempPinyin}.html`
          } else {
            path = `${PAGE_PATH.TU_PIAN.LIST}/${tempPinyin}-${tempParams.home_keyword_id}-0-0-0-0-0-default-1-0.html`;
          }
          fnRouter.navigationTo(path, '_self');
        } else {
          let path = `${PAGE_PATH.TU_PIAN.LIST}/${tempPinyin}-0-0-0-0-0-0-default-1-0-${tempParams.design}-1.html`;
          fnRouter.navigationTo(path, '_self');
        }

      } else {
        /* 其他页默认搜索词 */
        let path =  pinyin == '' ? `${PAGE_PATH.TU_PIAN.LIST}` : `${PAGE_PATH.TU_PIAN.LIST}/${pinyin}.html`;
        fnRouter.navigationTo(path, '_self');
      }
      this.toggleShowCombobox(1, false)
      this.$emit('toMaterialList', path);

    },

    /* 保存搜索记录 */
    saveSearchHistory(searchKeyword) {
      searchKeyword = this.$utils.string.trim(searchKeyword);
      if (searchKeyword !== '') {
        this.findStorage('searchKeyword', this.searchKeyword, 'array');
      }
    },

    /* 查找本地搜索关键词，有添加,无则创建 */
    findStorage(key, val, type) {
      if (type === 'array' || type === 1) {
        let temp = this.$localStorage.getItem(key);
        if (!temp) {
          let arr = [];
          arr.push(val);
          this.$localStorage.setItem('searchKeyword', arr);
          this.historyList = arr
        } else {
          temp = temp.filter(vo => vo !== val);
          temp.unshift(val);
          this.$localStorage.setItem('searchKeyword', temp);
          this.historyList = temp
        }
      }
    },

    input() {
      fnCommon.fnBasic.listenSearchInput(this.searchKeyword)
    }
  }
}
</script>

<style lang="scss" scoped>
.HeaderSearch {

  .search-box {
    width: 220px;
    height: 44px;
    position: relative;
    border: 1px solid $color-primary;
    border-radius: 12px !important;
    box-sizing: border-box;

    .searchComboBox {
      @include Position(1, 5, -230px, 56px);

      /deep/ .search-combobox {
        width: 700px !important;

        .MORE {
          display: flex;
        }

        .content {

          .recommend-item {
            margin-right: 16px;

            &:nth-child(3n) {
              margin-right: 0;
            }
          }

        }

        .pic-list {

          .pic-item {
            width: 204px;
            height: 123px;

            &:nth-child(3) {
              margin-right: 0;
            }
          }

        }

        .pic-item-more {
          display: none;
        }
      }

    }


    .search-text {
      //width: 172px;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 0 0 0 rgba(255, 255, 255, 0.4);
      //border: 1px solid transparent;
      //border: 1px solid $color-primary !important;
      padding: 0 80px 0 16px;
      box-sizing: border-box;
      //color: rgba(255, 255, 255, 1);
      //background: rgba(255, 255, 255, 0.14);
      background: #FFFFFF !important;
      //border-radius: 16px;
      border-radius: 12px !important;

      @include Screen(1) {
        padding: 0 54px 0 12px;


      }


      &::placeholder {
        color: rgba(0, 0, 0, 0.3);
      }

      &:focus {
        transition: all 0.5s ease-in-out;
        box-shadow: 0px 2px 4px 0px rgba(105, 105, 255, 0.18);
      }
    }

    .icon-cha {
      @include Position(1, 2, 60px, 50%);
      transform: translateY(-50%);
      font-size: 10px;
      color: rgba(0, 0, 0, 0.3);
      @include ElementHover(rgba(0, 0, 0, 0.5), '');

      @include Screen(1) {
        @include Position(1, 2, 50px, 50%);
        display: none;
      }
    }

    .icon_search-box {
      display: inline-block;
      background-color: $color-primary;
      width: 48px;
      height: 100%;
      border-radius: 12px !important;
      @include Position(1, 2, 0, 50%);
      transform: translateY(-50%);
      @include LayoutFlex(1);
      margin-right: -1px;
      //.icon-search {
      //  @include BgImage(18px, 18px, "home/images/icon-search.svg", 2);
      //}
      .icon-chaxun2 {
        font-size: 16px;
        color: #FFFFFF !important;
      }

      &:hover {
        //background: rgba(255, 255, 255, 0.14);
        box-shadow: inset 0px 0px 0px 0px rgba(255, 255, 255, 0.4);
      }
    }

    @include Screen(1) {
      .search-text {
        &::placeholder {
          font-size: 13px;
        }
      }
    }

  }
}
</style>
