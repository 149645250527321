<template>
  <div class="invite-take">
    免费下载此图&获取更多免费下载
    <div
      class="take"
      @click="navigatorTo"
    >点此获取
    </div>
  </div>
</template>

<script>
import {INVITE_FRIENDS} from "@/constant/PATH";
import {fnInviteFriend} from "~/fn";

export default {
  name: "InviteTake",
  data() {
    return {
      INVITE_FRIENDS
    }
  },
  props: {
    entry: {
      type: String | Number,
      default: 5
    }
  },
  methods: {
    navigatorTo() {
      fnInviteFriend.inviteFriendEntry(this.entry)
    }
  }
}
</script>

<style lang="scss" scoped>
.invite-take {
  width: 470px;
  height: 62px;
  line-height: 62px;
  background: #2A3F52;
  border-radius: 8px;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  color: #35D0BA;
  letter-spacing: 1px;
  padding-left: 17px;
  box-sizing: border-box;
  margin-top: 20px;
  cursor: pointer;

  .take {
    width: 186px;
    height: 62px;
    background: #35D0BA;
    border-radius: 8px;
    @include Position(1, 2, 0, 0);
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    line-height: 62px;
    transition: all 0.3s ease-in;
    @include ElementHover('', #30BBA8);
  }
}

</style>
