export default {
  namespaced: true,
  state(){
    return{
      isShowSurveyUser: false, // 是否显示用户画像dialog
    }
  },
  getters: {},
  mutations: {
    SET_IS_SHOW_SURVEY_USER(state, payload) {
      state.isShowSurveyUser = payload;
    },

  },
  actions: {}
}
