<template>
  <div class="HeaderSwitch"
       v-if=" companyInfoByPerson.is_company_member === 1"

  >
    <!-- 退出切换企业按钮 -->
    <div class="toggle-company-box">
      <template v-if="
      (userVipInfo.company_vip_id > 0 && userVipInfo.business_vip_id > 0 ) || (userVipInfo.company_vip_id > 0 && userVipInfo.personal_vip_id > 0)
      || (companyInfoByPerson.switch && companyInfoByPerson.switch.type != COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.COMPANY) || companyInfoByPerson.switch == null
">
        <div
          class="toggle-company"
          @click="toggleCompany"
        >
          <i class="icon_switch"/>
          切换至<span>
            <span v-if="companyInfoByPerson.switch == null || (companyInfoByPerson.switch && companyInfoByPerson.switch.type == COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.PERSON)">企业  </span>
            <span v-if="(companyInfoByPerson.switch && companyInfoByPerson.switch.type== COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.COMPANY) ">个人</span>
          </span>
        </div>
        <i class="icon_line" />
      </template>

      <div
        class="txt-exit"
        @click="loginOut"
      >退出</div>
    </div>
  </div>
</template>

<script>
import {COMMON} from "@/constant/COMMON";
import {switchMainAccount} from "@/api/company/home";
import {mapState} from "vuex";

export default {
  name: "HeaderSwitch",
  computed:{
    ...mapState('user',['userVipInfo'])
  },
  data(){
    return{
      COMMON
    }
  },
  props:{
    companyInfoByPerson:{
      type: Object,
      default: ()=>{}
    }
  },
  methods:{
    toggleCompany(){
      this.$emit('toggleCompany')
    },

    loginOut(){
      this.$emit('loginOut')
    }
  }
}
</script>

<style lang="scss" scoped>
/* 退出切换企业按钮 */
.toggle-company-box{
  //width: 140px;
  height: 28px;
  background: #FFFFFF;
  border-radius: 14px 0px 0px 14px;
  @include Position(1,2,0,10px);
  z-index: 12;
  display: flex;
  align-items: center;
  padding: 0 2px 0 3px;

  .toggle-company{
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #2D2B33;
    line-height: 16px;

    height: 22px;
    //background: #6969FF;
    border-radius: 13px;
    padding: 0 8px;

    .icon_switch{
      @include BgImage(16px,16px,'header/login/icon_switch.png');
      margin-right: 4px;
    }

    @include ElementHover('', rgba(105, 105, 255, 0.08) )
  }
  .icon_line{
    width: 1px;
    height: 14px;
    background: #CACDDE;
    border-radius: 1px;
    margin: 0 2px;
  }
  .txt-exit{
    font-size: 13px;
    color: #6969FF;
    line-height: 22px;
    padding: 0 8px;
    border-radius: 13px;

    @include ElementHover('', rgba(105, 105, 255, 0.08) )
  }
}
</style>
