export default {
  namespaced: true,
  state() {
    return {
      InvoiceRemindModal: false, // 发票填写提示弹框
      NewUserFreeDownGuideModal: false, // 发票填写提示弹框
      CompanyVipExpiredModal: true, // 企业vip过期或张数使用完毕拦截弹框
      showBlackModal: false, // 黑产账户提示弹框
    }
  },
  mutations: {
    SET_INVOICE_REMIND_MODAL(state, payload) {
      state.InvoiceRemindModal = payload
    },
    SET_NEW_USER_FREE_DOWN_GUIDE_MODAL(state, payload) {
      state.NewUserFreeDownGuideModal = payload
    },
    SET_COMPANY_VIP_EXPIRED_MODAL(state, payload) {
      state.CompanyVipExpiredModal = payload
    },
    SET_BLANK_MODAL(state, payload) {
      state.showBlackModal = payload
    }
  },
  actions: {},
}
