import request from "@/utils/request";
import qs from "qs";

/* 个人企业信息 */
export function getCompanyInfoByPerson(query) {
  return request({
    url: '?m=Company&a=personalCenter',
    method: 'get',
    params: query
  })
}

/* 账号主体切换 */
export function switchMainAccount(data) {
  return request({
    url: '?m=Company&a=switchAccount',
    method: 'post',
    data: qs.stringify(data)
  })
}


/* 公司是否认证 */
export function getCompanyIsVerify(query) {
  return request({
    url: '?m=Company&a=verify',
    method: 'get',
    params: query
  })
}

/* 当前公司认证信息和主体列表 */
export function getCompanyMainList(query) {
  return request({
    url: '?m=Company&a=home',
    method: 'get',
    params: query
  })
}


/* 提交公司和主体认证 */
export function submitCompanyVerify(data) {
  return request({
    url: '?m=Company&a=verify',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 行业列表 */
export function getIndustryList(query) {
  return request({
    url: '?m=Config&a=industry',
    method: 'get',
    params: query
  })
}

/* 城市列表 */
export function getCityList(query) {
  return request({
    url: '?m=Config&a=map',
    method: 'get',
    params: query
  })
}


/* 主体拖拽排序 */
export function submitMainListOrder(data) {
  return request({
    url: '?m=Company&a=swap',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 公司填充主体信息 */
export function getCompanyFillInfo(query) {
  return request({
    url: '?m=Company&a=detail',
    method: 'get',
    params: query
  })
}

/* 企业案例申请列表 */
export function getCompanyCaseRecord(query) {
  return request({
    url: '?m=Company&a=caseApplyDetail',
    method: 'get',
    params: query
  })
}
/* 企业案例提交 */
export function submitCompanyCase(data) {
  return request({
    url: '?m=Company&a=caseApply',
    method: 'post',
    data: qs.stringify(data)
  })
}
/* 企业案例申请进度 */
export function getSubmitCompanyCaseStatus(query) {
  return request({
    url: '?m=Company&a=caseApplyProgress',
    method: 'get',
    params: query
  })
}

/* 用户忽略主体规模和企业vip等级不一致 */
export function updateRejectState(data) {
  return request({
    url: '?m=Company&a=updateRejectState',
    method: 'post',
    data: qs.stringify(data)
  })
}



