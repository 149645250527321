import {MODEL_UPDATE_COUPON_BY_DAY, MODEL_UPDATE_DOWNLOAD_COUNT, MODEL_UPDATE_USER_VIP} from "~/constant/MODELS";
import {DOWNLOAD_COUNT} from "~/constant/USER";
import {getCookie} from "~/utils/auth";
import localStorage from "~/utils/localStorage";
import {toolDate} from "@/tools";
import {VIP_STATE, vipType} from "@/constant/VIP";
import {PAY, PAY_BUSINESS, PAY_COMPANY, PAY_PERSON} from "@/constant/PATH";
import ToolDate from "@/tools/ToolDate";
import {getAccountIsVip} from "~/api/user";

class FnUser {
  constructor() {
  }

  /* 处理Vip */
  disposeVip(data, commit) {
    // console.log('--处理vip--')
    // let {$store} = $nuxt;
    let currentTime = toolDate.getDate().time;
    if ('person_down_vip' in data) {
      let isPersonVip = toolDate.getDate(data.person_down_vip.end_time).time > currentTime ? 1 : 0;  // 检查是否会员是否过期
      commit('SET_vipIsPersonData', data['person_down_vip']);
      commit('SET_vipIsPerson', isPersonVip);
    } else {
      commit('SET_vipIsPerson', 0);
    }
    if ('person_auth_vip' in data) {
      let isBusinessVip = toolDate.getDate(data.person_auth_vip.end_time).time > currentTime ? 1 : 0;  // 检查是否会员是否过期
      commit('SET_vipIsBusinessData', data['person_auth_vip']);
      commit('SET_vipIsBusiness', isBusinessVip);
    } else {
      commit('SET_vipIsBusiness', 0);
    }
  }

  /* 保存会员信息 */
  saveVipInfo(data, commit) {
    // let {$store} = $nuxt;
    let currentTime = toolDate.getDate().time;

    _findVipInfo('person_down_vip', data, vipType.person);    // 个人
    _findVipInfo('person_auth_vip', data, vipType.business);  // 商用
    _findVipInfo('company_vip', data, vipType.company);       // 企业

    /**
     * data查找会员对象，私有方法禁止外部调用
     * @param finData 查找字段
     * @param data  个人会员信息
     * @param TYPE  会员类型
     * @private
     */
    function _findVipInfo(finData, data, TYPE) {
      if (finData in data) {
        let endTime = toolDate.getDate(data[finData].end_time).time;
        let _VIP_STATE = endTime > currentTime ? VIP_STATE.ON : VIP_STATE.OUT;  // 检查是否会员是否过期
        /* 处理过期时间 */
        data[finData].remaining_days = toolDate.getDiffDay(endTime, currentTime);
        console.log(data[finData].remaining_days)
        data[finData].END_TIME = data[finData].end_time.slice(0, 10);
        /* 处理支付链接 */
        addPayUrl(finData, data)

        /* 设置是否是老用户，用户显示分层公告 */


        commit('SET_VIP', {
          TYPE,
          _VIP_STATE,
          VIP_INFO: data[finData]
        });
      } else {
        commit('SET_VIP', {TYPE});
      }

    }

    /* 添加支付链接 */
    function addPayUrl(finData, data) {
      let payId
      if (finData === 'person_down_vip') {
        switch (data[finData].vip) {
          case 5:
            payId = 1;
            break;
          case 4:
            payId = 5;
            break;
          case 3:
            payId = 7;
            break;
          default:
            payId = 1;
        }
        data[finData].PAY_URL = `${PAY}/${payId}`
      }

      if (finData === 'person_auth_vip') {
        switch (data[finData].vip) {
          case 5:
            payId = 2;
            break;
          case 4:
            payId = 6;
            break;
          case 3:
            payId = 4;
            break;
          default:
            payId = 2;
        }
        data[finData].PAY_URL = `${PAY}/${payId}`
      }

      if (finData === 'company_vip') {
        console.log(data[finData].vip)
        // payId = data[finData].type === 3 ? 9 : 10;
        switch (parseInt(data[finData].vip)) {
          case 8:
            payId = 38;
            break;
          case 5:
            payId = 35;
            break;
          case 6:
            payId = 36;
            break;
          case 7:
            payId = 37;
            break;
          default:
            payId = 38;
        }
        data[finData].PAY_URL = `${PAY}/${payId}`
      }
    }

  }


  /* 解析会员信息 */
  parseVipInfo() {
    let day = toolDate.getDate().day;
    console.log(day);
    if (!localStorage.getItemDate(MODEL_UPDATE_USER_VIP)) {
      store.dispatch('user/getUserInfo');
      localStorage.setItemDate(MODEL_UPDATE_USER_VIP, 1);
    } else {
      /*/!* 检测本地是否缓存会员 *!/
      let vipIsPerson = !getCookie('vipIsPerson') ? 0 : getCookie('vipIsPerson');
      let vipIsPersonData = !getCookie('vipIsPersonData') ? {} : JSON.parse(getCookie('vipIsPersonData'));
      let vipIsBusiness = !getCookie('vipIsBusiness') ? 0 : getCookie('vipIsBusiness');
      let vipIsBusinessData = !getCookie('vipIsBusinessData') ? {} : JSON.parse(getCookie('vipIsBusinessData'));
      store.commit('user/SET_vipIsPerson', vipIsPerson);
      store.commit('user/SET_vipIsPersonData', vipIsPersonData);
      store.commit('user/SET_vipIsBusiness', vipIsBusiness);
      store.commit('user/SET_vipIsBusinessData', vipIsBusinessData);*/

    }
  }

  /* 处理下载次数(免费下载次数等) */
  disposeDownload(downloadData) {
    // console.log('--处理下载次数--')
    let {$store} = $nuxt;
    let {free_down_cnt, down_cnt, day_free_limit, today_free_down} = downloadData;
    if (free_down_cnt != null && down_cnt != null) {
      $store.commit('user/SET_DOWNLOAD_COUNT', {
        free_down_cnt,
        down_cnt,
        day_free_limit, today_free_down,
        free_remain: free_down_cnt - down_cnt
      })
    }
  }

  /* 日更新用户累计下载次数 */
  updateDownloadCountByDay(store) {


    let downloadCurrIsUpdate = localStorage.getItemDate(MODEL_UPDATE_DOWNLOAD_COUNT)
    if (downloadCurrIsUpdate == undefined) {
      /* 每日更新一次 */
      store.dispatch('user/getMaterialFreeDownloadNum');
      setTimeout(() => {
        store.commit('home/SET_IS_SHOW_GUIDE_DOWNLOAD_COUNT', true)
      }, 600)
      localStorage.setItemDate(MODEL_UPDATE_DOWNLOAD_COUNT, 1);
    } else {
      let downloadCount = getCookie('downloadCount');
      if (downloadCount != undefined) {
        store.commit('user/SET_DOWNLOAD_COUNT', JSON.parse(downloadCount));
      } else {
        store.commit('user/SET_DOWNLOAD_COUNT', DOWNLOAD_COUNT);
      }
    }

    /* 每日更新优惠券 */
    let updateCouponByDay = localStorage.getItemDate(MODEL_UPDATE_COUPON_BY_DAY)
    if (updateCouponByDay == undefined) {
      setTimeout(() => {
        store.dispatch('user/getCouponList');
        localStorage.setItemDate(MODEL_UPDATE_COUPON_BY_DAY, 1);
      }, 600)
    }

  }

  /* 获取会员到期时间 */
  getVipEndTime() {
    let personVip = getCookie('vipIsPerson')
    let businessVip = getCookie('vipIsBusiness')
    if (personVip == 1 && businessVip == 1) {

      let personVipEndTime = toolDate.getDate(JSON.parse(getCookie('vipIsPersonData')).end_time).time
      let businessVipEndTime = toolDate.getDate(JSON.parse(getCookie('vipIsBusinessData')).end_time).time;
      if (personVipEndTime > businessVipEndTime) {
        return JSON.parse(getCookie('vipIsPersonData')).end_time.slice(0, 10)
      } else {
        return JSON.parse(getCookie('vipIsBusinessData')).end_time.slice(0, 10)
      }
    } else if (personVip == 1) {
      return JSON.parse(getCookie('vipIsPersonData')).end_time.slice(0, 10)
    } else if (businessVip == 1) {
      return JSON.parse(getCookie('vipIsBusinessData')).end_time.slice(0, 10)
    }

  }


  /*
  *   检查是否是会员
  *   已弃用
  * */
  checkIsVip() {
    let personVip = getCookie('vipIsPerson');
    let businessVip = getCookie('vipIsBusiness');
    let tempType = 0
    if (personVip == 1 || businessVip == 1) {
      return true;
    }
    return false;
  }


  /* 检查是否是会员 新版 */
  checkUserIsVip() {
    let personVipState = getCookie('personVipState')
    let businessVipState = getCookie('businessVipState')
    let companyVipState = getCookie('companyVipState')
    return personVipState === VIP_STATE.ON || businessVipState === VIP_STATE.ON || companyVipState === VIP_STATE.ON;
  }

  checkUserIsExpire() {
    let personVipState = getCookie('personVipState')
    let businessVipState = getCookie('businessVipState')
    let companyVipState = getCookie('companyVipState')
    return personVipState === VIP_STATE.OUT || businessVipState === VIP_STATE.OUT || companyVipState === VIP_STATE.OUT;
  }

  /* 即将到期 */
  checkUserIsAboutToExpire() {
    let personVipState = getCookie('personVipState')
    let personVipInfo = JSON.parse(getCookie('personVipInfo'))
    let businessVipState = getCookie('businessVipState')
    let businessVipInfo = JSON.parse(getCookie('businessVipInfo'))
    let companyVipState = getCookie('companyVipState')
    let companyVipInfo = JSON.parse(getCookie('companyVipInfo'))
    // return personVipState === VIP_STATE.OUT || businessVipState === VIP_STATE.OUT || companyVipState === VIP_STATE.OUT;
    let arr = [];


    if (personVipState === VIP_STATE.ON) {
      let remaining_days = personVipInfo.remaining_days;
      let data = vipNoticeTip(remaining_days, '普通VIP', PAY_PERSON, 1);
      arr = arr.concat(data)
    }

    if (businessVipState === VIP_STATE.ON) {
      let remaining_days = businessVipInfo.remaining_days;
      console.log(remaining_days)
      let data = vipNoticeTip(remaining_days, '商用VIP', PAY_BUSINESS, 2);
      arr = arr.concat(data)
    }

    if (companyVipState === VIP_STATE.ON) {
      let remaining_days = companyVipInfo.remaining_days;
      let data = vipNoticeTip(remaining_days, '企业VIP', PAY_COMPANY, 3);
      arr = arr.concat(data)
    }

    return arr;


    function vipNoticeTip(remaining_days, vipType, url, id) {
      let dataArr = [7, 3, 2, 1],
        noticeData = [];

      /*dataArr.forEach((vo, idx) => {

      })*/

      if (remaining_days <= 7 && remaining_days > -1) {
        noticeData.push({
          vipType: vipType,
          url: url,
          endDay: remaining_days,
          id
        })
      }

      return noticeData;
    }
  }

  checkUserIsNoVip() {
    let personVipState = getCookie('personVipState')
    let businessVipState = getCookie('businessVipState')
    let companyVipState = getCookie('companyVipState')
    return personVipState === VIP_STATE.NO && businessVipState === VIP_STATE.NO && companyVipState === VIP_STATE.NO;
  }


  /**
   * 用户注册天数
   * @param day
   */
  checkUserRegisterDay(day) {
    let userInfo = JSON.parse(getCookie('userInfo'));
    console.log(ToolDate.getDate(userInfo.create_time).time)
    console.log(ToolDate.getDate().time)
    return ToolDate.getDiffDay(ToolDate.getDate(userInfo.create_time).time, ToolDate.getDate().time)
  }

  /* 获取用户下载数据 */
  getUserDownloadCount() {
    /*day_free_limit, // 日最大下载数量
     today_free_down, // 日下载数量
     down_cnt, // 已下载数量
     free_down_cnt, // 最大下载数量
     free_remain, // 总剩余下载数量
     */
    return JSON.parse(getCookie('downloadCount'));
  }


  /* 检查用户非正常会员(不包含体验会员(8)) */
  checkUserVipExcludeFree() {
    let personVipState = getCookie('personVipState')
    let personVipInfo = JSON.parse(getCookie('personVipInfo'))
    let businessVipState = getCookie('businessVipState')
    let companyVipState = getCookie('companyVipState')

    /* 过滤体验vip */
    if (personVipState !== VIP_STATE.ON && personVipInfo.vip == 1) {
      return true;
    }

    return personVipState === VIP_STATE.NO && businessVipState === VIP_STATE.NO && companyVipState === VIP_STATE.NO;
  }

  /* 检查是否是会员账号 */
  async getAccountIsVip() {
    let res = await getAccountIsVip();
    let {
      business_vip_id,
      company_vip_id,
      personal_vip_id
    } = res.data.data;
    return business_vip_id != 0 || company_vip_id != 0 || personal_vip_id != 0
  }
}

export default new FnUser();
