import Vue from "vue";


/* 处理图片详情文件格式 */
Vue.filter("disposePicLayout", (value) => {
  if (Array.isArray(value)) {
    return value.join(' / ')
  } else {
    return value;
  }
});

