import {localCookies} from '~/constant/COOKIES.js'
import {DOWNLOAD_COUNT} from "~/constant/USER";
import {fnBindIphone, fnLocalStorage, fnMaterial, fnUser, fnUserSurvey} from '~/fn'
import {toolDate} from "@/tools";
import localStorage from "~/utils/localStorage";
import myNotify from "@/utils/notify";
import iphoneMyNotify from "@/utils/notify-h5";
import {getCookie, removeCookie, setCookie} from "@/utils/auth";
import {
  MODEL_EXPIRATION_TIME, MODEL_INTRODUCE_DIALOG,
  MODEL_LOGIN_PV_COUNT,
  MODEL_PV_COUNT, MODEL_UPDATE_BY_DAY,
  MODEL_UPDATE_USER_VIP, MODEL_USER_CHECK_PROTOCOL,
  prefix
} from '@/constant/MODELS'
import {DEVICE} from "@/constant/SYSTEM";
import {wechatShare} from "../plugins/wechat";
import {vipType} from "@/constant/VIP";
import {
  initPicFreeDownCouponModal,
  setPicFreeDownCouponModal
} from "~/components/_Privately/Pic/PicFreeDownCouponModal/module";
import {checkUserIsVip} from "@/fn/_Privately/User";
import {checkUserIsAgreeNewProtocol, getAccountIsVip} from "@/api/user";
import {
  setIsNeedShowUserCertificationModal,
  setUserBindIphoneModalStatus
} from "@/components/_Privately/User/UserBindIphoneModal/module";
import {
  setCertificationModalStatus,
  setCertificationStatus
} from "@/components/_Privately/User/UserCertificationModal/module";
import {setUserBindIphoneAloneModalStatus} from "@/components/_Privately/User/UserBindIphoneAloneModal/module";
import {setUserCheckNewProtocol20240426} from "@/components/_Privately/Act/updateProtocol/module";

class FnLogin {
  constructor() {
    this.loginByQQTimer = null; // qq登录轮询登录定时器
    this.loginByQQWindow = 'loginByQQWindow';  // qq登录window名字
  }

  showLogin() {
    /*if (this.checkLogin()){
      myNotify.show('账户已登录', () => {
        // let currRouter = this.$route.path;
        window.location.href = this.path;
      },2500);
      return false;
    }*/
  }

  checkLogin() {
    let auth_id = getCookie('auth_id');
    return !!auth_id;
  }

  /* 登录拦截 */
  loginIntercept(isShowMsg = true) {
    if (!this.checkLogin()) {
      let {$store} = $nuxt;
      let path = $store.state.user.path

      if (isShowMsg) {
        myNotify.show('请先登录', () => {
          $store.commit("user/SET_IS_SHOW_LOGIN", true);
        }, 1000)
      } else {
        $store.commit("user/SET_IS_SHOW_LOGIN", true);
      }
      return false
    }
    return true
  }

  /* 清除本地cookie */
  clearCookies(store) {
    // 清空 cookie
    removeCookie(localCookies);
    // 清空vuex
    // let store = $nuxt.$store;
    store.commit('user/SET_LOGIN', false);
    store.commit('user/SET_vipIsPerson', 0);
    store.commit('user/SET_vipIsBusiness', 0);
    store.commit('user/SET_vipIsPersonData', {});
    store.commit('user/SET_vipIsPersonData', {});
    store.commit('user/SET_USER_INFO', {});
    store.commit('user/SET_UNIQ_ID', '');
    store.commit('user/SET_PHONE', 0);
    store.commit('user/SET_IS_HAS_USER_COUPON', false);
    store.commit('user/SET_DOWNLOAD_COUNT', DOWNLOAD_COUNT);
    store.commit('system/SET_DEVICE', DEVICE.iphone);
    store.commit('user/SET_VIP', {'TYPE': 'person_down_vip', '_VIP_STATE': 0, 'VIP_INFO': {}});
    store.commit('user/SET_VIP', {'TYPE': 'person_auth_vip', '_VIP_STATE': 0, 'VIP_INFO': {}});
    store.commit('user/SET_VIP', {'TYPE': 'company_vip', '_VIP_STATE': 0, 'VIP_INFO': {}});
    store.commit('user/SET_USER_INFO', {
      business_vip_id: 0,
      company_vip_id: 0,
      personal_vip_id: 0
    });


  }

  /* 是否多设备登录 */
  isMultiDevice(store) {
    const MULTI_LOGIN_FLAG = getCookie('MULTI_LOGIN_FLAG');
    if (!!MULTI_LOGIN_FLAG && parseInt(MULTI_LOGIN_FLAG) === 1) {
      myNotify.error('您的账号已在其他地方登陆。若非本人操作，请注意账号安全。', () => {
        removeCookie('MULTI_LOGIN_FLAG');
        store.dispatch('user/loginOut');
      })
    } else {
      // console.log('--未在其他终端设备登录--');
    }
  }

  async updateUserVipAll(){
    const res = await getAccountIsVip();
    const {status, data} = res.data;
    if (status === 200) {
      // 更新用户信息
      console.log('更新用户信息ALL')
      store.commit('user/SET_USER_VIP_INFO',data)
    }
  }

  async updateByDay(app) {
    let {store, router} = app;
    await fnMaterial.updateMaterial()

    if (!fnLocalStorage.getItemDate(MODEL_UPDATE_BY_DAY)) {
      await fnMaterial.updateMaterial();
      // store.dispatch('user/GetUserVipInfo')
      fnLocalStorage.setItemDate(MODEL_UPDATE_BY_DAY, 1)

      store.dispatch('user/getUserInfo');
      store.dispatch('user/getCompanyInfoByPerson');

      /* 更新用户vip */
      // this.updateUserVipAll();

    }
  }

  customGtag() {
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      dataLayer.push(arguments);
    }

    gtag('js', new Date());
    gtag('config', 'G-EDQ6DT5ZYH');
  }

  setHbScript() {
    // 创建 script 元素
    var scriptElement = document.createElement('script');

// 设置 script 元素的属性
    scriptElement.id = 'huaban_share_script';
    scriptElement.async = true;
    scriptElement.charset = 'utf-8';
    scriptElement.setAttribute('data-style', 'outside');
    scriptElement.setAttribute('data-color', 'red');
    scriptElement.setAttribute('data-size', 'large');
    scriptElement.setAttribute('data-position', 'top right');
    scriptElement.setAttribute('data-image-min-size', ' ');
    scriptElement.src = 'https://huaban.com/js/widgets.min.js';

// 将 script 元素插入到 body 中
    document.body.appendChild(scriptElement);


    setTimeout(() => {
      // 获取具有指定 id 的元素
      var widgetsContainer = document.getElementById('HUABAN_WIDGETS');

      // 检查是否找到了具有指定 id 的元素
      if (widgetsContainer) {
        // 获取具有指定类名的元素
        var buttonElements = widgetsContainer.getElementsByClassName('HUABAN-f-button');

        // 检查是否找到了具有指定类名的元素
        if (buttonElements.length > 0) {
          // 修改第一个符合条件的元素的文本内容
          buttonElements[0].innerText = '采集到花瓣';

          buttonElements.map(item => {
            item.innerText = '采集到花瓣';
          })
          // buttonElements[0].style.width = '200px';
          // buttonElements[0].style.height = '60px';
          // buttonElements[0].style.borderRadius = '5px';
          // buttonElements[0].style.display = 'block ';
          // buttonElements[0].style.background = 'red ';
          // buttonElements[0].style.backgroundColor = 'red !important ';
        } else {
          console.error('找不到具有指定类名的元素');
        }
      } else {
        console.error('找不到具有指定 id 的元素');
      }
    }, 100)

  }

  checkUserAgreeUpdateProtocol(store){
    /* 检测用户是否阅读新的用户协议 */
    if (fnLocalStorage.getItemDate(MODEL_USER_CHECK_PROTOCOL) == undefined){
      checkUserIsAgreeNewProtocol({type:1}).then(res =>{
        const { status,data } = res.data;
        console.log({
          status,data
        })
        if (status == 200){
          if (data.code == 200 && data.alert == true){
            setUserCheckNewProtocol20240426(store, true)
          }else{
            fnLocalStorage.setItemDate(MODEL_USER_CHECK_PROTOCOL,1)
            setUserCheckNewProtocol20240426(store, false)

          }
        }
      })
    }

  }

  /**
   * 登录状态监听
   * @param { object } store 本地vuex
   */
  listen(app) {
    // this.customGtag();
    let {store, router} = app;
    this.isMultiDevice(store);
    this.clearLocalStorage();
    this.weChatShare(store);


    store.commit('home/SET_IS_SHOW_GUIDE_DOWNLOAD_COUNT', false);

    /* 每日仅执行一次 */
    this.updateByDay(app)


    /* 检查收藏成功是否弹出 */
    store.commit('favorites/SET_SHOW_COLLECT_SUCCESS_DIALOG_TIMER', false);
    store.commit('favorites/SET_SHOW_COLLECT_SUCCESS_DIALOG', false)
    store.commit('favorites/SET_SHOW_DIALOG_BY_COMPANY', false)


    /* 初始化 Creator */
    store.commit("Creator/SET_IS_SHOW_SIGN_EXPIRE_MODAL", !getCookie("isShowSignExpireModal") ? false : JSON.parse(getCookie("isShowSignExpireModal")))
    store.commit("Creator/SET_SIGN_RENEW_LINK", getCookie("signRenewLink"))


    store.commit('Modal/SET_INVOICE_REMIND_MODAL', false)
    store.commit('Modal/SET_NEW_USER_FREE_DOWN_GUIDE_MODAL', false)
    store.commit('Modal/SET_COMPANY_VIP_EXPIRED_MODAL', false)
    store.commit('Modal/SET_BLANK_MODAL', false)

    store.commit('user/SET_IS_SHOW_BIND_IPHONE_MODAL', false)

    store.commit('_Public/Header/setHeaderHeight',80)

    setPicFreeDownCouponModal(store, false);
    setUserBindIphoneModalStatus(store, false)
    setIsNeedShowUserCertificationModal(store, false)
    setCertificationModalStatus(store, false)
    setCertificationStatus(store, false)
    setUserBindIphoneAloneModalStatus(store, false)
    setUserCheckNewProtocol20240426(store, false)


    /* 登录将更新状态 */
    if (this.checkLogin()) {
      store.commit('user/SET_LOGIN', this.checkLogin());
      store.commit('user/SET_IS_SHOW_LOGIN', false);
      store.commit('user/SET_IS_SHOW_LOGIN_HOME', false);
      store.commit('user/SET_PHONE', getCookie('phone'));
      store.commit('system/SET_DEVICE', getCookie('device') || DEVICE.iphone);
      let tem_user_uniq_id;
      if (!!getCookie('user_uniq_id')) {
        tem_user_uniq_id = getCookie('user_uniq_id').replace(/"/g, '');
      }
      store.commit('user/SET_UNIQ_ID', tem_user_uniq_id);

      store.commit('user/SET_IS_HAS_USER_COUPON', parseInt(getCookie('isHasUserCoupon')));
      store.commit('user/SET_USER_INFO', !getCookie('userInfo') ? {} : JSON.parse(getCookie('userInfo')));
      store.commit('user/SET_USER_VIP_INFO', !getCookie('userVipInfo') ? {
        business_vip_id: 0,
        company_vip_id: 0,
        personal_vip_id: 0
      } : JSON.parse(getCookie('userVipInfo')));
      this.updateUserVip(store);
      fnUser.updateDownloadCountByDay(store);
      this.listenLoginPvCount(store)

      store.commit('pic/SET_DownloadInterestModal', !getCookie('DownloadInterestModal') ? {
        isShow: false,  // 显示
        isOpenTimer: false, // 是否开启定时器
        upgradeLink: '' // 设置升级跳转链接
      } : JSON.parse(getCookie('DownloadInterestModal')));

      this.checkUserAgreeUpdateProtocol(store);

    } else {
      this.clearCookies(store);
      // this.listenPvCount(store);
      this.listenPageStart(store, router);
      /* 未登录弹介绍弹框 */
      // this.listenIntroduceDialog(store);
      store.commit('system/SET_DEVICE', DEVICE.iphone);

      /* 重置会员信息 */
      store.commit('user/SET_VIP', {
        TYPE: vipType.person,
        _VIP_STATE: 0,
        VIP_INFO: {}
      });
      store.commit('user/SET_VIP', {
        TYPE: vipType.business,
        _VIP_STATE: 0,
        VIP_INFO: {}
      });
      store.commit('user/SET_VIP', {
        TYPE: vipType.company,
        _VIP_STATE: 0,
        VIP_INFO: {}
      });
    }
    store.commit('base/SET_SEARCH_VAL', getCookie('searchVal') || '');
  }

  /* 控制介绍弹框显示 */
  listenIntroduceDialog(store, router) {
    store.commit('user/SET_IS_SHOW_LOGIN', false);
    let isShow = fnLocalStorage.getItemDate(MODEL_INTRODUCE_DIALOG);
    if (!isShow) {
      store.commit('base/SET_INTRODUCE_DIALOG', true);
    } else {
      store.commit('base/SET_INTRODUCE_DIALOG', false);
      this.listenPageStart(store, router)
    }
  }

  /* 检查是否 新用户登录免费下载引导提示弹框 */

  listenNewUserFreeDownGuideModal() {
    let {$store} = $nuxt;
    let registerDay = fnUser.checkUserRegisterDay()
    if (parseInt(registerDay) === 1) {
      $store.commit('Modal/SET_NEW_USER_FREE_DOWN_GUIDE_MODAL', true)
    } else {
      $store.commit('Modal/SET_NEW_USER_FREE_DOWN_GUIDE_MODAL', false)
    }

  }


  /**
   * 微信分享配置
   * @param store
   */
  weChatShare(store) {
    let currentPath = store.state.user.path
    if (currentPath == '/' || currentPath == '') {
      let url = 'https://chaopx.com/';
      wechatShare(['updateAppMessageShareData', 'updateTimelineShareData'], {url})
    }

  }


  // 每日更新用户会员信息
  updateUserVip(store) {


    let day = toolDate.getDate().day;
    if (!localStorage.getItemDate(MODEL_UPDATE_USER_VIP)) {
      store.dispatch('user/getUserInfo');
      localStorage.setItemDate(MODEL_UPDATE_USER_VIP, 1);
    } else {
      /* 检测本地是否缓存会员 */
      let vipIsPerson = !getCookie('vipIsPerson') ? 0 : getCookie('vipIsPerson');
      let vipIsPersonData = !getCookie('vipIsPersonData') ? {} : JSON.parse(getCookie('vipIsPersonData'));
      let vipIsBusiness = !getCookie('vipIsBusiness') ? 0 : getCookie('vipIsBusiness');
      let vipIsBusinessData = !getCookie('vipIsBusinessData') ? {} : JSON.parse(getCookie('vipIsBusinessData'));
      store.commit('user/SET_vipIsPerson', vipIsPerson);
      store.commit('user/SET_vipIsPersonData', vipIsPersonData);
      store.commit('user/SET_vipIsBusiness', vipIsBusiness);
      store.commit('user/SET_vipIsBusinessData', vipIsBusinessData);

      /* 新版会员配置更新 */

      if (getCookie('personVipState') !== 0) {
        // let isEmptyObj = Object.values(obj).length === 0 // true
        store.commit('user/SET_VIP', {
          TYPE: vipType.person,
          _VIP_STATE: getCookie('personVipState'),
          // VIP_INFO: JSON.parse(getCookie('personVipInfo'))
          VIP_INFO: !getCookie('personVipInfo') ? {} : JSON.parse(getCookie('personVipInfo'))
        });
      } else {
        store.commit('user/SET_VIP', {
          TYPE: vipType.person,
          _VIP_STATE: 0,
          VIP_INFO: {}
        });
      }

      if (getCookie('businessVipState') !== 0) {
        store.commit('user/SET_VIP', {
          TYPE: vipType.business,
          _VIP_STATE: getCookie('businessVipState'),
          // VIP_INFO: JSON.parse(getCookie('businessVipInfo'))
          VIP_INFO: !getCookie('businessVipInfo') ? {} : JSON.parse(getCookie('businessVipInfo'))
        });
      } else {
        store.commit('user/SET_VIP', {
          TYPE: vipType.business,
          _VIP_STATE: 0,
          VIP_INFO: {}
        });
      }

      if (getCookie('companyVipState') !== 0) {
        store.commit('user/SET_VIP', {
          TYPE: vipType.company,
          _VIP_STATE: getCookie('companyVipState'),
          // VIP_INFO: JSON.parse(getCookie('companyVipInfo'))
          VIP_INFO: !getCookie('companyVipInfo') ? {} : JSON.parse(getCookie('companyVipInfo'))
        });
      } else {
        store.commit('user/SET_VIP', {
          TYPE: vipType.company,
          _VIP_STATE: 0,
          VIP_INFO: {}
        });
      }
    }
  }

  /* 登录pv */
  async listenLoginPvCount(store) {
    let pvCount = localStorage.getItemDate(MODEL_LOGIN_PV_COUNT);
    if (!pvCount) {
      localStorage.setItemDate(MODEL_LOGIN_PV_COUNT, 1)
    } else {
      pvCount++;
      localStorage.setItemDate(MODEL_LOGIN_PV_COUNT, pvCount)
    }


    /* pv第五次检查用户是否绑定手机 */
    if (pvCount == 5) {
      const phone = getCookie('phone');
      const isPhoneBound = (phone == '' || phone == '0');
      setUserBindIphoneAloneModalStatus(store, isPhoneBound)
    } else {
      setUserBindIphoneAloneModalStatus(store, false)
    }

    /* pv == 6 弹出免费下载红包弹框 */
    if (pvCount == 6) {
      try {
        const res = await getAccountIsVip();
        const {status, data} = res.data;
        if (status === 200) {
          const {business_vip_id = 0, company_vip_id = 0, personal_vip_id = 0} = data;
          if (business_vip_id === 0 && company_vip_id === 0 && personal_vip_id === 0) {
            setPicFreeDownCouponModal(store, true);
          }
        }
      } catch (error) {
      }
    } else {
      setPicFreeDownCouponModal(store, false);
    }


    if (pvCount > 14) {
      fnUserSurvey.checkIsWrite(store);
    } else {
      store.commit('surveyUser/SET_IS_SHOW_SURVEY_USER', false, {root: true});
    }


  }

  listenPageStart(store, router) {

    /* 排除弹框登录路由 */
    let excludeRouter = ['/act/rabbit'];
    for (let i = 0; i < excludeRouter.length; i++) {
      if (store.state.user.path.indexOf(excludeRouter[i]) > -1) {
        store.commit('user/SET_IS_SHOW_LOGIN', false);
        return false;
      }
    }

    setTimeout(() => {
      store.commit('user/SET_IS_SHOW_LOGIN', true);
      store.commit('user/SET_IS_SHOW_LOGIN_CLOSE_BTN', true);
      let pvCount = localStorage.getItemDate(MODEL_PV_COUNT);
      if (!pvCount) {
        pvCount = 1;
        localStorage.setItemDate(MODEL_PV_COUNT, 1)
      } else {
        pvCount++;
        localStorage.setItemDate(MODEL_PV_COUNT, pvCount)
      }
      /*if (pvCount <= 5) {
        store.commit('user/SET_IS_SHOW_LOGIN_CLOSE_BTN', true);
      } else {
        setInterval(() => {
          store.commit('user/SET_IS_SHOW_LOGIN_CLOSE_BTN', true);
        }, 5000)
      }*/
    }, 10000)
  }

  /**
   *  pv3次设置登录
   * @param store
   */
  listenPvCount(store) {
    let pvCount = localStorage.getItemDate(MODEL_PV_COUNT);
    if (!pvCount) {
      localStorage.setItemDate(MODEL_PV_COUNT, 1)
    } else {
      pvCount++;
      localStorage.setItemDate(MODEL_PV_COUNT, pvCount)
    }

    /* 排除弹框登录路由 */
    let excludeRouter = ['/act/rabbit'];


    for (let i = 0; i < excludeRouter.length; i++) {
      if (store.state.user.path.indexOf(excludeRouter[i]) > -1) {
        return false;
      }
    }


    // let pvCountWhere = pvCount == 2 || (pvCount > 2 && (pvCount - 2) % 2 == 0);
    if (pvCount == 2 || pvCount == 4 || pvCount >= 6) {
      store.commit('user/SET_IS_SHOW_LOGIN', true);
      store.commit('user/SET_IS_SHOW_LOGIN_CLOSE_BTN', false);
      if (pvCount == 2 || pvCount == 4) {
        store.commit('user/SET_IS_SHOW_LOGIN_CLOSE_BTN', true);
      } else {
        setInterval(() => {
          store.commit('user/SET_IS_SHOW_LOGIN_CLOSE_BTN', true);
        }, 10000)
      }
    } else {
      store.commit('user/SET_IS_SHOW_LOGIN', false);
      store.commit('user/SET_IS_SHOW_LOGIN_CLOSE_BTN', false);
    }
  }

  loginByQQ(store, refer = '', type) {
    let link = 'https://api.chaopx.com/?m=Api&a=snsLogin&type=qq';
    if (refer != '') {
      link = `${link}&refer=${refer}`
    }
    this.loginByQQWindow = window.open(link, "QQLoginDialog", "width=750, height=500, top=50, left=50");
    this.getLoginByQQStatus(store, type);
  }

  /* QQ登录查询 */
  getLoginByQQStatus(store, type) {

    return new Promise((resolve, reject) => {
      let second = 60
      this.loginByQQTimer = window.setInterval(() => {
        second--;
        if (this.checkLogin()) {
          window.clearInterval(this.loginByQQTimer);
          if (type === 'H5') {
            iphoneMyNotify.success('QQ登录成功', () => {
              store.dispatch('user/LoginSuccessHandle');
              store.commit('system/SET_DEVICE', DEVICE.qq);
              store.commit('user/SET_IS_SHOW_LOGIN_BY_IPHONE', false)
            })
          } else {
            myNotify.success('QQ登录成功', () => {
              store.dispatch('user/LoginSuccessHandle');
              store.commit('system/SET_DEVICE', DEVICE.qq)
            })
          }
          resolve({
            status: 1,
            msg: '登录成功'
          })
        }
        if (second < 1) {
          window.clearInterval(this.loginByQQTimer);
          reject({
            status: 0,
            msg: '登录失败'
          })
          this.loginByQQWindow.close()
        }
      }, 2000)
    })
  }

  /* 清空关闭qq登录查询 */
  clearListenLoginByQQ() {
    window.clearInterval(this.loginByQQTimer);
    if (this.loginByQQWindow && !this.loginByQQWindow.closed) {
    }
    try {
      this.loginByQQWindow.close();
    } catch (e) {
    }
  }


  /**
   * 清空本地过期 localStorage
   * @returns {boolean}
   */
  clearLocalStorage() {

    /* 清除所有 _MODEL 开头的 storage */


    let expirationTime = toolDate.getDate(toolDate.getNextDate()).day;
    let local = localStorage.getItemAll()
    let removeStorage = []
    let oldStorage = [];

    if (JSON.stringify(local) === '{}') {
      return false
    }
    for (const key in local) {
      if (key.indexOf('_MODEL_') > -1) {
        oldStorage.push(key)
      }

      if (key.indexOf(prefix) > -1) {
        removeStorage.push(key)
      }
    }

    if (oldStorage.length) {
      oldStorage.forEach(vo => {
        localStorage.remove(vo);
      })
    }

    if (removeStorage.length) {
      removeStorage.forEach((vo) => {
        let a = vo.split('_')
        let _date = a[a.length - 1]

        if (toolDate.isSameDay(_date)) {
        } else {
          localStorage.remove(vo);
        }
        /* if (a[a.length - 1] < expirationTime) {
           localStorage.remove(vo);
         }*/
      })
    }
  }
}

export default new FnLogin();
