<template>
  <div class="UserCenter">
    <div class="inner_center">
      <div class="menu">
        <UserCenterMenu :record-menu="menu"
        ></UserCenterMenu>
      </div>
      <div class="content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import UserCenterMenu from "@/components/_Privately/User/UserCenter/UserCenterMenu";
import {menu} from "@/components/_Privately/User/UserCenter/UserCenterMenu/module.js"
import {fnLogin} from "@/fn";
import {getAuthList} from "@/api/exchange-code";
import {recordMenu} from "@/components/user-center/common/module";

export default {
  name: "UserCenter",
  components: {
    UserCenterMenu
  },
  data() {
    return {
      menu
    }
  },
  mounted() {
    this.getAuthList();
  },
  methods:{
    async getAuthList() {

      if (!fnLogin.checkLogin()){
        return false;
      }
      let {data} = await getAuthList()
      this.isShowExchange = data.data.check;
      if (data.data.check) {
        this.menu = menu.slice(0)
      } else {
        this.menu = menu.filter(vo => vo.id != 6)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.inner_center {
  width: 1200px !important;
  display: flex;

  .menu {

  }

  .content {
    flex: 1;
  }
}
</style>
