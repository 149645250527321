<template>
  <div class="CompanySidebar _user-select">
    <ul class="sidebar-section">
      <li
        v-for="vo in sidebarList"
        :key="vo.id">

        <!-- 菜单 -->
        <div
          ref="sidebarItem"
          :class="['sidebar-item',{ 'ON': vo.id === sidebarId }]"
          @click="toggleSidebar(vo)">
          <i :class="['iconfont2',vo.iconClass]"/>
          <span>{{ vo.label }}</span>
          <i
            v-if="vo.children && vo.children.length"
            class="iconfont2 icon-qy-arrow-down-line"
            :class="[ {'icon-qy-arrow-down-line2':vo.collapse}]"
            @click.stop="vo.collapse = !vo.collapse"
          />
        </div>

        <!-- 子菜单 -->
        <ul
          :class="[ vo.collapse ? 'ON': 'NO' ]"
          class="sidebar-item-wrap">
          <li
            v-for="subItem in vo.children"
            :key="subItem.id"
            :class="['sidebar-item','sub-sidebar-item',{ 'ON': subItem.id === subSidebarId }]"
            @click="toggleSubSidebar(vo,subItem)">
            <i :class="['iconfont2',subItem.iconClass]"/>
            <span>{{ subItem.label }}</span>
            <i class="iconfont2 icon-qy-arrow-down-line"/>
            <span class="join-num" v-if="subItem.id == 33 && joinNum > 0">{{ joinNum }}</span>
          </li>
        </ul>
      </li>
      <!-- 选中背景 -->
      <div class="sidebar-item-bar" :style="'top:'+ sidebarTop"/>
    </ul>

    <div
      @click="toCaseCollection"
      class="case-collect-buoy"></div>

  </div>
</template>

<script>
import {SIDEBAR_LIST} from "@/constant/COMPANY_MANAGE";

import {fnRouter} from "@/fn";
import {mapGetters, mapState} from "vuex";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {COMMON} from "@/constant/COMMON";
import {getJoinNoReadNum} from "@/api/company/team-manage";

export default {
  name: "CompanySidebar",
  data() {
    return {
      sidebarList: [],
      sidebarId: 0,     // 菜单选中id
      subSidebarId: -1, // 子菜单选中id
      sidebarTop: 0,    // 选中bar距离顶部位置
      // joinNum: 0, // 申请加入数量
      PAGE_PATH
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState('user',
      [
        'companyInfoByPerson',
      ]
    ),
    ...mapState('company',
      [
        'joinNum',
      ]
    ),
  },
  watch: {
    sidebarId: {
      handler(n, o) {
        this.listenSidebarTop(n);
      },
    },
  },
  methods: {
    init() {
      // this.sidebarList = SIDEBAR_LIST.ADMIN;
      setTimeout(()=>{
        this.sidebarList = parseInt(this.companyInfoByPerson.is_admin) == 1 ? SIDEBAR_LIST.ADMIN : SIDEBAR_LIST.DEFAULT;
        this.initSidebarId();

        if (this.companyInfoByPerson.is_admin == 1){
        }
      },300)

    },

    /* 初始化选中id */
    initSidebarId() {
      let arr = this.handleData(this.sidebarList)
      let findSidebarItem = arr.find(vo => vo.link === this.$route.path);
      this.sidebarId = findSidebarItem.id;
      /* 检查是否有父元素 */
      if (findSidebarItem.parentId) {
        this.subSidebarId = findSidebarItem.id;
        this.sidebarList.forEach(vo => {
          if (vo.id == findSidebarItem.parentId) {
            this.sidebarId = vo.id;
            vo.collapse = true;
          }
        })
      }

      /* 检查是否有子元素 */
      if (findSidebarItem.children) {
        findSidebarItem.collapse = true;
        // this.sidebarId = findSidebarItem.children[0].id;
        this.subSidebarId = findSidebarItem.children[0].id;
      }
    },


    toggleSidebar(vo) {
      this.sidebarId = vo.id;
      if (vo.children && vo.children.length) {
        this.subSidebarId = vo.children[0].id
        vo.collapse = !vo.collapse;
      } else {
        this.subSidebarId = -1;
      }

      if (vo.link ===PAGE_PATH.COMPANY.HOME){
        fnRouter.routerTo(vo.link);
        return
      }


      fnRouter.routerTo(vo.link)

      /* 公司和主体是否认证 */
      // if (this.companyInfoByPerson.company.status == 1){
      //   if (this.companyInfoByPerson.cert_main_num != 0){
      //     fnRouter.routerTo(vo.link)
      //   }else{
      //     fnRouter.routerTo(PAGE_PATH.COMPANY.MAIN_CERTIFICATE)
      //   }
      // }else{
      //   fnRouter.routerTo(PAGE_PATH.COMPANY.HOME)
      // }
    },

    toggleSubSidebar(vo, subItem) {
      console.log(vo)
      console.log(subItem)
      this.subSidebarId = subItem.id;
      this.sidebarId = vo.id;

      fnRouter.routerTo(subItem.link)

      /* 公司和主体是否认证 */
      // if (this.companyInfoByPerson.company.status == 1){
      //   if (this.companyInfoByPerson.cert_main_num != 0){
      //     fnRouter.routerTo(subItem.link)
      //   }else{
      //     fnRouter.routerTo(PAGE_PATH.COMPANY.MAIN_CERTIFICATE)
      //   }
      // }else{
      //   fnRouter.routerTo(PAGE_PATH.COMPANY.HOME)
      // }
    },

    handleData(arr) {
      // 先实现将所有id都push进一个数组中
      let idList = arr.reduce((p, c) => {
        // p 代表上一次的数据，用于下一次循环中使用，初始化为[]
        // c 代表当前遍历项
        // 把每一项的id push进去
        c.id && p.push(c)
        // 判断每一项中的children长度是否存在,并且也一个空数组,如果都满足,进行递归遍历push
        c.children && c.children.length && (p = p.concat(this.handleData(c.children)))
        return p
      }, [])


      // 进行去重
      let resultArr = []
      // 对id组成的数组进行遍历
      for (let i = 0; i < idList.length; i++) {
        // 判断每一项是否在新数组中，如果不在就push到新数组中
        if (!resultArr.includes(idList[i])) {
          resultArr.push(idList[i])
        }
      }

      // 对去重的数组进行从小到大排序,这里就使用冒泡排序算法来实现了
      for (let i = 0; i < resultArr.length; i++) {
        for (let j = 0; j < resultArr.length - 1 - i; j++) {
          // 判断前一项是否大于后面一项，如果大于就交换位置
          if (resultArr[j] > resultArr[j + 1]) {
            // 交换位置
            [resultArr[j], resultArr[j + 1]] = [resultArr[j + 1], resultArr[j]]
          }
        }
      }
      // 最后把处理好的数组return出去就大功告成!!
      return resultArr
    },

    listenSidebarTop(n) {
      let index = 0;
      let sidebarList = this.sidebarList;
      let findSidebarIndex = sidebarList.findIndex(vo => vo.id === n);
      console.log(findSidebarIndex)
      setTimeout(() => {
        if (this.$refs.sidebarItem) {
          this.$nextTick(() => {
            let sidebarItemHeight = this.$refs.sidebarItem[0].offsetHeight;
            /* 检测是否有父菜单打开 */
            for (let i = 0; i < findSidebarIndex; i++) {
              if (sidebarList[i].children && sidebarList[i].children.length && sidebarList[i].collapse) {
                index += sidebarList[i].children.length + 1;
              } else {
                index += 1;
              }
            }
            this.sidebarTop = (index) * sidebarItemHeight + 'px';
          })
        }
      }, 200)
    },

    toCaseCollection(){
      fnRouter.routerTo(PAGE_PATH.COMPANY.CASE_COLLECTION)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "index";

.CompanySidebar {

  position: fixed;
  left: 0;
  top: $headerHeight;
  width: $sidebarWidth;
  bottom: 0;
  background-color: #fff;
  border-right: 1px solid #F2F3F7;
  box-sizing: border-box;
  padding: 24px;

  &__name {
    color: red;

  }

  .sidebar-section {
    position: relative;

    .sidebar-item {
      width: 208px;
      height: 56px;
      //background: #FFFFFF;
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      box-sizing: border-box;
      cursor: pointer;
      position: relative;
      z-index: 2;
      color: #626F96;

      .iconfont2 {
        font-size: 18px;
        margin-right: 16px;
      }

      span, .iconfont2 {
      }

      .icon-qy-arrow-down-line {
        @include Position(1, 2, 8px, 50%);
        transform: translateY(-50%);
        transition: all 0.5s ease-in-out;

        &.icon-qy-arrow-down-line2 {
          transform: translateY(-50%) rotate(180deg);
        }
      }

      .join-num{
        display: inline-block;
        @include Position(1, 2, 8px, 50%);
        transform: translateY(-50%);
        transition: all 0.5s ease-in-out;
        width: 18px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        background: #255BFF;
        border-radius: 9px;
        font-size: 12px;
        color: #FFFFFF;
      }


      &.ON {
        //background-color: #FAF8FB;
        color: #255BFF;

        .iconfont2 {
          color: #255BFF;
        }
      }


      &.sub-sidebar-item {
        //height: 0;
        //display: none;

        .iconfont2 {
          opacity: 0;
        }

        &.SHOW {
          height: 56px;
          display: flex;
        }
      }
    }

    .sidebar-item-wrap {
      height: 0;
      //transition: height 0.5s ease-in-out;
      opacity: 0;

      &.ON {
        height: 100%;
        opacity: 1;
      }
      &.NO {
        //height: 100%;
        //opacity: 1;
        .sub-sidebar-item{
          height: 0;
        }
      }
    }

    .sidebar-item-bar {
      width: 208px;
      height: 56px;
      background-color: #FAF8FB;
      border-radius: 8px;
      @include Position(1, 5, 0, 0);
      transition: all 0.3s ease-in-out;
    }
  }

  .case-collect-buoy{
    @include BgImage(184px,88px,'company/case-collection/case-collect-buoy.png');
    @include Position(1,4,36px,36px);
    z-index: 5;
  }
}
</style>
