/**
 * @Author: wulizhou
 * @Description: 网站配置
 * @Date:
 *
 **/

/* 引入公共方法和常量 */
import * as CON_COMMON from '@/constant/_constant';
import {SERVICE_LINK as SERVICELINK} from "@/constant/_Public/Path";

/* 友情链接 */
export const FRIENDSHIP_LINK = [
  {
    label: "千库网",
    link: 'https://588ku.com/'
  },
  {
    label: "图片素材下载",
    link: 'https://chaopx.com/img/0.html'
  },
  {
    label: "高清素材图片下载",
    link: 'https://chaopx.com/sucai/0.html'
  },
  {
    label: "图片素材免费下载",
    link: 'https://chaopx.com/mianfei/0.html'
  },
  {
    label: "千库编辑",
    link: 'https://editor.588ku.com/'
  },
  {
    label: "虎课网",
    link: 'https://huke88.com/'
  },
  {
    label: "我图网",
    link: 'https://www.ooopic.com/'
  },
  {
    label: "笔灵AI写作",
    link: 'https://ibiling.cn/'
  },
  /*{
    label: "设计导航",
    link: 'https://www.jiafangbb.com'
  },*/
  {
    label: "品牌策划",
    link: 'https://www.staerlined.com'
  },
  {
    label: "ps样机贴图",
    link: 'https://www.psyangji.com'
  },
  {
    label: "树图思维导图",
    link: 'https://shutu.cn'
  },
  {
    label: "资源大全",
    link: 'https://www.iya88.com'
  },
  /*{
    label: "电脑壁纸",
    link: 'http://www.sobz123.com'
  },*/
  /* {
     label: "PPT免费下载",
     link: 'https://pppt.cc'
   },*/
  {
    label: "高清图片素材",
    link: 'https://xsj.699pic.com/'
  },
  {
    label: "图片素材",
    link: 'https://ibaotu.com/'
  },
  {
    label: "素材天下",
    link: 'https://www.sucaisucai.com/'
  },
  {
    label: "潮点视频",
    link: 'https://shipin520.com/'
  },
  {
    label: "版权音乐",
    link: 'https://houzi8.com/'
  },
  /*{
    label: "千图网",
    link: 'https://58pic.com/'
  },*/
  {
    label: "美工云（海外高端素材）",
    link: 'https://meigongyun.com'
  },
  {
    label: "设计素材",
    link: 'https://www.fengtupic.com/'
  },
  {
    label: "飞特网",
    link: 'https://www.fevte.com/'
  },
  {
    label: "率叶插件",
    link: 'http://ly.jiuxihuan.net/'
  },
  {
    label: "设计师兼职接单",
    link: 'https://www.93jiang.com/'
  },
  /* {
     label: "Diffshop店湖",
     link: 'https://www.diffshop.cn'
   },*/
  /*{
    label: "素材管理工具",
    link: 'https://www.billfish.cn/'
  },*/
  /*{
    label: "数字生活指南",
    link: 'https://nav.guidebook.top/'
  },*/
  /*{
    label: "潮运营",
    link: 'https://www.chaoyunying.com'
  },*/
  {
    label: "Ai宇宙吧",
    link: 'https://www.aiyuzhou8.com/'
  },
  {
    label: "一流导航",
    link: 'https://16map.com/'
  },
  {
    label: "溜溜软件中心",
    link: 'https://software.3d66.com/'
  },
  {
    label: "笔点网址导航",
    link: 'https://www.bidianer.com/'
  },
  {
    label: "UIED",
    link: 'https://www.uied.cn/'
  }
]

/* 侧边栏 */
export const SIDEBAR_WX_CODE = [
  /* 已加满 */
  /*{
    label: "官方微信号",
    link: CON_COMMON.CON_BASIC.BASE_IMG_UPLOAD + "common/sidebar/icon_code-wx2.png"
  },*/
  /*{
    label: "官方微信群",
    // link: CON_COMMON.CON_BASIC.BASE_IMG_UPLOAD + "common/sidebar/icon_code-wx2-1.png"
    // link: CON_COMMON.CON_BASIC.BASE_IMG_UPLOAD + "common/sidebar/icon_code-wx2-10.jpg"
    link: CON_COMMON.CON_BASIC.BASE_IMG_UPLOAD + "common/sidebar/icon_code-wx2-12.png"
  },*/
  /*{
    label: "商务合作",
    link: CON_COMMON.CON_BASIC.BASE_IMG_UPLOAD + "common/sidebar/icon_code-qq.png"
  }*/
  {
    label: "无需验证 直接咨询",
    // link: CON_COMMON.CON_BASIC.BASE_IMG_UPLOAD + "common/sidebar/icon_code-wx2-1.png"
    // link: CON_COMMON.CON_BASIC.BASE_IMG_UPLOAD + "common/sidebar/icon_code-wx2-10.jpg"
    link: CON_COMMON.CON_BASIC.BASE_IMG_UPLOAD + "common/sidebar/icon_code-zx-1.png?v=8"
  },
]


/* FOOTER */
/* 官方渠道图标和二维码配置 */
export const CHANNEL_ICON = {

  wb: {
    label: '微博',
    id: 1,
    iconClass: 'icon-weibo21',
    iconHoverClass: 'icon_weibo@hover',
    qrCodeImg: '/common/footer/channel/weibo.png',
    qrCodeDesc: '扫码关注 官方微博',
  },
  wx: {
    label: '微信',
    id: 2,
    iconClass: 'icon_weixin',
    iconHoverClass: 'icon_weixin@hover',
    qrCodeImg: '/common/footer/channel/weixin.png',
    qrCodeDesc: '扫码关注 微信公众号'
  },
  dy: {
    label: '抖音',
    id: 3,
    iconClass: 'icon_douyin',
    iconHoverClass: 'icon_douyin@hover',
    qrCodeImg: '/common/footer/channel/douyin.png',
    qrCodeDesc: '扫码关注 官方抖音'
  },
  xhs: {
    label: '小红书',
    id: 4,
    iconClass: 'icon-xhs21',
    iconHoverClass: 'icon_xhs@hover',
    qrCodeImg: '/common/footer/channel/xhs.png',
    qrCodeDesc: '扫码关注 官方小红书'
  },
  link: {
    label: '复制链接',
    id: 5,
    iconClass: 'icon-copylink',
  },
}


/* 爬虫页类型 */
export const SPIDERS = [
  'tool',
  'curl',
  'jiankongbao',
  'monitor',
  'duckduckgo',
  'archive',
  'search',
  'bot',
  'crawl',
  'slurp',
  'spider',
  'mediapartners',
  'megaindex',
  'bubing',
]


/* 数据统计弹框位置和类型 */
export const MODAL_TRACK = {

  POSITION: {
    COUPON: 'coupon',    // 8.8 红包
    RED_PACKET: 'redPacket', // 新人注册自动发放3 5优惠券
    HORIZON: 'horizon', // 新人10天优惠券点击统计
    RENEW: 'renew', // 续费红包 10元通用
    COMPANY_ENJOY: 'companyEnjoy', // 企业专享
    RED_PACKET_RAIN: 'redPacketRain', // 天降红包
  },

  /* 弹框位置 */
  TYPE: {
    CURRENT_PAGE: 1,  // 预览
    TO_PAY_PAGE: 2,   // 点击
  }
}


/* 宠粉模块 */

/* 需求池任务状态
* 1 待审核（提交了需求后台未审核，仅用户可见），2 生产中（审核通过了需求），3 已取消（后台操作驳回，显示驳回理由），4 已完成（后台点击完成的需求）
* */
export const DEMAND_TASK_STATUS = {
  0: "待审核",
  1: "生产中",
  2: "已取消",
  3: "已完成",
}


/* 网站配置二维码 */
export const QR_CODE = {
  WX_SERVICE: CON_COMMON.CON_BASIC.BASE_IMG_UPLOAD + "_chaotu/static/wx-service20240426_1.png"
}

export const SERVICE_LINK = SERVICELINK


export const LEAFLET_MEAL_LIST = [
  {
    id: 1,
    title: "线上曝光",
    equity: [
      "微博、微信等新媒体宣传",
      "电商宣传、网络推广、网页"
    ],
    price: 99
  },
  {
    id: 2,
    title: "线下印刷+图书出版",
    equity: [
      "线下活动、宣传册、海报、易拉宝等线下宣传物料印刷",
      "图书/绘本出版发售",
    ],
    price: 299
  },
  {
    id: 3,
    title: "户外广告+传媒影视",
    equity: [
      "公司年会、颁奖活动、启动仪式、演出、展会、发布会等线下活动背景及屏幕",
      "楼宇、车身、灯箱、围挡、橱窗、户外广告牌、公共场所陈列",
      "影视制作：电影、电视剧、新闻、纪录片、微电影、自制剧、广播等",
      "传媒平台 ：B站、优酷、爱奇艺、腾讯视频、抖音、快手、西瓜视频、咪咕视频、芒果TV等",
    ],
    price: 699
  },
  {
    id: 4,
    title: "转售商品 + 产品内置",
    equity: [
      "实物印刷 ：图书、期刊、杂志、报纸、产品外包装、服装服饰、艺术品、 工艺品（装饰画、手机壳、贺卡、明信片、日历、杯子）等实物商品及赠品",
      "产品内置：软件主题/皮肤/背景、软件内置、H5应用、小程序、网络课程、付费音视频、游戏内置、智能硬件等"
    ],
    price: 999
  }
]


export const MANDATE_SCOPE_COMPANY = [
  "新媒体用途",
  "电商用途",
  "企业办公",
  "广告宣传",
  "线下用途",
  "传媒影视",
  "转售商品",
  "产品内置",
  "敏感用途",
  "商标LOGO",
  "内置转售",
  "其他用途",
]

export const COMPANY_VIP_MANDATE = {
  8: {
    name: "标准版",
    price: "399",
    accountNum: "•1个子账号•全站200张/年",
    payId: 38,
    scope: [
      '新媒体用途',
      '电商用途',
      '企业办公'
    ],

    _scope: [
      '新媒体用途',
      '电商用途',
      '企业办公'
    ]
  },
  2: {
    name: "标准版",
    price: "399",
    accountNum: "•1个子账号•全站200张/年",
    payId: 10,
    scope: [
      '新媒体用途',
      // '电商用途',
      '企业办公'
    ],

    _scope: [
      '新媒体用途',
      // '电商用途',
      '企业办公'
    ]
  },
  5: {
    name: "升级版",
    price: "990",
    accountNum: "•4个子账号 全站海量下载",
    payId: 35,
    scope: [
      '新媒体用途',
      '电商用途',
      '企业办公',
      '广告宣传',
      '线下用途'
    ],
    _scope: [
      '线上全用途',
      '广告宣传',
      '线下用途'
    ]
  },
  6: {
    name: "高级版",
    price: "2600",
    accountNum: "•10个子账号 全站海量下载",
    payId: 36,
    scope: [
      '新媒体用途',
      '电商用途',
      '企业办公',
      '广告宣传',
      '线下用途',
      '传媒影视'
    ],
    _scope: [
      '线上全用途',
      '广告宣传',
      '线下用途',
      '传媒影视'
    ]
    // 线上全用途 广告宣传 线下用途 传媒影视 转售商品
  },
  7: {
    name: "转售版",
    price: "5600",
    accountNum: "•20个子账号 全站海量下载",
    payId: 37,
    scope: [
      '新媒体用途',
      '电商用途',
      '企业办公',
      '广告宣传',
      '线下用途',
      '传媒影视',
      '转售商品'
    ],
    _scope: [
      '线上全用途',
      '广告宣传',
      '线下用途',
      '传媒影视',
      '转售商品'
    ]

  },

  14: {
    name: "升级版",
    price: "990",
    accountNum: "•4个子账号 全站海量下载",
    payId: 35,
    scope: [
      '新媒体用途',
      '电商用途',
      '企业办公',
      '广告宣传',
      '线下用途'
    ],
    _scope: [
      '线上全用途',
      '广告宣传',
      '线下用途'
    ]
  },
  15: {
    name: "高级版",
    price: "2600",
    accountNum: "•10个子账号 全站海量下载",
    payId: 36,
    scope: [
      '新媒体用途',
      '电商用途',
      '企业办公',
      '广告宣传',
      '线下用途',
      '传媒影视'
    ],
    _scope: [
      '线上全用途',
      '广告宣传',
      '线下用途',
      '传媒影视'
    ]
    // 线上全用途 广告宣传 线下用途 传媒影视 转售商品
  },
  16: {
    name: "转售版",
    price: "5600",
    accountNum: "•20个子账号 全站海量下载",
    payId: 37,
    scope: [
      '新媒体用途',
      '电商用途',
      '企业办公',
      '广告宣传',
      '线下用途',
      '传媒影视',
      '转售商品'
    ],
    _scope: [
      '线上全用途',
      '广告宣传',
      '线下用途',
      '传媒影视',
      '转售商品'
    ]

  },

  /* 大型企业线上版 */
  10: {
    name: "大型企业线上版",
    price: "8800",
    accountNum: "•4个子账号 全站海量下载",
    mandateNum: '大型企业线下版VIP（共10项授权）',
    payId: 10,
    scope: [
      '新媒体用途',
      '电商用途',
      '企业办公'
    ],
    _scope: [
      '新媒体用途',
      '电商用途',
      '企业办公'
    ],
    scopePermission: [
      1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ],
  },

  /* 大型企业线下版 */
  11: {
    name: "大型企业线下版",
    price: "16800",
    accountNum: "•4个子账号 全站海量下载",
    mandateNum: '大型企业线下版VIP（共10项授权）',
    payId: 11,
    scope: [
      '新媒体用途',
      '电商用途',
      '企业办公',
      '广告宣传',
      '线下用途',
      '传媒影视',
      '转售商品',
    ],
    _scope: [
      '广告宣传',
      '线下用途',
      '传媒影视',
      '转售商品',
    ]
  },
  /* 医美行业专业版 */
  12: {
    name: "医美行业专业版",
    price: "12800",
    accountNum: "•4个子账号 全站海量下载",
    payId: 12,
    scope: [
      '新媒体用途',
      '电商用途',
      '企业办公',
      '广告宣传',
      '线下用途',
      '户外广告',
      '敏感用途'
    ],
    _scope: [
      '广告宣传',
      '线下用途',
      '户外广告',
      '敏感用途'
    ]
  },
  /* 多甲方授权版 */
  13: {
    name: "多甲方授权版",
    price: "28800",
    accountNum: "•5家主体 | 各1个子账号  全站海量下载",
    payId: 13,
    scope: [
      '新媒体用途',
      '电商用途',
      '企业办公',
      '广告宣传',
      '线下用途',
    ],
    _scope: [
      '广告宣传',
      '线下用途',
    ]
  }
}

export const PERSON_VIP_MANDATE = {
  1: {
    name: "标准版",
    price: "399",
    accountNum: "•1个子账号•全站200张/年",
    payId: 38,
    scope: [
      '无授权',
    ],
    _scope: []
  },
}

export const BUSINESS_VIP_MANDATE = {
  1: {
    name: "标准版",
    price: "399",
    accountNum: "•1个子账号•全站200张/年",
    payId: 38,
    scope: [
      "新媒体用途",
      "电商用途",
      "企业办公",
      "广告宣传",
    ],
    _scope: [
      "新媒体用途",
      "电商用途",
      "企业办公",
      "广告宣传",
    ]
  },
}
