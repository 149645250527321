
/* 全部热点 24节气 节日数据埋点 */
import {navDropTracking} from "@/api/home";

class TrackingNav {
  navDrop(act, pos=1, event=''){
    navDropTracking({act,pos, event})
  }
}

export default new TrackingNav();
