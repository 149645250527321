import getCookie from "@/utils/getCookie";

export default function ({app: {$axios, $cookies, context}}) {
  // $axios.defaults.baseURL = process.env.baseUrl
  let isClient = process.client;
  let isServer = process.server;
  let cookie;

  let auth_id = $cookies.get('auth_id')

  $axios.defaults.baseURL = "https://api.chaopx.com"
  $axios.defaults.timeout = 30000;

  $axios.interceptors.request.use(config => {
    // 头部带上验证信息
    if (isServer) {
      cookie = context.req.headers.cookie
    }
    if (isClient) {
      // cookie = document.cookie
    }
    config.headers.cookie =  !!auth_id ? cookie : '';
    return config
  })
  $axios.interceptors.response.use(response => {
    if (/^[4|5]/.test(response.status)) {
      return Promise.reject(response.statusText)
    }
    return response.data
  })
}
