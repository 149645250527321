import {COMPANY_All_PURPOSE, COMPANY_ONLINE} from "@/constant/VIP_COMPANY";

/* 特权列表 */
export const equityList = [
  {
    iconClass:'icon_equity1',
    label:'新媒体/自媒体',
    value:'微博、微信、今日头条、知乎（微博/公众号短视频等）',
  },
  {
    iconClass:'icon_equity2',
    label:'电商平台',
    value:'淘宝、天猫、京东、头条、拼多多',
  },
  {
    iconClass:'icon_equity3',
    label:'户外广告',
    value:'楼宇、灯箱、围挡、橱窗、户外广告牌、公共场所陈列',
  },
  {
    iconClass:'icon_equity5',
    label:'印刷出版',
    value:'线下印刷限5万份、图书出版限10万份',
  }
]
export const packageList = [
  {
    name:'线上用途版',
    ID: COMPANY_ONLINE.ID,
    PARENT_ID: COMPANY_ONLINE.PARENT_ID,
    PRICE: COMPANY_ONLINE.PRICE,
    time:'1年',
    className:'online',
  },
  {
    name:'全用途版',
    ID: COMPANY_All_PURPOSE.ID,
    PARENT_ID: COMPANY_All_PURPOSE.PARENT_ID,
    PRICE: COMPANY_All_PURPOSE.PRICE,
    time:'1年',
    className:'all'
  },
  {
    name:'企业定制',
    ID: -1,
    PARENT_ID: COMPANY_All_PURPOSE.PARENT_ID,
    PRICE: COMPANY_All_PURPOSE.PRICE,
    time:'1年',
    className:'custom'
  }
]
