<template>
  <div class="work-efficiency-section">
    <div class="work-efficiency-content inner_center">
      <div class="efficiency-info">
        <div class="headline">{{ workEfficiencyData.headline }}</div>
        <div class="efficiency-ways">
          <div
            v-for="vo in workEfficiencyData.content"
            class="efficiency-item">
            <i
              :class="vo.iconClass"
              class="efficiency-item-icon iconfont "/>
            <div class="efficiency-item-intro">
              <div class="intro-title">{{ vo.title }}</div>
              <div class="intro-details">{{ vo.details }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="efficiency-view"/>
    </div>
  </div>
</template>

<script>
/* 工作效率 */
const workEfficiencyData = {
  headline: "全方位提高您的工作效率",
  content:[
    {
      iconClass:"icon-editor",
      title:"编辑矢量",
      details:"我们所有的图都包含原始矢量设计文件，并且完全可编辑，您可以轻松自定义它们以适合您的品牌需求。"
    },
    {
      iconClass:"icon-copyright",
      title:"版权授权",
      details:"一旦您购买「商用授权」服务，您就可以永久获得授权，没有任何限制，同时也能享受在服务期限内的保障。"
    },
    {
      iconClass:"icon-dingzhi2",
      title:"定制设计",
      details:"我们提供定制规则，可以遵循我们的风格，也可以创建一个全新的以适合您品牌的风格，这样您将始终获得所需的东西。"
    },
    {
      iconClass:"icon-dingzhi",
      title:"内容更新",
      details:"我们的图库与日俱增，不断添加新的样式和场景，使其成为您项目的最终资源来源。"
    }
  ]
}

export default {
  name: "WorkEfficiency",
  data(){
    return {
      workEfficiencyData
    }
  }
}
</script>

<style lang="scss" scoped>
.work-efficiency-section {
  //width: 1920px;
  width: 100%;
  height: 598px;
  background: linear-gradient(225deg, #D093FF 0%, #6969FF 100%, #6969FF 100%);
  box-shadow: 0 0 0 4px rgba(251, 247, 255, 0.2);

  .work-efficiency-content {
    position: relative;

    .efficiency-info {
      .headline {
        padding-top: 80px;
        margin-bottom: 77px;

        height: 59px;
        font-size: 44px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 59px;
        letter-spacing: 1px;

      }

      .efficiency-ways {

        display: flex;
        flex-wrap: wrap;
        width: 936px;

        .efficiency-item {
          display: flex;
          margin: 0 116px 72px 0;


          &:nth-child(2n) {
            margin-right: 0;
          }

          .efficiency-item-icon {
            display: inline-block;
            width: 27px;
            height: 26px;
            margin: 3.5px 24px 0 0;
            font-size: 27px;
            color: #FFFFFF;
          }

          .efficiency-item-intro {
            width: 302px;

            .intro-title {
              font-size: 24px;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 31px;
              letter-spacing: 1px;
              margin-bottom: 18px;
            }

            .intro-details {
              height: 63px;
              font-size: 14px;
              color: #FFFFFF;
              line-height: 19px;
              letter-spacing: 1px;

            }
          }

          @include Screen(1) {
            .efficiency-item-intro {
              width: 270px;
            }
          }

        }

        @include Screen(1) {
          .efficiency-item {
            margin: 0 84px 72px 0;
          }
        }
      }
    }

    .efficiency-view {
      @include BgImage(420px, 420px, "home/images/work-efficiency-view.png");
      @include Position(1, 2, 0, 127px);
    }

    @include Screen(1) {
      .efficiency-view {
        @include BgImage(420px, 420px, "home/images/work-efficiency-view.png");
        @include Position(1, 2, 0, 173px);
      }
    }
  }
}
</style>
