/* 引入公共方法和常量 */
import * as fnCommon from '@/fn/_fn';
import * as CON_COMMON from '@/constant/_constant';
import Clipboard from "clipboard";
import myNotify from "@/utils/notify";
import {listenInput, trackNewUserDialog} from "@/api/home";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {BASE_URL} from "@/constant/_constant/CON_BASIC";

/**
 * @Author: wulizhou
 * @Description: 全局公共方法封装
 * @Date: 2023.4.7
 **/

class FnBasic {

  constructor() {
  }


  /**
   * 深拷贝
   * @param obj
   * @returns {*[]|*}
   */
  deepClone(obj) {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }
    const newObj = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
      // 过滤掉原型链上的属性
      if (obj.hasOwnProperty(key)) {
        newObj[key] = this.deepClone(obj[key]);
      }
    }
    return newObj;
  }

  /**
   * 判断是否是空对象
   * @param obj
   * @returns {boolean}
   */
  isEmptyObj(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }


  /**
   * A链接跳转
   * @param url 跳转地址
   * @param target 是否新窗口打开
   */
  navigationTo(url, target = '_blank') {

    /* 判断是否是非本站 */
    url = url.indexOf('http') == -1 ? CON_COMMON.CON_BASIC.BASE_URL + url : url
    let a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('target', target);
    a.setAttribute('rel', 'noopener noreferrer');
    document.body.appendChild(a);
    a.click();
  }


  /**
   * 基于Clipboard封装 复制功能
   * @param txt 复制文本
   * @param e 复制dom对象
   * @returns {Promise<unknown>}
   */
  async copy(txt, e = '.Copy') {
    return new Promise((resolve, reject) => {
      let clipboard = new Clipboard(e, {
        text: function () {
          return txt;
        }
      })
      clipboard.on('success', e => {
        myNotify.success("复制成功")
        resolve(e)
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        reject(e)
        myNotify.show("该浏览器不支持自动复制")
        // 释放内存
        clipboard.destroy()
      })
    })

  }


  /* 检查是否是爬虫页 */
  checkIsSpider(UA) {
    let spiders = CON_COMMON.CON_CONFIG.SPIDERS;
    let isSpider = false;

    try {
      for (let i = 0; i < spiders.length; i++) {
        if (UA.toLowerCase().indexOf(spiders[i].toLowerCase()) > -1) {
          isSpider = true;
          break;
        }
      }
    } catch {
    }
    return isSpider
  }


  /**
   * 数据埋点弹框位置和类型
   * @param position  // 位置 - 具体见 CON_CONFIG -> MODAL_TRACK
   * @param type      // 类型 - 具体见 CON_CONFIG -> MODAL_TRACK
   * @returns {Promise<void>}
   */
  dataTrackByModal(position, type) {
    trackNewUserDialog({
      position,
      type
    }).then(res => {
      console.log('数据埋点弹框位置和类型' + position + "：" + type);
    })
  }

  // https://service.weibo.com/share/share.php?url=http%3A%2F%2Fchaopx.com%3A3000%2Fcollection%2F327-large.html&title=%E6%88%91%E5%9C%A8%40%E6%BD%AE%E5%9B%BD%E5%88%9B%E6%84%8F%20%E5%8F%91%E7%8E%B0%E4%BA%86%E3%80%90%E6%98%A5%E5%AD%A3%E8%90%A5%E9%94%80%E7%B4%A0%E6%9D%90%E5%8C%85%E4%B8%93%E9%A2%98%E5%90%8D%E3%80%91%E8%BF%99%E4%B8%AA%E7%89%B9%E8%89%B2%E4%B8%93%E9%A2%98%EF%BC%8C%E8%B4%A8%E9%87%8F%E4%B8%8D%E9%94%99%E5%88%9B%E6%84%8F%E5%BA%A6%E5%A5%BD%EF%BC%8C%E5%BF%AB%E6%9D%A5%E5%9B%B4%E8%A7%82%EF%BC%81%E9%93%BE%E6%8E%A5%EF%BC%9A&pic=https://pic.chaopx.com/portal_upload/20/23/02/114e063c0eae35c616a3980481d366a5.jpg#_loginLayer_1681786820252

  /* 分享 */
  shareByWeibo(shareUrl, shareTitle, shareCover) {
    var _shareUrl = 'http://service.weibo.com/share/share.php?'
    _shareUrl += 'url=' + encodeURIComponent(shareUrl) //参数url设置分享的内容链接
    _shareUrl += '&title=' + encodeURIComponent(shareTitle) //参数title设置分享的标题
    _shareUrl += '&pic=' + shareCover //参数title设置分享的标题
    // 保留当前页面,打开一个非tab页面（按需求来，可以新开标签页，也可以在当前页新开页面）
    window.open(
      _shareUrl,
      '_blank',
      // 'height=800, width=1000',
      // 'scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes'
    )

  }


  /* 敏感词实时监测 */
  listenSearchInput(val) {
    listenInput({
      word: val
    }).then(res => {
      if (res.data.data.is_forbid == 1) {
        console.log("应国家法律法规，该词不允许搜索！")

        myNotify.warning("应国家法律法规，该词不允许搜索！", () => {
        }, 2000);

      }
    })
  }

  countdown(seconds, fun) {
    return new Promise((resolve, reject) => {

      if (seconds < 0) {
        reject("倒计时时间不能为负数");
        return;
      }

      let remainingSeconds = seconds;
      const timer = setInterval(() => {
        remainingSeconds--;
        typeof fun === 'function' && fun(remainingSeconds);

        if (remainingSeconds === 0) {
          clearInterval(timer);
          resolve(remainingSeconds);
        }

      }, 1000);
    });
  }

  /* 获取网站完整链接 */
  getChaopxFullLink(path) {
    const {BASE_URL} = CON_COMMON.CON_BASIC
    if (path.indexOf('http') > -1) {
      return path;
    }
    return BASE_URL + path;
  }

  setMeta() {

    const metaTag = document.createElement('meta');
    metaTag.setAttribute('charset', 'utf-8');
    document.head.appendChild(metaTag);

    addMeta('viewport','width=device-width, user-scalable=yes, initial-scale=0.3, maximum-scale=1.0, minimum-scale=0.3')
    // addMeta('huaban','nopin')

    function addMeta(name, content) {
      console.log('addMeta----------------')
      // 创建一个 meta 标签元素
      const metaTag = document.createElement('meta');
      // 设置 meta 标签的属性
      metaTag.setAttribute('name', name);
      metaTag.setAttribute('content', content);
      // 将 meta 标签添加到页面的头部
      document.head.appendChild(metaTag);
    }
  }

}

export default new FnBasic()
