import {MATERIAL_LIST_PATH} from "@/constant/PATH";

export const categoryList = [
  {
    id: 0,
    title: '全部类型'
  },
  {
    id: 1,
    title: '扁平简约'
  },
  {
    id: 2,
    title: '商务科技'
  },
  {
    id: 3,
    title: '文艺清新'
  },
  {
    id: 4,
    title: '城市建筑'
  },
  {
    id: 5,
    title: '质感纹理'
  },
  {
    id: 6,
    title: '电商狂欢'
  },
  {
    id: 7,
    title: '复古中国风'
  },
]

export const menuList = [
  {
    id: 0,
    sort: 'default',
    title: '综合排序'
  },
  {
    id: 1,
    sort: 'pxnum',
    title: '近期热门'
  },
  {
    id: 2,
    sort: 'new',
    title: '近期上新'
  },
  /*{
    id: 3,
    sort: 'cnum',
    title: '收藏优先'
  },*/
  {
    id: 4,
    sort: 'dnum',
    title: '人气优先'
  },
]

// 全部时间下拉列表
export const timeList = [
  {
    id: 0,
    title: "全部时间"
  },
  // {
  //   id: 1,
  //   title: "最近24小时"
  // },
  {
    id: 2,
    title: "最近一周"
  },
  {
    id: 3,
    title: "最近一月"
  },
  {
    id: 4,
    title: "最近半年"
  },
  {
    id: 5,
    title: "最近一年"
  }
]

// 类型下拉列表 layer 内容层次: 1免费区, 2普通区, 3商用区, 4企业区
export let typeList = [

  {
    id: '2',
    title: "VIP专区",
    title2: "个人",
    label: '成为VIP可下载'
  },
  /*{
    id: '3',
    title: "商用VIP专享",
    title2: "商用",
    label: '商用VIP和企业VIP可下载'
  },*/
  {
    id: '4',
    title: "企业VIP专享",
    title2: "企业",
    label: '仅企业VIP可下载'
  },
  {
    id: '1',
    title: "限时免费",
    title2: "免费",
    label: '所有用户每天可免费下载一次'
  }
]

// 使用类型下拉列表
export const usageTypeList = [
  /*{
    id: 0,
    title: "全部"
  },*/
  {
    id: 1,
    title: "PSD"
  },
  {
    id: 2,
    title: "AI"
  },
  {
    id: 3,
    title: "CDR"
  },

  {
    id: 4,
    title: "EPS"
  },
  {
    id: 5,
    title: "C4D"
  },
  {
    id: 6,
    title: "MAX"
  },
  {
    id: 8,
    title: "Blend"
  },
  {
    id: 9,
    title: "Sketch"
  },
  {
    id: 7,
    title: "GIF"
  },

]

// `/${pinyin}-${home_keyword_id}-${layout}-${time_filter}-${module}-${category}--${scene}-${desc}-${page}.html`;
export function parseUrlStr(str) {
  str = str.replace('.html', '');
  let idArr = str.split('-')

  return {
    pinyin: idArr[0] || 0,
    home_keyword_id: idArr[1] || 0,
    layout: idArr[2] || 0,
    time_filter: idArr[3] || 0,
    module: idArr[4] || 0,
    category: idArr[5] || 0,
    scene: idArr[6] || 0,
    desc: idArr[7] || 'default',
    page: parseInt(idArr[8]) || 1,
    layer: idArr[9] || '0',
    design: idArr[10] || '0',
    categoryType: idArr[11] || '0', // 0默认分类 1 设计风格
  };
}

export function ObjectToArray(o) {
  let arr = []
  for (const key in o) {
    arr.push(o[key])
  }
  return arr;
}


const LIST_URL_PARAMS = {
  pinyin: 0,
  home_keyword_id: 0,
  layout: 0,
  time_filter: 0,
  module: 0,
  category: 0,
  desc: "default",
  page: 1,
  scene: 0,
  layer: 0, // 1$2$3$4
  design: 0, // 风格
  categoryType: 0, // 风格
}

/* list url解析 */
export function parseListUrl(url, id) {
  let routerPath = url;
  let tempParams = {...{}, ...LIST_URL_PARAMS};

  if (routerPath.indexOf('.html') > -1) {
    if (routerPath.indexOf('-') > -1) {
      tempParams = parseUrlStr(id);
    } else {
      tempParams.pinyin = id.replace('.html', '');
    }
  }
  return tempParams;
}
