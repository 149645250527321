<!--
  头像下拉
-->
<template>
  <div class="login-box _pointer">
    <div
      v-if="isLogin === false"
      class="text-nologin _hover-element"
      @mouseover="toggleShowCombobox(2,true)"
      @mouseleave="toggleShowCombobox(2,false)"
    >
      <div class="text-login" @click.stop="showLogin">
        登录/注册
        <span
          class="text-login-tip animate-bounce-down"
          v-if="interceptMouse">首次登录即送10天免费下载</span>
      </div>
      <div class="CopyrightCombobox ">
        <CopyrightCombobox
          ref="CopyrightCombobox"
        />
      </div>
    </div>
    <div
      v-if="isLogin === true"
      class="avatar-box _hover-element"
      @mouseover="toggleShowCombobox(3,true)"
      @mouseleave="toggleShowCombobox(3,false)"
    >
      <div
        @click="navigatorTo(USER_CENTER)"
     >
        <img
          :src="avatar"
          class="avatar"
          alt="">
      </div>
      <!-- 登录设备 -->
      <i
        v-show="device == DEVICE.iphone"
        class="iconfont icon-mobile icon_device"/>
      <i
        v-show="device == DEVICE.wx"
        class="iconfont icon-wechat2 icon_device"/>
      <i
        v-show="device == DEVICE.qq"
        class="iconfont icon-QQ2 icon_device"/>

      <div class="UserCombobox">
        <UserCombobox
          ref="UserCombobox"
        />
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {USER_CENTER} from "@/constant/PATH";
import Cookies from "js-cookie";
import {DEVICE} from "@/constant/SYSTEM";

import {
  SearchComboBox, CopyrightCombobox, UserCombobox
} from "@/components/home/index.js"
import {fnBrowser} from "~/fn";

export default {
  name: "AvatarCombobox",
  computed: {
    ...mapGetters('user', [
      'phone', 'uniq_id', 'vipIsPerson', 'vipIsPersonData', 'vipIsBusiness', 'vipIsBusinessData', 'avatar', 'iphone', 'isLogin'
    ]),
    ...mapState('system', ['device']),
    ...mapState('user', ['path'])
  },
  components: {
    CopyrightCombobox,
    UserCombobox,
  },
  props: {
    /* 是否阻止 mouse事件 */
    interceptMouse: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      USER_CENTER,
      DEVICE,
    }
  },
  methods: {
    toggleShowCombobox(type, flag, e, enter = false) {
      this.$emit('toggleShowCombobox', 0);
      if (this.interceptMouse === false) {
        return false;
      }
      if (type === 1) {
        setTimeout(() => {
          if (this.$refs.searchComboBox != undefined) {
            this.$refs.searchComboBox.isShowCombobox = flag
          }
        }, 500)
      } else if (type === 2) {
        this.$refs.CopyrightCombobox.isShowCombobox = flag;
      } else if (type === 3) {
        this.$refs.UserCombobox.isShowCombobox = flag;
      }

    },

    /* 显示登录弹框 */
    showLogin() {
      /* 增加拦截判断 */
      let auth_id = Cookies.get('auth_id');
      if (auth_id) {
        this.$myNotify.show('账户已登录', () => {
          // let currRouter = this.$route.path;
          window.location.href = this.path;
        }, 2500);
        return false;
      }

      let {path} = this.$route;
      if (path == '/' || path == '') {
        this.$store.commit("user/SET_IS_SHOW_LOGIN_HOME", true);
      } else {
        this.$store.commit("user/SET_IS_SHOW_LOGIN", true);
      }
    },

    navigatorTo(path){
      fnBrowser.navTo(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.login-box {
  @include LayoutFlex(1);
  position: relative;

  .UserCombobox {
    @include Position(1, 2, 0, 40px);

  }


  .text-nologin {
    line-height: 32px;
    color: #FFFFFF;
    letter-spacing: 1px;
    font-size: 16px;
    display: flex;
    position: relative;
    @include ElementHover(rgba(255, 255, 255, 0.75), '');

    .text-login {
      position: relative;
      width: 110px;
      height: 34px;
      background: #FFFFFF;
      border-radius: 20px;
      font-size: 15px;
      color: #6969FF;
      line-height: 34px;
      text-align: center;
      @include ElementHover(rgba(105, 105, 255, 0.9), rgba(255, 255, 255,0.9));
      background: $color-primary;
      color: #FFFFFF !important;

      @include ElementHover(#FFFFFF,#7778FE);

      .text-login-tip {
        font-size: 14px;
        //@include Position(1, 2, -84px, 30px);
        @include Position(1,5,-38px,38px);
        background-color: #F45CA4;
        color: #FFFFFF;
        display: inline-block;
        line-height: 38px;
        text-align: center;
        border-radius: 4px;
        @include BgImage(188px, 34px, 'login/icon_tip-bg.png');
      }

    }

    .CopyrightCombobox {
      @include Position(1, 2, -30px, 38px);
    }
  }

  .avatar-box {
    display: inline-block;
    width: 32px;
    height: 32px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    position: relative;

    .avatar {
      display: inline-block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    .icon_device {
      display: inline-block;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      font-size: 12px;
      @include LayoutFlex(1);
      @include Position(1, 3, 0, 0);
      color: #fff;

    }

    .icon-mobile {
      background: #6969FF;
    }

    .icon-wechat2 {
      background: #51CF50;
    }

    .icon-QQ2 {
      background: #06B4FD;
    }


  }

}

@-webkit-keyframes bounce-down {
  25% {
    -webkit-transform: translateY(-4px);
  }
  50%, 100% {
    -webkit-transform: translateY(0);
  }
  75% {
    -webkit-transform: translateY(4px);
  }
}

@keyframes bounce-down {
  25% {
    transform: translateY(-4px);
  }
  50%, 100% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(4px);
  }
}

.animate-bounce-down {
  border-radius: 200px;
  background: #333;
  -webkit-animation: bounce-down 1.5s linear infinite;
  animation: bounce-down 1.5s linear infinite;
}
</style>
