<template>
  <no-ssr>
    <div
      class="Modal-wrap"
      :style="'z-index:' + zIndex "
    >
      <div class="Modal-cover"/>
      <div class="Modal">
        <div class="Modal-content">
          <slot name="modal_content"/>
        </div>

        <slot class="Modal-close" name="modal_close" />
        <div
          class="Modal-close"
          @click="close" v-if="isShowClose">
          <i class="iconfont icon-cha"/>
        </div>
      </div>
    </div>
  </no-ssr>
</template>

<script>
export default {
  name: "CgModal",
  props: {
    zIndex: {
      type: Number,
      default: 998
    },
    isShowClose: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style lang="scss" scoped>

.Modal-close {
  font-size: 18px;
  width: 40px;
  height: 40px;
  background: #ECEFF6;
  color: #596080;
  border-radius: 0 14px 0 14px;
  @include Position(1, 2, 0, 0);
  @include LayoutFlex(1);
  @include ElementHover('', #E1E3E7);

  .icon-cha {
    transition: transform 0.5s ease-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: inline-block;

    &:hover {
      transform: rotateZ(180deg);
    }
  }
}

.Modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .Modal {
    margin: 0 auto;
    position: relative;
    animation: fadeinB 0.3s 1;
  }

  .Modal-cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.15);
    //backdrop-filter: blur(20px);
  }

  .Modal-content {
    min-width: 400px;
    min-height: 300px;
    background-color: #FFFFFF;
    border-radius: 14px;
    overflow: hidden;
    animation-duration: 0.5s;
    position: relative;
  }

  .Modal-close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;

    .icon-guanbi {
      font-size: 24px;
      color: #FFFFFF;
      transition: transform 0.5s ease-out;
      display: inline-block;

      &:hover {
        transform: rotateZ(180deg);
      }
    }
  }
}

/* 淡入-从下 */
@keyframes fadeinB {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

</style>
