<template>
  <a :href="ENTERPRISE_CASE" target="_blank" class="HeaderCompanyCase">
    企业案例
  </a>
</template>

<script>
import {ENTERPRISE_CASE} from "@/constant/_constant/CON_BASIC";

export default {
  name: "HeaderCompanyCase",
  data() {
    return {
      ENTERPRISE_CASE
    }
  }
}
</script>

<style lang="scss" scoped>
.HeaderCompanyCase{
  font-size: 15px;
  letter-spacing: 1px;
  position: relative;
  color: #FFFFFF;
  @include ElementHover(rgba(255, 255, 255, 0.75),'');
  line-height: 22px;

  //&:after{
  //  content: '';
  //  display: inline-block;
  //  width: 1px;
  //  height: 16px;
  //  background: #D0D2DB;
  //  border-radius: 1px;
  //  position: absolute;
  //  left: 0;
  //  top: 50%;
  //  transform: translateY(-50%);
  //
  //}
}
</style>
