<template>
  <div class="CooperateCompany">
    <div class="title">你知道的企业都在用</div>
    <ScrollLogo />
  </div>
</template>

<script>
import ScrollLogo from '@/components/home/cooperate-company/components/scroll-logo'

export default {
  name: "CooperateCompany",
  components: {
    ScrollLogo
  },
  data(){
    return{
      ScrollAutoId:'ScrollAuto',
      scrollListData: [1,2,3,4,5,6,7,8,9,1,2,3,4,3,2]
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin IconLogo($count) {
  @for $i from 1 through $count {
    .icon_logo-#{$i} {
      @include BgImage(205px, 87px, 'home/cooperate-company/logo@#{$i+1}.png')
    }
  }
}

.CooperateCompany {
  height: 527px;
  background: #F5F6FA;
  padding-top: 80px;
  box-sizing: border-box;

  .title {
    font-size: 44px;
    font-weight: bold;
    color: #0A2540;
    line-height: 59px;
    letter-spacing: 1px;
    margin-bottom: 60px;
    text-align: center;
  }

  .login-box {
    @include IconLogo(11);
    display: flex;
    flex-wrap: nowrap;
    i{
      flex-shrink: 0;
    }

  }
}


.scroll-container {
  height: 200px;
  width: 200px;
  margin-bottom: 20px;
}
.service {
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
}


</style>
