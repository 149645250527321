/* 专题合集 */

import {listObjToArray} from "@/components/tupian/common/list";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {BASE_IMG_URL} from "@/constant/_Public/Path";

class FnCollection {

  isLarge(id) {
    return id.indexOf('large') > -1
  }

  parseCollectionId(id) {
    let temp = {}
    id = id.replace('.html', '');
    if (this.isLarge(id)) {
      /* 大专题 无分页 */
      let idArr = id.split('-');
      temp.collectionId = idArr[0]
      temp.collectionType = 'large';
    } else {
      /* 小专题 */
      let idArr = id.split('-');
      temp.collectionId = idArr[0]
      temp.page = idArr[1] || 1;
      temp.collectionType = 'small'
    }
    return temp;
  }

  disposeListByLarge(list, temp, currentRoutePath) {

    /*  */
    let tempList = listObjToArray(list);
    tempList.forEach(vo => {
      vo.pictures = listObjToArray(vo.pictures);
      vo.pictures.forEach(item => {
        // item._topic_id = temp.collectionId;
        // item._refererUrl = currentRoutePath;
      })

    })
    return tempList;
  }

  disposeRecommendList(list) {
    list.forEach(vo => {
      vo.type = 3;
      // vo.isCollect = false;
      vo.proto_pic = vo.cover_img
      if (vo.childs && vo.childs > 0) {
        vo.link_url = `${PAGE_PATH.BASE_URL}${PAGE_PATH.TOPIC.COLLECTION}/${vo.id}-large.html`
      } else {
        vo.link_url = `${PAGE_PATH.BASE_URL}${PAGE_PATH.TOPIC.COLLECTION}/${vo.id}.html`
      }

      /* 延展内部第一张图片是否是ai生成图片 */
      if (Array.isArray(vo.pictures) && vo.pictures.length) {
        vo._proto_pic = `${BASE_IMG_URL} ${vo.pictures[0].proto_pic}`;
        vo.is_transform_ai = vo.pictures[0].is_transform_ai || 0
        vo.transform_show = vo.pictures[0].transform_show || {
          transform_type: 0,
          base_color: 0,
          show_type: 0,
        }
      }
    })

    return list;
  }

  /* 单张图片转成专题 */
  convertToTopicPic(data) {
    let arr = []
    arr[0] = data
    return this.disposeRecommendList(arr)
  }
}

export default new FnCollection()
