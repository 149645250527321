<template>
  <div class="page">
    <nuxt/>
  </div>
</template>

<script>


/* 引入组件 */

/* 引入VUEX */
import {mapState} from "vuex";

/* 引入API */

export default {
  name: "module"

  , components: {}

  , watch: {
    /* 登录更新详情收藏 */
    isLogin(n, o) {
      if (n === false) {
        window.location.href = '/'
      }
    },
  }
  , props: {},


  computed: {
    ...mapState('user', ['isLogin','companyInfoByPerson'])
  },

   data() {
    return {}
  }

  , methods: {}

}
</script>

<style lang="scss" scoped>

</style>
