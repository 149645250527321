import { UserBindIphoneModalMutations, UserBindIphoneModalState } from "@/components/_Privately/User/UserBindIphoneModal/module";
import {
  UserCertificationModalMutations,
  UserCertificationModalState
} from "@/components/_Privately/User/UserCertificationModal/module";

import {
  UserBindIphoneAloneModalMutations,
  UserBindIphoneAloneModalStatus
} from "@/components/_Privately/User/UserBindIphoneAloneModal/module";
import {
  setUserCheckNewProtocol20240426Mutations,
  UserCheckNewProtocol20240426State
} from "@/components/_Privately/Act/updateProtocol/module";

export default {
  namespaced: true,

  state() {
    return {
      ...UserBindIphoneModalState,
      ...UserCertificationModalState,
      ...UserBindIphoneAloneModalStatus,
      ...UserCheckNewProtocol20240426State
    }
  },

  mutations: {
    ...UserBindIphoneModalMutations,
    ...UserCertificationModalMutations,
    ...UserBindIphoneAloneModalMutations,
    ...setUserCheckNewProtocol20240426Mutations
  }
}
