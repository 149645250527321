<template>
<!--

-->
  <div
    class="showMainRiskWaringModalInfo"
  >
    <ModalMiddle
      :is-show-close="false"
      v-if="showMainRiskWaringModalInfo.show "
    >
      <div slot="ModalContent" class="modal-content ">
        <i class="iconfont icon-cha" @click="close"></i>
        <div class="vip-version-tip" >
          <div class="title">风险提示</div>
          <div class="desc">
            {{ showMainRiskWaringModalInfo.message }}
           </div>
          <div class="btn-group">
              <div class="btn btn-confirm" @click="toService">联系客户经理升级</div>
          </div>
        </div>
      </div>
    </ModalMiddle>

  </div>
</template>

<script>

import {mapState} from "vuex";
import UserController from "@/controller/userController";

export default {
  name: "showMainRiskWaringModalInfo",
  computed:{
    ...mapState('_Privately/Company',['showMainRiskWaringModalInfo']),
  },
  async mounted() {
  },
  methods: {
    toService(){

      UserController.toSerVice();
      this.close();
    },
    close(){
      this.$store.commit('_Privately/Company/setShowMainRiskWaringModalInfo',{
        show: false,
        message:'',
      });


    }
  }
}
</script>

<style lang="scss" scoped>
@import "styles/company-manage/index";

.modal-content{
  .icon-cha{
    font-size: 14px;
    color: #FFFFFF;
    position: absolute;
    right: 15px;
    top:  15px;
    cursor: pointer;
  }
}



.vip-version-tip {
  width: 342px;
  background: #424866;
  border-radius: 8px;
  padding: 0 14px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;


  .title {
    margin-bottom: 8px;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 20px;
    text-align: center;
  }

  .desc {
    font-size: 12px;
    color: #FFFFFF;
    line-height: 18px;
  }

  .btn-group {
    display: flex;
    align-items: center;
    padding: 0 14px;
    justify-content: space-between;
    margin-top: 24px;

    .btn {
      font-size: 12px;
      color: #EE5253;
      line-height: 32px;
      width: 136px;
      text-align: center;
      cursor: pointer;

      &.btn-cancel {
        width: 136px;
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.6);

        &:hover {
          background-color: rgba(238, 82, 83, 1);
          color: #FFFFFF;
          border: 1px solid rgba(238, 82, 83, 1);
        }
      }

      &.btn-confirm {
        background: #FFFFFF;
        border-radius: 16px;
        color: rgba(37, 91, 255, 1);

        &:hover {
          background-color: rgba(37, 91, 255, 1);
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>
