<template>
  <div class="UserCertification">
    <ModalMiddle v-show="userCertificationModalStatus"
                 @close="close"
    >
      <div
        slot="ModalContent"
        class="bind-iphone ModalContent">
        <div class="headline">您已完成手机号绑定验证，还需进行真实姓名</div>
        <div class="subhead">
          <i class="iconfont icon-info"/>请确保手机号与姓名在通信运营服务商一致
        </div>
        <div class="form">
          <div class="form-item form-item-iphone">
            <input
              v-model="iphone"
              type="number"
              placeholder="请输入手机号"
              @input="changeIPhone"
              @blur="verifyIphone"
            >
            <div class="form-clues">{{ verifyIphoneClues }}</div>
          </div>
          <div class="form-item form-item-code">
            <input
              v-model="code"
              placeholder="请输入真实姓名"
              @input="blurUserName"
              @blur="blurUserName"
            >
            <div class="form-clues">
              {{ verifyCodeClues }}
            </div>
          </div>
          <div
            class="form-item form-item-submit"
            @click="bindIphone">确认
          </div>
        </div>
      </div>
    </ModalMiddle>
  </div>
</template>

<script>
import _ from 'lodash'
import user from "@/store/modules/user";
import {bindIphone, getCode} from "@/api/login";
import localStorage from "@/utils/localStorage";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import Cookies from 'js-cookie'
import {MODEL_BIND_IPHONE, MODEL_LOGIN_PV_COUNT, MODEL_PIC_ID} from "@/constant/MODELS";

import {fnBindIphone, fnLocalStorage, fnLogin} from '@/fn';
import {MATERIAL_DOWNLOAD} from "@/constant/PATH";
import {getCookie} from "@/utils/auth";
import {completeTaskByFanClub} from "@/api/fans/club";
import {
  setCertificationModalStatus,
  setCertificationStatus
} from "@/components/_Privately/User/UserCertificationModal/module";
import {userCertification} from "@/api/user";

export default {
  name: "UserCertification",
  data() {
    return {
      codeText: "获取验证码",
      verifyIphoneClues: "",   // 请输入手机号 手机号格式不正确
      verifyCodeClues: "",     // 请输入验证码 验证码不正确
      verifyCodeCluesTxt: "您的手机号与真实姓名在通信运营商处提供的信息不一致，没有通过校验，请确保真实姓名与手机号匹配的唯一性",     // 请输入验证码 验证码不正确
      iphone: "",
      code: "",
      verifyStatus: false,
      countdownTime: 60,
      downTimeStatus: true,

      isBindIphone: true,
      visible: true,
      bindIphoneErr: false,
      codeForm: {
        sessionId: undefined,
        sig: undefined,
        token: undefined,
        scene: undefined,
      },
      isShow: false,
      actIsShow: false,
      showHuakuai: false,
    }
  },
  mounted() {

    // this.initHuaKuai();
    // this.checkIsBind();
  },
  computed: {
    ...mapState('_Privately/User',
      ['userCertificationModalStatus',
        'userCertificationStatus']),
    ...mapGetters('user', [
      'phone', 'uniq_id', 'vipIsPerson', 'vipIsPersonData', 'vipIsBusiness', 'vipIsBusinessData', 'avatar', 'iphone', 'isLogin'
    ]),
  },
  watch: {

    phone: {
      handler(n, o) {
        if (n.length) {
          this.iphone = this.phone;
          if (this.iphone == 0){
            this.iphone = ''
          }

        } else {
        }
      },
      immediate: true
    },

    userCertificationModalStatus: {
      handler(n, o) {
        if (n == true) {
          this.iphone = this.phone;
          if (this.iphone == 0){
            this.iphone = ''
          }
        } else {
        }
      },
      immediate: true
    },

    isShowBindIphoneModal: {
      handler(n, o) {
        if (n == false) {
          this.resetForm();
        } else {
        }
      },
      immediate: true
    },


  },
  methods: {

    checkIsBind() {
      if (fnLogin.checkLogin()) {
        if (getCookie('phone') == 0 && !this.getTodayIsBind()) {
          console.log('--手机号码未绑定--')
          try {
            // this.isShow = true;
            // this.isShowBindIphone = true;
            this.$store.commit('user/SET_IS_SHOW_BIND_IPHONE_MODAL', true)
          } catch (e) {
            console.log(e)
          }
        } else {
          this.$store.commit('user/SET_IS_SHOW_BIND_IPHONE_MODAL', false)
        }
      }
    },
    /* 获取当天本地绑定 */
    getTodayIsBind() {
      return localStorage.getItemDate(MODEL_BIND_IPHONE);
    },

    finish() {
      // this.visible = false;
      this.actIsShow = false;
      this.$store.commit('user/SET_IS_SHOW_BIND_IPHONE_MODAL', false)
      setCertificationModalStatus(this.$store, false);
      this.resetForm();
    },
    close() {
      this.isShow = false;
      this.actIsShow = false;
      // this.$store.commit('user/SET_IS_SHOW_BIND_IPHONE_MODAL', false)
      setCertificationModalStatus(this.$store, false);
      // this.visible = false;
      fnBindIphone.updateTodayBindState();
      // this.$emit('close')
    },
    changeIPhone() {
      this.iphone = this.iphone.replace(/[^\d]/g, '')
      if (this.iphone.length) {
        this.verifyIphoneClues = '';
      }
    },
    verifyIphone() {
      let temp = this.$utils.verify.iphone(this.iphone);
      let clues = ["请输入手机号", "", "手机号格式不正确"];
      this.verifyIphoneClues = clues[temp.status];
      this.verifyStatus = temp.status === 1;
      return temp.status === 1;
    },

    blurUserName() {
      console.log('--blurUserName--')
      const outputStr = _.replace(this.code, /\s/g, '');
      console.log(outputStr.length)
      this.verifyCodeClues = outputStr.length ? '' : '请输入真实姓名';
      this.verifyStatus = outputStr.length;
      return outputStr.length;
    },


    changeCode() {
      if (this.code.length > 0) {
        this.verifyCodeClues = '';
      }
    },

    resetForm() {
      this.verifyIphoneClues = '';
      this.verifyCodeClues = '';
      this.codeText = '获取验证码';
      this.iphone = ''
      this.code = ''
      this.countdownTime = 60;
      this.downTimeStatus = true;
      this.verifyStatus = true;
    },

    bindIphone() {
      /* 验证滑块 */

      console.log(this.verifyIphone())
      console.log(this.blurUserName())

      if (this.verifyIphone() && this.blurUserName()) {
        if (this.verifyStatus) {
          userCertification({
            mobile: this.iphone,
            name: this.code,
          }).then(res => {
            let {status, message} = res.data;
            if (status == 200) {
              this.$store.dispatch('user/getUserInfo')
              this.$myNotify.success(message, () => {
                setCertificationModalStatus(this.$store, false)
                setCertificationStatus(this.$store, true);
              }, 3000);
            } else if (status == 403) {
              /* 已实名 */
              this.$myNotify.success(message, () => {
                setCertificationModalStatus(this.$store, false)
                setCertificationStatus(this.$store, true);
              }, 3000);
            } else {
              this.verifyCodeClues = message;
            }
          })
        }
      }


    },

  }
}
</script>

<style lang="scss" scoped>

.ModalContent {
  width: 516px;
  height: 425px;
  background: #FFFFFF;
  box-shadow: -4px 0px 12px 0px rgba(0, 0, 26, 0.06);
  border-radius: 14px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 60px 0;
  box-sizing: border-box;

  .headline {
    height: 28px;
    font-size: 18px;
    color: #2D2B33;
    line-height: 28px;
  }

  .subhead {
    margin: 4px 0 28px;
    color: #FE9300;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;

    .icon-info {
      font-size: 16px;
      margin-right: 1px;
    }
  }

}

/*.bind-iphone-wrap {
  /deep/ .dialog-cover {
    z-index: 999 !important;
  }

  /deep/ .dialog-content {
    z-index: 1000 !important;
  }
}*/


#nc3 {
  position: relative;
  margin-bottom: 14px;
  border-radius: 8px;
  overflow: hidden;

  /deep/ #nc_2_n1z {
    border-radius: 8px !important;
    overflow: hidden;
  }

  /deep/ #nc_1_n1z {
    border-radius: 8px !important;
    overflow: hidden;
  }
}

.form {
  //margin: 21px 0 44px;
  .form-item {
    width: 330px;
    height: 50px;
    position: relative;
    margin-bottom: 30px;


    input {
      width: 100%;
      height: 100%;
      padding: 0 50px 0 20px;
      box-sizing: border-box;
      font-size: 14px;
      color: #001013;
      border: 1px solid #E0E3E9;
      border-radius: 8px;
      transition: all 0.3s ease-in;

      &::placeholder {
        color: rgba(0, 16, 19, 0.4);
      }

      &:focus {
        outline: none;
        border: 1px solid $color-primary;

      }
    }

    .text-get-code {
      height: 100%;
      line-height: 50px;
      @include Position(1, 2, 0, 50%);
      padding-right: 20px;
      transform: translateY(-50%);
    }

    .code-disabled {
      color: rgba(0, 16, 19, 0.4);
    }

    .code-on {
      color: rgba(105, 105, 255, 0.8);
      @include ElementHover(#6969FF, '');
    }


    .form-clues {
      //@include Position(1,5,0,0);
      font-size: 12px;
      color: #EE5253;
      line-height: 16px;
      padding-top: 4px;
    }
  }

  .form-item-submit {
    width: 330px;
    height: 50px;
    background: #6969FF;
    border-radius: 8px;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 50px;
    text-align: center;
    border: none;
    @include ElementHover('', #5E5EE8);
    margin-bottom: 0;
    margin-top: 44px;
  }


}

.bind-iphone-succ {
  width: 516px;
  height: 382px;
  background: #FFFFFF;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon-gou2 {
    display: flex;
    font-size: 32px;
    color: #35D0BA;
    width: 64px;
    height: 64px;
    border: 4px solid #35D0BA;
    border-radius: 50%;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    margin: 72px 0 28px;

  }

  .text-bind-succ {
    font-size: 20px;
    color: #2D2B33;
    line-height: 28px;
    margin-bottom: 8px;
  }

  .text-bind-iphone {
    font-size: 14px;
    color: #596088;
    line-height: 26px;
    //margin: 8px 0 8px;
  }

  .btn-submit {
    margin-top: 40px;
    width: 240px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #6969FF;
    border-radius: 8px;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;
    @include ElementHover('', #5E5EE8);
  }
}
</style>

