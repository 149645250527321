<template>
  <div>
    <no-ssr>
      <div class="!isLogin">
        <div class="NewUserGift" v-show="show">
          <CgModalMiddle :is-show-close="false">
            <div slot="modal_content" class="modal-content" @click="login">
              <div class="login-btn">登录即享</div>
              <i class="iconfont icon-cha" @click.stop="close"></i>
            </div>
          </CgModalMiddle>
        </div>
      </div>
    </no-ssr>
  </div>

</template>

<script>

import {mapState} from "vuex";

const STATE = {}

import {fnLogin} from "@/fn";
import localStorage from "@/utils/localStorage";
import {
  MODEL_LOGIN_GUIDE,
  MODEL_NEW_USER_GIFT,
  MODEL_NEW_USER_GIFT_DAY,
  MODEL_PV_COUNT,
  prefix
} from "@/constant/MODELS";


export default {
  name: "NewUserGift",
  data() {
    return {
      show: false
    }
  },
  mounted() {
    this.init();
  },
  ...mapState('user', ['isLogin']),
  methods: {
    init() {
      this.checkIsShow();
    },

    checkIsShow() {
      /*if (!this.getState() && !fnLogin.checkLogin()) {
        this.show = true;
        this.$store.commit('user/SET_IS_SHOW_LOGIN', false)
      }*/
      if (!fnLogin.checkLogin()) {
        let currTime = localStorage.getItemDate(MODEL_NEW_USER_GIFT);
        let date = new Date();
        if (currTime == null || currTime == undefined) {
          let _currTime = date.getTime();
          localStorage.setItemDate(MODEL_NEW_USER_GIFT, _currTime);
          // this.$store.commit('user/SET_IS_SHOW_LOGIN_HOME', true);
          this.show = true;
          this.$store.commit('user/SET_IS_SHOW_LOGIN', false)

        } else {
          let updateTime = date.getTime();
          if (updateTime - currTime > 60000) {
            // this.$store.commit('user/SET_IS_SHOW_LOGIN_HOME', true);
            this.show = true;
            this.$store.commit('user/SET_IS_SHOW_LOGIN', false)
          } else {
            this.show = false;
            // this.$store.commit('user/SET_IS_SHOW_LOGIN', false)
          }
        }
      }

    },
    close() {
      this.show = false;
      this.updateState(new Date().getTime())
      localStorage.setItemDate(MODEL_PV_COUNT, 1)
      localStorage.setItemDate(MODEL_NEW_USER_GIFT_DAY, -1)
    },
    login() {
      // download-count-modal 标记
      this.close();
      localStorage.setItemDate(MODEL_NEW_USER_GIFT_DAY, 1);
      fnLogin.loginIntercept(false)
      this.$store.commit('user/SET_IS_SHOW_LOGIN_CLOSE_BTN', true);
    },


    getState() {
      return localStorage.getItemDate(MODEL_NEW_USER_GIFT);
      // return sessionStorage.getItem(MODEL_NEW_USER_GIFT);
    },
    updateState(value = 1) {
      localStorage.setItemDate(MODEL_NEW_USER_GIFT, value);
      // sessionStorage.setItem(MODEL_NEW_USER_GIFT, value)
    },
  }
}
</script>

<style lang="scss" scoped>

.NewUserGift {
  //ftp://qianku%252Fqianku-js@v0.ftp.upyun.com/chaotu/act/new-user-gift/new-user-gift.png
  //@include BgImage(100px,100px，'act/new-user-gift/new-user-gift.png');


  .modal-content {
    //@include BgImage(100px,100px，'act/new-user-gift/new-user-gift.png');
    @include BgImage(430px, 378px, 'act/new-user-gift/new-user-gift@22-8-17.png');
    overflow: visible;
    cursor: pointer;

    .login-btn {
      width: 263px;
      height: 56px;
      background: linear-gradient(90deg, #F56060 0%, #F5734F 99%);
      box-shadow: 0px 5px 16px 0px rgba(244, 99, 93, 0.3500);
      border-radius: 28px;
      font-size: 18px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 56px;
      text-align: center;
      @include Position(1, 4, 50%, 38px);
      transform: translateX(-50%);
      @include ElementHover('', linear-gradient(90deg, #F5734F 0%, #F56060 99%))
    }

    .icon-cha {
      font-size: 17px;
      width: 38px;
      height: 38px;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 50%;
      @include LayoutFlex(1);
      cursor: pointer;
      //@include Position(1, 4, 50%, -60px);
      //transform: translateX(-50%);
      @include Position(1, 2, -52px, 0);
    }
  }
}
</style>
