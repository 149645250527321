import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {BASE_URL, FANS_CLUB} from "@/constant/_constant/CON_BASIC";

export const FANS = {

  COMMON: {

    HEADER_NAV: [
      {
        label: '会员需求池',
        link: PAGE_PATH.BASE_URL + PAGE_PATH.FANS.DEMAND_POOL
      },
      {
        label: '签到有礼',
        link: BASE_URL + FANS_CLUB
      },
      {
        label: '愿望池',
        link: ''
      },
      {
        label: '定制服务',
        link: ''
      },
    ]
  },


  /* 需求池分类 new 最新,collect 需求人数,success 完成,oneself 个人*/
  DEMAND_POOL: {

    CATEGORY_LIST: [
      {
        label: '最新',
        sort: 'new',
        id: 1
      },
      {
        label: '同求最多',
        sort: 'collect',
        id: 2
      },
      {
        label: '已完成',
        sort: 'success',
        id: 3
      },
      {
        label: '我的需求',
        sort: 'oneself',
        id: 4
      },
    ],

    STATUS: {
      0: '待审核',
      1: '生产中',
      2: '已取消',
      3: '已完成'
    }
  }
}
