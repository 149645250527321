<!--
   Header 全部下拉组件
-->
<template>
  <div class="HeaderAllDropDown container"
  >
    <!-- 供稿 -->
<!--    <a class="part-time"
       :href="PART_TIME"
       target="_blank"
       title="设计师供稿"
       @click="updateNavId"
    >设计师供稿</a>-->


    <!-- 左侧菜单栏 -->
    <div class="menu">
      <div class="menu-bar" :style="menuItemBarStyle"></div>
      <div class="menu-item"
           :class="{ 'on':  index == menuIndex }"
           v-for="(item,index) in MENU" :key="item.label"
           @click="toggleMenuItem({item,index})"
           @mouseover="toggleMenuItem({item,index});trackingNavDrop( item.act,0,'show')"
      >
        {{ item.label }}
        <i class="icon_triangle"/>
      </div>
    </div>

    <!-- 右侧内容区 -->
    <div class="content">
      <!-- 近期热门 -->
      <div class="hot-box" v-show="menuIndex == 0">
        <Headline :headline="picNum"
                  subhead="精选创意素材，正版商用无忧"/>
        <div class="hot-scene">
          <div class="hot-scene-item _topic"
          >
            <div class="topic-title title">
              <i class="icon_subscription"/>
              热搜专题
            </div>
            <div class="topic-list" v-show="dropDownDataByAll && dropDownDataByAll.hot && dropDownDataByAll.hot.topic">
              <a
                v-for="item in dropDownDataByAll.hot.topic"
                :key="item.link_url"
                :href="item.link_url"
                :title="item.title"
                target="_blank" class="topic-name __text-hover"
                @click="trackingNavDrop( 1,1,'click')"
              >
                {{ item.title }}
              </a>
            </div>
          </div>
          <div class="hot-scene-item _festival">
            <div class="title">
              节日
            </div>
            <div class="festival-list"
                 v-show="dropDownDataByAll && dropDownDataByAll.hot && dropDownDataByAll.hot.marketing_festival">
              <a
                v-for="item in dropDownDataByAll.hot.marketing_festival"
                :key="item.link_url"
                class="festival-item"
                :href="item.link_url"
                target="_blank"
                :title="item.title"
                @click="trackingNavDrop( 1,1,'click')"
              >
                <span class="festival-item-date __text-hover"> {{ item.month | repairZero }}.{{
                    item.day | repairZero
                  }}</span>
                <span class="festival-item-name overflow-one __text-hover">{{ item.title }}</span>
              </a>
            </div>
          </div>
          <div class="hot-scene-item _festival">
            <div class="title">
              24节气
            </div>
            <div class="festival-list" v-show="dropDownDataByAll.hot && dropDownDataByAll.hot.twenty_festival">
              <a
                v-for="item in dropDownDataByAll.hot.twenty_festival"
                :key="item.link_url"
                class="festival-item"
                :href="item.link_url"
                target="_blank"
                :title="item.title"
                @click="trackingNavDrop( 1,1,'click')"
              >
                <span class="festival-item-date __text-hover"> {{ item.month | repairZero }}.{{
                    item.day | repairZero
                  }}</span>
                <span class="festival-item-name overflow-one __text-hover">{{ item.title }}</span>
              </a>
            </div>
          </div>
        </div>
        <div class="hot-pic">
          <PicList v-show="dropDownDataByAll.hot && dropDownDataByAll.hot.pic"
                   :pic-list="dropDownDataByAll.hot.pic"
                   @picListClick="trackingNavDrop( 1,2,'click')"
          />
        </div>
      </div>

      <!-- 电商 -->
      <div class="business-box _contentPadding" v-show="menuIndex == 1">
        <Headline class="Headline"
                  headline="60000+"
                  subhead="原创电商素材"/>
        <div class="category-list" v-show="dropDownDataByAll.business">
          <CategoryListVertical v-show="dropDownDataByAll.business.category"
                                @CategoryListVerticalClick="trackingNavDrop( 2,1,'click')"
                                :list=" dropDownDataByAll.business.category.length > 3 ? dropDownDataByAll.business.category.slice(0,3):  dropDownDataByAll.business.category "/>
          <CategoryListVertical v-show="dropDownDataByAll.business.category"
                                @CategoryListVerticalClick="trackingNavDrop( 2,1,'click')"
                                :list="dropDownDataByAll.business.category.length > 3 ? dropDownDataByAll.business.category.slice(3):  []"/>
          <CategoryListVertical class="common_color-primary"
                                @CategoryListVerticalClick="trackingNavDrop( 2,1,'click')"
                                v-show="dropDownDataByAll.business.content"
                                :list="dropDownDataByAll.business.content"
          />
        </div>
        <PicList v-show="dropDownDataByAll.business && dropDownDataByAll.business.pic"
                 @picListClick="trackingNavDrop( 2,2,'click')"
                 :pic-list="dropDownDataByAll.business.pic"/>
      </div>

      <!-- 3D -->
      <a class="three-d-box _contentPadding" v-show="menuIndex == 2"
         :href="THREE_D"
         target="_blank"
         title="3d"
      >
        <Headline class="Headline"
                  headline="50000+"
                  subhead="高品质3D素材"/>
        <div class="seek-design">前去发现新设计</div>

        <div class="classify-wrap">
          <div class="classify-list" v-show="dropDownDataByAll.navigate_3d && dropDownDataByAll.navigate_3d.category">
            <div
              v-for="(item,idx) in dropDownDataByAll.navigate_3d.category"
              :key="idx"
              class="classify-item">
              <!--              <i class="icon"/>-->
              <div class="second-level">
                {{ item.name }}
              </div>
              <div class="three-level">
                <a
                  v-for="categoryItem in item.childs"
                  :key="categoryItem.name"
                  :title="categoryItem.name"
                  :href="TUPIAN_LIST + '/0-4-0-0-'+ categoryItem.pid +'-'+ categoryItem.id +'-0-default-1.html' "
                  target="_blank"
                  @click.stop="trackingNavDrop( 3,1,'click')"
                >
                  {{ categoryItem.name }}
                </a>
              </div>
            </div>
          </div>

        </div>
        <PicList v-show="dropDownDataByAll.navigate_3d && dropDownDataByAll.navigate_3d.recommend"
                 :pic-list="dropDownDataByAll.navigate_3d.recommend"
                 @picListClick="trackingNavDrop( 3,2,'click')"
        />
      </a>

      <!-- 节日节气 -->
      <div class="festival-box" v-show="menuIndex == 3">
        <FestivalTab ref="FestivalTab"
                     @toggleFestivalMonth="toggleFestivalMonth"
        />
        <div class="pic-list">
          <FestivalPicList v-show="dropDownDataByAll.festival && currentMonth  > 0"
                   @picListClick="trackingNavDrop( 4,2,'click')"
                   :pic-list="dropDownDataByAll.festival[currentMonth]"/>
        </div>
      </div>

      <!-- 平面 -->
      <div class="plane-box _contentPadding" v-show="menuIndex == 4">
        <Headline class="Headline"
                  subhead="优质营销宣传素材"/>
        <div class="category-list" v-show="dropDownDataByAll.plane">
          <CategoryListVertical v-show="dropDownDataByAll.plane.category"
                                @CategoryListVerticalClick="trackingNavDrop(5,1,'click')"
                                :list=" dropDownDataByAll.plane.category.length > 4 ? dropDownDataByAll.plane.category.slice(0,4):  dropDownDataByAll.business.category "/>
          <CategoryListVertical v-show="dropDownDataByAll.plane.category"
                                @CategoryListVerticalClick="trackingNavDrop(5,1,'click')"
                                :list="dropDownDataByAll.plane.category.length > 4 ? dropDownDataByAll.plane.category.slice(4):  []"/>
          <CategoryListVertical class="common_color-primary"
                                @CategoryListVerticalClick="trackingNavDrop(5,1,'click')"
                                v-show="dropDownDataByAll.plane.content"
                                :list="dropDownDataByAll.plane.content"
          />
        </div>
        <PicList v-show="dropDownDataByAll.plane && dropDownDataByAll.plane.content"
                 @picListClick="trackingNavDrop( 5,2,'click')"
                 :pic-list="dropDownDataByAll.plane.pic"/>
      </div>

      <!-- PPT -->
      <div class="three-d-box _contentPadding" v-show="menuIndex == 5">
        <Headline class="Headline"
                  subhead="商务精选PPT"/>
        <div class="classify-wrap">
          <div class="classify-list">
            <div
              v-show="dropDownDataByAll.ppt && dropDownDataByAll.ppt.scene"
              class="classify-item">
              <i class="icon"/>
              <div class="second-level">
                热门场景
              </div>
              <div class="three-level">
                <a
                  v-for="item in dropDownDataByAll.ppt.scene"
                  :key="item.name"
                  :title="item.name"
                  :href="item._link"
                  target="_blank"
                  @click="trackingNavDrop(6,1,'click')"
                >
                  {{ item.name }}
                </a>
              </div>
            </div>

            <div
              v-show="dropDownDataByAll.ppt && dropDownDataByAll.ppt.category"
              class="classify-item">
              <i class="icon"/>
              <div class="second-level">
                类型
              </div>
              <div class="three-level">
                <a
                  v-for="item in dropDownDataByAll.ppt.category"
                  :key="item.name"
                  :title="item.name"
                  :href="item._link"
                  target="_blank"
                  @click="trackingNavDrop(6,1,'click')"
                >
                  {{ item.name }}
                </a>
              </div>
            </div>

          </div>

        </div>
        <PicList v-show="dropDownDataByAll.ppt && dropDownDataByAll.ppt.pic"
                 :pic-list="dropDownDataByAll.ppt.pic"
                 @picListClick="trackingNavDrop( 6,2,'click')"
        />

      </div>

      <!-- 流行风格 -->
      <div class="style-box _contentPadding" v-show="menuIndex == 6">
        <Headline class="Headline"
                  subhead="集结当下潮流风格，探索设计前沿"/>

        <div class="category-list">
          <CategoryListVertical v-show="dropDownDataByAll.design && dropDownDataByAll.design.design"
                                @CategoryListVerticalClick="trackingNavDrop(7,1,'click')"
                                :list="dropDownDataByAll.design.design"/>
        </div>

        <PicList v-show="dropDownDataByAll.design && dropDownDataByAll.design.recommendPic"
                 @picListClick="trackingNavDrop( 7,2,'click')"
                 :pic-list="dropDownDataByAll.design.recommendPic"
        />

      </div>
    </div>

  </div>
</template>
<script>
import {
  disposeDropDownData,
  FESTIVAL_MONTH,
  MENU,
} from "@/components/_Public/Header/HeaderAllDropDown/module";
import {fnFlowStatistics, fnMaterial} from "@/fn";
import PicList from "@/components/_Public/Header/HeaderAllDropDown/PicList";
import Headline from "@/components/_Public/Header/HeaderAllDropDown/Headline";
import CategoryListVertical from "@/components/_Public/Header/HeaderAllDropDown/CategoryListVertical";
import FestivalTab from "@/components/_Public/Header/HeaderAllDropDown/FestivalTab";
import FestivalPicList from "@/components/_Public/Header/HeaderAllDropDown/FestivalPicList";
import {mapGetters, mapState} from "vuex";
import {PART_TIME, THREE_D, TUPIAN_LIST} from "@/constant/_constant/CON_BASIC";
import {TrackingNav} from "~/tracking";
import _ from 'lodash'

export default {
  name: "HeaderAllDropDown",
  components: {
    PicList,
    Headline,
    CategoryListVertical,
    FestivalTab,
    FestivalPicList
  },
  computed: {
    menuItemBarStyle() {
      return {'top': 12 + this.menuIndex * 40 + 'px'}
    },
    ...mapGetters('_Public/Header', ['dropDownDataByAll']),
  },
  data() {
    return {
      MENU,
      FESTIVAL_MONTH,
      TUPIAN_LIST,
      menuIndex: 3,
      picNum: 0,
      currentMonth: 0,
      THREE_D,
      PART_TIME

    }
  },
  mounted() {
    this.init();
  },
  methods: {

    init() {
      this.initPicNum();
      this.initGetCurrentMonth();
    },

    async initPicNum() {
      this.picNum = await fnMaterial.getMaterialNum() + '+';
    },

    initGetCurrentMonth() {
      this.currentMonth = this.$refs.FestivalTab.getCurrentMonth();
    },

    /* 切换月 */
    toggleFestivalMonth(monthItem) {
      this.currentMonth = monthItem.key;
    },

    toggleMenuItem(menu) {
      const {index, item} = menu;
      setTimeout(()=>{
        this.menuIndex = index;
      },100)
    },

    trackingNavDrop: _.throttle(function (act, pos, e) {
      TrackingNav.navDrop(act, pos, e)
    }, 3000),

    updateNavId(navId){
      fnFlowStatistics.pageFlow('draft')
    },
  },
  filters: {
    repairZero(val) {
      val = parseInt(val)
      return val < 10 ? ('0' + Number(val)) : val;
    }
  },
}
</script>

<style lang="scss" scoped>
@import "index";
</style>
