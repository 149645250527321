import request from '@/utils/request'
import qs from 'qs'

/* 素材搜索转拼音 */
export function keywordConvertPinyin(query) {
  return request({
    url: '/?m=Search&a=pinYin',
    method: 'get',
    params: query
  })
}

export function setKeyword(query) {
  return request({
    url: '/?m=Api&a=setSearchCookie',
    method: 'get',
    params: query
  })
}

/* 素材列表 */
export function getMaterialList(query) {
  return request({
    url: '/?m=Api&a=getList',
    method: 'get',
    params: query
  })
}

export function getMaterialClassify(query) {
  return request({
    url: '/?m=Api&a=getCategory',
    method: 'get',
    params: query
  })
}

/* 素材列表keyword cookie埋点 */
export function materialKeywordTrack(query) {
  return request({
    url: '/?m=Api&a=setSearchCookie',
    method: 'get',
    params: query
  })
}

/* 素材详情 */
export function getMaterialDetail(query) {
  return request({
    url: '/?m=Api&a=getDetail',
    method: 'get',
    params: query
  })
}

export function getMaterialDetail2(query) {
  return request({
    url: '/?m=Creator&a=lastUploadLog',
    method: 'get',
    params: query
  })
}

/* PPT详情测试 */
export function getMaterialDetailPPT(query) {
  return request({
    url: '/?m=Picture&a=test',
    method: 'get',
    params: query
  })
}


/* 素材详情浏览等信息 */
export function getMaterialDetailInfo(query) {
  return request({
    url: '/?m=Problem&a=picStatistics',
    method: 'get',
    params: query
  })
}

/* 详情页浏览 */
export function glanceDetail(query) {
  return request({
    url: '/?m=Api&a=glance',
    method: 'get',
    params: query
  })
}

/* 首页素材列表 */
export function getHomeMaterialList(query) {
  return request({
    url: '/?m=Api&a=getIndexPic',
    method: 'get',
    params: query
  })
}

/* 检测单张图片是否收藏 */
export function getPicCollectStatus(query) {
  return request({
    url: '/?m=Picture&a=isCollected',
    method: 'get',
    params: query
  })
}


/* 首页素材列表查看更多 */
export function getHomeMaterialListMore(query) {
  return request({
    url: '/?m=Api&a=moreNew',
    method: 'get',
    params: query
  })
}

/* 获取素材套图列表 */
export function getMaterialSuite(query) {
  return request({
    url: '/?m=Api&a=getSetPic',
    method: 'get',
    params: query
  })
}

/* 更多潮图 */
export function getTrendList(query) {
  return request({
    url: '/?m=Api&a=recommend',
    method: 'get',
    params: query
  })
}

/* 素材详情收藏切换 */
export function toggleMaterialCollect(query) {
  return request({
    url: '/?m=Api&a=collect',
    method: 'get',
    params: query
  })
}

/* 时间轴 */
export function getTimeline(query) {
  return request({
    url: '/?m=Api&a=timeLine',
    method: 'get',
    params: query
  })
}

/* 新版时间轴 */
export function getTimelineV2(query) {
  return request({
    url: '/?m=Api&a=timeLineV2',
    method: 'get',
    params: query
  })
}

/* 素材免费下载次数 */
export function getMaterialFreeDownloadNum(query) {
  return request({
    url: '/?m=Api&a=checkFreeDown',
    method: 'get',
    params: query
  })
}

/* 图片包列表 */
export function getPicPackageList(query) {
  return request({
    url: '/?m=Api&a=package',
    method: 'get',
    params: query
  })
}

export function downStat(query) {
  return request({
    url: '/?m=Api&a=downStat',
    method: 'get',
    params: query
  })
}


/* 图片素材举报 */
export function picReport(data) {
  return request({
    url: '/?m=Problem&a=picture',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 图片下载拦截弹框埋点统计 */
export function confirmDownStat(query) {
  return request({
    url: '/?m=Api&a=confirmDown',
    method: 'get',
    params: query
  })
}

/* 每日更新素材量 */
export function updateMaterialNum(query) {
  return request({
    url: '/?m=Auxfun&a=statistics',
    method: 'get',
    params: query
  })
}

/* 列表专题推荐 */
export function getTupianListMoreTopic(query) {
  return request({
    url: '/?m=Topic&a=materialBottom',
    method: 'get',
    params: query
  })
}

/* 详情专题推荐 */
export function detailTopicList(query) {
  return request({
    url: '/?m=Topic&a=picBottom',
    method: 'get',
    params: query
  })
}

/* 素材一级分类 */
export function getPicCategory(query) {
  return request({
    url: '/?m=Api&a=getPicCategory',
    method: 'get',
    params: query
  })
}



export function getPicFreeDownCoupon(query) {
  return request({
    url: '/?m=Coupon&a=freeDown',
    method: 'get',
    params: query
  })
}


export function getListByTest(query) {
  return request({
    url: '/?m=Picture&a=testList',
    method: 'get',
    params: query
  })
}
