<template>
  <div class="CompanyMain">
    <slot name="CompanyMain" class="CompanyMainSlot"/>

<!--    <Menu @backToTop="backTop"/>-->
  </div>
</template>

<script>

import {
  Menu
} from '@/components/company/layout'

export default {
  name: "CompanyMain",
  components: {
    Menu,
  },
  methods:{
    backTop(){
      console.log(222222222)
//backTop点击时间
      var _wrapper=document.querySelector(".CompanyMain");//获取wrapper
      var wrapperY=_wrapper.scrollTop;	//获取Y轴滚动的距离
      var id=setInterval(function(){		//定时器函数
        if(wrapperY<=0){				//如果wrapperY轴小于等于0
          clearInterval(id);			//停止计时器
        }
        wrapperY-=5;
        _wrapper.scrollTop=wrapperY;
      },10);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "index";

.CompanyMain {
  //position: fixed;
  left: $sidebarWidth;
  top: $headerHeight;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #FFFFFF;
  margin:  $headerHeight 0 0 $sidebarWidth;
  height: calc(100vh - 72px);
}

</style>
