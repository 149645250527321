import Cookies from "js-cookie";
import {setCustomCookie} from "@/utils/auth";


export default {
  namespaced: true,
  state() {
    return {
      searchVal: Cookies.get('searchVal') || '',  // 素材列表搜索值
      isShowListBar: false,
      isShowCaseCollectionBar: false,
      isBoot: false,
      header: {},
      isShowIntroduceDialog: false,
      isShowPicFreeDownCouponDialog: false,


      spider: false,
    }
  },

  mutations: {
    SET_SEARCH_VAL(state, payload) {
      state.searchVal = payload;
      setCustomCookie('searchVal', payload);
    },

    SET_IS_SHOW_LIST_BAR(state, payload) {
      state.isShowListBar = payload;
    },
    SET_IS_SHOW_CASE_COLLECTION_BAR(state, payload) {
      state.isShowCaseCollectionBar = payload;
    },

    SET_IS_BOOT(state, payload) {
      state.isBoot = payload;
    },

    SET_HEADER(state, payload) {
      state.header = payload;
    },

    /* 设置介绍框是否显示 */
    SET_INTRODUCE_DIALOG(state, payload) {
      state.isShowIntroduceDialog = payload;
    },

    /* 设置介绍框是否显示 */
    SET_PIC_FREE_DOWN_COUPON_DIALOG(state, payload) {
      state.isShowPicFreeDownCouponDialog = payload;
    },

    SET_SPIDER(state, payload) {
      state.spider = payload;
    },
  },
  actions: {},
}
