<template>
  <div v-if="showBlackModal">
    <ModalMiddle @close="close">
      <div slot="ModalContent" class="ModalContent">
        <div class="title">您当前账号异常，无法进行任何操作</div>
        <a :href="SERVICE_LINK" target="_blank" class="btn">疑问联系在线客服</a>
      </div>
    </ModalMiddle>
  </div>
</template>

<script>
import {SERVICE_LINK} from "@/constant/_Public/Path";
import {mapState} from "vuex";

export default {
  name: "BlackModal",
  computed: {
    ...mapState('Modal', ['showBlackModal'])
  },
  data(){
    return{
      SERVICE_LINK
    }
  },
  methods:{
    close(){
      this.$store.commit('Modal/SET_BLANK_MODAL', false)
    }
  }
}
</script>

<style lang="scss" scoped>

.ModalContent {
  width: 500px;
  height: 300px;
  background: #FFFFFF;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 20px;
    margin: 80px 0 60px;
    font-weight: bold;
  }
  .btn{
    display: inline-block;
    background: $color-primary;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 50px;
    padding: 0 80px;
    border-radius: 20px;
  }
}

</style>
